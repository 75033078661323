import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
} from "@volley/data";
import { Tag } from "@volley/data";
import type { CuratedWorkoutConfig } from "@volley/shared/apps/curated-workout-models";
import type { JSONObject } from "@volley/shared/common-models";

// eslint-disable-next-line import/no-cycle
import { getApp } from "..";
import fetchApi, {
    logFetchError,
    pairedFetchApi,
} from "../../../../../util/fetchApi";
import {
    CoordLike,
    CoordWithSys,
    PositionLike,
} from "../../../../../util/position-types";
import { pluralize } from "../../../../../util/text";
import Loading from "../../../../common/Loading";
import {
    VisualizerShot,
    WorkoutForVisualizer,
} from "../../../../common/Visualizer/types";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import DetailsAccordion from "../../Accordions/Details";
import HeightAccordion from "../../Accordions/HeightAccordion";
import PlayerAccordion from "../../Accordions/Player";
import TouchOnlyPositionAccordion from "../../Accordions/TouchOnlyPosition";
import WorkoutVisualizerAccordion from "../../Accordions/VisualizerAccordion";
import PublishButton from "../../ContentManagement/PublishButton";
import Tagging from "../../Shared/Tagging";
import WorkoutDeleteConfirmation from "../../Shared/WorkoutDeleteConfirmation";
import useAppWorkouts, { appWorkoutToUpdate, isAppWorkout } from "../../db";
import makeSafeSpinLevel from "../util";

import ParamsAccordion from "./ParamsAccordion";
import ShotsAccordion from "./ShotAccordion";
import ProblemsDialog from "./play/ProblemsDialog";

export default function AppWorkoutEditWizard(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const { physicsModelName } = usePhysicsModelContext();
    const { status } = useStatus();
    const playState = status?.workouts.playState ?? "stopped";
    const { selected: selectedSport, getDefaultPosition } = useSelectedSport();
    const currentWorkoutId = status?.workouts.currentWorkout?.id;
    const trainerId = status?.clientId;
    const navigate = useNavigate();
    const {
        error: workoutError,
        loading,
        addWorkout,
        deleteWorkout,
        getWorkout,
        updateWorkout,
    } = useAppWorkouts();
    const { liftRange, safeHeight, setHeight, stop } = useLift();
    const numericId = parseInt(id ?? "", 10);
    const [workout, setWorkout] = React.useState<
        AppWorkout | AppWorkoutCreatePayload | null
    >(null);
    const [toDelete, setToDelete] = React.useState<
        AppWorkout | AppWorkoutCreatePayload | null
    >(null);
    const [workoutProblems, setWorkoutProblems] = React.useState<string[]>([]);
    const [paddingProblems, setPaddingProblems] = React.useState<string | null>(
        null,
    );
    type CompletionButton = "Save" | "Play" | "Publish" | null;

    const [tagsOpen, setTagsOpen] = React.useState(false);
    const [selectedTags, setSelectedTags] = React.useState<Record<
        number,
        Tag[]
    > | null>(null);

    const updateConfig = React.useCallback(
        (config: CuratedWorkoutConfig) => {
            if (workout) {
                const updated = {
                    ...(workout.config as CuratedWorkoutConfig),
                    ...config,
                };
                setWorkout({
                    ...workout,
                    config: updated as unknown as JSONObject,
                });

                const matches = config.shots?.filter((s) => {
                    if (s.intervalOverride && config.interval) {
                        return s.intervalOverride <= config.interval;
                    }

                    return false;
                });

                if (matches?.length) {
                    const s = pluralize(matches.length, "shot");
                    const hasOrHave = matches.length === 1 ? "has" : "have";
                    setPaddingProblems(
                        `${matches.length} ${s} ${hasOrHave} a padded time that may need adjusted`,
                    );
                } else {
                    setPaddingProblems(null);
                }
            }
        },
        [workout, setWorkout],
    );

    const saveWorkout = React.useCallback(
        async (
            changed: Partial<AppWorkout>,
            completionButton: CompletionButton = "Save",
            publishTo: number | null = null,
        ) => {
            const problems: string[] = [];
            const cc = changed.config as unknown as CuratedWorkoutConfig;

            if (cc.shots === undefined || cc.shots.length === 0) {
                problems.push(
                    "There are no shots configured for this workout.",
                );
            }
            if (
                changed.positionHeight === undefined ||
                changed.positionHeight < liftRange.min ||
                changed.positionHeight > liftRange.max
            ) {
                const err = `The height is not set or is out the trainers range. [${liftRange.min}/${liftRange.max}]`;
                problems.push(err);
            }
            if (
                changed.positionX === undefined ||
                changed.positionY === undefined ||
                changed.positionYaw === undefined
            ) {
                const err = "The trainer position has not been set";
                problems.push(err);
            }
            if (changed.name === undefined || changed.name === "") {
                problems.push("The workout name has not been set.");
            }
            if (cc.playerPosition === undefined) {
                problems.push("The player position has not been set.");
            }

            if (problems.length > 0) {
                setWorkoutProblems(problems);
            } else {
                let workoutId;
                const publishToId =
                    completionButton === "Publish"
                        ? publishTo
                        : changed.contentProviderId;
                const originalShots = cc.shots ?? [];
                const shots = originalShots.map((shot) => {
                    const { launchSpeed, spinLevel, spinDirection } = shot;
                    const safeSpinLevel = makeSafeSpinLevel(
                        spinLevel ?? 0,
                        spinDirection,
                        launchSpeed,
                    );
                    return {
                        ...shot,
                        spinLevel: safeSpinLevel,
                    };
                });
                cc.shots = shots;
                const config = cc as unknown as JSONObject;
                const toSave: Partial<AppWorkout> = {
                    ...changed,
                    config,
                    contentProviderId: publishToId,
                };
                if (changed.id === undefined) {
                    const updated = await addWorkout(
                        toSave as AppWorkoutCreatePayload,
                    );
                    setWorkout(updated);
                    workoutId = updated?.id;
                } else {
                    const asUpdate = appWorkoutToUpdate(toSave as AppWorkout);
                    const updated = await updateWorkout(asUpdate);
                    setWorkout(updated);
                    workoutId = updated?.id;
                }

                if (selectedTags) {
                    try {
                        const tagIds = Object.values(selectedTags).flatMap(
                            (t) => t.map((tag) => tag.id),
                        );
                        await fetchApi(
                            `/api/app-workouts/${workoutId}/tags`,
                            "PUT",
                            {
                                tagIds,
                            },
                        );
                    } catch (e) {
                        logFetchError(e, "Failed to update tags");
                    }
                }

                if (completionButton === "Save") {
                    navigate(-1);
                }
                if (completionButton === "Play") {
                    if (workoutId) {
                        navigate(`../play/4/${workoutId}`, { replace: true });
                    } else {
                        navigate(-1); // unlikely fallback
                    }
                }
            }
        },
        [
            liftRange.min,
            liftRange.max,
            selectedTags,
            addWorkout,
            updateWorkout,
            navigate,
        ],
    );

    const fetchWorkout = React.useCallback(async () => {
        const result = await getWorkout(numericId);
        setWorkout(result);
        if (result?.workoutTags.length) {
            const workoutTags: Record<number, Tag[]> = {};
            result.workoutTags.forEach((wt) => {
                if (!Object.hasOwn(workoutTags, wt.tag.tagCategoryId)) {
                    workoutTags[wt.tag.tagCategoryId] = [];
                }
                workoutTags[wt.tag.tagCategoryId].push(wt.tag);
            });
            setSelectedTags(workoutTags);
        }
    }, [numericId, getWorkout, setWorkout]);

    React.useEffect(() => {
        if (numericId) {
            void fetchWorkout();
        } else {
            let defaultPosition = getDefaultPosition();
            if (selectedSport === "PLATFORM_TENNIS") {
                // convert to mm
                defaultPosition = {
                    x: 3048,
                    y: 14215.5,
                    yaw: defaultPosition.yaw,
                    sys: "court",
                };
            }
            const newInstance: AppWorkoutCreatePayload = {
                appId: 4,
                appName: "Curated Workout",
                config: {
                    shotCount: 60,
                    interval: 3,
                    shots: [],
                },
                contentProviderId: null,
                copiedFromAppWorkoutId: null,
                description: "",
                name: "",
                extendedData: null,
                overview: "",
                physicsModelName,
                positionHeight: safeHeight,
                positionX: defaultPosition.x,
                positionY: defaultPosition.y,
                positionYaw: defaultPosition.yaw ?? 0,
                sport: {
                    name: selectedSport,
                },
                state: "BUILD",
            };
            setWorkout(newInstance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [physicsModelName, numericId, fetchWorkout]);

    const [expanded, setExpanded] = React.useState<string | false>("details");

    const handlePanelChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleSaveClick = React.useCallback(
        async (completionButton: CompletionButton = "Save") => {
            // TODO: Remove this after implementing logic to update parameters on un-pause in coach
            // If a user is editing a workout, we're assuming that they may play this workout
            // If there is a workout already running with this same ID, we should stop it so when the
            // user plays again, coach will use the updated parameters
            if (
                playState !== "stopped" &&
                workout !== null &&
                isAppWorkout(workout) &&
                currentWorkoutId === workout.id &&
                trainerId
            ) {
                await pairedFetchApi(
                    trainerId,
                    "/api/apps/workouts/stop",
                    "POST",
                );
            }

            if (workout !== null) {
                await saveWorkout(workout, completionButton);
            }
        },
        [playState, currentWorkoutId, workout, trainerId, saveWorkout],
    );

    const handleDeleteConfirmed = React.useCallback(async () => {
        if (!loading && toDelete && isAppWorkout(toDelete)) {
            setToDelete(null);
            await deleteWorkout(toDelete as AppWorkout);
        }
        setWorkout(null);
        navigate(-1);
    }, [loading, toDelete, deleteWorkout, navigate]);

    const selectedPosition = React.useMemo<CoordWithSys | undefined>(() => {
        if (workout === null) {
            return undefined;
        }

        let defaultPosition = getDefaultPosition();
        if (selectedSport === "PLATFORM_TENNIS") {
            // convert to mm
            defaultPosition = {
                x: 3048,
                y: 14215.5,
                yaw: defaultPosition.yaw,
                sys: "court",
            };
        }

        if (
            workout.positionX === undefined &&
            workout.positionY === undefined &&
            workout.positionYaw === undefined
        ) {
            return defaultPosition;
        }

        return {
            x: workout.positionX ?? defaultPosition.x,
            y: workout.positionY ?? defaultPosition.y,
            yaw: workout.positionYaw ?? defaultPosition.yaw,
            sys: selectedSport === "PLATFORM_TENNIS" ? "court" : "physics",
        };
    }, [workout, selectedSport, getDefaultPosition]);

    const workoutForVisualizer: WorkoutForVisualizer = React.useMemo(() => {
        const defaultPosition = {
            player: [] as CoordLike[],
            trainer: {
                x: 0,
                y: 0,
                yaw: 0,
                heightIn: liftRange.min,
            },
            shots: [] as VisualizerShot[],
        };

        if (workout === null) {
            return defaultPosition;
        }

        defaultPosition.trainer = {
            x: workout.positionX ?? 0,
            y: workout.positionY ?? 0,
            heightIn: workout.positionHeight ?? liftRange.min,
            yaw: workout.positionYaw ?? 0,
        };

        const config = workout.config as CuratedWorkoutConfig;

        if (config === undefined) {
            return defaultPosition;
        }

        const { playerPosition, shots } = config;

        if (playerPosition) {
            defaultPosition.player = [playerPosition];
        }

        if (shots) {
            defaultPosition.shots = shots.map((s) => ({
                launchSpeed: s.launchSpeed,
                pan: s.pan,
                spinDirection: s.spinDirection,
                tilt: s.tilt,
                spinLevel: s.spinLevel,
                spinSpeed: s.spinSpeed,
            }));
        }

        return defaultPosition;
    }, [liftRange, workout]);

    const plannedPosition = React.useMemo(() => {
        // explicit check for undefined on yaw because yaw is very commonly 0
        if (
            !workout?.positionX ||
            !workout.positionY ||
            workout.positionYaw === undefined
        ) {
            return undefined;
        }

        return {
            x: workout.positionX,
            y: workout.positionY,
            yaw: workout.positionYaw,
        };
    }, [workout]);

    // Potential network error loading workout, let user retry or go back
    if (workout === null && workoutError !== null) {
        return (
            <Stack spacing={4} textAlign="center">
                <Typography variant="h3">
                    There was an error loading your workout:
                </Typography>
                <Typography variant="h4">{workoutError}</Typography>
                <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => window.location.reload()}
                >
                    Reload to Try Again
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => navigate("../", { relative: "route" })}
                >
                    Back to Workout List
                </Button>
            </Stack>
        );
    }

    // No error loading, but workout isn't loaded yet, show loading
    if (workout === null && workoutError === null) {
        return <Loading />;
    }

    if (workout === null) {
        return (
            <Stack spacing={4} textAlign="center">
                <Typography variant="h3">
                    There was an unexpected error loading your workout.
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() =>
                        navigate("../", { replace: true, relative: "route" })
                    }
                >
                    Back to Workout List
                </Button>
            </Stack>
        );
    }

    const app = getApp(4);

    if (app === null) {
        return (
            <Stack spacing={4} textAlign="center">
                <Typography variant="h3">
                    There was an error with your workout&apos;s configuration.
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => navigate("../", { relative: "route" })}
                >
                    Back to Workout List
                </Button>
            </Stack>
        );
    }

    return (
        <Stack>
            <DetailsAccordion
                expanded={expanded === "details"}
                onChange={handlePanelChange("details")}
                name={workout.name ?? ""}
                onNameChanged={(n) =>
                    setWorkout({
                        ...workout,
                        name: n,
                    })
                }
                overview={workout.overview ?? ""}
                onOverviewChanged={(overview) =>
                    setWorkout({
                        ...workout,
                        overview,
                    })
                }
            />
            <TouchOnlyPositionAccordion
                expanded={expanded === "position"}
                onChange={handlePanelChange("position")}
                selectedPosition={selectedPosition as PositionLike}
                onPositionChanged={(p) =>
                    setWorkout({
                        ...workout,
                        positionX: p.x,
                        positionY: p.y,
                        positionYaw: p.yaw,
                    })
                }
            />
            <PlayerAccordion
                expanded={expanded === "player"}
                onChange={handlePanelChange("player")}
                selectedTrainerPosition={selectedPosition as PositionLike}
                selectedPlayerPosition={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        .playerPosition
                }
                onPlayerPositionChanged={(p) =>
                    updateConfig({
                        ...(workout.config as CuratedWorkoutConfig),
                        playerPosition: p,
                    })
                }
            />
            <HeightAccordion
                expanded={expanded === "height"}
                onChange={handlePanelChange("height")}
                selectedHeight={workout.positionHeight ?? safeHeight}
                onHeightChanged={(h) =>
                    setWorkout({
                        ...workout,
                        positionHeight: h,
                    })
                }
                moveToHeight={() =>
                    setHeight(workout.positionHeight ?? safeHeight)
                }
            />
            <ShotsAccordion
                defaultInterval={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        ?.interval ?? 2
                }
                plannedPosition={plannedPosition}
                height={workout.positionHeight ?? safeHeight}
                expanded={expanded === "shots"}
                onChange={handlePanelChange("shots")}
                selectedShots={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        ?.shots ?? []
                }
                onSelectedShotsChanged={(shots) =>
                    updateConfig({
                        ...(workout.config as CuratedWorkoutConfig),
                        shots,
                    })
                }
                randomize={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        .randomize ?? false
                }
                onRandomizeChange={(randomize) =>
                    updateConfig({
                        ...(workout.config as CuratedWorkoutConfig),
                        randomize,
                    })
                }
            />
            <ParamsAccordion
                expanded={expanded === "feed"}
                onChange={handlePanelChange("feed")}
                selectedInterval={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        ?.interval ?? 2
                }
                onIntervalChanged={(i) =>
                    updateConfig({
                        ...(workout.config as CuratedWorkoutConfig),
                        interval: i,
                    })
                }
                selectedShotCount={
                    (workout.config as unknown as CuratedWorkoutConfig)
                        ?.shotCount ?? 20
                }
                onShotCountChanged={(s) =>
                    updateConfig({
                        ...(workout.config as CuratedWorkoutConfig),
                        shotCount: s,
                    })
                }
                validationError={paddingProblems ?? undefined}
            />
            <WorkoutVisualizerAccordion
                expanded={expanded === "Visualizer"}
                onChange={handlePanelChange("Visualizer")}
                trainerPosition={workoutForVisualizer.trainer}
                playerPosition={workoutForVisualizer.player[0]}
                playMode="standard"
                selectedSport={selectedSport}
                shots={workoutForVisualizer.shots}
            />
            <Box
                component="div"
                sx={{
                    marginTop: "15px",
                }}
            >
                <Stack spacing={2}>
                    <Button
                        color="secondary"
                        onClick={() => handleSaveClick("Play")}
                        variant="contained"
                        fullWidth
                    >
                        Play
                    </Button>
                    <Button
                        onClick={() => setTagsOpen(true)}
                        fullWidth
                        variant="contained"
                    >
                        Set Tags
                    </Button>
                    <PublishButton
                        workout={workout}
                        saveFunction={(publishId) =>
                            saveWorkout(workout, "Publish", publishId)
                        }
                    />
                    <Button
                        color="info"
                        onClick={() => handleSaveClick()}
                        variant="contained"
                        fullWidth
                    >
                        Save & Close
                    </Button>
                    <Button
                        onClick={() => {
                            if (!Object.hasOwn(workout, "id")) {
                                navigate("../", { relative: "route" });
                            } else {
                                setToDelete(workout);
                            }
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {Object.hasOwn(workout, "id")
                            ? "Delete Workout"
                            : "Cancel Creation"}
                    </Button>
                </Stack>
            </Box>
            <LiftModal
                targetHeight={workout.positionHeight ?? safeHeight}
                stop={() => stop()}
            />
            <ProblemsDialog
                open={workoutProblems.length > 0}
                problems={workoutProblems}
                cancel={() => setWorkoutProblems([])}
            />
            <WorkoutDeleteConfirmation
                workout={toDelete as AppWorkout}
                onCancel={() => setToDelete(null)}
                onConfirm={() => handleDeleteConfirmed()}
            />
            <Tagging
                open={tagsOpen}
                onCancel={() => setTagsOpen(false)}
                onFinish={(updated) => {
                    setSelectedTags(updated);
                    setTagsOpen(false);
                }}
                selectedTags={selectedTags || {}}
            />
        </Stack>
    );
}
