import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import logger from "../log";
import theme from "../theme";

import Auth from "./Auth";
import BallCabinetUnlock from "./BallCabinetUnlock";
import DialogProvider from "./Dialog/DialogProvider";
import HorizontalScrollHome from "./HorizontalScrollHome";
import Login from "./Login";
import Sessions from "./Sessions";
import PairDialog from "./Sessions/Pair/PairDialog";
import StackIndicator from "./StackIndicator";
import Tutorials from "./Tutorials/Tutorials";
import Loading from "./common/Loading";
import ReactLazyError from "./common/ReactLazyError";
import RequireAdmin from "./common/RequireAdmin";
import RequireAuth from "./common/RequireAuth";
import useScrollToTop from "./common/ScrollToTop";
import UserPage from "./common/UserPage";
import { SportProvider } from "./common/context/sport";
import { CurrentUserProvider } from "./hooks/currentUser";
import { DebugProvider } from "./hooks/debugView";
import { PairingProvider } from "./hooks/pairingStatus";
import { StatusProvider } from "./hooks/status";
import { LocalizationProvider } from "./hooks/usePosition";
import { TrainerFeatureProvider } from "./hooks/useTrainerFeatures";
import { UpdateProvider } from "./hooks/useUpdate";
import { UserEventsProvider } from "./hooks/useUserEvents";

function handleImportError(e: Error) {
    logger.error(`Error lazy loading component: ${e.message}`, undefined, e);
    return { default: () => <ReactLazyError /> };
}

const Account = React.lazy(() => import("./Account").catch(handleImportError));
const Admin = React.lazy(() => import("./Admin").catch(handleImportError));
const PlayerPTI = React.lazy(() =>
    import("./PTI/PlayerPTI").catch(handleImportError),
);
const Signup = React.lazy(() => import("./Signup").catch(handleImportError));
const Trainer = React.lazy(() => import("./Trainer").catch(handleImportError));

function ContextProviders({ children }: React.PropsWithChildren): JSX.Element {
    useScrollToTop();
    return (
        <CurrentUserProvider>
            <PairingProvider>
                <DebugProvider>
                    <StatusProvider>
                        <SportProvider>
                            <TrainerFeatureProvider>
                                <LocalizationProvider>
                                    <UpdateProvider>
                                        <DialogProvider>
                                            <UserEventsProvider>
                                                {children}
                                            </UserEventsProvider>
                                        </DialogProvider>
                                    </UpdateProvider>
                                </LocalizationProvider>
                            </TrainerFeatureProvider>
                        </SportProvider>
                    </StatusProvider>
                </DebugProvider>
            </PairingProvider>
        </CurrentUserProvider>
    );
}

export default function App(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <StackIndicator />
            <Router>
                <ContextProviders>
                    <Routes>
                        <Route
                            path="*"
                            element={
                                <RequireAuth>
                                    <HorizontalScrollHome />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/horizontal"
                            element={
                                <RequireAuth>
                                    <HorizontalScrollHome />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/content/*"
                            element={
                                <RequireAuth>
                                    <React.Suspense fallback={<Loading />}>
                                        <UserPage>
                                            <Trainer />
                                        </UserPage>
                                    </React.Suspense>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/player-pti/*"
                            element={
                                <React.Suspense fallback={<Loading />}>
                                    <UserPage>
                                        <PlayerPTI />
                                    </UserPage>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/signup/*"
                            element={
                                <React.Suspense fallback={<Loading />}>
                                    <Signup />
                                </React.Suspense>
                            }
                        />
                        <Route path="/login" element={<Login />} />
                        <Route path="/auth/*" element={<Auth />} />
                        <Route
                            path="/account"
                            element={
                                <RequireAuth>
                                    <React.Suspense fallback={<Loading />}>
                                        <Account />
                                    </React.Suspense>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/admin/*"
                            element={
                                <RequireAdmin>
                                    <React.Suspense fallback={<Loading />}>
                                        <Admin />
                                    </React.Suspense>
                                </RequireAdmin>
                            }
                        />
                        <Route
                            path="/sessions/*"
                            element={
                                <RequireAuth>
                                    <Sessions />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/tutorials/*"
                            element={
                                <RequireAuth>
                                    <Tutorials />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/ball-cabinet-unlock"
                            element={
                                <RequireAuth>
                                    <UserPage>
                                        <BallCabinetUnlock />
                                    </UserPage>
                                </RequireAuth>
                            }
                        />
                    </Routes>
                    <PairDialog />
                </ContextProviders>
            </Router>
        </ThemeProvider>
    );
}
