import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import useFreshchat from "../../../../../hooks/useFreshchat";

export default function ModuleCompatibilityAlert(): JSX.Element {
    const freshchat = useFreshchat();
    return (
        <Stack spacing={4} height="100vh" mt={5} alignItems="center" px={1}>
            <Typography variant="h3" component="h1">
                Trainer update required
            </Typography>
            <Typography>
                To play skills, your trainer needs to be updated. Please press
                the icon below to message support, and we will update it for
                you.
            </Typography>
            <IconButton
                color="primary"
                size="large"
                onClick={() => freshchat.open()}
            >
                <HelpIcon />
            </IconButton>
        </Stack>
    );
}
