import React from "react";

import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import useFreshchat from "../../../../hooks/useFreshchat";

export default function ContactSupport() {
    const freshchat = useFreshchat();

    return (
        <Typography>
            If this issue persists, please message support.
            <IconButton
                onClick={() => {
                    freshchat.open();
                }}
                color="primary"
            >
                <HelpIcon />
            </IconButton>
        </Typography>
    );
}
