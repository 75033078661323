import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import HistoryIcon from "@mui/icons-material/History";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PasswordIcon from "@mui/icons-material/Password";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

import { ProPartnerLocationWithRelations } from "@volley/data";

import fetchApi, { logFetchError } from "../../util/fetchApi";
import { useCurrentUser } from "../hooks/currentUser";

import { useSelectedSport, type Sport } from "./context/sport";

interface UserHamburgerMenuProps {
    open: boolean;
    onClose: () => void;
}

interface UserMenuItemsProps {
    closeMenu: () => void;
}

function UserMenuItems({ closeMenu }: UserMenuItemsProps): JSX.Element {
    const { isAdmin, isPro } = useCurrentUser();
    const { selected, friendlyName, available, updateSelected } =
        useSelectedSport();
    const [open, setOpen] = React.useState(false);

    const [displayClubSummary, setDisplayClubSummary] = React.useState(false);

    React.useEffect(() => {
        if (isAdmin()) {
            setDisplayClubSummary(true);
        } else if (isPro()) {
            fetchApi<ProPartnerLocationWithRelations[]>("/api/partners")
                .then((locAssgResp) => {
                    // Pro must be assigned to a location by Volley to view feature
                    if (locAssgResp && locAssgResp.length > 0) {
                        setDisplayClubSummary(true);
                    }
                })
                // Action or message to user is probably not needed if call fails
                .catch((error) =>
                    logFetchError(
                        error,
                        "GET /api/partners failed in Home component",
                    ),
                );
        } // else don't allow anyone to view this feature
    }, [isAdmin, isPro]);

    return (
        <>
            <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemIcon>
                    <SportsTennisIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={friendlyName} sx={{ color: "white" }} />
                {open ? (
                    <ExpandLessIcon sx={{ color: "white" }} />
                ) : (
                    <ExpandMoreIcon sx={{ color: "white" }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.keys(available).map((sport) => (
                        <ListItemButton
                            key={sport}
                            sx={{ pl: 4 }}
                            onClick={() => {
                                updateSelected(sport as Sport);
                                setOpen(false);
                                closeMenu();
                            }}
                        >
                            <ListItemText
                                primary={available[sport as Sport]}
                                sx={{
                                    color:
                                        sport === selected
                                            ? "secondary.main"
                                            : "white",
                                }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
            <ListItemButton component={RouterLink} to="/tutorials">
                <ListItemIcon>
                    <HelpIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Tutorial" sx={{ color: "white" }} />
            </ListItemButton>
            <ListItemButton
                component={RouterLink}
                to="/content/history-stats/mine"
            >
                <ListItemIcon>
                    <HistoryIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                    primary="Sharing & Stats"
                    sx={{ color: "white" }}
                />
            </ListItemButton>
            {displayClubSummary && (
                <ListItemButton
                    component={RouterLink}
                    to="/content/club-summary"
                >
                    <ListItemIcon>
                        <LeaderboardIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Club Summary"
                        sx={{ color: "white" }}
                    />
                </ListItemButton>
            )}
            <ListItemButton component={RouterLink} to="/ball-cabinet-unlock">
                <ListItemIcon>
                    <PasswordIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                    primary="Ball Cabinet Unlock"
                    sx={{ color: "white" }}
                />
            </ListItemButton>
            {isAdmin() && (
                <>
                    <Divider
                        sx={{
                            borderColor: "white",
                            width: "80%",
                            margin: "auto",
                        }}
                    />
                    <ListItemButton component={RouterLink} to="/admin">
                        <ListItemIcon>
                            <AdminPanelSettingsIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Admin Panel"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/lobapp"
                    >
                        <ListItemText
                            inset
                            primary="Lob App"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/responsive"
                    >
                        <ListItemText
                            inset
                            primary="Responsive Play"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/plugin/play/13"
                    >
                        <ListItemText
                            inset
                            primary="Baseline Depth"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/serveAndVolley"
                    >
                        <ListItemText
                            inset
                            primary="Serve & Volley"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/servechallenge"
                    >
                        <ListItemText
                            inset
                            primary="Serve Challenge"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                </>
            )}
            {/* TODO: Add lob app and responsive play links here */}
        </>
    );
}

const DRAWER_WIDTH = 240;

export default function UserHamburgerMenu({
    open,
    onClose,
}: UserHamburgerMenuProps): JSX.Element {
    return (
        <>
            <Drawer
                variant="temporary"
                open={open}
                onClose={() => onClose()}
                sx={{
                    zindex: (t) => t.zIndex.appBar + 1,
                    mb: 12,
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: DRAWER_WIDTH,
                    },
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderBottomRightRadius: 24,
                        borderTopRightRadius: 24,
                    },
                }}
                ModalProps={{ keepMounted: true }}
            >
                <Toolbar
                    sx={{
                        backgroundColor: (t) => t.palette.primary.main,
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon sx={{ color: "white" }} />
                    </IconButton>
                </Toolbar>
                <List
                    sx={{
                        backgroundColor: (t) => t.palette.primary.main,
                        height: "100%",
                    }}
                >
                    <UserMenuItems closeMenu={() => onClose()} />
                </List>
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    backgroundColor: (t) => t.palette.primary.main,
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: DRAWER_WIDTH,
                    },
                }}
                open
            >
                <Toolbar
                    sx={{
                        zIndex: (t) => t.zIndex.appBar + 1,
                        backgroundColor: (t) => t.palette.primary.main,
                    }}
                />
                <List
                    sx={{
                        backgroundColor: (t) => t.palette.primary.main,
                        height: "100%",
                    }}
                >
                    <UserMenuItems closeMenu={() => onClose()} />
                </List>
            </Drawer>
        </>
    );
}
