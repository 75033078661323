"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableLimitsMap = void 0;
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/*
    Trainer-specific physics limits
*/
const LIMITS_ATOM_20ABCDE = {
    PLATFORM_TENNIS: {
        RPM: {
            limit: 5000,
            maxSafe: 3500,
            minSafe: 300,
            maxSpin: 3199,
        },
    },
    PADEL: {
        RPM: {
            limit: 5000,
            maxSafe: 5000,
            minSafe: 300,
            maxSpin: 4699,
        },
    },
    TENNIS: {
        RPM: {
            limit: 5000,
            maxSafe: 5000,
            minSafe: 300,
            maxSpin: 4699,
        },
    },
    PICKLEBALL: {
        RPM: {
            limit: 3750,
            maxSafe: 3750,
            minSafe: 300,
            maxSpin: 3449,
        },
    },
};
const LIMITS_ATOM_30A = {
    PLATFORM_TENNIS: {
        RPM: {
            limit: 5000,
            maxSafe: 3500,
            minSafe: 300,
            maxSpin: 3199,
        },
    },
    PADEL: {
        RPM: {
            limit: 5000,
            maxSafe: 5000,
            minSafe: 300,
            maxSpin: 4699,
        },
    },
    TENNIS: {
        RPM: {
            limit: 5000,
            maxSafe: 5000,
            minSafe: 300,
            maxSpin: 4699,
        },
    },
    PICKLEBALL: {
        RPM: {
            limit: 3750,
            maxSafe: 3750,
            minSafe: 300,
            maxSpin: 3449,
        },
    },
};
exports.tableLimitsMap = {
    "physics-20B-base": LIMITS_ATOM_20ABCDE,
    "physics-20C-base": LIMITS_ATOM_20ABCDE,
    "physics-20D-base": LIMITS_ATOM_20ABCDE,
    "physics-20E-base": LIMITS_ATOM_20ABCDE,
    "physics-30A-base": LIMITS_ATOM_30A,
};
