import * as React from "react";

import Slider, { SliderProps } from "@mui/material/Slider";

import logger from "../../../../log";
import iOS from "../../../../util/detection";

const browserIsiOS = iOS();

export default function AccordionSlider({
    ...props
}: SliderProps): JSX.Element {
    const { onChange, onChangeCommitted, sx } = props;
    const handleChange = React.useCallback(
        (event: Event, value: number | number[], activeThumb: number) => {
            if (onChange) {
                if (browserIsiOS && event.type === "mousedown") {
                    logger.debug(
                        `Ignoring slider change event (${event.type}), value: ${JSON.stringify(value)}`,
                    );
                    return;
                }

                onChange(event, value, activeThumb);
            }
        },
        [onChange],
    );

    const handleChangeCommitted = React.useCallback(
        (
            event: Event | React.SyntheticEvent<Element, Event>,
            value: number | number[],
        ) => {
            if (onChangeCommitted) {
                if (browserIsiOS && event.type === "mouseup") {
                    logger.debug(
                        `Ignoring slider changeCommitted event (${event.type}), value: ${value.toString()}`,
                    );
                    return;
                }

                onChangeCommitted(event, value);
            }
        },
        [onChangeCommitted],
    );

    return (
        <Slider
            {...props}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            sx={{
                ...sx,
                "& .MuiSlider-track": {
                    color: props.disabled ? "grey" : "primary.light",
                },
                "& .MuiSlider-thumb": {
                    width: 28,
                    height: 28,
                    backgroundColor: "white",
                    border: "4px solid",
                    borderColor: props.disabled ? "grey" : "primary.light",
                },
            }}
        />
    );
}
