"use strict";
/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
/* eslint-disable max-len */
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableRPM2MPHMap = void 0;
const RPM2MPH_ATOM_30A = {
    "PLATFORM_TENNIS": [],
    "TENNIS": [],
    "PADEL": [],
    "PICKLEBALL": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.007506436237329828, 0.020371825003267174, 0.03323721376920441,
        0.046102602535141646, 0.05896799130107888, 0.07183338006701612, 0.08469876883295335, 0.09756415759889059, 0.11042954636482782, 0.12329493513076506, 0.1361603238967023, 0.14902571266263953, 0.16189110142857677,
        0.174756490194514, 0.18762187896045124, 0.20048726772638847, 0.2133526564923257, 0.22621804525826295, 0.23908343402420018, 0.2519488227901374, 0.26481421155607465, 0.2776796003220119, 0.2905449890879491,
        0.30341037785388636, 0.3162757666198236, 0.32914115538576083, 0.34200654415169807, 0.3548719329176353, 0.36773732168357254, 0.3806027104495098, 0.393468099215447, 0.40633348798138424, 0.4191988767473216,
        0.4320642655132587, 0.44492965427919606, 0.4577950430451332, 0.47066043181107053, 0.48352582057700766, 0.496391209342945, 0.5092565981088821, 0.5221219868748195, 0.5349873756407566, 0.547852764406694,
        0.5607181531726311, 0.5735835419385684, 0.5864489307045055, 0.5993143194704429, 0.61217970823638, 0.6250450970023174, 0.6379104857682547, 0.6507758745341918, 0.6636412633001292, 0.6765066520660663,
        0.6893720408320037, 0.7022374295979408, 0.7151028183638781, 0.7279682071298152, 0.7408335958957526, 0.7536989846616897, 0.7665643734276271, 0.7794297621935642, 0.7922951509595015, 0.8051605397254387,
        0.818025928491376, 0.8308913172573131, 0.8437567060232505, 0.8566220947891876, 0.869487483555125, 0.8823528723210621, 0.8952182610869994, 0.9080836498529365, 0.9209490386188739, 0.933814427384811,
        0.9466798161507484, 0.9595452049166855, 0.9724105936826228, 0.98527598244856, 0.9981413712144973, 1.0110067599804344, 1.0238721487463718, 1.036737537512309, 1.0496029262782463, 1.0624683150441836,
        1.0753337038101207, 1.088199092576058, 1.1010644813419952, 1.1139298701079325, 1.1267952588738697, 1.139660647639807, 1.1525260364057441, 1.1653914251716815, 1.1782568139376186, 1.191122202703556,
        1.203987591469493, 1.2168529802354304, 1.2297183690013676, 1.242583757767305, 1.255449146533242, 1.2683145352991794, 1.2811799240651165, 1.2940453128310538, 1.306910701596991, 1.3197760903629283,
        1.3326414791288654, 1.3455068678948028, 1.35837225666074, 1.3712376454266773, 1.3841030341926144, 1.3969684229585517, 1.4098338117244889, 1.4226992004904262, 1.4355645892563635, 1.4484299780223004,
        1.4612953667882378, 1.4741607555541751, 1.4870261443201125, 1.4998915330860498, 1.5127569218519867, 1.525622310617924, 1.5384876993838614, 1.5513530881497988, 1.5642184769157357, 1.577083865681673,
        1.5899492544476104, 1.6028146432135477, 1.6156800319794846, 1.628545420745422, 1.6414108095113593, 1.6542761982772967, 1.6671415870432336, 1.680006975809171, 1.6928723645751083, 1.7057377533410456,
        1.7186031421069825, 1.7314685308729199, 1.7443339196388572, 1.7571993084047945, 1.7700646971707314, 1.7829300859366688, 1.7957954747026061, 1.8086608634685435, 1.8215262522344804, 1.8343916410004177,
        1.847257029766355, 1.8601224185322924, 1.8729878072982298, 1.8858531960641667, 1.898718584830104, 1.9115839735960414, 1.9244493623619787, 1.9373147511279156, 1.950180139893853, 1.9630455286597903,
        1.9759109174257277, 1.9887763061916646, 2.001641694957602, 2.0145070837235393, 2.0273724724894766, 2.0402378612554135, 2.053103250021351, 2.065968638787288, 2.0788340275532255, 2.0916994163191625,
        2.1045648050851, 2.117430193851037, 2.1302955826169745, 2.1431609713829114, 2.1560263601488487, 2.168891748914786, 2.1817571376807234, 2.1946225264466603, 2.2074879152125977, 2.220353303978535,
        2.2332186927444724, 2.2460840815104093, 2.2589494702763466, 2.271814859042284, 2.2846802478082213, 2.2975456365741587, 2.3104110253400956, 2.323276414106033, 2.3361418028719703, 2.3490071916379076,
        2.3618725804038445, 2.374737969169782, 2.387603357935719, 2.4004687467016566, 2.4133341354675935, 2.426199524233531, 2.439064912999468, 2.4519303017654055, 2.4647956905313424, 2.4776610792972797,
        2.490526468063217, 2.5033918568291544, 2.5162572455950913, 2.5291226343610287, 2.541988023126966, 2.5548534118929034, 2.5677188006588403, 2.5805841894247776, 2.593449578190715, 2.6063149669566523,
        2.6191803557225892, 2.6320457444885266, 2.644911133254464, 2.6577765220204013, 2.670641910786338, 2.6835072995522755, 2.696372688318213, 2.70923807708415, 2.7221034658500876, 2.7349688546160245,
        2.747834243381962, 2.760699632147899, 2.7735650209138365, 2.7864304096797734, 2.7992957984457107, 2.812161187211648, 2.8250265759775854, 2.8378919647435223, 2.8507573535094597, 2.863622742275397,
        2.8764881310413344, 2.8893535198072713, 2.9022189085732086, 2.915084297339146, 2.9279496861050833, 2.9408150748710202, 2.9536804636369576, 2.966545852402895, 2.9794112411688323, 2.992276629934769,
        3.0051420187007065, 3.018007407466644, 3.030872796232581, 3.043738184998518, 3.0566035737644555, 3.069468962530393, 3.08233435129633, 3.095199740062267, 3.1080651288282044, 3.1209305175941418,
        3.133795906360079, 3.1466612951260164, 3.1595266838919533, 3.1723920726578907, 3.185257461423828, 3.1981228501897654, 3.2109882389557023, 3.2238536277216396, 3.236719016487577, 3.2495844052535143,
        3.2624497940194512, 3.2753151827853886, 3.288180571551326, 3.3010459603172633, 3.3139113490832, 3.3267767378491375, 3.339642126615075, 3.352507515381012, 3.365372904146949, 3.3782382929128865,
        3.391103681678824, 3.4039690704447607, 3.416834459210698, 3.4296998479766354, 3.4425652367425728, 3.45543062550851, 3.4682960142744474, 3.481161403040385, 3.4940267918063213, 3.5068921805722586,
        3.519757569338196, 3.5326229581041333, 3.5454883468700706, 3.558353735636008, 3.5712191244019453, 3.5840845131678827, 3.59694990193382, 3.6098152906997565, 3.622680679465694, 3.635546068231631,
        3.6484114569975685, 3.661276845763506, 3.674142234529443, 3.6870076232953806, 3.699873012061318, 3.7127384008272544, 3.7256037895931917, 3.738469178359129, 3.7513345671250664, 3.7641999558910038,
        3.777065344656941, 3.7899307334228785, 3.802796122188816, 3.8156615109547523, 3.8285268997206896, 3.841392288486627, 3.8542576772525643, 3.8671230660185016, 3.879988454784439, 3.8928538435503763,
        3.9057192323163137, 3.91858462108225, 3.9314500098481875, 3.944315398614125, 3.957180787380062, 3.9700461761459995, 3.982911564911937, 3.995776953677874, 4.008642342443812, 4.0215077312097485,
        4.034373119975685, 4.047238508741623, 4.06010389750756, 4.072969286273498, 4.085834675039434, 4.0987000638053726, 4.111565452571309, 4.124430841337247, 4.137296230103184, 4.15016161886912,
        4.163027007635058, 4.175892396400995, 4.188757785166933, 4.2016231739328695, 4.214488562698808, 4.227353951464744, 4.240219340230681, 4.253084728996619, 4.265950117762555, 4.278815506528494,
        4.29168089529443, 4.304546284060368, 4.317411672826305, 4.330277061592243, 4.3431424503581795, 4.356007839124116, 4.368873227890054, 4.381738616655991, 4.394604005421929, 4.407469394187865,
        4.4203347829538036, 4.43320017171974, 4.446065560485678, 4.458930949251615, 4.471796338017551, 4.484661726783489, 4.497527115549426, 4.510392504315364, 4.5232578930813006, 4.536123281847239,
        4.548988670613175, 4.561854059379112, 4.57471944814505, 4.587584836910986, 4.600450225676925, 4.613315614442861, 4.626181003208799, 4.639046391974736, 4.651911780740674, 4.6647771695066105,
        4.677642558272547, 4.690507947038485, 4.703373335804422, 4.71623872457036, 4.729104113336296, 4.741969502102235, 4.754834890868171, 4.767700279634109, 4.780565668400046, 4.793431057165982,
        4.80629644593192, 4.819161834697857, 4.832027223463795, 4.8448926122297316, 4.85775800099567, 4.870623389761606, 4.883488778527543, 4.896354167293481, 4.909219556059417, 4.922084944825356,
        4.934950333591292, 4.94781572235723, 4.960681111123167, 4.973546499889105, 4.9864118886550415, 4.999277277420978, 5.012142666186916, 5.025008054952853, 5.037873443718791, 5.050738832484727,
        5.063604221250666, 5.076469610016602, 5.0893349987825385, 5.102200387548477, 5.115065776314413, 5.127931165080351, 5.140796553846288, 5.153661942612226, 5.166527331378163, 5.179392720144101,
        5.192258108910037, 5.205123497675974, 5.217988886441912, 5.230854275207848, 5.243719663973787, 5.256585052739723, 5.269450441505661, 5.282315830271598, 5.295181219037536, 5.3080466078034725,
        5.320911996569409, 5.333777385335347, 5.346642774101284, 5.359508162867222, 5.372373551633158, 5.385238940399097, 5.398104329165033, 5.4109697179309695, 5.423835106696908, 5.436700495462844,
        5.449565884228782, 5.462431272994719, 5.475296661760657, 5.488162050526594, 5.501027439292532, 5.513892828058468, 5.526758216824405, 5.539623605590343, 5.552488994356279, 5.565354383122218,
        5.578219771888154, 5.591085160654092, 5.603950549420029, 5.616815938185967, 5.6296813269519035, 5.64254671571784, 5.655412104483778, 5.668277493249715, 5.681142882015653, 5.694008270781589,
        5.706873659547528, 5.719739048313464, 5.7326044370794005, 5.745469825845339, 5.758335214611275, 5.771200603377213, 5.78406599214315, 5.796931380909088, 5.809796769675025, 5.822662158440963,
        5.835527547206899, 5.848392935972836, 5.861258324738774, 5.87412371350471, 5.886989102270649, 5.899854491036585, 5.912719879802523, 5.92558526856846, 5.938450657334396, 5.9513160461003345,
        5.964181434866271, 5.977046823632209, 5.989912212398146, 6.002777601164084, 6.01564298993002, 6.028508378695959, 6.041373767461895, 6.0542391562278315, 6.06710454499377, 6.079969933759706,
        6.092835322525644, 6.105700711291581, 6.118566100057519, 6.131431488823456, 6.144296877589394, 6.15716226635533, 6.170027655121267, 6.182893043887205, 6.195758432653141, 6.20862382141908,
        6.221489210185016, 6.234354598950954, 6.247219987716891, 6.260085376482827, 6.2729507652487655, 6.285816154014702, 6.29868154278064, 6.311546931546577, 6.324412320312515, 6.337277709078451,
        6.35014309784439, 6.363008486610326, 6.3758738753762625, 6.388739264142201, 6.401604652908137, 6.414470041674075, 6.427335430440012, 6.44020081920595, 6.453066207971887, 6.465931596737825,
        6.478796985503761, 6.491662374269698, 6.504527763035636, 6.517393151801572, 6.530258540567511, 6.543123929333447, 6.555989318099385, 6.568854706865322, 6.581720095631258, 6.5945854843971965,
        6.607450873163133, 6.620316261929071, 6.633181650695008, 6.646047039460946, 6.658912428226882, 6.671777816992821, 6.684643205758757, 6.6975085945246935, 6.710373983290632, 6.723239372056568,
        6.736104760822506, 6.748970149588443, 6.761835538354381, 6.774700927120318, 6.787566315886254, 6.800431704652192, 6.813297093418129, 6.826162482184067, 6.839027870950003, 6.851893259715942,
        6.864758648481878, 6.877624037247816, 6.890489426013753, 6.903354814779689, 6.9162202035456275, 6.929085592311564, 6.941950981077502, 6.954816369843439, 6.967681758609377, 6.980547147375313,
        6.993412536141252, 7.006277924907188, 7.0191433136731245, 7.032008702439063, 7.044874091204999, 7.057739479970937, 7.070604868736874, 7.083470257502812, 7.096335646268749, 7.109201035034685,
        7.122066423800623, 7.13493181256656, 7.147797201332498, 7.160662590098434, 7.173527978864373, 7.186393367630309, 7.199258756396247, 7.212124145162184, 7.22498953392812, 7.2378549226940585,
        7.250720311459995, 7.263585700225933, 7.27645108899187, 7.289316477757808, 7.302181866523744, 7.315047255289683, 7.327912644055619, 7.3407780328215555, 7.353643421587494, 7.36650881035343,
        7.379374199119368, 7.392239587885305, 7.405104976651241, 7.41797036541718, 7.430835754183116, 7.443701142949054, 7.456566531714991, 7.469431920480929, 7.482297309246865, 7.495162698012802,
        7.50802808677874, 7.520893475544677, 7.533758864310615, 7.546624253076551, 7.5594896418424895, 7.572355030608426, 7.585220419374362, 7.598085808140301, 7.610951196906237, 7.623816585672175,
        7.636681974438112, 7.64954736320405, 7.6624127519699865, 7.675278140735925, 7.688143529501861, 7.701008918267798, 7.713874307033736, 7.726739695799672, 7.739605084565611, 7.752470473331547,
        7.765335862097485, 7.778201250863422, 7.79106663962936, 7.803932028395296, 7.816797417161233, 7.829662805927171, 7.842528194693108, 7.855393583459046, 7.868258972224982, 7.8811243609909205,
        7.893989749756857, 7.906855138522793, 7.919720527288732, 7.932585916054668, 7.945451304820606, 7.958316693586543, 7.971182082352481, 7.9840474711184175, 7.996912859884356, 8.009778248650292,
        8.022643637416229, 8.035509026182167, 8.048374414948103, 8.061239803714042, 8.074105192479978, 8.086970581245916, 8.099835970011853, 8.112701358777791, 8.125566747543727, 8.138432136309664,
        8.151297525075602, 8.164162913841539, 8.177028302607477, 8.189893691373413, 8.202759080139352, 8.215624468905288, 8.228489857671224, 8.241355246437163, 8.2542206352031, 8.267086023969037,
        8.279951412734974, 8.292816801500912, 8.305682190266849, 8.318547579032787, 8.331412967798723, 8.34427835656466, 8.357143745330598, 8.370009134096534, 8.382874522862473, 8.395739911628409,
        8.408605300394347, 8.421470689160284, 8.43433607792622, 8.447201466692158, 8.460066855458095, 8.472932244224033, 8.48579763298997, 8.498663021755908, 8.511528410521844, 8.524393799287783,
        8.537259188053719, 8.550124576819655, 8.562989965585594, 8.57585535435153, 8.588720743117468, 8.601586131883405, 8.614451520649343, 8.62731690941528, 8.640182298181218, 8.653047686947154,
        8.66591307571309, 8.678778464479029, 8.691643853244965, 8.704509242010904, 8.71737463077684, 8.730240019542778, 8.743105408308715, 8.755970797074651, 8.76883618584059, 8.781701574606526,
        8.794566963372464, 8.8074323521384, 8.820297740904339, 8.833163129670275, 8.846028518436214, 8.85889390720215, 8.871759295968086, 8.884624684734025, 8.897490073499961, 8.9103554622659,
        8.923220851031836, 8.936086239797774, 8.94895162856371, 8.961817017329649, 8.974682406095585, 8.987547794861522, 9.00041318362746, 9.013278572393396, 9.026143961159335, 9.039009349925271,
        9.05187473869121, 9.064740127457146, 9.077605516223082, 9.09047090498902, 9.103336293754957, 9.116201682520895, 9.129067071286832, 9.14193246005277, 9.154797848818706, 9.167663237584645,
        9.180528626350581, 9.193394015116517, 9.206259403882456, 9.219124792648392, 9.23199018141433, 9.244855570180267, 9.257720958946205, 9.270586347712142, 9.28345173647808, 9.296317125244016,
        9.309182514009953, 9.322047902775891, 9.334913291541827, 9.347778680307766, 9.360644069073702, 9.37350945783964, 9.386374846605577, 9.399240235371513, 9.412105624137451, 9.424971012903388,
        9.437836401669326, 9.450701790435263, 9.4635671792012, 9.476432567967137, 9.489297956733076, 9.502163345499012, 9.515028734264948, 9.527894123030887, 9.540759511796823, 9.553624900562761,
        9.566490289328698, 9.579355678094636, 9.592221066860573, 9.605086455626509, 9.617951844392447, 9.630817233158384, 9.643682621924322, 9.656548010690258, 9.669413399456197, 9.682278788222133,
        9.695144176988071, 9.708009565754008, 9.720874954519944, 9.733740343285882, 9.746605732051819, 9.759471120817757, 9.772336509583694, 9.785201898349632, 9.798067287115568, 9.810932675881507,
        9.823798064647443, 9.83666345341338, 9.849528842179318, 9.862394230945254, 9.875259619711192, 9.888125008477129, 9.900990397243067, 9.913855786009004, 9.92672117477494, 9.939586563540878,
        9.952451952306815, 9.965317341072753, 9.97818272983869, 9.991048118604628, 10.003913507370564, 10.016778896136502, 10.029644284902439, 10.042509673668375, 10.055375062434313, 10.06824045120025,
        10.081105839966188, 10.093971228732125, 10.106836617498063, 10.119702006264, 10.132567395029938, 10.145432783795874, 10.15829817256181, 10.171163561327749, 10.184028950093685, 10.196894338859623,
        10.20975972762556, 10.222625116391498, 10.235490505157435, 10.248355893923371, 10.26122128268931, 10.274086671455246, 10.286952060221184, 10.29981744898712, 10.312682837753059, 10.325548226518995,
        10.338413615284933, 10.35127900405087, 10.364144392816806, 10.377009781582744, 10.389875170348681, 10.40274055911462, 10.415605947880556, 10.428471336646494, 10.44133672541243, 10.454202114178367,
        10.467067502944305, 10.479932891710241, 10.49279828047618, 10.505663669242116, 10.518529058008054, 10.53139444677399, 10.544259835539929, 10.557125224305866, 10.569990613071802, 10.58285600183774,
        10.595721390603677, 10.608586779369615, 10.621452168135551, 10.63431755690149, 10.647182945667426, 10.660048334433364, 10.6729137231993, 10.685779111965237, 10.698644500731175, 10.711509889497112,
        10.72437527826305, 10.737240667028987, 10.750106055794925, 10.762971444560861, 10.775836833326798, 10.788702222092736, 10.801567610858672, 10.81443299962461, 10.827298388390547, 10.840163777156485,
        10.853029165922422, 10.86589455468836, 10.878759943454297, 10.891625332220233, 10.904490720986171, 10.917356109752108, 10.930221498518046, 10.943086887283982, 10.95595227604992, 10.968817664815857,
        10.981683053581795, 10.994548442347732, 11.007413831113668, 11.020279219879606, 11.033144608645543, 11.046009997411481, 11.058875386177418, 11.071740774943356, 11.084606163709292, 11.097471552475229,
        11.110336941241167, 11.123202330007103, 11.136067718773042, 11.148933107538978, 11.161798496304916, 11.174663885070853, 11.187529273836791, 11.200394662602728, 11.213260051368664, 11.226125440134602,
        11.238990828900539, 11.251856217666477, 11.264721606432413, 11.277586995198352, 11.290452383964288, 11.303317772730225, 11.316183161496163, 11.3290485502621, 11.341913939028037, 11.354779327793974,
        11.367644716559912, 11.380510105325849, 11.393375494091787, 11.406240882857723, 11.41910627162366, 11.431971660389598, 11.444837049155534, 11.457702437921473, 11.47056782668741, 11.483433215453347,
        11.496298604219284, 11.509163992985222, 11.522029381751159, 11.534894770517095, 11.547760159283033, 11.56062554804897, 11.573490936814908, 11.586356325580844, 11.599221714346783, 11.612087103112719,
        11.624952491878656, 11.637817880644594, 11.65068326941053, 11.663548658176468, 11.676414046942405, 11.689279435708343, 11.70214482447428, 11.715010213240218, 11.727875602006154, 11.74074099077209,
        11.753606379538029, 11.766471768303965, 11.779337157069904, 11.79220254583584, 11.805067934601778, 11.817933323367715, 11.830798712133653, 11.84366410089959, 11.856529489665526, 11.869394878431464,
        11.8822602671974, 11.895125655963339, 11.907991044729275, 11.920856433495214, 11.93372182226115, 11.946587211027087, 11.959452599793025, 11.972317988558961, 11.9851833773249, 11.998048766090836,
        12.010914154856774, 12.02377954362271, 12.036644932388649, 12.049510321154585, 12.062375709920522, 12.07524109868646, 12.088106487452396, 12.100971876218335, 12.113837264984271, 12.12670265375021,
        12.139568042516146, 12.152433431282082, 12.16529882004802, 12.178164208813957, 12.191029597579895, 12.203894986345832, 12.21676037511177, 12.229625763877706, 12.242491152643645, 12.255356541409581,
        12.268221930175518, 12.281087318941456, 12.293952707707392, 12.30681809647333, 12.319683485239267, 12.332548874005205, 12.345414262771142, 12.35827965153708, 12.371145040303016, 12.384010429068953,
        12.396875817834891, 12.409741206600827, 12.422606595366766, 12.435471984132702, 12.44833737289864, 12.461202761664577, 12.474068150430513, 12.486933539196452, 12.499798927962388, 12.512664316728326,
        12.525529705494263, 12.538395094260201, 12.551260483026137, 12.564125871792076, 12.576991260558012, 12.589856649323949, 12.602722038089887, 12.615587426855823, 12.628452815621761, 12.641318204387698,
        12.654183593153636, 12.667048981919573, 12.67991437068551, 12.692779759451447, 12.705645148217384, 12.718510536983322, 12.731375925749258, 12.744241314515197, 12.757106703281133, 12.769972092047071,
        12.782837480813008, 12.795702869578944, 12.808568258344883, 12.821433647110819, 12.834299035876757, 12.847164424642694, 12.860029813408632, 12.872895202174568, 12.885760590940507, 12.898625979706443,
        12.91149136847238, 12.924356757238318, 12.937222146004254, 12.950087534770192, 12.962952923536129, 12.975818312302067, 12.988683701068004, 13.001549089833942, 13.014414478599878, 13.027279867365815,
        13.040145256131753, 13.05301064489769, 13.065876033663628, 13.078741422429564, 13.091606811195502, 13.104472199961439, 13.117337588727375, 13.130202977493314, 13.14306836625925, 13.155933755025188,
        13.168799143791125, 13.181664532557063, 13.194529921323, 13.207395310088938, 13.220260698854874, 13.23312608762081, 13.245991476386749, 13.258856865152685, 13.271722253918623, 13.28458764268456,
        13.297453031450498, 13.310318420216435, 13.323183808982371, 13.33604919774831, 13.348914586514246, 13.361779975280184, 13.37464536404612, 13.387510752812059, 13.400376141577995, 13.413241530343933,
        13.42610691910987, 13.438972307875806, 13.451837696641745, 13.464703085407681, 13.47756847417362, 13.490433862939556, 13.503299251705494, 13.51616464047143, 13.529030029237369, 13.541895418003305,
        13.554760806769242, 13.56762619553518, 13.580491584301116, 13.593356973067054, 13.606222361832991, 13.61908775059893, 13.631953139364866, 13.644818528130802, 13.65768391689674, 13.670549305662677,
        13.683414694428615, 13.696280083194551, 13.70914547196049, 13.722010860726426, 13.734876249492364, 13.7477416382583, 13.760607027024237, 13.773472415790176, 13.786337804556112, 13.79920319332205,
        13.812068582087987, 13.824933970853925, 13.837799359619861, 13.8506647483858, 13.863530137151736, 13.876395525917673, 13.88926091468361, 13.902126303449547, 13.914991692215485, 13.927857080981422,
        13.94072246974736, 13.953587858513297, 13.966453247279233, 13.979318636045171, 13.992184024811108, 14.005049413577046, 14.017914802342982, 14.03078019110892, 14.043645579874857, 14.056510968640795,
        14.069376357406732, 14.082241746172668, 14.095107134938607, 14.107972523704543, 14.120837912470481, 14.133703301236418, 14.146568690002356, 14.159434078768292, 14.172299467534229, 14.185164856300167,
        14.198030245066104, 14.210895633832042, 14.223761022597978, 14.236626411363916, 14.249491800129853, 14.262357188895791, 14.275222577661728, 14.288087966427664, 14.300953355193602, 14.313818743959539,
        14.326684132725477, 14.339549521491413, 14.352414910257352, 14.365280299023288, 14.378145687789226, 14.391011076555163, 14.4038764653211, 14.416741854087038, 14.429607242852974, 14.442472631618912,
        14.455338020384849, 14.468203409150787, 14.481068797916723, 14.49393418668266, 14.506799575448598, 14.519664964214535, 14.532530352980473, 14.54539574174641, 14.558261130512347, 14.571126519278284,
        14.583991908044222, 14.596857296810159, 14.609722685576095, 14.622588074342033, 14.63545346310797, 14.648318851873908, 14.661184240639844, 14.674049629405783, 14.68691501817172, 14.699780406937657,
        14.712645795703594, 14.72551118446953, 14.738376573235469, 14.751241962001405, 14.764107350767343, 14.77697273953328, 14.789838128299218, 14.802703517065154, 14.81556890583109, 14.828434294597029,
        14.841299683362966, 14.854165072128904, 14.86703046089484, 14.879895849660778, 14.892761238426715, 14.905626627192653, 14.91849201595859, 14.931357404724526, 14.944222793490464, 14.9570881822564,
        14.969953571022339, 14.982818959788275, 14.995684348554214, 15.00854973732015, 15.021415126086087, 15.034280514852025, 15.047145903617961, 15.0600112923839, 15.072876681149836, 15.085742069915774,
        15.09860745868171, 15.111472847447649, 15.124338236213585, 15.137203624979522, 15.15006901374546, 15.162934402511397, 15.175799791277335, 15.188665180043271, 15.20153056880921, 15.214395957575146,
        15.227261346341084, 15.24012673510702, 15.252992123872957, 15.265857512638895, 15.278722901404832, 15.29158829017077, 15.304453678936706, 15.317319067702645, 15.330184456468581, 15.343049845234518,
        15.355915234000456, 15.368780622766392, 15.38164601153233, 15.394511400298267, 15.407376789064203, 15.420242177830142, 15.43310756659608, 15.445972955362015, 15.458838344127953, 15.471703732893891,
        15.48456912165983, 15.497434510425764, 15.510299899191702, 15.52316528795764, 15.536030676723579, 15.548896065489513, 15.561761454255452, 15.57462684302139, 15.587492231787325, 15.600357620553263,
        15.613223009319201, 15.62608839808514, 15.638953786851074, 15.651819175617012, 15.66468456438295, 15.677549953148885, 15.690415341914823, 15.703280730680762, 15.7161461194467, 15.729011508212634,
        15.741876896978573, 15.754742285744511, 15.767607674510446, 15.780473063276384, 15.793338452042322, 15.80620384080826, 15.819069229574195, 15.831934618340133, 15.844800007106072, 15.85766539587201,
        15.870530784637944, 15.883396173403883, 15.896261562169821, 15.909126950935756, 15.921992339701694, 15.934857728467632, 15.94772311723357, 15.960588505999505, 15.973453894765443, 15.986319283531381,
        15.999184672297316, 16.012050061063256, 16.024915449829194, 16.037780838595133, 16.050646227361067, 16.063511616127006, 16.076377004892944, 16.08924239365888, 16.102107782424817, 16.114973171190755,
        16.127838559956693, 16.140703948722628, 16.153569337488566, 16.166434726254504, 16.179300115020443, 16.192165503786377, 16.205030892552315, 16.217896281318254, 16.23076167008419, 16.243627058850127,
        16.256492447616065, 16.269357836382003, 16.282223225147938, 16.295088613913876, 16.307954002679814, 16.32081939144575, 16.333684780211687, 16.346550168977625, 16.359415557743564, 16.3722809465095,
        16.385146335275437, 16.398011724041375, 16.41087711280731, 16.423742501573248, 16.436607890339186, 16.449473279105124, 16.46233866787106, 16.475204056636997, 16.488069445402935, 16.500934834168874,
        16.513800222934808, 16.526665611700746, 16.539531000466685, 16.55239638923262, 16.565261777998558, 16.578127166764496, 16.590992555530434, 16.60385794429637, 16.616723333062307, 16.629588721828245,
        16.64245411059418, 16.655319499360118, 16.668184888126056, 16.681050276891995, 16.69391566565793, 16.706781054423868, 16.719646443189806, 16.73251183195574, 16.74537722072168, 16.758242609487617,
        16.771107998253555, 16.78397338701949, 16.796838775785428, 16.809704164551366, 16.822569553317305, 16.83543494208324, 16.848300330849177, 16.861165719615116, 16.87403110838105, 16.88689649714699,
        16.899761885912927, 16.912627274678865, 16.9254926634448, 16.938358052210738, 16.951223440976676, 16.96408882974261, 16.97695421850855, 16.989819607274487, 17.002684996040426, 17.01555038480636,
        17.0284157735723, 17.041281162338237, 17.05414655110417, 17.06701193987011, 17.079877328636048, 17.092742717401986, 17.10560810616792, 17.11847349493386, 17.131338883699797, 17.144204272465736,
        17.15706966123167, 17.16993504999761, 17.182800438763547, 17.19566582752948, 17.20853121629542, 17.221396605061358, 17.234261993827296, 17.24712738259323, 17.25999277135917, 17.272858160125107,
        17.285723548891042, 17.29858893765698, 17.31145432642292, 17.324319715188857, 17.33718510395479, 17.35005049272073, 17.362915881486668, 17.375781270252602, 17.38864665901854, 17.40151204778448,
        17.414377436550417, 17.427242825316352, 17.44010821408229, 17.45297360284823, 17.465838991614163, 17.4787043803801, 17.49156976914604, 17.504435157911978, 17.517300546677912, 17.53016593544385,
        17.54303132420979, 17.555896712975727, 17.56876210174166, 17.5816274905076, 17.594492879273538, 17.607358268039473, 17.62022365680541, 17.63308904557135, 17.645954434337288, 17.658819823103222,
        17.67168521186916, 17.6845506006351, 17.697415989401033, 17.71028137816697, 17.72314676693291, 17.736012155698848, 17.748877544464783, 17.76174293323072, 17.77460832199666, 17.787473710762594,
        17.800339099528532, 17.81320448829447, 17.82606987706041, 17.838935265826343, 17.85180065459228, 17.86466604335822, 17.877531432124158, 17.890396820890093, 17.90326220965603, 17.91612759842197,
        17.928992987187904, 17.941858375953842, 17.95472376471978, 17.96758915348572, 17.980454542251653, 17.99331993101759, 18.00618531978353, 18.019050708549464, 18.031916097315403, 18.04478148608134,
        18.05764687484728, 18.070512263613214, 18.083377652379152, 18.09624304114509, 18.109108429911025, 18.121973818676963, 18.1348392074429, 18.14770459620884, 18.160569984974774, 18.173435373740713,
        18.18630076250665, 18.19916615127259, 18.212031540038524, 18.224896928804462, 18.2377623175704, 18.250627706336335, 18.263493095102273, 18.27635848386821, 18.28922387263415, 18.302089261400084,
        18.314954650166023, 18.32782003893196, 18.340685427697895, 18.353550816463834, 18.366416205229772, 18.37928159399571, 18.392146982761645, 18.405012371527583, 18.41787776029352, 18.430743149059456,
        18.443608537825394, 18.456473926591332, 18.46933931535727, 18.482204704123205, 18.495070092889144, 18.507935481655082, 18.52080087042102, 18.533666259186955, 18.546531647952893, 18.55939703671883,
        18.572262425484766, 18.585127814250704, 18.597993203016642, 18.61085859178258, 18.623723980548515, 18.636589369314454, 18.64945475808039, 18.662320146846326, 18.675185535612265, 18.688050924378203,
        18.70091631314414, 18.713781701910076, 18.726647090676014, 18.739512479441952, 18.752377868207887, 18.765243256973825, 18.778108645739763, 18.7909740345057, 18.803839423271636, 18.816704812037575,
        18.829570200803513, 18.84243558956945, 18.855300978335386, 18.868166367101324, 18.881031755867262, 18.893897144633197, 18.906762533399135, 18.919627922165073, 18.93249331093101, 18.945358699696946,
        18.958224088462885, 18.971089477228823, 18.983954865994757, 18.996820254760696, 19.009685643526634, 19.022551032292572, 19.035416421058507, 19.048281809824445, 19.061147198590383, 19.074012587356318,
        19.086877976122256, 19.099743364888194, 19.112608753654133, 19.125474142420067, 19.138339531186006, 19.151204919951944, 19.164070308717882, 19.176935697483817, 19.189801086249755, 19.202666475015693,
        19.215531863781628, 19.228397252547566, 19.241262641313504, 19.254128030079443, 19.266993418845377, 19.279858807611316, 19.292724196377254, 19.30558958514319, 19.318454973909127, 19.331320362675065,
        19.344185751441003, 19.357051140206938, 19.369916528972876, 19.382781917738814, 19.39564730650475, 19.408512695270687, 19.421378084036625, 19.434243472802564, 19.4471088615685, 19.459974250334437,
        19.472839639100375, 19.48570502786631, 19.498570416632248, 19.511435805398186, 19.524301194164124, 19.53716658293006, 19.550031971695997, 19.562897360461935, 19.575762749227874, 19.58862813799381,
        19.601493526759747, 19.614358915525685, 19.62722430429162, 19.640089693057558, 19.652955081823496, 19.665820470589434, 19.67868585935537, 19.691551248121307, 19.704416636887245, 19.71728202565318,
        19.73014741441912, 19.743012803185056, 19.755878191950995, 19.76874358071693, 19.781608969482868, 19.794474358248806, 19.80733974701474, 19.82020513578068, 19.833070524546617, 19.845935913312555,
        19.85880130207849, 19.871666690844428, 19.884532079610366, 19.897397468376305, 19.91026285714224, 19.923128245908178, 19.935993634674116, 19.94885902344005, 19.96172441220599, 19.974589800971927,
        19.987455189737865, 20.0003205785038, 20.013185967269738, 20.026051356035676, 20.03891674480161, 20.05178213356755, 20.064647522333487, 20.077512911099426, 20.09037829986536, 20.1032436886313,
        20.116109077397237, 20.12897446616317, 20.14183985492911, 20.154705243695048, 20.167570632460986, 20.18043602122692, 20.19330140999286, 20.206166798758797, 20.219032187524736, 20.23189757629067,
        20.24476296505661, 20.257628353822547, 20.27049374258848, 20.28335913135442, 20.296224520120358, 20.309089908886296, 20.32195529765223, 20.33482068641817, 20.347686075184107, 20.360551463950042,
        20.37341685271598, 20.38628224148192, 20.399147630247857, 20.41201301901379, 20.42487840777973, 20.437743796545668, 20.450609185311603, 20.46347457407754, 20.47633996284348, 20.489205351609417,
        20.502070740375352, 20.51493612914129, 20.52780151790723, 20.540666906673167, 20.5535322954391, 20.56639768420504, 20.579263072970978, 20.592128461736912, 20.60499385050285, 20.61785923926879,
        20.630724628034727, 20.643590016800662, 20.6564554055666, 20.66932079433254, 20.682186183098473, 20.69505157186441, 20.70791696063035, 20.720782349396288, 20.733647738162222, 20.74651312692816,
        20.7593785156941, 20.772243904460034, 20.785109293225972, 20.79797468199191, 20.81084007075785, 20.823705459523783, 20.83657084828972, 20.84943623705566, 20.862301625821598, 20.875167014587532,
        20.88803240335347, 20.90089779211941, 20.913763180885343, 20.92662856965128, 20.93949395841722, 20.952359347183158, 20.965224735949093, 20.97809012471503, 20.99095551348097, 21.003820902246904,
        21.016686291012842, 21.02955167977878, 21.04241706854472, 21.055282457310653, 21.06814784607659, 21.08101323484253, 21.093878623608465, 21.106744012374403, 21.11960940114034, 21.13247478990628,
        21.145340178672214, 21.158205567438152, 21.17107095620409, 21.183936344970025, 21.196801733735963, 21.2096671225019, 21.22253251126784, 21.235397900033774, 21.248263288799713, 21.26112867756565,
        21.27399406633159, 21.286859455097524, 21.299724843863462, 21.3125902326294, 21.325455621395335, 21.338321010161273, 21.35118639892721, 21.36405178769315, 21.376917176459084, 21.389782565225023,
        21.40264795399096, 21.415513342756896, 21.428378731522834, 21.441244120288772, 21.45410950905471, 21.466974897820645, 21.479840286586583, 21.49270567535252, 21.505571064118456, 21.518436452884394,
        21.531301841650333, 21.54416723041627, 21.557032619182205, 21.569898007948144, 21.582763396714082, 21.59562878548002, 21.608494174245955, 21.621359563011893, 21.63422495177783, 21.647090340543766,
        21.659955729309704, 21.672821118075642, 21.68568650684158, 21.698551895607515, 21.711417284373454, 21.724282673139392, 21.737148061905327, 21.750013450671265, 21.762878839437203, 21.77574422820314,
        21.788609616969076, 21.801475005735014, 21.814340394500952, 21.827205783266887, 21.840071172032825, 21.852936560798764, 21.865801949564702, 21.878667338330636, 21.891532727096575, 21.904398115862513,
        21.91726350462845, 21.930128893394386, 21.942994282160324, 21.955859670926262, 21.968725059692197, 21.981590448458135, 21.994455837224073, 22.00732122599001, 22.020186614755946, 22.033052003521885,
        22.045917392287823, 22.058782781053758, 22.071648169819696, 22.084513558585634, 22.097378947351572, 22.110244336117507, 22.123109724883445, 22.135975113649383, 22.148840502415318, 22.161705891181256,
        22.174571279947195, 22.187436668713133, 22.200302057479067, 22.213167446245006, 22.226032835010944, 22.238898223776882, 22.251763612542817, 22.264629001308755, 22.277494390074693, 22.290359778840628,
        22.303225167606566, 22.316090556372504, 22.328955945138443, 22.341821333904377, 22.354686722670316, 22.367552111436254, 22.38041750020219, 22.393282888968127, 22.406148277734065, 22.419013666500003,
        22.431879055265938, 22.444744444031876, 22.457609832797814, 22.47047522156375, 22.483340610329687, 22.496205999095626, 22.509071387861564, 22.5219367766275, 22.534802165393437, 22.547667554159375,
        22.560532942925313, 22.573398331691248, 22.586263720457186, 22.599129109223124, 22.61199449798906, 22.624859886754997, 22.637725275520935, 22.650590664286874, 22.66345605305281, 22.676321441818747,
        22.689186830584685, 22.70205221935062, 22.714917608116558, 22.727782996882496, 22.740648385648434, 22.75351377441437, 22.766379163180307, 22.779244551946245, 22.79210994071218, 22.80497532947812,
        22.817840718244057, 22.830706107009995, 22.84357149577593, 22.856436884541868, 22.869302273307806, 22.882167662073744, 22.89503305083968, 22.907898439605617, 22.920763828371555, 22.93362921713749,
        22.94649460590343, 22.959359994669367, 22.972225383435305, 22.98509077220124, 22.997956160967178, 23.010821549733116, 23.02368693849905, 23.03655232726499, 23.049417716030927, 23.062283104796865,
        23.0751484935628, 23.088013882328738, 23.100879271094676, 23.11374465986061, 23.12661004862655, 23.139475437392488, 23.152340826158426, 23.16520621492436, 23.1780716036903, 23.190936992456237,
        23.20380238122217, 23.21666776998811, 23.229533158754048, 23.242398547519986, 23.25526393628592, 23.26812932505186, 23.280994713817798, 23.293860102583736, 23.30672549134967, 23.31959088011561,
        23.332456268881547, 23.34532165764748, 23.35818704641342, 23.371052435179358, 23.383917823945296, 23.39678321271123, 23.40964860147717, 23.422513990243107, 23.435379379009042, 23.44824476777498,
        23.46111015654092, 23.473975545306857, 23.48684093407279, 23.49970632283873, 23.512571711604668, 23.525437100370603, 23.53830248913654, 23.55116787790248, 23.564033266668417, 23.576898655434352,
        23.58976404420029, 23.60262943296623, 23.615494821732167, 23.6283602104981, 23.64122559926404, 23.654090988029978, 23.666956376795913, 23.67982176556185, 23.69268715432779, 23.705552543093727,
        23.718417931859662, 23.7312833206256, 23.74414870939154, 23.757014098157473, 23.76987948692341, 23.78274487568935, 23.795610264455288, 23.808475653221223, 23.82134104198716, 23.8342064307531,
        23.847071819519034, 23.859937208284972, 23.87280259705091, 23.88566798581685, 23.898533374582783, 23.91139876334872, 23.92426415211466, 23.937129540880598, 23.949994929646532, 23.96286031841247,
        23.97572570717841, 23.988591095944344, 24.001456484710282, 24.01432187347622, 24.02718726224216, 24.040052651008093, 24.05291803977403, 24.06578342853997, 24.078648817305904, 24.091514206071842,
        24.10437959483778, 24.11724498360372, 24.130110372369654, 24.14297576113559, 24.15584114990153, 24.168706538667465, 24.181571927433403, 24.19443731619934, 24.20730270496528, 24.220168093731214,
        24.233033482497152, 24.24589887126309, 24.25876426002903, 24.271629648794963, 24.2844950375609, 24.29736042632684, 24.310225815092775, 24.323091203858713, 24.33595659262465, 24.34882198139059,
        24.361687370156524, 24.374552758922462, 24.3874181476884, 24.400283536454335, 24.413148925220273, 24.42601431398621, 24.43887970275215, 24.451745091518085, 24.464610480284023, 24.47747586904996,
        24.490341257815896, 24.503206646581834, 24.516072035347772, 24.52893742411371, 24.541802812879645, 24.554668201645583, 24.56753359041152, 24.58039897917746, 24.593264367943394, 24.606129756709333,
        24.61899514547527, 24.631860534241206, 24.644725923007144, 24.657591311773082, 24.67045670053902, 24.683322089304955, 24.696187478070893, 24.70905286683683, 24.721918255602766, 24.734783644368704,
        24.747649033134643, 24.76051442190058, 24.773379810666516, 24.786245199432454, 24.799110588198392, 24.811975976964327, 24.824841365730265, 24.837706754496203, 24.85057214326214, 24.863437532028076,
        24.876302920794014, 24.889168309559953, 24.902033698325887, 24.914899087091825, 24.927764475857764, 24.940629864623702, 24.953495253389637, 24.966360642155575, 24.979226030921513, 24.99209141968745,
        25.004956808453386, 25.017822197219324, 25.030687585985262, 25.043552974751197, 25.056418363517135, 25.069283752283074, 25.082149141049012, 25.095014529814947, 25.107879918580885, 25.120745307346823,
        25.133610696112758, 25.146476084878696, 25.159341473644634, 25.172206862410572, 25.185072251176507, 25.197937639942445, 25.210803028708384, 25.223668417474318, 25.236533806240256, 25.249399195006195,
        25.262264583772133, 25.275129972538068, 25.287995361304006, 25.300860750069944, 25.313726138835882, 25.326591527601817, 25.339456916367755, 25.352322305133693, 25.365187693899628, 25.378053082665566,
        25.390918471431505, 25.403783860197443, 25.416649248963378, 25.429514637729316, 25.442380026495254, 25.45524541526119, 25.468110804027127, 25.480976192793065, 25.493841581559003, 25.506706970324938,
        25.519572359090876, 25.532437747856815, 25.54530313662275, 25.558168525388687, 25.571033914154626, 25.583899302920564, 25.5967646916865, 25.609630080452437, 25.622495469218375, 25.635360857984313,
        25.648226246750248, 25.661091635516186, 25.673957024282124, 25.68682241304806, 25.699687801813997, 25.712553190579936, 25.725418579345874, 25.73828396811181, 25.751149356877747, 25.764014745643685,
        25.77688013440962, 25.789745523175558, 25.802610911941496, 25.815476300707434, 25.82834168947337, 25.841207078239307, 25.854072467005246, 25.86693785577118, 25.87980324453712, 25.892668633303057,
        25.905534022068995, 25.91839941083493, 25.931264799600868, 25.944130188366806, 25.956995577132744, 25.96986096589868, 25.982726354664617, 25.995591743430555, 26.00845713219649, 26.02132252096243,
        26.034187909728367, 26.047053298494305, 26.05991868726024, 26.072784076026178, 26.085649464792116, 26.09851485355805, 26.11138024232399, 26.124245631089927, 26.137111019855865, 26.1499764086218,
        26.16284179738774, 26.175707186153677, 26.18857257491961, 26.20143796368555, 26.214303352451488, 26.227168741217426, 26.24003412998336, 26.2528995187493, 26.265764907515237, 26.278630296281175,
        26.29149568504711, 26.304361073813048, 26.317226462578986, 26.33009185134492, 26.34295724011086, 26.355822628876798, 26.368688017642736, 26.38155340640867, 26.39441879517461, 26.407284183940547,
        26.42014957270648, 26.43301496147242, 26.445880350238358, 26.458745739004296, 26.47161112777023, 26.48447651653617, 26.497341905302108, 26.510207294068042, 26.52307268283398, 26.53593807159992,
        26.548803460365857, 26.56166884913179, 26.57453423789773, 26.587399626663668, 26.600265015429606, 26.61313040419554, 26.62599579296148, 26.638861181727417, 26.651726570493352, 26.66459195925929,
        26.67745734802523, 26.690322736791167, 26.7031881255571, 26.71605351432304, 26.728918903088978, 26.741784291854913, 26.75464968062085, 26.76751506938679, 26.780380458152727, 26.793245846918662,
        26.8061112356846, 26.81897662445054, 26.831842013216473, 26.84470740198241, 26.85757279074835, 26.870438179514288, 26.883303568280223, 26.89616895704616, 26.9090343458121, 26.921899734578034,
        26.934765123343972, 26.94763051210991, 26.96049590087585, 26.973361289641783, 26.98622667840772, 26.99909206717366, 27.011957455939598, 27.024822844705533, 27.03768823347147, 27.05055362223741,
        27.063419011003344, 27.076284399769282, 27.08914978853522, 27.10201517730116, 27.114880566067093, 27.12774595483303, 27.14061134359897, 27.153476732364904, 27.166342121130842, 27.17920750989678,
        27.19207289866272, 27.204938287428654, 27.217803676194592, 27.23066906496053, 27.243534453726465, 27.256399842492403, 27.26926523125834, 27.28213062002428, 27.294996008790214, 27.307861397556152,
        27.32072678632209, 27.33359217508803, 27.346457563853964, 27.3593229526199, 27.37218834138584, 27.385053730151775, 27.397919118917713, 27.41078450768365, 27.42364989644959, 27.436515285215524,
        27.449380673981462, 27.4622460627474, 27.475111451513335, 27.487976840279273, 27.50084222904521, 27.51370761781115, 27.526573006577085, 27.539438395343023, 27.55230378410896, 27.565169172874896,
        27.578034561640834, 27.590899950406772, 27.60376533917271, 27.616630727938645, 27.629496116704583, 27.64236150547052, 27.65522689423646, 27.668092283002395, 27.680957671768333, 27.69382306053427,
        27.706688449300206, 27.719553838066144, 27.732419226832082, 27.74528461559802, 27.758150004363955, 27.771015393129893, 27.78388078189583, 27.796746170661766, 27.809611559427704, 27.822476948193643,
        27.83534233695958, 27.848207725725516, 27.861073114491454, 27.873938503257392, 27.886803892023327, 27.899669280789265, 27.912534669555203, 27.92540005832114, 27.938265447087076, 27.951130835853014,
        27.963996224618953, 27.97686161338489, 27.989727002150826, 28.002592390916764, 28.015457779682702, 28.028323168448637, 28.041188557214575, 28.054053945980513, 28.06691933474645, 28.079784723512386,
        28.092650112278324, 28.105515501044263, 28.118380889810197, 28.131246278576135, 28.144111667342074, 28.156977056108012, 28.169842444873947, 28.182707833639885, 28.195573222405823, 28.208438611171758,
        28.221303999937696, 28.234169388703634, 28.247034777469572, 28.259900166235507, 28.272765555001445, 28.285630943767384, 28.298496332533322, 28.311361721299257, 28.324227110065195, 28.337092498831133,
        28.349957887597068, 28.362823276363006, 28.375688665128944, 28.388554053894882, 28.401419442660817, 28.414284831426755, 28.427150220192694, 28.44001560895863, 28.452880997724566, 28.465746386490505,
        28.478611775256443, 28.491477164022378, 28.504342552788316, 28.517207941554254, 28.53007333032019, 28.542938719086127, 28.555804107852065, 28.568669496618003, 28.581534885383938, 28.594400274149876,
        28.607265662915815, 28.62013105168175, 28.632996440447688, 28.645861829213626, 28.658727217979564, 28.6715926067455, 28.684457995511437, 28.697323384277375, 28.710188773043313, 28.723054161809248,
        28.735919550575186, 28.748784939341125, 28.76165032810706, 28.774515716872997, 28.787381105638936, 28.800246494404874, 28.81311188317081, 28.825977271936747, 28.838842660702685, 28.85170804946862,
        28.864573438234558, 28.877438827000496, 28.890304215766434, 28.90316960453237, 28.916034993298307, 28.928900382064246, 28.94176577083018, 28.95463115959612, 28.967496548362057, 28.980361937127995,
        28.99322732589393, 29.006092714659868, 29.018958103425806, 29.031823492191744, 29.04468888095768, 29.057554269723617, 29.070419658489556, 29.08328504725549, 29.09615043602143, 29.109015824787367,
        29.121881213553305, 29.13474660231924, 29.147611991085178, 29.160477379851116, 29.17334276861705, 29.18620815738299, 29.199073546148927, 29.211938934914865, 29.2248043236808, 29.23766971244674,
        29.250535101212677, 29.26340048997861, 29.27626587874455, 29.289131267510488, 29.301996656276426, 29.31486204504236, 29.3277274338083, 29.340592822574237, 29.353458211340175, 29.36632360010611,
        29.37918898887205, 29.392054377637987, 29.40491976640392, 29.41778515516986, 29.430650543935798, 29.443515932701736, 29.45638132146767, 29.46924671023361, 29.482112098999547, 29.494977487765482,
        29.50784287653142, 29.52070826529736, 29.533573654063296, 29.54643904282923, 29.55930443159517, 29.572169820361108, 29.585035209127042, 29.59790059789298, 29.61076598665892, 29.623631375424857,
        29.63649676419079, 29.64936215295673, 29.662227541722668, 29.675092930488606, 29.68795831925454, 29.70082370802048, 29.713689096786418, 29.726554485552352, 29.73941987431829, 29.75228526308423,
        29.765150651850167, 29.7780160406161, 29.79088142938204, 29.803746818147978, 29.816612206913913, 29.82947759567985, 29.84234298444579, 29.855208373211727, 29.868073761977662, 29.8809391507436,
        29.89380453950954, 29.906669928275473, 29.91953531704141, 29.93240070580735, 29.945266094573288, 29.958131483339223, 29.97099687210516, 29.9838622608711, 29.996727649637037, 30.009593038402972,
        30.02245842716891, 30.03532381593485, 30.048189204700783, 30.06105459346672, 30.07391998223266, 30.086785370998598, 30.099650759764533, 30.11251614853047, 30.12538153729641, 30.138246926062344,
        30.151112314828282, 30.16397770359422, 30.17684309236016, 30.189708481126093, 30.20257386989203, 30.21543925865797, 30.228304647423904, 30.241170036189843, 30.25403542495578, 30.26690081372172,
        30.279766202487654, 30.292631591253592, 30.30549698001953, 30.31836236878547, 30.331227757551403, 30.34409314631734, 30.35695853508328, 30.369823923849214, 30.382689312615152, 30.39555470138109,
        30.40842009014703, 30.421285478912964, 30.434150867678902, 30.44701625644484, 30.459881645210775, 30.472747033976713, 30.48561242274265, 30.49847781150859, 30.511343200274524, 30.524208589040462,
        30.5370739778064, 30.549939366572335, 30.562804755338274, 30.575670144104212, 30.58853553287015, 30.601400921636085, 30.614266310402023, 30.62713169916796, 30.639997087933896, 30.652862476699834,
        30.665727865465772, 30.67859325423171, 30.691458642997645, 30.704324031763583, 30.71718942052952, 30.73005480929546, 30.742920198061395, 30.755785586827333, 30.76865097559327, 30.781516364359206,
        30.794381753125144, 30.807247141891082, 30.82011253065702, 30.832977919422955, 30.845843308188893, 30.85870869695483, 30.871574085720766, 30.884439474486705, 30.897304863252643, 30.91017025201858,
        30.923035640784516, 30.935901029550454, 30.948766418316392, 30.961631807082327, 30.974497195848265, 30.987362584614203, 31.00022797338014, 31.013093362146076, 31.025958750912014, 31.038824139677953,
        31.05168952844389, 31.064554917209826, 31.077420305975764, 31.090285694741702, 31.103151083507637, 31.116016472273575, 31.128881861039513, 31.14174724980545, 31.154612638571386, 31.167478027337324,
        31.180343416103263, 31.193208804869197, 31.206074193635136, 31.218939582401074, 31.231804971167012, 31.244670359932947, 31.257535748698885, 31.270401137464823, 31.283266526230758, 31.296131914996696,
        31.308997303762634, 31.321862692528573, 31.334728081294507, 31.347593470060445, 31.360458858826384, 31.373324247592322, 31.386189636358257, 31.399055025124195, 31.41192041389013, 31.424785802656068,
        31.437651191422006, 31.450516580187944, 31.463381968953883, 31.47624735771982, 31.489112746485752, 31.50197813525169, 31.51484352401763, 31.527708912783567, 31.540574301549505, 31.553439690315443,
        31.56630507908138, 31.57917046784732, 31.59203585661325, 31.60490124537919, 31.617766634145127, 31.630632022911065, 31.643497411677004, 31.656362800442942, 31.66922818920888, 31.68209357797481,
        31.69495896674075, 31.707824355506688, 31.720689744272626, 31.733555133038564, 31.746420521804502, 31.75928591057044, 31.77215129933637, 31.78501668810231, 31.797882076868248, 31.810747465634186,
        31.823612854400125, 31.836478243166063, 31.849343631932, 31.862209020697932, 31.87507440946387, 31.88793979822981, 31.900805186995747, 31.913670575761685, 31.926535964527623, 31.93940135329356,
        31.952266742059493, 31.96513213082543, 31.97799751959137, 31.990862908357308, 32.003728297123246, 32.016593685889184, 32.02945907465512, 32.04232446342105, 32.05518985218699, 32.06805524095293,
        32.08092062971887, 32.093786018484806, 32.106651407250745, 32.11951679601668, 32.132382184782614, 32.14524757354855, 32.15811296231449, 32.17097835108043, 32.18384373984637, 32.196709128612305,
        32.20957451737824, 32.22243990614418, 32.23530529491011, 32.24817068367605, 32.26103607244199, 32.27390146120793, 32.286766849973866, 32.299632238739804, 32.31249762750574, 32.32536301627167,
        32.33822840503761, 32.35109379380355, 32.36395918256949, 32.376824571335426, 32.389689960101364, 32.4025553488673, 32.415420737633234, 32.42828612639917, 32.44115151516511, 32.45401690393105,
        32.46688229269699, 32.479747681462925, 32.49261307022886, 32.505478458994794, 32.51834384776073, 32.53120923652667, 32.54407462529261, 32.55694001405855, 32.569805402824485, 32.582670791590424,
        32.595536180356355, 32.60840156912229, 32.62126695788823, 32.63413234665417, 32.64699773542011, 32.659863124186046, 32.672728512951984, 32.685593901717915, 32.698459290483854, 32.71132467924979,
        32.72419006801573, 32.73705545678167, 32.74992084554761, 32.762786234313545, 32.775651623079476, 32.788517011845414, 32.80138240061135, 32.81424778937729, 32.82711317814323, 32.83997856690917,
        32.852843955675105, 32.86570934444104, 32.878574733206975, 32.89144012197291, 32.90430551073885, 32.91717089950479, 32.93003628827073, 32.942901677036666, 32.955767065802604, 32.968632454568535,
        32.98149784333447, 32.99436323210041, 33.00722862086635, 33.02009400963229, 33.032959398398226, 33.045824787164165, 33.058690175930096, 33.071555564696034, 33.08442095346197, 33.09728634222791,
        33.11015173099385, 33.12301711975979, 33.135882508525725, 33.148747897291656, 33.161613286057595, 33.17447867482353, 33.18734406358947, 33.20020945235541, 33.21307484112135, 33.225940229887286,
        33.23880561865322, 33.251671007419155, 33.26453639618509, 33.27740178495103, 33.29026717371697, 33.30313256248291, 33.315997951248846, 33.32886334001478, 33.341728728780716, 33.354594117546654,
        33.36745950631259, 33.38032489507853, 33.39319028384447, 33.40605567261041, 33.41892106137634, 33.431786450142276, 33.444651838908214, 33.45751722767415, 33.47038261644009, 33.48324800520603,
        33.49611339397197, 33.5089787827379, 33.52184417150384, 33.534709560269775, 33.54757494903571, 33.56044033780165, 33.57330572656759, 33.58617111533353, 33.599036504099466, 33.6119018928654,
        33.624767281631335, 33.637632670397274, 33.65049805916321, 33.66336344792915, 33.67622883669509, 33.68909422546103, 33.70195961422696, 33.714825002992896, 33.727690391758834, 33.74055578052477,
        33.75342116929071, 33.76628655805665, 33.77915194682259, 33.79201733558852, 33.80488272435446, 33.817748113120395, 33.83061350188633, 33.84347889065227, 33.85634427941821, 33.86920966818415,
        33.88207505695008, 33.89494044571602, 33.907805834481955, 33.92067122324789, 33.93353661201383, 33.94640200077977, 33.95926738954571, 33.97213277831164, 33.98499816707758, 33.997863555843516,
        34.010728944609454, 34.02359433337539, 34.03645972214133, 34.04932511090727, 34.0621904996732, 34.07505588843914, 34.087921277205076, 34.100786665971015, 34.11365205473695, 34.12651744350289,
        34.13938283226883, 34.15224822103476, 34.1651136098007, 34.17797899856664, 34.190844387332575, 34.20370977609851, 34.21657516486445, 34.22944055363039, 34.24230594239633, 34.25517133116226,
        34.2680367199282, 34.280902108694136, 34.293767497460074, 34.30663288622601, 34.31949827499195, 34.33236366375789, 34.34522905252382, 34.35809444128976, 34.370959830055696, 34.383825218821634,
        34.39669060758757, 34.40955599635351, 34.42242138511945, 34.43528677388538, 34.44815216265132, 34.46101755141726, 34.473882940183195, 34.48674832894913, 34.49961371771507, 34.51247910648101,
        34.52534449524694, 34.53820988401288, 34.55107527277882, 34.563940661544756, 34.576806050310694, 34.58967143907663, 34.60253682784257, 34.6154022166085, 34.62826760537444, 34.64113299414038,
        34.653998382906316, 34.666863771672254, 34.67972916043819, 34.69259454920413, 34.70545993797006, 34.718325326736, 34.73119071550194, 34.74405610426788, 34.756921493033815, 34.76978688179975,
        34.78265227056569, 34.79551765933162, 34.80838304809756, 34.8212484368635, 34.83411382562944, 34.846979214395375, 34.859844603161314, 34.87270999192725, 34.88557538069319, 34.89844076945912,
        34.91130615822506, 34.924171546991, 34.937036935756936, 34.949902324522874, 34.96276771328881, 34.97563310205475, 34.98849849082068, 35.00136387958662, 35.01422926835256, 35.0270946571185,
        35.039960045884435, 35.05282543465037, 35.06569082341631, 35.07855621218224, 35.09142160094818, 35.10428698971412, 35.11715237848006, 35.130017767245995, 35.14288315601193, 35.15574854477787,
        35.1686139335438, 35.18147932230974, 35.19434471107568, 35.20721009984162, 35.220075488607556, 35.232940877373494, 35.24580626613943, 35.25867165490536, 35.2715370436713, 35.28440243243724,
        35.29726782120318, 35.310133209969116, 35.322998598735055, 35.33586398750099, 35.348729376266924, 35.36159476503286, 35.3744601537988, 35.38732554256474, 35.40019093133068, 35.413056320096615,
        35.42592170886255, 35.438787097628484, 35.45165248639442, 35.46451787516036, 35.4773832639263, 35.49024865269224, 35.503114041458176, 35.515979430224114, 35.528844818990045, 35.54171020775598,
        35.55457559652192, 35.56744098528786, 35.5803063740538, 35.593171762819736, 35.606037151585674, 35.61890254035161, 35.631767929117544, 35.64463331788348, 35.65749870664942, 35.67036409541536,
        35.6832294841813, 35.696094872947235, 35.70896026171317, 35.721825650479104, 35.73469103924504, 35.74755642801098, 35.76042181677692, 35.77328720554286, 35.786152594308795, 35.799017983074734,
        35.811883371840665, 35.8247487606066, 35.83761414937254, 35.85047953813848, 35.86334492690442, 35.876210315670356, 35.889075704436294, 35.901941093202225, 35.914806481968164, 35.9276718707341,
        35.94053725950004, 35.95340264826598, 35.96626803703192, 35.979133425797855, 35.991998814563786, 36.004864203329724, 36.01772959209566, 36.0305949808616, 36.04346036962754, 36.05632575839348,
        36.069191147159415, 36.08205653592535, 36.094921924691285, 36.10778731345722, 36.12065270222316, 36.1335180909891, 36.14638347975504, 36.159248868520976, 36.17211425728691, 36.184979646052845,
        36.19784503481878, 36.21071042358472, 36.22357581235066, 36.2364412011166, 36.249306589882536, 36.262171978648475, 36.275037367414406, 36.287902756180344, 36.30076814494628, 36.31363353371222,
        36.32649892247816, 36.3393643112441, 36.352229700010035, 36.365095088775966, 36.377960477541905, 36.39082586630784, 36.40369125507378, 36.41655664383972, 36.42942203260566, 36.442287421371596,
        36.45515281013753, 36.468018198903465, 36.4808835876694, 36.49374897643534, 36.50661436520128, 36.51947975396722, 36.532345142733156, 36.54521053149909, 36.558075920265026, 36.570941309030964,
        36.5838066977969, 36.59667208656284, 36.60953747532878, 36.62240286409472, 36.63526825286065, 36.648133641626586, 36.660999030392524, 36.67386441915846, 36.6867298079244, 36.69959519669034,
        36.71246058545628, 36.72532597422221, 36.73819136298815, 36.751056751754085, 36.76392214052002, 36.77678752928596, 36.7896529180519, 36.80251830681784, 36.81538369558377, 36.82824908434971,
        36.841114473115645, 36.853979861881584, 36.86684525064752, 36.87971063941346, 36.8925760281794, 36.90544141694534, 36.91830680571127, 36.931172194477206, 36.944037583243144, 36.95690297200908,
        36.96976836077502, 36.98263374954096, 36.9954991383069, 37.00836452707283, 37.02122991583877, 37.034095304604705, 37.04696069337064, 37.05982608213658, 37.07269147090252, 37.08555685966846,
        37.09842224843439, 37.11128763720033, 37.124153025966265, 37.137018414732204, 37.14988380349814, 37.16274919226408, 37.17561458103002, 37.18847996979595, 37.20134535856189, 37.214210747327826,
        37.227076136093764, 37.2399415248597, 37.25280691362564, 37.26567230239158, 37.27853769115751, 37.29140307992345, 37.304268468689386, 37.317133857455325, 37.32999924622126, 37.3428646349872,
        37.35573002375314, 37.36859541251907, 37.38146080128501, 37.39432619005095, 37.407191578816885, 37.42005696758282, 37.43292235634876, 37.4457877451147, 37.45865313388063, 37.47151852264657,
        37.48438391141251, 37.497249300178446, 37.510114688944384, 37.52298007771032, 37.53584546647626, 37.54871085524219, 37.56157624400813, 37.57444163277407, 37.587307021540006, 37.600172410305944,
        37.61303779907188, 37.62590318783782, 37.63876857660376, 37.65163396536969, 37.66449935413563, 37.67736474290157, 37.690230131667505, 37.70309552043344, 37.71596090919938, 37.72882629796532,
        37.74169168673125, 37.75455707549719, 37.76742246426313, 37.780287853029066, 37.793153241795004, 37.80601863056094, 37.81888401932688, 37.83174940809281, 37.84461479685875, 37.85748018562469,
        37.870345574390626, 37.883210963156564, 37.8960763519225, 37.90894174068844, 37.92180712945437, 37.93467251822031, 37.94753790698625, 37.96040329575219, 37.973268684518125, 37.98613407328406,
        37.99899946205, 38.01186485081593, 38.02473023958187, 38.03759562834781, 38.05046101711375, 38.063326405879685, 38.076191794645624, 38.08905718341156, 38.10192257217749, 38.11478796094343,
        38.12765334970937, 38.14051873847531, 38.153384127241246, 38.166249516007184, 38.17911490477312, 38.19198029353905, 38.20484568230499, 38.21771107107093, 38.23057645983687, 38.24344184860281,
        38.256307237368745, 38.26917262613468, 38.28203801490062, 38.29490340366655, 38.30776879243249, 38.32063418119843, 38.33349956996437, 38.346364958730305, 38.35923034749624, 38.37209573626218,
        38.38496112502811, 38.39782651379405, 38.41069190255999, 38.42355729132593, 38.436422680091866, 38.449288068857804, 38.46215345762374, 38.47501884638967, 38.48788423515561, 38.50074962392155,
        38.51361501268749, 38.526480401453426, 38.539345790219365, 38.5522111789853, 38.565076567751234, 38.57794195651717, 38.59080734528311, 38.60367273404905, 38.61653812281499, 38.629403511580925,
        38.64226890034686, 38.655134289112794, 38.66799967787873, 38.68086506664467, 38.69373045541061, 38.70659584417655, 38.719461232942486, 38.732326621708424, 38.745192010474355, 38.75805739924029,
        38.77092278800623, 38.78378817677217, 38.79665356553811, 38.809518954304046, 38.822384343069984, 38.835249731835916, 38.848115120601854, 38.86098050936779, 38.87384589813373, 38.88671128689967,
        38.89957667566561, 38.912442064431545, 38.92530745319748, 38.938172841963414, 38.95103823072935, 38.96390361949529, 38.97676900826123, 38.98963439702717, 39.002499785793106, 39.015365174559044,
        39.028230563324975, 39.04109595209091, 39.05396134085685, 39.06682672962279, 39.07969211838873, 39.092557507154666, 39.105422895920604, 39.118288284686535, 39.131153673452474, 39.14401906221841,
        39.15688445098435, 39.16974983975029, 39.18261522851623, 39.195480617282165, 39.208346006048096, 39.221211394814034, 39.23407678357997, 39.24694217234591, 39.25980756111185, 39.27267294987779,
        39.285538338643725, 39.29840372740966, 39.311269116175595, 39.32413450494153, 39.33699989370747, 39.34986528247341, 39.36273067123935, 39.375596060005286, 39.38846144877122, 39.401326837537155,
        39.41419222630309, 39.42705761506903, 39.43992300383497, 39.45278839260091, 39.465653781366846, 39.47851917013278, 39.491384558898716, 39.504249947664654, 39.51711533643059, 39.52998072519653,
        39.54284611396247, 39.55571150272841, 39.56857689149434, 39.581442280260276, 39.594307669026215, 39.60717305779215, 39.62003844655809, 39.63290383532403, 39.64576922408997, 39.658634612855906,
        39.67150000162184, 39.684365390387775, 39.69723077915371, 39.71009616791965, 39.72296155668559, 39.73582694545153, 39.748692334217466, 39.7615577229834, 39.774423111749336, 39.787288500515274,
        39.80015388928121, 39.81301927804715, 39.82588466681309, 39.83875005557903, 39.85161544434496, 39.864480833110896, 39.877346221876834, 39.89021161064277, 39.90307699940871, 39.91594238817465,
        39.92880777694059, 39.94167316570652, 39.95453855447246, 39.967403943238395, 39.98026933200433, 39.99313472077027, 40.00600010953621, 40.01886549830215, 40.03173088706808, 40.04459627583402,
        40.057461664599955, 40.070327053365894, 40.08319244213183, 40.09605783089777, 40.10892321966371, 40.12178860842964, 40.13465399719558, 40.147519385961516, 40.160384774727454, 40.17325016349339,
        40.18611555225933, 40.19898094102527, 40.2118463297912, 40.22471171855714, 40.23757710732308, 40.250442496089015, 40.26330788485495, 40.27617327362089, 40.28903866238683, 40.30190405115277,
        40.3147694399187, 40.32763482868464, 40.340500217450575, 40.353365606216514, 40.36623099498245, 40.37909638374839, 40.39196177251433, 40.40482716128026, 40.4176925500462, 40.430557938812136,
        40.443423327578074, 40.45628871634401, 40.46915410510995, 40.48201949387589, 40.49488488264182, 40.50775027140776, 40.520615660173696, 40.533481048939635, 40.54634643770557, 40.55921182647151,
        40.57207721523745, 40.58494260400338, 40.59780799276932, 40.61067338153526, 40.623538770301195, 40.63640415906713, 40.64926954783307, 40.66213493659901, 40.67500032536494, 40.68786571413088,
        40.70073110289682, 40.713596491662756, 40.726461880428694, 40.73932726919463, 40.75219265796057, 40.7650580467265, 40.77792343549244, 40.79078882425838, 40.803654213024316, 40.816519601790255,
        40.82938499055619, 40.84225037932213, 40.85511576808806, 40.867981156854, 40.88084654561994, 40.89371193438588, 40.906577323151815, 40.91944271191775, 40.93230810068369, 40.94517348944962,
        40.95803887821556, 40.9709042669815, 40.98376965574744, 40.996635044513376, 41.009500433279314, 41.02236582204525, 41.03523121081119, 41.04809659957712, 41.06096198834306, 41.073827377109,
        41.086692765874936, 41.099558154640874, 41.11242354340681, 41.12528893217275, 41.13815432093868, 41.15101970970462, 41.16388509847056, 41.1767504872365, 41.189615876002435, 41.20248126476837,
        41.21534665353431, 41.22821204230024, 41.24107743106618, 41.25394281983212, 41.26680820859806, 41.279673597363995, 41.292538986129934, 41.30540437489587, 41.3182697636618, 41.33113515242774,
        41.34400054119368, 41.35686592995962, 41.369731318725556, 41.382596707491494, 41.39546209625743, 41.408327485023364, 41.4211928737893, 41.43405826255524, 41.44692365132118, 41.45978904008712,
        41.472654428853055, 41.48551981761899, 41.498385206384924, 41.51125059515086, 41.5241159839168, 41.53698137268274, 41.54984676144868, 41.562712150214615, 41.57557753898055, 41.588442927746485,
        41.60130831651242, 41.61417370527836, 41.6270390940443, 41.63990448281024, 41.652769871576176, 41.665635260342114, 41.67850064910805, 41.69136603787398, 41.70423142663992, 41.71709681540586,
        41.7299622041718, 41.742827592937736, 41.755692981703675, 41.76855837046961, 41.781423759235544, 41.79428914800148, 41.80715453676742, 41.82001992553336, 41.8328853142993, 41.845750703065235,
        41.85861609183117, 41.871481480597105, 41.88434686936304, 41.89721225812898, 41.91007764689492, 41.92294303566086, 41.935808424426796, 41.948673813192734, 41.961539201958665, 41.9744045907246,
        41.98726997949054, 42.00013536825648, 42.01300075702242, 42.025866145788356, 42.038731534554294, 42.051596923320226, 42.064462312086164, 42.0773277008521, 42.09019308961804, 42.10305847838398,
        42.11592386714992, 42.128789255915855, 42.141654644681786, 42.154520033447724, 42.16738542221366, 42.1802508109796, 42.19311619974554, 42.20598158851148, 42.218846977277416, 42.23171236604335,
        42.244577754809285, 42.25744314357522, 42.27030853234116, 42.2831739211071, 42.29603930987304, 42.308904698638976, 42.321770087404914, 42.334635476170845, 42.347500864936784, 42.36036625370272,
        42.37323164246866, 42.3860970312346, 42.39896242000054, 42.411827808766475, 42.424693197532406, 42.437558586298344, 42.45042397506428, 42.46328936383022, 42.47615475259616, 42.4890201413621,
        42.501885530128035, 42.51475091889397, 42.527616307659905, 42.54048169642584, 42.55334708519178, 42.56621247395772, 42.57907786272366, 42.591943251489596, 42.60480864025553, 42.617674029021465,
        42.6305394177874, 42.64340480655334, 42.65627019531928, 42.66913558408522, 42.68200097285116, 42.69486636161709, 42.707731750383026, 42.720597139148964, 42.7334625279149, 42.74632791668084,
        42.75919330544678, 42.77205869421272, 42.78492408297865, 42.797789471744586, 42.810654860510525, 42.82352024927646, 42.8363856380424, 42.84925102680834, 42.86211641557428, 42.87498180434021,
        42.88784719310615, 42.900712581872085, 42.91357797063802, 42.92644335940396, 42.9393087481699, 42.95217413693584, 42.96503952570177, 42.97790491446771, 42.990770303233646, 43.003635691999584,
        43.01650108076552, 43.02936646953146, 43.0422318582974, 43.05509724706334, 43.06796263582927, 43.080828024595206, 43.093693413361144, 43.10655880212708, 43.11942419089302, 43.13228957965896,
        43.1451549684249, 43.15802035719083, 43.17088574595677, 43.183751134722705, 43.19661652348864, 43.20948191225458, 43.22234730102052, 43.23521268978646, 43.24807807855239, 43.26094346731833,
        43.273808856084266, 43.286674244850204, 43.29953963361614, 43.31240502238208, 43.32527041114802, 43.33813579991395, 43.35100118867989, 43.363866577445826, 43.376731966211764, 43.3895973549777,
        43.40246274374364, 43.41532813250958, 43.42819352127551, 43.44105891004145, 43.45392429880739, 43.466789687573325, 43.47965507633926, 43.4925204651052, 43.50538585387114, 43.51825124263707,
        43.53111663140301, 43.54398202016895, 43.556847408934885, 43.569712797700824, 43.58257818646676, 43.5954435752327, 43.60830896399863, 43.62117435276457, 43.63403974153051, 43.646905130296446,
        43.659770519062384, 43.67263590782832, 43.68550129659426, 43.6983666853602, 43.71123207412613, 43.72409746289207, 43.73696285165801, 43.749828240423945, 43.76269362918988, 43.77555901795582,
        43.78842440672176, 43.80128979548769, 43.81415518425363, 43.82702057301957, 43.839885961785505, 43.85275135055144, 43.86561673931738, 43.87848212808332, 43.89134751684925, 43.90421290561519,
        43.91707829438113, 43.929943683147066, 43.942809071913004, 43.95567446067894, 43.96853984944488, 43.98140523821081, 43.99427062697675, 44.00713601574269, 44.020001404508626, 44.032866793274565,
        44.0457321820405, 44.05859757080644, 44.07146295957237, 44.08432834833831, 44.09719373710425, 44.11005912587019, 44.122924514636125, 44.13578990340206, 44.148655292168, 44.16152068093393,
        44.17438606969987, 44.18725145846581, 44.20011684723175, 44.212982235997686, 44.225847624763624, 44.23871301352956, 44.25157840229549, 44.26444379106143, 44.27730917982737, 44.29017456859331,
        44.303039957359246, 44.315905346125184, 44.32877073489112, 44.34163612365706, 44.35450151242299, 44.36736690118893, 44.38023228995487, 44.39309767872081, 44.405963067486745, 44.41882845625268,
        44.43169384501862, 44.44455923378455, 44.45742462255049, 44.47029001131643, 44.48315540008237, 44.496020788848305, 44.508886177614244, 44.52175156638018, 44.53461695514611, 44.54748234391205,
        44.56034773267799, 44.57321312144393, 44.586078510209866, 44.598943898975804, 44.61180928774174, 44.624674676507674, 44.63754006527361, 44.65040545403955, 44.66327084280549, 44.67613623157143,
        44.689001620337365, 44.7018670091033, 44.714732397869234, 44.72759778663517, 44.74046317540111, 44.75332856416705, 44.76619395293299, 44.779059341698925, 44.791924730464864, 44.804790119230795,
        44.81765550799673, 44.83052089676267, 44.84338628552861, 44.85625167429455, 44.869117063060486, 44.881982451826424, 44.894847840592355, 44.90771322935829, 44.92057861812423, 44.93344400689017,
        44.94630939565611, 44.959174784422046, 44.972040173187985, 44.984905561953916, 44.997770950719854, 45.01063633948579, 45.02350172825173, 45.03636711701767, 45.04923250578361, 45.062097894549545,
        45.07496328331548, 45.087828672081415, 45.10069406084735, 45.11355944961329, 45.12642483837923, 45.13929022714517, 45.152155615911106, 45.165021004677044, 45.177886393442975, 45.19075178220891,
        45.20361717097485, 45.21648255974079, 45.22934794850673, 45.242213337272666, 45.255078726038604, 45.267944114804536, 45.280809503570474, 45.29367489233641, 45.30654028110235, 45.31940566986829,
        45.33227105863423, 45.345136447400165, 45.358001836166096, 45.370867224932034, 45.38373261369797, 45.39659800246391, 45.40946339122985, 45.42232877999579, 45.435194168761726, 45.44805955752766,
        45.460924946293595, 45.47379033505953, 45.48665572382547, 45.49952111259141, 45.51238650135735, 45.525251890123286, 45.53811727888922, 45.550982667655155, 45.563848056421094, 45.57671344518703,
        45.58957883395297, 45.60244422271891, 45.61530961148485, 45.62817500025078, 45.641040389016716, 45.653905777782654, 45.66677116654859, 45.67963655531453, 45.69250194408047, 45.70536733284641,
        45.718232721612345, 45.73109811037828, 45.743963499144215, 45.75682888791015, 45.76969427667609, 45.78255966544203, 45.79542505420797, 45.808290442973906, 45.82115583173984, 45.834021220505775,
        45.846886609271714, 45.85975199803765, 45.87261738680359, 45.88548277556953, 45.89834816433547, 45.9112135531014, 45.924078941867336, 45.936944330633274, 45.94980971939921, 45.96267510816515,
        45.97554049693109, 45.98840588569703, 46.00127127446296, 46.014136663228896, 46.027002051994835, 46.03986744076077, 46.05273282952671, 46.06559821829265, 46.07846360705859, 46.09132899582452,
        46.10419438459046, 46.117059773356395, 46.12992516212233, 46.14279055088827, 46.15565593965421, 46.16852132842015, 46.18138671718608, 46.19425210595202, 46.207117494717956, 46.219982883483894,
        46.23284827224983, 46.24571366101577, 46.25857904978171, 46.27144443854764, 46.28430982731358, 46.297175216079516, 46.310040604845454, 46.32290599361139, 46.33577138237733, 46.34863677114327,
        46.36150215990921, 46.37436754867514, 46.38723293744108, 46.400098326207015, 46.41296371497295, 46.42582910373889, 46.43869449250483, 46.45155988127077, 46.4644252700367, 46.47729065880264,
        46.490156047568576, 46.503021436334514, 46.51588682510045, 46.52875221386639, 46.54161760263233, 46.55448299139826, 46.5673483801642, 46.580213768930136, 46.593079157696074, 46.60594454646201,
        46.61880993522795, 46.63167532399389, 46.64454071275982, 46.65740610152576, 46.6702714902917, 46.683136879057635, 46.69600226782357, 46.70886765658951, 46.72173304535545, 46.73459843412138,
        46.74746382288732, 46.76032921165326, 46.773194600419195, 46.786059989185134, 46.79892537795107, 46.81179076671701, 46.82465615548294, 46.83752154424888, 46.85038693301482, 46.863252321780756,
        46.876117710546694, 46.88898309931263, 46.90184848807857, 46.9147138768445, 46.92757926561044, 46.94044465437638, 46.95331004314232, 46.966175431908255, 46.97904082067419, 46.99190620944013,
        47.00477159820606, 47.017636986972, 47.03050237573794, 47.04336776450388, 47.056233153269815, 47.06909854203575, 47.08196393080169, 47.09482931956763, 47.10769470833356, 47.1205600970995,
        47.13342548586544, 47.146290874631376, 47.159156263397314, 47.17202165216325, 47.18488704092919, 47.19775242969512, 47.21061781846106, 47.223483207227, 47.236348595992936, 47.249213984758875,
        47.26207937352481, 47.27494476229075, 47.28781015105668, 47.30067553982262, 47.31354092858856, 47.3264063173545, 47.339271706120435, 47.35213709488637, 47.36500248365231, 47.37786787241824,
        47.39073326118418, 47.40359864995012, 47.41646403871606, 47.429329427481996, 47.442194816247934, 47.45506020501387, 47.4679255937798, 47.48079098254574, 47.49365637131168, 47.50652176007762,
        47.519387148843556, 47.532252537609494, 47.54511792637543, 47.557983315141364, 47.5708487039073, 47.58371409267324, 47.59657948143918, 47.60944487020512, 47.622310258971055, 47.63517564773699,
        47.648041036502924,
    ],
};
const RPM2MPH_ATOM_20ABCDE = {
    "PLATFORM_TENNIS": [0, 0, 0, 0, 0, 0, 0, 0, 0.3869144470794245, 0.40061183272985823, 0.4143012300879487, 0.42798265725323487, 0.4416561323150622, 0.455321673352583, 0.46897929843475616, 0.48262902562034726, 0.49627087295792854, 0.509904858485879, 0.5235310002323841, 0.5371493162154362,
        0.5507598244428341, 0.5643625429121837, 0.577957489610897, 0.5915446825161931, 0.6051241395950975, 0.6186958788044427, 0.6322599180908675, 0.6458162753908174, 0.6593649686305452, 0.6729060157261093,
        0.6864394345833758, 0.6999652430980168, 0.7134834591555113, 0.7269941006311449, 0.7404971853900102, 0.7539927312870061, 0.7674807561668382, 0.7809612778640187, 0.7944343142028671, 0.8078998829975086,
        0.821358002051876, 0.834808689159708, 0.8482519621045503, 0.8616878386597557, 0.8751163365884826, 0.8885374736436975, 0.9019512675681722, 0.915357736094486, 0.9287568969450246, 0.9421487678319802,
        0.9555333664573522, 0.9689107105129464, 0.982280817680375, 0.9956437056310572, 1.0089993920262186, 1.022347894516892, 1.035689230743916, 1.049023418337937, 1.0623504749194068, 1.0756704180985852,
        1.0889832654755374, 1.1022890346401364, 1.1155877431720609, 1.1288794086407967, 1.1421640486056366, 1.1554416806156798, 1.1687123222098317, 1.181975990916805, 1.1952327042551192, 1.2084824797330997,
        1.2217253348488792, 1.2349612870903968, 1.2481903539353985, 1.2614125528514366, 1.2746279012958708, 1.2878364167158662, 1.301038116548396, 1.314233018220239, 1.3274211391479813, 1.3406024967380155,
        1.3537771083865406, 1.3669449914795626, 1.3801061633928944, 1.3932606414921547, 1.4064084431327697, 1.4195495856599718, 1.4326840864088006, 1.445811962704102, 1.4589332318605281, 1.4720479111825386,
        1.4851560179643994, 1.4982575694901832, 1.511352583033769, 1.524441075858843, 1.5375230652188978, 1.5505985683572325, 1.5636676025069531, 1.5767301848909725, 1.5897863327220099, 1.602836063202591,
        1.615879393525049, 1.628916340871523, 1.641946922413959, 1.6549711553141095, 1.6679890567235338, 1.6810006437835983, 1.6940059336254754, 1.7070049433701446, 1.7199976901283918, 1.73298419100081,
        1.745964463077798, 1.7589385234395623, 1.7719063891561155, 1.7848680772872771, 1.7978236048826728, 1.8107729889817357, 1.8237162466137051, 1.8366533947976271, 1.8495844505423544, 1.8625094308465464,
        1.8754283526986693, 1.8883412330769958, 1.901248088949605, 1.9141489372743834, 1.9270437949990238, 1.9399326790610254, 1.9528156063876947, 1.9656925938961443, 1.9785636584932933, 1.991428817075868,
        2.004288086530402, 2.017141483733233, 2.0299890255505093, 2.042830728838182, 2.0556666104420116, 2.068496687197564, 2.0813209759302116, 2.0941394934551347, 2.1069522565773187, 2.119759282091557,
        2.1325605867824486, 2.1453561874244005, 2.1581461007816247, 2.1709303436081413, 2.183708932647776, 2.196481884634162, 2.209249216290739, 2.2220109443307527, 2.234767085457257, 2.2475176563631103,
        2.2602626737309794, 2.273002154233337, 2.285736114532463, 2.298464571280444, 2.3111875411191716, 2.323905040680346, 2.3366170865854743, 2.3493236954458685, 2.3620248838626487, 2.3747206684267406,
        2.387411065718878, 2.4000960923095995, 2.4127757647592523, 2.4254500996179886, 2.4381191134257687, 2.4507828227123585, 2.463441243997331, 2.4760943937900666, 2.48874228858975, 2.5013849448853755,
        2.514022379155743, 2.526654607869458, 2.539281647484933, 2.5519035144503897, 2.5645202252038524, 2.577131796173155, 2.5897382437759373, 2.6023395844196453, 2.6149358345015323, 2.627527010408658,
        2.6401131285178887, 2.6526942051958975, 2.665270256799164, 2.677841299673975, 2.690407350156423, 2.7029684245724077, 2.715524539237636, 2.7280757104576208, 2.740621954527682, 2.7531632877329457,
        2.765699726348345, 2.77823128663862, 2.790757984858317, 2.803279837251789, 2.8157968600531955, 2.8283090694865036, 2.840816481765486, 2.8533191130937228, 2.8658169796646, 2.8783100976613114,
        2.890798483256856, 2.903282152614041, 2.915761121885479, 2.92823540721359, 2.9407050247306006, 2.9531699905585445, 2.9656303208092605, 2.9780860315843958, 2.9905371389754034, 3.002983659063543,
        3.015425607919881, 3.0278630016052914, 3.040295856170453, 3.052724187655853, 3.0651480120917847, 3.0775673454983474, 3.089982203885448, 3.1023926032528, 3.1147985595899232, 3.127200088876144,
        3.139597207080595, 3.1519899301622174, 3.164378274069757, 3.176762254741767, 3.1891418881066076, 3.2015171900824453, 3.213888176577253, 3.2262548634888115, 3.238617266704707, 3.2509754021023327,
        3.2633292855488882, 3.2756789329013807, 3.2880243600066232, 3.300365582701236, 3.3127026168116456, 3.325035478154085, 3.337364182534594, 3.3496887457490203, 3.3620091835830164, 3.3743255118120423,
        3.3866377462013655, 3.3989459025060578, 3.4112499964710006, 3.42355004383088, 3.4358460603101895, 3.4481380616232293, 3.460426063474105, 3.472710081556732, 3.4849901315548286, 3.497266229141922,
        3.509538389981346, 3.5218066297262403, 3.5340709640195516, 3.5463314084940336, 3.558587978772246, 3.5708406904665555, 3.5830895591791356, 3.595334600501967, 3.6075758300168355, 3.6198132632953355,
        3.6320469158988664, 3.644276803378635, 3.6565029412756545, 3.668725345120746, 3.6809440304345356, 3.693159012727457, 3.70537030749975, 3.7175779302414615, 3.729781896432445, 3.7419822215423606,
        3.7541789210306757, 3.7663720103466636, 3.778561504929403, 3.790747420207783, 3.8029297716004953, 3.8151085745160414, 3.827283844352727, 3.839455596498666, 3.851623846331779, 3.863788609219793,
        3.8759499005202405, 3.8881077355804625, 3.9002621297376057, 3.9124130983186234, 3.9245606566402764, 3.936704820009131, 3.948845603721561, 3.9609830230637475, 3.973117093311676, 3.98524782973114,
        3.997375247577741, 4.009499362096886, 4.021620188523786, 4.033737742083464, 4.0458520379907466, 4.057963091450266, 4.070070917656464, 4.082175531793586, 4.094276949035687, 4.106375184546628,
        4.118470253480073, 4.130562170979498, 4.142650952178183, 4.154736612199215, 4.166819166155485, 4.178898629149698, 4.190975016274358, 4.20304834261178, 4.215118623234082, 4.227185873203194,
        4.2392501075708475, 4.2513113413785835, 4.263369589657749, 4.275424867429499, 4.287477189704792, 4.299526571484396, 4.311573027758885, 4.323616573508637, 4.335657223703843, 4.3476949933044935,
        4.35972989726039, 4.371761950511139, 4.383791167986156, 4.39581756460466, 4.407841155275678, 4.419861954898043, 4.431879978360398, 4.443895240541188, 4.455907756308667, 4.467917540520896,
        4.479924608025742, 4.491928973660878, 4.503930652253786, 4.515929658621752, 4.527926007571869, 4.53991971390104, 4.551910792395969, 4.563899257833172, 4.575885124978969, 4.587868408589487,
        4.59984912341066, 4.611827284178228, 4.6238029056177385, 4.635776002444546, 4.647746589363809, 4.659714681070497, 4.671680292249383, 4.683643437575047, 4.695604131711877, 4.707562389314066,
        4.719518225025615, 4.731471653480331, 4.743422689301829, 4.755371347103528, 4.767317641488655, 4.779261587050246, 4.791203198371139, 4.803142490023984, 4.815079476571234, 4.827014172565147,
        4.838946592547794, 4.850876751051046, 4.862804662596585, 4.874730341695899, 4.88665380285028, 4.89857506055083, 4.910494129278455, 4.922411023503872, 4.934325757687597, 4.946238346279962,
        4.958148803721098, 4.970057144440945, 4.981963382859254, 4.9938675333855755, 5.005769610419271, 5.017669628349508, 5.029567601555262, 5.04146354440531, 5.053357471258244, 5.0652493964624545,
        5.077139334356144, 5.089027299267318, 5.100913305513793, 5.112797367403188, 5.124679499232931, 5.136559715290256, 5.148438029852202, 5.16031445718562, 5.172189011547161, 5.184061707183286,
        5.195932558330265, 5.207801579214168, 5.219668784050879, 5.231534187046084, 5.243397802395278, 5.25525964428376, 5.267119726886641, 5.278978064368831, 5.290834670885051, 5.302689560579832,
        5.314542747587506, 5.326394246032212, 5.338244070027901, 5.350092233678325, 5.361938751077044, 5.3737836363074285, 5.38562690344265, 5.39746856654569, 5.4093086396693355, 5.421147136856183,
        5.4329840721386295, 5.444819459538886, 5.456653313068965, 5.468485646730687, 5.48031647451568, 5.492145810405379, 5.503973668371024, 5.515800062373662, 5.527625006364149, 5.539448514283143,
        5.551270600061115, 5.563091277618336, 5.57491056086489, 5.586728463700663, 5.598545000015347, 5.610360183688447, 5.622174028589268, 5.6339865485769245, 5.645797757500338, 5.657607669198237,
        5.669416297499153, 5.681223656221429, 5.693029759173213, 5.704834620152458, 5.716638252946924, 5.728440671334182, 5.740241889081604, 5.75204191994637, 5.76384077767547, 5.775638476005696,
        5.787435028663651, 5.7992304493657425, 5.8110247518181835, 5.822817949716995, 5.834610056748006, 5.84640108658685, 5.858191052898968, 5.8699799693396075, 5.881767849553823, 5.893554707176476,
        5.905340555832234, 5.91712540913557, 5.9289092806907675, 5.940692184091913, 5.9524741329229, 5.96425514075743, 5.9760352211590115, 5.987814387680959, 5.999592653866392, 6.01137003324824,
        6.023146539349236, 6.034922185681922, 6.046696985748646, 6.058470953041561, 6.070244101042629, 6.082016443223619, 6.093787993046102, 6.105558763961463, 6.1173287694108875, 6.129098022825371,
        6.140866537625713, 6.152634327222523, 6.164401405016215, 6.17616778439701, 6.187933478744935, 6.199698501429825, 6.211462865811323, 6.223226585238874, 6.234989673051735, 6.2467521425789645,
        6.258514007139432, 6.270275280041812, 6.282035974584585, 6.29379610405604, 6.30555568173427, 6.317314720887177, 6.329073234772469, 6.340831236637661, 6.352588739720073, 6.3643457572468325,
        6.376102302434876, 6.387858388490943, 6.399614028611583, 6.41136923598315, 6.423124023781804, 6.434878405173514, 6.4466323933140535, 6.458386001349005, 6.470139242413757, 6.481892129633502,
        6.493644676123244, 6.505396894987788, 6.51714879932175, 6.528900402209552, 6.54065171672542, 6.55240275593339, 6.564153532887304, 6.575904060630809, 6.587654352197359, 6.599404420610216,
        6.611154278882449, 6.62290394001693, 6.634653417006343, 6.646402722833175, 6.6581518704697205, 6.66990087287808, 6.681649743010163, 6.693398493807684, 6.705147138202164, 6.7168956891149305,
        6.728644159457119, 6.74039256212967, 6.752140910023333, 6.7638892160186606, 6.775637492986016, 6.787385753785567, 6.799134011267288, 6.8108822782709595, 6.822630567626171, 6.834378892152317,
        6.846127264658597, 6.8578756979440225, 6.8696242047974065, 6.881372797997369, 6.89312149031234, 6.904870294500554, 6.916619223310052, 6.928368289478682, 6.940117505734099, 6.951866884793764,
        6.963616439364946, 6.975366182144719, 6.987116125819966, 6.998866283067373, 7.010616666553435, 7.022367288934457, 7.034118162856542, 7.045869300955608, 7.057620715857376, 7.069372420177373,
        7.081124426520935, 7.092876747483205, 7.104629395649128, 7.11638238359346, 7.128135723880764, 7.1398894290654065, 7.151643511691562, 7.163397984293214, 7.1751528593941485, 7.186908149507963,
        7.198663867138057, 7.2104200247776395, 7.222176634909725, 7.233933710007135, 7.245691262532498, 7.25744930493825, 7.26920784966663, 7.280966909149688, 7.292726495809281, 7.304486622057066,
        7.316247300294513, 7.328008542912899, 7.339770362293304, 7.3515327708066165, 7.36329578081353, 7.375059404664549, 7.386823654699979, 7.3985885432499385, 7.410354082634344, 7.422120285162928,
        7.433887163135226, 7.445654728840576, 7.457422994558128, 7.469191972556837, 7.4809616750954655, 7.492732114422581, 7.504503302776558, 7.51627525238558, 7.528047975467634, 7.539821484230515,
        7.551595790871826, 7.563370907578973, 7.575146846529173, 7.586923619889447, 7.598701239816624, 7.610479718457338, 7.62225906794803, 7.634039300414952, 7.645820427974156, 7.657602462731504,
        7.669385416782665, 7.681169302213115, 7.6929541310981335, 7.7047399155028105, 7.716526667482041, 7.728314399080526, 7.740103122332775, 7.751892849263103, 7.763683591885631, 7.775475362204287,
        7.7872681722128085, 7.799062033894734, 7.810856959223416, 7.822652960162006, 7.834450048663468, 7.8462482366705695, 7.858047536115885, 7.869847958921797, 7.881649517000496, 7.893452222253974,
        7.905256086574035, 7.917061121842286, 7.928867339930144, 7.940674752698828, 7.95248337199937, 7.964293209672603, 7.97610427754917, 7.987916587449518, 7.999730151183903, 8.011544980552387,
        8.023361087344838, 8.035178483340935, 8.046997180310154, 8.058817190011787, 8.07063852419493, 8.082461194598482, 8.094285212951153, 8.106110590971458, 8.11793734036772, 8.129765472838066,
        8.141595000070433, 8.153425933742561, 8.165258285522002, 8.177092067066107, 8.188927290022042, 8.200763966026772, 8.212602106707074, 8.22444172367953, 8.236282828550529, 8.248125432916265,
        8.259969548362742, 8.271815186465766, 8.283662358790954, 8.295511076893728, 8.307361352319317, 8.319213196602757, 8.331066621268887, 8.342921637832358, 8.354778257797625, 8.366636492658952,
        8.378496353900402, 8.390357852995857, 8.402221001408995, 8.414085810593306, 8.425952291992084, 8.437820457038434, 8.449690317155262, 8.461561883755286, 8.473435168241025, 8.48531018200481,
        8.497186936428776, 8.509065442884863, 8.520945712734822, 8.532827757330208, 8.544711588012385, 8.556597216112518, 8.568484652951584, 8.580373909840366, 8.592264998079452, 8.60415792895924,
        8.616052713759927, 8.627949363751526, 8.639847890193849, 8.651748304336522, 8.663650617418973, 8.675554840670435, 8.687460985309952, 8.699369062546374, 8.711279083578352, 8.723191059594354,
        8.735105001772645, 8.747020921281301, 8.758938829278208, 8.770858736911048, 8.782780655317321, 8.79470459562433, 8.806630568949181, 8.818558586398792, 8.830488659069882, 8.842420798048984,
        8.85435501441243, 8.866291319226365, 8.878229723546736, 8.8901702384193, 8.902112874879618, 8.914057643953061, 8.926004556654801, 8.937953623989822, 8.949904856952914, 8.961858266528672,
        8.9738138636915, 8.985771659405604, 8.997731664624999, 9.00969389029351, 9.021658347344765, 9.0336250467022, 9.045593999279056, 9.057565215978384, 9.069538707693038, 9.08151448530568,
        9.093492559688782, 9.105472941704615, 9.117455642205266, 9.129440672032622, 9.141428042018378, 9.153417762984038, 9.165409845740909, 9.177404301090107, 9.189401139822555, 9.201400372718984,
        9.213402010549926, 9.225406064075726, 9.237412544046531, 9.2494214612023, 9.261432826272792, 9.273446649977576, 9.285462943026031, 9.297481716117337, 9.309502979940484, 9.32152674517427,
        9.33355302248729, 9.34558182253796, 9.357613155974496, 9.369647033434916, 9.38168346554705, 9.393722462928537, 9.405764036186817, 9.41780819591914, 9.42985495271256, 9.441904317143942,
        9.453956299779955, 9.466010911177072, 9.478068161881579, 9.490128062429562, 9.50219062334692, 9.514255855149353, 9.52632376834237, 9.538394373421289, 9.55046768087123, 9.562543701167126,
        9.57462244477371, 9.586703922145523, 9.598788143726917, 9.610875119952047, 9.622964861244876, 9.635057378019173, 9.647152680678511, 9.659250779616277, 9.671351685215662, 9.683455407849653,
        9.69556195788106, 9.70767134566249, 9.71978358153636, 9.73189867583489, 9.744016638880112, 9.75613748098386, 9.768261212447777, 9.780387843563314, 9.792517384611727, 9.804649845864075,
        9.81678523758123, 9.828923570013869, 9.84106485340247, 9.853209097977327, 9.865356313958536, 9.877506511555996, 9.88965970096942, 9.90181589238832, 9.913975095992024, 9.926137321949657,
        9.938302580420157, 9.950470881552265, 9.96264223548453, 9.974816652345313, 9.98699414225277, 9.999174715314876, 10.011358381629401, 10.023545151283935, 10.035735034355861, 10.047928040912378,
        10.060124181010488, 10.072323464697003, 10.084525902008535, 10.09673150297151, 10.108940277602155, 10.121152235906507, 10.133367387880407, 10.145585743509509, 10.157807312769265, 10.170032105624937,
        10.1822601320316, 10.194491401934124, 10.206725925267195, 10.218963711955302, 10.231204771912742, 10.243449115043616, 10.255696751241832, 10.267947690391109, 10.28020194236497, 10.292459517026744,
        10.304720424229567, 10.31698467381638, 10.329252275619934, 10.341523239462788, 10.3537975751573, 10.36607529250564, 10.378356401299788, 10.390640911321523, 10.402928832342436, 10.415220174123926,
        10.42751494641719, 10.439813158963242, 10.452114821492895, 10.464419943726776, 10.476728535375312, 10.489040606138738, 10.5013561657071, 10.513675223760245, 10.52599778996783, 10.538323873989318,
        10.550653485473982, 10.562986634060891, 10.575323329378934, 10.587663581046796, 10.600007398672977, 10.61235479185578, 10.624705770183311, 10.63706034323349, 10.649418520574038, 10.661780311762486,
        10.674145726346167, 10.686514773862228, 10.698887463837616, 10.71126380578909, 10.723643809223212, 10.736027483636349, 10.748414838514678, 10.760805883334186, 10.773200627560659, 10.785599080649693,
        10.798001252046694, 10.810407151186869, 10.822816787495237, 10.835230170386616, 10.84764730926564, 10.860068213526747, 10.872492892554174, 10.884921355721977, 10.897353612394008, 10.90978967192393,
        10.922229543655217, 10.93467323692114, 10.947120761044788, 10.959572125339045, 10.972027339106612, 10.984486411639987, 10.996949352221487, 11.009416170123218, 11.021886874607114, 11.0343614749249,
        11.04683998031811, 11.05932240001809, 11.07180874324599, 11.084299019212766, 11.096793237119181, 11.109291406155805, 11.121793535503013, 11.13429963433099, 11.146809711799724, 11.159323777059015,
        11.171841839248462, 11.184363907497476, 11.196889990925277, 11.209420098640882, 11.221954239743125, 11.234492423320642, 11.247034658451875, 11.259580954205076, 11.2721313196383, 11.28468576379941,
        11.297244295726076, 11.309806924445775, 11.32237365897579, 11.334944508323211, 11.347519481484932, 11.360098587447663, 11.372681835187906, 11.385269233671982, 11.397860791856013, 11.410456518685931,
        11.42305642309747, 11.435660514016176, 11.448268800357393, 11.460881291026283, 11.47349799491781, 11.48611892091674, 11.498744077897651, 11.511373474724929, 11.52400712025276, 11.536645023325143,
        11.54928719277588, 11.56193363742858, 11.574584366096664, 11.587239387583352, 11.599898710681675, 11.612562344174467, 11.625230296834374, 11.637902577423846, 11.65057919469514, 11.663260157390319,
        11.675945474241251, 11.688635153969614, 11.701329205286894, 11.714027636894377, 11.72673045748316, 11.73943767573415, 11.752149300318054, 11.764865339895389, 11.777585803116478, 11.790310698621454,
        11.803040035040251, 11.815773820992613, 11.82851206508809, 11.841254775926037, 11.854001962095621, 11.866753632175811, 11.87950979473538, 11.892270458332918, 11.90503563151681, 11.917805322825256,
        11.930579540786255, 11.943358293917623, 11.956141590726972, 11.968929439711728, 11.98172184935912, 11.994518828146186, 12.007320384539765, 12.020126526996515, 12.032937263962888, 12.045752603875146,
        12.058572555159362, 12.071397126231414, 12.08422632549698, 12.097060161351557, 12.109898642180436, 12.122741776358724, 12.135589572251332, 12.148442038212973, 12.161299182588175, 12.174161013711263,
        12.187027539906378, 12.199898769487463, 12.212774710758266, 12.225655372012348, 12.238540761533068, 12.251430887593598, 12.264325758456915, 12.277225382375804, 12.290129767592854, 12.30303892234046,
        12.315952854840829, 12.32887157330597, 12.341795085937697, 12.35472340092764, 12.367656526457223, 12.380594470697686, 12.393537241810073, 12.406484847945233, 12.419437297243821, 12.432394597836307,
        12.445356757842953, 12.458323785373844, 12.471295688528858, 12.484272475397688, 12.497254154059828, 12.510240732584586, 12.523232219031069, 12.536228621448197, 12.549229947874691, 12.562236206339081,
        12.575247404859708, 12.58826355144471, 12.601284654092042, 12.61431072078946, 12.627341759514524, 12.64037777823461, 12.653418784906892, 12.666464787478356, 12.679515793885786, 12.692571812055787,
        12.70563284990476, 12.718698915338912, 12.731770016254265, 12.74484616053664, 12.75792735606167, 12.771013610694787, 12.784104932291239, 12.797201328696074, 12.810302807744153, 12.823409377260136,
        12.836521045058497, 12.849637818943506, 12.862759706709255, 12.87588671613963, 12.88901885500833, 12.902156131078856, 12.915298552104522, 12.928446125828444, 12.941598859983545, 12.954756762292558,
        12.967919840468014, 12.981088102212265, 12.994261555217454, 13.007440207165544, 13.020624065728294, 13.03381313856728, 13.047007433333874, 13.060206957669264, 13.073411719204437, 13.086621725560192,
        13.099836984347133, 13.11305750316567, 13.126283289606022, 13.13951435124821, 13.152750695662068, 13.165992330407226, 13.179239263033137, 13.192491501079047, 13.205749052074014, 13.219011923536902,
        13.232280122976382, 13.24555365789093, 13.25883253576883, 13.272116764088173, 13.285406350316858, 13.298701301912587, 13.312001626322871, 13.325307330985028, 13.338618423326182, 13.351934910763262,
        13.365256800703005, 13.378584100541957, 13.391916817666468, 13.405254959452698, 13.418598533266605, 13.431947546463965, 13.445302006390351, 13.45866192038115, 13.472027295761553, 13.485398139846556,
        13.498774459940963, 13.512156263339387, 13.525543557326241, 13.538936349175753, 13.552334646151952, 13.565738455508674, 13.579147784489566, 13.592562640328078, 13.605983030247465, 13.619408961460794,
        13.632840441170934, 13.646277476570564, 13.659720074842166, 13.673168243158031, 13.68662198868026, 13.700081318560752, 13.713546239941222, 13.727016759953184, 13.740492885717964, 13.753974624346693,
        13.767461982940308, 13.780954968589551, 13.794453588374978, 13.807957849366941, 13.821467758625607, 13.834983323200946, 13.848504550132738, 13.862031446450562, 13.875564019173813, 13.889102275311686,
        13.902646221863188, 13.916195865817128, 13.929751214152125, 13.9433122738366, 13.956879051828786, 13.970451555076721, 13.984029790518248, 13.99761376508102, 14.011203485682488, 14.024798959229928,
        14.038400192620399, 14.052007192740785, 14.06561996646777, 14.079238520667843, 14.092862862197302, 14.106492997902253, 14.120128934618602, 14.133770679172075, 14.147418238378188, 14.161071619042277,
        14.174730827959475, 14.18839587191473, 14.202066757682795, 14.215743492028222, 14.22942608170538, 14.243114533458437, 14.25680885402137, 14.270509050117965, 14.284215128461817, 14.297927095756316,
        14.31164495869467, 14.32536872395989, 14.339098398224793, 14.352833988152005, 14.366575500393955, 14.380322941592881, 14.39407631838083, 14.40783563737965, 14.421600905200998, 14.43537212844634,
        14.449149313706949, 14.462932467563899, 14.476721596588076, 14.49051670734017, 14.50431780637068, 14.518124900219913, 14.531937995417975, 14.545757098484787, 14.559582215930071, 14.573413354253361,
        14.587250519943991, 14.60109371948111, 14.614942959333666, 14.628798245960414, 14.642659585809927, 14.656526985320568, 14.67040045092052, 14.684279989027763, 14.69816560605009, 14.712057308385102,
        14.725955102420198, 14.739858994532591, 14.753768991089302, 14.767685098447151, 14.781607322952773, 14.795535670942604, 14.809470148742887, 14.823410762669674, 14.837357519028824, 14.851310424116003,
        14.865269484216679, 14.879234705606128, 14.893206094549443, 14.907183657301506, 14.92116740010702, 14.935157329200486, 14.949153450806218, 14.963155771138334, 14.977164296400758, 14.991179032787219,
        15.00519998648126, 15.01922716365622, 15.033260570475253, 15.047300213091319, 15.061346097647176, 15.075398230275402, 15.08945661709837, 15.10352126422827, 15.117592177767088, 15.131669363806624,
        15.145752828428485, 15.159842577704078, 15.173938617694624, 15.188040954451147, 15.202149594014475, 15.216264542415251, 15.230385805673917, 15.244513389800725, 15.258647300795731, 15.272787544648804,
        15.286934127339611, 15.301087054837632, 15.31524633310215, 15.32941196808226, 15.343583965716856, 15.357762331934646, 15.37194707265414, 15.386138193783655, 15.400335701221316, 15.414539600855056,
        15.428749898562609, 15.442966600211525, 15.457189711659153, 15.47141923875265, 15.485655187328982, 15.499897563214923, 15.514146372227042, 15.528401620171733, 15.542663312845187, 15.556931456033396,
        15.571206055512171, 15.585487117047117, 15.599774646393659, 15.614068649297018, 15.628369131492224, 15.642676098704118, 15.656989556647344, 15.671309511026353, 15.685635967535404, 15.69996893185856,
        15.714308409669695, 15.728654406632485, 15.743006928400414, 15.757365980616777, 15.771731568914669, 15.786103698916998, 15.800482376236474, 15.814867606475612, 15.82925939522674, 15.84365774807199,
        15.858062670583301, 15.872474168322412, 15.88689224684088, 15.901316911680064, 15.915748168371126, 15.930186022435038, 15.944630479382576, 15.959081544714332, 15.973539223920689, 15.988003522481849,
        16.00247444586782, 16.01695199953841, 16.031436188943236, 16.045927019521727, 16.06042449670311, 16.074928625906427, 16.089439412540518, 16.103956862004043, 16.118480979685454, 16.133011770963016,
        16.147549241204803, 16.162093395768693, 16.176644240002368, 16.191201779243322, 16.205766018818853, 16.22033696404607, 16.234914620231876, 16.249498992672997, 16.26409008665595, 16.27868790745708,
        16.29329246034251, 16.307903750568194, 16.322521783379884, 16.337146564013132, 16.35177809769331, 16.366416389635585, 16.38106144504494, 16.395713269116154, 16.410371867033824, 16.425037243972344,
        16.439709405095925, 16.45438835555857, 16.469074100504105, 16.48376664506615, 16.498465994368143, 16.513172153523318, 16.52788512763472, 16.5426049217952, 16.557331541087414, 16.572064990583836,
        16.586805275346734, 16.60155240042818, 16.616306370870067, 16.631067191704084, 16.64583486795173, 16.66060940462431, 16.675390806722934, 16.69017907923852, 16.704974227151798, 16.719776255433292,
        16.73458516904335, 16.749400972932108, 16.764223672039527, 16.779053271295357, 16.793889775619167, 16.80873318992033, 16.82358351909802, 16.83844076804123, 16.853304941628743, 16.868176044729164,
        16.883054082200896, 16.89793905889215, 16.912830979640944, 16.927729849275103, 16.942635672612266, 16.95754845445986, 16.972468199615136, 16.987394912865152, 17.002328598986754, 17.017269262746616,
        17.032216908901212, 17.04717154219681, 17.062133167369506, 17.077101789145182, 17.092077412239547, 17.1070600413581, 17.122049681196152, 17.137046336438825, 17.152050011761045, 17.16706071182754,
        17.18207844129285, 17.19710320480132, 17.212135006987104, 17.22717385247416, 17.242219745876252, 17.257272691796953, 17.272332694829643, 17.287399759557506, 17.30247389055353, 17.31755509238052,
        17.33264336959108, 17.347738726727616, 17.362841168322355, 17.37795069889732, 17.393067322964338, 17.408191045025053, 17.42332186957091, 17.43845980108316, 17.45360484403286, 17.468757002880878,
        17.483916282077885, 17.49908268606436, 17.514256219270585, 17.52943688611666, 17.544624691012476, 17.55981963835774, 17.575021732541966, 17.590230977944476, 17.60544737893439, 17.62067093987064,
        17.635901665101965, 17.651139558966914, 17.666384625793835, 17.681636869900892, 17.696896295596048, 17.71216290717707, 17.727436708931545, 17.742717705136855, 17.758005900060187, 17.773301297958547,
        17.788603903078737, 17.803913719657373, 17.819230751920866, 17.834555004085452, 17.849886480357153, 17.865225184931816, 17.880571121995082, 17.895924295722406, 17.91128471027904, 17.926652369820058,
        17.94202727849033, 17.95740944042453, 17.97279885974715, 17.988195540572477, 18.003599487004614, 18.019010703137464, 18.03442919305474, 18.04985496082996, 18.065288010526455, 18.08072834619735,
        18.096175971885586, 18.111630891623907, 18.12709310943487, 18.142562629330833, 18.158039455313958, 18.173523591376217, 18.189015041499395, 18.204513809655072, 18.220019899804644, 18.235533315899303,
        18.251054061880065, 18.266582141677734, 18.282117559212935, 18.29766031839609, 18.31321042312743, 18.328767877296993, 18.344332684784632, 18.359904849459994, 18.375484375182538, 18.391071265801532,
        18.406665525156043, 18.42226715707496, 18.43787616537696, 18.453492553870536, 18.46911632635399, 18.484747486615426, 18.500386038432758, 18.516031985573704, 18.53168533179579, 18.547346080846346,
        18.563014236462514, 18.57868980237124, 18.594372782289277, 18.61006317992318, 18.625760998969316, 18.64146624311386, 18.65717891603279, 18.67289902139189, 18.688626562846753, 18.704361544042783,
        18.720103968615177, 18.735853840188955, 18.751611162378936, 18.767375938789737, 18.7831481730158, 18.79892786864136, 18.814715029240464, 18.83050965837696, 18.846311759604514, 18.86212133646659,
        18.87793839249646, 18.8937629312172, 18.9095949561417, 18.925434470772647, 18.94128147860255, 18.957135983113705, 18.972997987778225, 18.988867496058038, 19.004744511404862, 19.020629037260235,
        19.03652107705549, 19.052420634211778, 19.068327712140047, 19.084242314241056, 19.10016444390538, 19.116094104513383, 19.132031299435248, 19.147976032030957, 19.163928305650305, 19.179888123632892,
        19.19585548930812, 19.211830405995208, 19.22781287700317, 19.243802905630837, 19.259800495166836, 19.27580564888961, 19.2918183700674, 19.307838661958268, 19.32386652781006, 19.339901970860456,
        19.355944994336923, 19.371995601456735, 19.388053795426984, 19.404119579444558, 19.420192956696166, 19.4362739303583, 19.452362503597286, 19.468458679569235, 19.484562461420076, 19.500673852285537,
        19.51679285529117, 19.532919473552305, 19.549053710174107, 19.56519556825153, 19.581345050869334, 19.597502161102103, 19.61366690201421, 19.629839276659844, 19.646019288083, 19.662206939317464,
        19.67840223338686, 19.69460517330459, 19.710815762073878, 19.727034002687745, 19.74325989812903, 19.759493451370364, 19.775734665374202, 19.79198354309279, 19.808240087468192, 19.824504301432274,
        19.840776187906705, 19.85705574980297, 19.873342990022348, 19.88963791145594, 19.90594051698464, 19.922250809479152, 19.938568791799998, 19.954894466797487, 19.97122783731175, 19.987568906172722,
        20.003917676200135, 20.020274150203544, 20.036638330982296, 20.053010221325557, 20.069389824012287, 20.085777141811256, 20.10217217748105, 20.118574933770056, 20.134985413416462, 20.151403619148272,
        20.167829553683287, 20.184263219729125, 20.200704619983203, 20.217153757132746, 20.23361063385479, 20.250075252816174, 20.266547616673538, 20.283027728073346, 20.299515589651847, 20.316011204035117,
        20.33251457383902, 20.34902570166924, 20.365544590121264, 20.382071241780384, 20.398605659221698, 20.415147845010114, 20.431697801700345, 20.44825553183691, 20.464821037954135, 20.481394322576154,
        20.497975388216904, 20.514564237380135, 20.531160872559397, 20.547765296238055, 20.56437751088927, 20.580997518976012, 20.597625322951064, 20.61426092525702, 20.630904328326263, 20.647555534580995,
        20.664214546433225, 20.680881366284765, 20.69755599652723, 20.714238439542054, 20.730928697700467, 20.74762677336351, 20.764332668882023, 20.781046386596664, 20.797767928837896, 20.81449729792598,
        20.831234496170985, 20.847979525872802, 20.864732389321112, 20.88149308879541, 20.898261626564988, 20.915038004888963, 20.931822226016244, 20.94861429218555, 20.9654142056254, 20.982221968554143,
        20.999037583179906, 21.01586105170064, 21.0326923763041, 21.049531559167846, 21.06637860245924, 21.083233508335454, 21.100096278943475, 21.116966916420086, 21.133845422891874, 21.150731800475253,
        21.167626051276415, 21.184528177391385, 21.20143818090597, 21.218356063895808, 21.235281828426327, 21.25221547655277, 21.26915701032018, 21.28610643176341, 21.30306374290712, 21.32002894576578,
        21.337002042343663, 21.353983034634844, 21.370971924623213, 21.38796871428246, 21.404973405576094, 21.421986000457412, 21.43900650086953, 21.45603490874537, 21.473071226007654, 21.49011545456892,
        21.507167596331506, 21.52422765318756, 21.541295627019032, 21.558371519697687, 21.575455333085085, 21.592547069032605, 21.609646729381424, 21.62675431596253, 21.643869830596714, 21.66099327509458,
        21.678124651256528, 21.69526396087278, 21.712411205723352, 21.729566387578064, 21.746729508196562, 21.76390056932828, 21.78107957271246, 21.798266520078162, 21.815461413144245, 21.83266425361937,
        21.849875043202022, 21.867093783580465, 21.8843204764328, 21.901555123426913, 21.91879772622051, 21.93604828646109, 21.95330680578597, 21.97057328582227, 21.987847728186917, 22.005130134486645,
        22.022420506317992, 22.039718845267302, 22.05702515291074, 22.074339430814256, 22.091661680533615, 22.1089919036144, 22.126330101591982, 22.143676275991556, 22.161030428328107, 22.17839256010644,
        22.195762672821164, 22.21314076795668, 22.230526846987225, 22.247920911376816, 22.265322962579294, 22.28273300203829, 22.30015103118725, 22.317577051449437, 22.335011064237907, 22.352453070955526,
        22.36990307299497, 22.387361071738713, 22.404827068559044, 22.42230106481806, 22.439783061867665, 22.457273061049552, 22.474771063695247, 22.492277071126068, 22.509791084653134, 22.52731310557739,
        22.54484313518957, 22.56238117477022, 22.579927225589696, 22.597481288908153, 22.615043365975566, 22.632613458031706, 22.650191566306155, 22.667777692018287, 22.68537183637731, 22.702974000582223,
        22.72058418582183, 22.73820239327474, 22.755828624109384, 22.773462879483976, 22.791105160546557, 22.80875546843497, 22.82641380427686, 22.844080169189677, 22.86175456428068, 22.87943699064694,
        22.897127449375333, 22.91482594154254, 22.932532468215037, 22.950247030449127, 22.96796962929091, 22.985700265776288, 23.00343894093098, 23.02118565577051, 23.038940411300192, 23.05670320851517,
        23.07447404840038, 23.092252931930574, 23.110039860070298, 23.127834833773917, 23.145637853985598, 23.163448921639315, 23.181268037658853, 23.199095202957785, 23.216930418439517, 23.234773684997247,
        23.252625003513984, 23.270484374862534, 23.28835179990552, 23.306227279495378, 23.32411081447433, 23.342002405674425, 23.359902053917505, 23.377809760015232, 23.395725524769052, 23.41364934897024,
        23.43158123339987, 23.449521178828824, 23.467469186017787, 23.485425255717256, 23.503389388667525, 23.521361585598704, 23.53934184723071, 23.55733017427326, 23.575326567425883, 23.59333102737791,
        23.61134355480849, 23.629364150386557, 23.647392814770875, 23.665429548609996, 23.6834743525423, 23.70152722719595, 23.719588173188928, 23.737657191129024, 23.755734281613837, 23.773819445230753,
        23.79191268255699, 23.810013994159558, 23.828123380595287, 23.846240842410793, 23.86436638014251, 23.882499994316685, 23.900641685449358, 23.918791454046392, 23.936949300603438, 23.95511522560597,
        23.97328922952926, 23.99147131283839, 24.009661475988242, 24.027859719423514, 24.046066043578712, 24.06428044887813, 24.082502935735896, 24.10073350455592, 24.118972155731935, 24.137218889647475,
        24.155473706675878, 24.173736607180295, 24.192007591513672, 24.210286660018777, 24.22857381302818, 24.246869050864248, 24.26517237383916, 24.283483782254915, 24.301803276403295, 24.320130856565903,
        24.33846652301415, 24.356810276009256, 24.375162115802226, 24.3935220426339, 24.411890056734904, 24.43026615832569, 24.448650347616493, 24.46704262480737, 24.48544299008818, 24.503851443638606,
        24.522267985628105, 24.54069261621596, 24.55912533555127, 24.577566143772913, 24.5960150410096, 24.614472027379833, 24.63293710299193, 24.651410267944016, 24.669891522324004, 24.68838086620964,
        24.706878299668467, 24.725383822757827, 24.743897435524868, 24.762419138006564, 24.780948930229673, 24.799486812210777, 24.81803278395624, 24.836586845462275, 24.855148996714856, 24.873719237689794,
        24.89229756835269, 24.910883988658963, 24.92947849855383, 24.948081097972324, 24.96669178683927, 24.985310565069323, 25.003937432566914, 25.02257238922631, 25.041215434931566, 25.059866569556547,
        25.07852579296494, 25.097193105010213, 25.11586850553566, 25.134551994374366, 25.15324357134924, 25.17194323627299, 25.190650988948132, 25.20936682916698, 25.22809075671167, 25.246822771354125,
        25.265562872856094, 25.284311060969124, 25.303067335434566, 25.321831695983587, 25.34060414233715, 25.359384674206026, 25.378173291290803, 25.396969993281868, 25.41577477985941, 25.434587650693437,
        25.453408605443748, 25.472237643759964, 25.491074765281507, 25.509919969637593, 25.528773256447273, 25.54763462531938, 25.56650407585256, 25.585381607635266, 25.604267220245774, 25.623160913252125,
        25.642062686212217, 25.660972538673718, 25.679890470174122, 25.698816480240726, 25.71775056839062, 25.73669273413072, 25.755642976957738, 25.7746012963582, 25.79356769180842, 25.812542162774548,
        25.831524708712518, 25.85051532906808, 25.869514023276786, 25.888520790764, 25.907535630944892, 25.926558543224424, 25.94558952699739, 25.96462858164837, 25.983675706551768, 26.00273090107177,
        26.021794164562404, 26.04086549636747, 26.05994489582059, 26.07903236224519, 26.098127894954512, 26.117231493251598, 26.136343156429287, 26.155462883770245, 26.174590674546916, 26.193726528021585,
        26.21287044344632, 26.232022420063, 26.251182457103315, 26.27035055378876, 26.289526709330634, 26.30871092293005, 26.327903193777914, 26.347103521054958, 26.3663119039317, 26.385528341568477,
        26.40475283311544, 26.423985377712523, 26.44322597448949, 26.462474622565892, 26.48173132105111, 26.50099606904431, 26.520268865634474, 26.539549709900395, 26.558838600910658, 26.578135537723675,
        26.597440519387654, 26.616753544940593, 26.636074613410333, 26.655403723814494, 26.67474087516051, 26.69408606644562, 26.71343929665688, 26.732800564771136, 26.752169869755054, 26.7715472105651,
        26.79093258614755, 26.81032599543849, 26.8297274373638, 26.849136910839178, 26.86855441477012, 26.887979948051946, 26.90741350956976, 26.926855098198487, 26.946304712802856, 26.9657623522374,
        26.985228015346465, 27.00470170096419, 27.024183407914542, 27.04367313501127, 27.06317088105795, 27.082676644847954, 27.102190425164462, 27.121712220780463, 27.141242030458756, 27.16077985295194,
        27.180325687002416, 27.199879531342408, 27.21944138469393, 27.239011245768822, 27.25858911326871, 27.278174985885034, 27.29776886229904, 27.3173707411818, 27.33698062119415, 27.35659850098678,
        27.376224379200153, 27.395858254464553, 27.41550012540007, 27.435149990616594, 27.454807848713834, 27.474473698281294, 27.494147537898286, 27.513829366133937, 27.53351918154717, 27.553216982686724,
        27.57292276809114, 27.59263653628876, 27.612358285797747, 27.632088015126065, 27.651825722771473, 27.67157140722155, 27.691325066953674, 27.71108670043504, 27.730856306122636, 27.75063388246327,
        27.77041942789355, 27.79021294083988, 27.810014419718495, 27.829823862935417, 27.849641268886483, 27.869466635957334, 27.889299962523417, 27.909141246949986, 27.92899048759211, 27.948847682794643,
        27.968712830892272, 27.98858593020948, 28.008466979060543, 28.028355975749566, 28.04825291857045, 28.068157805806905, 28.088070635732436, 28.10799140661037, 28.127920116693844, 28.14785676422578,
        28.167801347438925, 28.187753864555827, 28.207714313788845, 28.227682693340135, 28.24765900140167, 28.267643236155212, 28.28763539577237, 28.307635478414504, 28.327643482232823, 28.347659405368326,
        28.36768324595182, 28.387715002103924, 28.40775467193506, 28.427802253545448, 28.447857745025136, 28.467921144453957, 28.487992449901558, 28.5080716594274, 28.528158771080736, 28.548253782900648,
        28.568356692916, 28.58846749914548, 28.60858619959757, 28.628712792270573, 28.648847275152583, 28.668989646221515, 28.689139903445078, 28.709298044780798, 28.729464068176004, 28.74963797156783,
        28.769819752883215, 28.790009410038913, 28.810206940941477, 28.830412343487264, 28.850625615562446, 28.870846755043004, 28.89107575979471, 28.91131262767316, 28.931557356523747, 28.951809944181665,
        28.972070388471938, 28.992338687209365, 29.012614838198584, 29.03289883923401, 29.053190688099885, 29.07349038257025, 29.09379792040895, 29.114113299369645, 29.134436517195795, 29.15476757162067,
        29.175106460367342, 29.195453181148697, 29.215807731667418, 29.236170109616005, 29.256540312676762, 29.27691833852179, 29.297304184813008, 29.317697849202144, 29.338099329330717, 29.358508622830065,
        29.378925727321334, 29.399350640415463, 29.41978335971322, 29.440223882805164, 29.46067220727166, 29.48112833068288, 29.50159225059881, 29.52206396456924, 29.542543470133772, 29.563030764821793,
        29.58352584615252, 29.604028711634964, 29.62453935876795, 29.645057785040116, 29.665583987929878, 29.686117964905495, 29.706659713425008, 29.72720923093627, 29.747766514876947, 29.76833156267451,
        29.78890437174623, 29.80948493949919, 29.830073263330277, 29.850669340626197, 29.871273168763437, 29.891884745108317, 29.912504067016943, 29.933131131835243, 29.95376593689895, 29.97440847953359,
        29.99505875705451, 30.015716766766857, 30.03638250596559, 30.05705597193547, 30.077737161951063, 30.098426073276748, 30.119122703166703, 30.139827048864916, 30.16053910760519, 30.18125887661112,
        30.201986353096117, 30.2227215342634, 30.243464417305987, 30.264214999406708, 30.284973277738196, 30.305739249462903, 30.32651291173306, 30.347294261690738, 30.36808329646779, 30.388880013185897,
        30.40968440895653, 30.430496480880954, 30.451316226050285, 30.472143641545394, 30.492978724437002, 30.513821471785608, 30.534671880641532, 30.555529948044892, 30.576395671025615, 30.597269046603447,
        30.618150071787923, 30.639038743578393, 30.659935058964006, 30.68083901492374, 30.70175060842635, 30.72266983643042, 30.743596695884325, 30.764531183726262, 30.785473296884213, 30.806423032275998,
        30.82738038680921, 30.84834535738128, 30.86931794087942, 30.890298134180657, 30.911285934151834, 30.93228133764959, 30.953284341520376, 30.974294942600448, 30.995313137715865, 31.016338923682493,
        31.037372297306014, 31.058413255381904, 31.07946179469547, 31.100517912021783, 31.121581604125762, 31.142652867762102, 31.163731699675335, 31.184818096599773, 31.20591205525955, 31.227013572368598,
        31.24812264463066, 31.269239268739287, 31.29036344137783, 31.31149515921946, 31.332634418927142, 31.35378121715365, 31.37493555054156, 31.39609741572328, 31.41726680932099, 31.438443727946694,
        31.459628168202197, 31.48082012667913, 31.502019599958913, 31.52322658461276, 31.544441077201718, 31.565663074276628, 31.58689257237814, 31.608129568036706, 31.629374057772594, 31.65062603809587,
        31.671885505506403, 31.693152456493884, 31.714426887537808, 31.73570879510746, 31.75699817566194, 31.77829502565017, 31.799599341510856, 31.820911119672523, 31.8422303565535, 31.86355704856193,
        31.884891192095743, 31.9062327835427, 31.927581819280338, 31.948938295676044, 31.970302209086977, 31.991673555860107, 32.01305233233223, 32.03443853482991, 32.05583215966957, 32.0772332031574,
        32.09864166158942, 32.120057531251426, 32.141480808419054, 32.16291148935773, 32.1843495703227, 32.20579504755899, 32.22724791730147, 32.24870817577476, 32.27017581919336, 32.29165084376153,
        32.31313324567333, 32.334623021112655, 32.3561201662532, 32.37762467725845, 32.39913655028171, 32.420655781466095, 32.442182366944515, 32.46371630283969, 32.48525758526416, 32.50680621032025,
        32.52836217410012, 32.54992547268569, 32.57149610214874, 32.593074058550826, 32.61465933794332, 32.636251936367394, 32.65785184985403, 32.679459074424024, 32.70107360608796, 32.72269544084625,
        32.7443245746891, 32.76596100359654, 32.78760472353837, 32.80925573047423, 32.83091402035355, 32.85257958911559, 32.87425243268938, 32.89593254699379, 32.91761992793747, 32.939314571418905,
        32.96101647332635, 32.98272562953792, 33.00444203592147, 33.026165688334714, 33.04789658262515, 33.06963471463009, 33.091380080176656, 33.11313267508176, 33.13489249515213, 33.156659536184314,
        33.17843379396464, 33.200215264269275, 33.22200394286417, 33.24379982550508, 33.26560290793757, 33.28741318589703, 33.30923065510864, 33.33105531128739, 33.352887150138066, 33.374726167355284,
        33.396572358623445, 33.41842571961677, 33.44028624599928, 33.46215393342481, 33.484028777536984, 33.505910773969255, 33.52779991834486, 33.54969620627687, 33.57159963336815, 33.59351019521135,
        33.61542788738897, 33.637352705473276, 33.65928464502636, 33.681223701600125, 33.703169870736275, 33.72512314796631, 33.74708352881155, 33.769051008783116, 33.791025583381945, 33.81300724809877,
        33.83499599841412, 33.85699182979837, 33.878994737711665, 33.90100471760397, 33.92302176491504, 33.94504587507446, 33.96707704350164, 33.989115265605726, 34.01116053678574, 34.03321285243048,
        34.05527220791855, 34.07733859861837, 34.09941201988816, 34.12149246707597, 34.14357993551961, 34.165674420546736, 34.18777591747479, 34.20988442161104, 34.231999928252534, 34.25412243268615,
        34.27625193018858, 34.29838841602628, 34.32053188545555, 34.34268233372249, 34.364839756063006, 34.387004147702804, 34.40917550385739, 34.431353819732095, 34.45353909052206, 34.475731311412204,
        34.497930477577285, 34.520136584181834, 34.542349626380236, 34.56456959931662, 34.586796498124976, 34.60903031792908, 34.63127105384252, 34.65351870096867, 34.67577325440073, 34.69803470922172,
        34.720303060504435, 34.74257830331149, 34.76486043269531, 34.787149443698134, 34.809445331351995, 34.83174809067872, 34.85405771668999, 34.876374204387226, 34.89869754876172, 34.92102774479452,
        34.943364787456524, 34.9657086717084, 34.98805939250064, 35.010416944773546, 35.032781323457215, 35.05515252347157, 35.077530539726304, 35.099915367120964, 35.122307000544865, 35.144705434877146,
        35.16711066498676, 35.18952268573244, 35.21194149196276, 35.23436707851607, 35.25679944022055, 35.27923857189417, 35.30168446834472, 35.32413712436978, 35.34659653475675, 35.369062694282846,
        35.39153559771506, 35.41401523981021, 35.43650161531494, 35.458994718965656, 35.4814945454886, 35.50400108959982, 35.52651434600517, 35.54903430940031, 35.571560974470685, 35.594094335891576,
        35.61663438832806, 35.63918112643502, 35.66173454485715, 35.68429463822894, 35.7068614011747, 35.72943482830852, 35.75201491423434, 35.77460165354588, 35.79719504082667, 35.81979507065003,
        35.84240173757912, 35.86501503616689, 35.88763496095609, 35.91026150647929, 35.93289466725886, 35.95553443780697, 35.978180812625595, 36.00083378620655, 36.02349335303141, 36.046159507571595,
        36.0688322442883, 36.09151155763256, 36.114197442045175, 36.1368898919568, 36.15958890178784, 36.18229446594857, 36.20500657883903, 36.22772523484908, 36.25045042835837, 36.27318215373638,
        36.29592040534239, 36.318665177525475, 36.34141646462452, 36.36417426096824, 36.386938560875144, 36.40970935865351, 36.43248664860147, 36.45527042500696, 36.4780606821477, 36.50085741429121,
        36.52366061569487, 36.54647028060581, 36.56928640326098, 36.59210897788716, 36.6149379987009, 36.6377734599086, 36.660615355706426, 36.68346368028038, 36.70631842780625, 36.72917959244965,
        36.75204716836597, 36.77492114970045, 36.7978015305881, 36.82068830515376, 36.84358146751205, 36.86648101176743, 36.889386932014155, 36.912299222336266, 36.93521787680763, 36.95814288949192,
        36.98107425444262, 37.00401196570299, 37.02695601730614, 37.04990640327498, 37.07286311762219, 37.09582615435029, 37.118795507451594, 37.14177117090823, 37.16475313869212, 37.187741404765006,
        37.210735963078434, 37.233736807573756, 37.25674393218212, 37.27975733082449, 37.302776997411655, 37.32580292584417, 37.34883511001242, 37.371873543796625, 37.394918221066746, 37.417969135682604,
        37.4410262814938, 37.46408965233976, 37.48715924204971, 37.510235044442666, 37.53331705332747, 37.556405262502786, 37.57949966575705, 37.6026002568685, 37.62570702960522, 37.6488199777251,
        37.67193909497578, 37.69506437509475, 37.71819581180932, 37.74133339883658, 37.764477129883424, 37.78762699864657, 37.810782998812535, 37.83394512405765, 37.85711336804802, 37.880287724439604,
        37.90346818687815, 37.9266547489992, 37.949847404428105, 37.973046146780035, 37.99625096965997, 38.01946186666267, 38.042678831372726, 38.06590185736453, 38.08913093820228, 38.11236606743998,
        38.13560723862144, 38.15885444528026, 38.1821076809399, 38.20536693911357, 38.228632213304294, 38.25190349700494, 38.275180783698154, 38.29846406685637, 38.32175333994188, 38.34504859640675,
        38.36834982969284, 38.391657033231844, 38.41497020044527, 38.43828932474439, 38.46161439953032, 38.48494541819396, 38.508282374116035, 38.53162526066708, 38.55497407120741, 38.578328799087174,
        38.60168943764631, 38.62505598021455, 38.64842842011148, 38.671806750646454, 38.69519096511864, 38.71858105681702, 38.741977019020375, 38.765378844997294, 38.78878652800618, 38.812200061295236,
        38.83561943810246, 38.859044651655694, 38.88247569517254, 38.905912561860426, 38.929355244916614, 38.95280373752813, 38.976258032871826, 38.99971812411436, 39.0231840044122, 39.046655666911626,
        39.0701331047487, 39.093616311049296, 39.11710527892914, 39.140600001493695, 39.16410047183828, 39.187606683048, 39.211118628197795, 39.234636300352356, 39.258159692566224, 39.28168879788376,
        39.30522360933907, 39.32876411995614, 39.3523103227487, 39.37586221072033, 39.3994197768644, 39.42298301416409, 39.44655191559236, 39.47012647411204, 39.493706682675686, 39.51729253422574,
        39.54088402169439, 39.56448113800366, 39.588083876065376, 39.61169222878116, 39.63530618904246, 39.65892574973052, 39.682550903716376, 39.706181643860916, 39.72981796301477, 39.753459854018416,
        39.77710730970216, 39.800760322886056, 39.82441888638, 39.848082992983706, 39.871752635486644, 39.895427806668174, 39.91910849929737, 39.94279470613318, 39.96648641992433, 39.99018363340935,
        40.01388633931659, 40.03759453036421, 40.061308199260154, 40.0850273387022, 40.10875194137789, 40.13248199996463, 40.1562175071296, 40.179958455529786, 40.20370483781198, 40.2274566466128,
        40.25121387455865, 40.274976514265745, 40.298744558340104, 40.32251799937758, 40.3462968299638, 40.370081042674194, 40.39387063007402, 40.41766558471835, 40.441465899152035, 40.46527156590975,
        40.48908257751596, 40.51289892648498, 40.53672060532088, 40.560547606517545, 40.58437992255871, 40.60821754591787, 40.63206046905833, 40.655908684433236, 40.679762184485504, 40.703620961647886,
        40.727485008342924, 40.751354316982955, 40.77522887997014, 40.79910868969647, 40.82299373854368, 40.84688401888336, 40.87077952307691, 40.89468024347551, 40.91858617242014, 40.94249730224164,
        40.9664136252606, 40.990335133787426, 41.014261820122364, 41.038193676555444, 41.06213069536649, 41.086072868825156, 41.11002018919089, 41.13397264871296, 41.15793023963042, 41.18189295417212,
        41.20586078455679, 41.22983372299287, 41.25381176167868, 41.27779489280229, 41.30178310854162, 41.32577640106439, 41.3497747625281, 41.37377818508008, 41.39778666085747, 41.42180018198721,
        41.44581874058602, 41.46984232876047, 41.493870938606925, 41.517904562211534, 41.541943191650276, 41.56598681898893, 41.590035436283074, 41.6140890355781, 41.638147608909215, 41.66221114830142,
        41.68627964576952, 41.71035309331813, 41.73443148294168, 41.75851480662441, 41.78260305634036, 41.80669622405336, 41.83079430171706, 41.854897281274944, 41.87900515466024, 41.90311791379604,
        41.92723555059521, 41.951358056960466, 41.97548542478427, 41.99961764594891, 42.02375471232652, 42.047896615779, 42.07204334815806, 42.096194901305246, 42.120351267051866, 42.14451243721906,
        42.16867840361779, 42.192849158048794, 42.217024692302644, 42.241204998159674, 42.26539006739009, 42.28957989175385, 42.313774463000755, 42.33797377287038, 42.36217781309212, 42.386386575385195,
        42.410600051458616, 42.43481823301118, 42.459041111731544, 42.483268679298114, 42.50750092737914, 42.531737847632655, 42.55597943170654, 42.580225671238416, 42.60447655785576, 42.62873208317585,
        42.65299223880577, 42.677257016342395, 42.70152640737241, 42.72580040347232, 42.750078996208444, 42.77436217713687, 42.798649937803525, 42.822942269744146, 42.84723916448424, 42.87154061353917,
        42.89584660841406, 42.92015714060388, 42.94447220159338, 42.968791782857124, 42.99311587585947, 43.01744447205465, 43.04177756288657, 43.06611513978907, 43.090457194185745, 43.11480371748999,
        43.13915470110502, 43.16351013642385, 43.18787001482932, 43.212234327694055, 43.236603066380475, 43.26097622224084, 43.285353786617215, 43.30973575084143, 43.33412210623516, 43.35851284410989,
        43.38290795576689, 43.407307432497234, 43.43171126558182, 43.45611944629136, 43.48053196588635, 43.50494881561709, 43.52936998672371, 43.55379547043614, 43.5782252579741, 43.60265934054713,
        43.62709770935459, 43.651540355585624, 43.675987270419164, 43.700438445024005, 43.72489387055872, 43.74935353817168, 43.77381743900106, 43.798285564174854, 43.82275790481089, 43.84723445201672,
        43.87171519688981, 43.89620013051734, 43.92068924397637, 43.94518252833369, 43.969679974645956, 43.99418157395963, 44.01868731731095, 44.043197195725966, 44.06771120022054, 44.092229321800374,
        44.11675155146093, 44.141277880187474, 44.16580829895512, 44.19034279872878, 44.21488137046313, 44.239424005102684, 44.263970693581776, 44.28852142682452, 44.313076195744856, 44.3376349912465,
        44.36219780422304, 44.386764625557795, 44.41133544612392, 44.4359102567844, 44.460489048392, 44.48507181178931, 44.50965853780867, 44.53424921727233, 44.55884384099226, 44.58344239977026,
        44.60804488439795, 44.63265128565676, 44.65726159431789, 44.68187580114238, 44.70649389688109, 44.73111587227464, 44.75574171805351, 44.78037142493792, 44.80500498363794, 44.82964238485348,
        44.854283619274206, 44.878928677579566, 44.9035775504389, 44.928230228511275, 44.9528867024456, 44.977546962880595, 45.00221100044479, 45.0268788057565, 45.05155036942383, 45.07622568204476,
        45.10090473420702, 45.12558751648815, 45.15027401945552, 45.1749642336663, 45.199658149667464, 45.22435575799577, 45.249057049177814, 45.27376201373, 45.29847064215853, 45.323182924959376,
        45.34789885261836, 45.37261841561113, 45.39734160440309, 45.42206840944946, 45.44679882119531, 45.47153283007547, 45.49627042651458, 45.52101160092711, 45.54575634371733, 45.5705046452793,
        45.595256495996914, 45.620011886243844, 45.64477080638358, 45.66953324676945, 45.694299197744506, 45.71906864964171, 45.74384159278377, 45.768618017483185, 45.79339791404231, 45.818181272753286,
        45.84296808389805, 45.867758337748356, 45.89255202456575, 45.91734913460162, 45.942149658097144, 45.966953585283264, 45.99176090638079, 46.01657161160032, 46.04138569114224, 46.06620313519675,
        46.09102393394387, 46.11584807755344, 46.14067555618505, 46.165506359988136, 46.19034047910198, 46.21517790365558, 46.240018623767796, 46.2648626295473, 46.28970991109256, 46.31456045849184,
        46.339414261823215, 46.364271311154575, 46.38913159654361, 46.41399510803784, 46.43886183567453, 46.46373176948082, 46.48860489947363, 46.51348121565967, 46.53836070803548, 46.56324336658741,
        46.58812918129159, 46.61301814211398, 46.63791023901033, 46.66280546192621, 46.68770380079701, 46.71260524554789, 46.73750978609384, 46.762417412339644, 46.787328114179914, 46.81224188149904,
        46.83715870417126, 46.86207857206057, 46.887001475020796, 46.911927402895586, 46.936856345518365, 46.96178829271239, 46.9867232342907, 47.011661160056164, 47.03660205980146, 47.06154592330903,
        47.086492740351176, 47.11144250068997, 47.13639519407731, 47.1613508102549, 47.18630933895423, 47.21127076989664, 47.23623509279322, 47.261202297344916, 47.28617237324245, 47.311145310166374,
        47.336121097787014, 47.36109972576454, 47.38608118374889, 47.41106546137985, 47.43605254828699, 47.461042434089684, 47.48603510839711, 47.511030560808265, 47.53602878091196, 47.5610297582868,
        47.58603348250118, 47.611039943113326, 47.63604912967127, 47.661061031712855, 47.6860756387657, 47.71109294034725, 47.73611292596477, 47.761135585115305, 47.78616090728574, 47.81118888195274,
        47.83621949858278, 47.86125274663214, 47.886288615546924, 47.91132709476303, 47.93636817370616, 47.96141184179182, 47.986458088425344, 48.01150690300185, 48.03655827490628, 48.06161219351335,
        48.08666864818763, 48.111727628283454, 48.13678912314499, 48.161853122106216, 48.18691961449088, 48.21198858961258, 48.2370600367747, 48.26213394527042, 48.287210304382754, 48.3122891033845,
        48.337370331538274, 48.36245397809649, 48.387540032301374, 48.412628483384964, 48.437719320569094, 48.46281253306542, 48.48790811007539, 48.51300604079025, 48.53810631439108, 48.56320892004876,
        48.58831384692396, 48.61342108416715, 48.63853062091865, 48.663642446308536, 48.68875654945674, 48.713872919472955, 48.73899154545669, 48.764112416497305, 48.7892355216739, 48.81436085005544,
        48.83948839070066, 48.86461813265811, 48.88975006496614, 48.91488417665294, 48.94002045673646, 48.965158894224494, 48.99029947811463, 49.01544219739424, 49.04058704104055, 49.06573399802055,
        49.09088305729106, 49.116034207798684, 49.14118743847987, 49.16634273826084, 49.19150009605763, 49.216659500776096, 49.241820941311886, 49.266984406550456, 49.292149885367074, 49.31731736662681,
        49.342486839184545, 49.36765829188498, 49.392831713562586, 49.418007093041666, 49.44318441913634, 49.468363680650505, 49.493544866377896, 49.51872796510203, 49.54391296559625, 49.56909985662367,
        49.594288626937264, 49.619479265279764, 49.64467176038374, 49.669866100971554, 49.6950622757554, 49.72026027343722, 49.745460082708824, 49.7706616922518, 49.79586509073755, 49.82107026682728,
        49.84627720917201, 49.87148590641254, 49.89669634717951, 49.92190852009334, 49.94712241376429, 49.97233801679239, 49.99755531776751, 50.02277430526929, 50.047994967867204, 50.073217294120525,
        50.098441272578334, 50.123666891779514, 50.148894140252764, 50.17412300651658, 50.19935347907927, 50.22458554643894, 50.24981919708352, 50.275054419490736, 50.30029120212811, 50.32552953345298,
        50.35076940191251, 50.37601079594364, 50.40125370397313, 50.42649811441755, 50.451744015683275, 50.47699139616649, 50.502240244253166, 50.52749054831909, 50.552742296729896, 50.57799547784096,
        50.603250079997494, 50.62850609153455, 50.65376350077691, 50.67902229603925, 50.70428246562598, 50.72954399783135, 50.75480688093942, 50.78007110322406, 50.80533665294892, 50.83060351836747,
        50.855871687723, 50.88114114924859, 50.90641189116715, 50.93168390169136, 50.95695716902373, 50.98223168135658, 51.00750742687204, 51.032784393742, 51.05806257012823, 51.083341944182244,
        51.10862250404542, 51.133904237848874, 51.159187133713594, 51.18447117975033, 51.20975636405965, 51.23504267473196, 51.26033009984742, 51.28561862747604, 51.31090824567761, 51.33619894250175,
        51.361490705987855, 51.38678352416516, 51.41207738505268, 51.43737227665927, 51.46266818698353, 51.48796510401394, 51.51326301572874, 51.53856191009599, 51.56386177507357, 51.589162598609136,
        51.61446436864017, 51.63976707309397, 51.66507069988762, 51.69037523692802, 51.71568067211189, 51.74098699332573, 51.76629418844585, 51.79160224533839, 51.81691115185929, 51.842220895854275,
        51.86753146515891, 51.89284284759852, 51.918155030988295, 51.943468003133184, 51.96878175182796, 51.99409626485722, 52.01941152999533, 52.04472753500649, 52.070044267644704, 52.09536171565377,
        52.120679866767325, 52.14599870870875, 52.17131822919131, 52.196638415918024, 52.22195925658171, 52.247280738865044, 52.27260285044048, 52.29792557897025, 52.32324891210645, 52.34857283749094,
        52.3738973427554, 52.39922241552133, 52.424548043399994, 52.44987421399251, 52.4752009148898, 52.50052813367255, 52.5258558579113, 52.55118407516636, 52.576512772987876, 52.60184193891578,
        52.62717156047984, 52.652501625199584, 52.67783212058437, 52.70316303413339, 52.72849435333559, 52.75382606566978, 52.779158158604524, 52.80449061959821, 52.82982343609906, 52.85515659554507,
        52.880490085364045, 52.90582389297362, 52.931158005781214, 52.956492411184065, 52.98182709656921, 53.00716204931349, 53.032497256783586, 53.05783270633592, 53.083168385316775, 53.10850428106222,
        53.13384038089815, 53.15917667214024, 53.184513142093984, 53.20984977805468, 53.23518656730745, 53.260523497127195, 53.28586055477863, 53.3111977275163, 53.33653500258451, 53.36187236721743,
        53.387209808639, 53.41254731406295, 53.437884870692876, 53.463222465722104, 53.48856008633385, 53.51389771970108, 53.53923535298656, 53.5645729733429, 53.5899105679125, 53.615248123827584,
        53.640585628210125, 53.665923068171985, 53.691260430814765, 53.716597703229894, 53.74193487249863, 53.76727192569203, 53.79260884987092, 53.81794563208599, 53.843282259377666, 53.868618718776254,
        53.893954997301826, 53.91929108196425, 53.944626959763276, 53.96996261768835, 53.995298042718794, 54.02063322182372, 54.04596814196205, 54.07130279008251, 54.096637153123645, 54.12197121801378,
        54.14730497167107, 54.172638401003475, 54.19797149290875, 54.223304234274444, 54.24863661197796, 54.273968612886456, 54.29930022385694, 54.32463143173618, 54.3499622233608, 54.3752925855572,
        54.40062250514158, 54.42595196891997, 54.45128096368821, 54.47660947623192, 54.50193749332654, 54.52726500173732, 54.55259198821931, 54.57791843951738, 54.6032443423662, 54.62856968349022,
        54.653894449603754, 54.679218627410854, 54.70454220360544, 54.72986516487119, 54.755187497881636, 54.78050918930008, 54.80583022577964, 54.83115059396325, 54.856470280483634, 54.88178927196334,
        54.90710755501471, 54.93242511623991, 54.957741942230896, 54.98305801956942, 55.008373334827084, 55.03368787456523, 55.05900162533508, 55.08431457367759, 55.10962670612362, 55.13493800919372,
        55.16024846939833, 55.18555807323767, 55.210866807201775, 55.23617465777047, 55.26148161141337, 55.286787654589965, 55.312092773749484, 55.33739695533098, 55.36270018576336, 55.388002451465255,
        55.41330373884517, 55.43860403430137, 55.46390332422198, 55.48920159498488, 55.514498832957784, 55.5397950244982, 55.565090155953456, 55.59038421366067, 55.61567718394679, 55.64096905312854,
        55.66625980751248, 55.691549433394954, 55.71683791706214, 55.74212524478998, 55.767411402844274, 55.79269637748059, 55.81798015494431, 55.84326272147063, 55.86854406328457, 55.893824166600915,
        55.91910301762428, 55.94438060254909, 55.96965690755958, 55.99493191882978, 56.02020562252352, 56.04547800479446, 56.070749051786066, 56.09601874963157, 56.121287084454046, 56.14655404236638,
        56.17181960947126, 56.19708377186115, 56.222346515618355, 56.24760782681497, 56.27286769151292, 56.298126095763905, 56.32338302560945, 56.34863846708087, 56.37389240619932, 56.39914482897573,
        56.424395721410846, 56.44964506949522, 56.47489285920921, 56.50013907652302, 56.52538370739658, 56.55062673777968, 56.57586815361192, 56.60110794082268, 56.62634608533117, 56.651582573046404,
        56.676817389867175, 56.70205052168213, 56.72728195436967, 56.75251167379805, 56.77773966582531, 56.802965916299286, 56.82819041105764, 56.85341313592782, 56.87863407672712, 56.90385321926259,
        56.929070549331115, 56.95428605271939, 56.9794997152039, 57.004711522550956, 57.02992146051667, 57.05512951484694, 57.08033567127749, 57.10553991553385, 57.13074223333138, 57.15594261037518,
        57.181141032360216, 57.20633748497124, 57.23153195388282, 57.25672442475931, 57.2819148832549, 57.307103315013556, 57.332289705669076, 57.357474040845055, 57.3826563061549, 57.407836487201784,
        57.43301456957876, 57.458190538868635, 57.48336438064405, 57.508536080467415, 57.53370562389097, 57.5588729964568, 57.58403818369671, 57.6092011711324, 57.63436194427532, 57.659520488626754,
        57.684676789677766, 57.709830832909255, 57.73498260379192, 57.76013208778627, 57.785279270342585, 57.81042413690099, 57.83556667289142, 57.860706863733576, 57.885844694837026, 57.910980151601095,
        57.93611321941492, 57.961243883657474, 57.9863721296975, 58.01149794289359, 58.03662130859411, 58.06174221213722, 58.08686063885093, 58.11197657405303, 58.13709000305111, 58.162200911142584,
        58.18730928361468, 58.212415105744405, 58.23751836279858, 58.26261904003387, 58.28771712269668, 58.31281259602328, 58.337905445239706, 58.36299565556184, 58.388083212195355, 58.413168100335696,
        58.43825030516816, 58.463329811867844, 58.48840660559962, 58.513480671518224, 58.53855199476813, 58.563620560483656, 58.58868635378894, 58.61374935979791, 58.63880956361428, 58.66386695033161,
        58.688921505033235, 58.71397321279233, 58.739022058671836, 58.76406802772452, 58.78911110499298, 58.81415127550957, 58.839188524296496, 58.864222836365755, 58.88925419671911, 58.914282590348236,
        58.939308002234476, 58.96433041734911, 58.98934982065313, 59.01436619709738, 59.03937953162252, 59.06438980915897, 59.08939701462699, 59.114401132936656, 59.13940214898783, 59.16440004767016,
        59.18939481386319, 59.214386432436136, 59.23937488824814, 59.26436016614809, 59.2893422509747, 59.31432112755647, 59.339296780711734, 59.36426919524861, 59.38923835596504, 59.414204247648776,
        59.43916685507734, 59.4641261630181, 59.48908215622822, 59.51403481945467, 59.53898413743422, 59.56393009489345, 59.58887267654875, 59.61381186710633, 59.638747651262165, 59.663680013702084,
        59.688608939101684, 59.713534412126386, 59.73845641743144, 59.763374939661865, 59.7882899634525, 59.81320147342801, 59.83810945420282, 59.86301389038123, 59.88791476655727, 59.91281206731484,
        59.93770577722762, 59.962595880859105, 59.98748236276255, 60.0123652074811, 60.03724439954764, 60.0621199234849, 60.086991763805386, 60.111859905011436, 60.13672433159518, 60.161585028038566,
        60.18644197881333, 60.21129516838104, 60.23614458119306, 60.26099020169055, 60.28583201430449, 60.31067000345564, 60.335504153554616, 60.360334449001805, 60.3851608741874, 60.40998341349142,
        60.43480205128367, 60.45961677192378, 60.48442755976118, 60.509234399135096, 60.534037274374576, 60.55883616979846, 60.58363106971542, 60.608421958423904, 60.633208820212175, 60.65799163935832,
        60.682770400130224, 60.70754508678556, 60.73231568357182, 60.757082174726335, 60.781844544476186, 60.8066027770383, 60.831356856619394, 60.856106767415994, 60.880852493614455, 60.90559401939089,
        60.930331328911265, 60.95506440633132, 60.97979323579664, 61.00451780144257, 61.0292380873943, 61.05395407776681, 61.078665756664876, 61.103373108183106, 61.128076116405914, 61.15277476540746,
        61.17746903925182, 61.202158921992776, 61.226844397673965, 61.251525450328835, 61.27620206398061, 61.30087422264234, 61.3255419103169, 61.35020511099694, 61.374863808664905, 61.39951798729311,
        61.424167630843606, 61.4488127232683, 61.47345324850888, 61.498089190496856, 61.52272053315353, 61.547347260390005, 61.57196935610722, 61.59658680419589, 61.62119958853656, 61.64580769299957,
        61.670411101445076, 61.695009797723024, 61.71960376567318, 61.7441929891251, 61.76877745189817, 61.79335713780157, 61.8179320306343, 61.84250211418513, 61.86706737223268, 61.891627788545364,
        61.91618334688138, 61.940734030988764, 61.96527982460533, 61.98982071145873, 62.014356675266384, 62.03888769973557, 62.06341376856332, 62.08793486543651, 62.11245097403179, 62.13696207801567,
        62.16146816104438, 62.18596920676406, 62.21046519881057, 62.23495612080962, 62.25944195637675, 62.28392268911721, 62.3083983026262, 62.33286878048858, 62.35733410627915, 62.38179426356239,
        62.406249235892666, 62.43069900681416, 62.45514355986083, 62.47958287855641, 62.504016946414495, 62.52844574693848, 62.55286926362153, 62.57728747994666, 62.601700379386685, 62.62610794540416,
        62.65051016145154, 62.67490701097107, 62.69929847739472, 62.72368454414436, 62.748065194631636, 62.77244041225799, 62.79681018041467, 62.821174482482746, 62.84553330183307, 62.869886621826346,
        62.89423442581303, 62.918576697133425, 62.94291341911764, 62.967244575085545, 62.99157014834687, 63.015890122201135, 63.04020447993764, 63.064513204835535, 63.088816280163726, 63.11311368918099,
        63.137405415135866, 63.16169144126669, 63.18597175080165, 63.21024632695869, 63.234515152945626, 63.258778211959985, 63.2830354871892, 63.30728696181044, 63.33153261899073, 63.35577244188687,
        63.38000641364546, 63.40423451740294, 63.428456736285526, 63.452673053409264, 63.47688345188, 63.50108791479337, 63.52528642523484, 63.54947896627965, 63.57366552099292, 63.59784607242946,
        63.622020603634006, 63.64618909764102, 63.6703515374748, 63.69450790614944, 63.718658186668875, 63.74280236202679, 63.76694041520674, 63.79107232918203, 63.81519808691579, 63.839317671360995,
        63.86343106546036, 63.88753825214646, 63.91163921434165, 63.9357339349581, 63.95982239689779, 63.9839045830525, 64.00798047630381, 64.03205005952312, 64.05611331557164, 64.08017022730037,
        64.10422077755014, 64.12826494915156, 64.15230272492506, 64.17633408768086, 64.20035902021904, 64.22437750532941, 64.24838952579167, 64.27239506437525, 64.29639410383942, 64.32038662693324,
        64.34437261639565, 64.36835205495528, 64.39232492533064, 64.41629121023004, 64.44025089235161, 64.46420395438324, 64.48815037900266, 64.51209014887738, 64.53602324666477, 64.55994965501195,
        64.58386935655588, 64.6077823339233, 64.63168856973077, 64.65558804658468, 64.67948074708121, 64.70336665380633, 64.72724574933581, 64.75111801623528, 64.77498343706011, 64.79884199435554,
        64.82269367065658, 64.84653844848802, 64.87037631036455, 64.89420723879053, 64.91803121626026, 64.94184822525777, 64.96565824825691, 64.98946126772135, 65.01325726610457, 65.03704622584982,
        65.0608281293902, 65.08460295914861, 65.10837069753771, 65.13213132696004, 65.1558848298079, 65.17963118846338, 65.20337038529844, 65.22710240267477, 65.25082722294394, 65.27454482844729,
        65.29825520151594, 65.32195832447087, 65.34565417962284, 65.36934274927242, 65.39302401570997, 65.41669796121569, 65.44036456805956, 65.4640238185014, 65.48767569479078, 65.51132017916714,
        65.53495725385966, 65.55858690108737, 65.58220910305911, 65.60582384197355, 65.62943110001906, 65.65303085937394, 65.67662310220625, 65.70020781067382, 65.72378496692436, 65.74735455309528,
        65.77091655131393, 65.79447094369738, 65.81801771235251, 65.84155683937603, 65.86508830685446, 65.8886120968641, 65.91212819147108, 65.93563657273133, 65.95913722269057, 65.9826301233844,
        66.0061152568381, 66.02959260506685, 66.05306215007563, 66.0765238738592, 66.09997775840213, 66.1234237856788, 66.1468619376534, 66.17029219627993, 66.19371454350221, 66.21712896125382,
        66.24053543145818, 66.26393393602854, 66.28732445686789, 66.31070697586911, 66.33408147491481, 66.35744793587746, 66.38080634061929, 66.40415667099238, 66.42749890883859, 66.45083303598962,
        66.47415903426692, 66.49747688548183, 66.52078657143538, 66.54408807391852, 66.56738137471193, 66.59066645558615, 66.61394329830149, 66.63721188460809, 66.66047219624588, 66.68372421494459,
        66.7069679224238, 66.73020330039283, 66.75343033055087, 66.77664899458686, 66.79985927417964, 66.8230611509977, 66.84625460669952, 66.86943962293323, 66.89261618133686, 66.91578426353823,
        66.93894385115495, 66.96209492579442, 66.98523746905389, 67.00837146252039, 67.03149688777076, 67.05461372637167, 67.07772195987955, 67.10082156984068, 67.12391253779113, 67.14699484525677,
        67.17006847375328, 67.19313340478611, 67.21618961985067, 67.23923710043195, 67.26227582800492, 67.28530578403426, 67.30832694997453, 67.33133930727003, 67.35434283735489, 67.3773375216531,
        67.40032334157837, 67.42330027853426, 67.44626831391415, 67.4692274291012, 67.49217760546837, 67.51511882437846, 67.53805106718407, 67.56097431522757, 67.58388854984119, 67.60679375234692,
        67.62968990405658, 67.6525769862718, 67.675454980284, 67.69832386737443, 67.72118362881412, 67.74403424586393, 67.7668756997745, 67.78970797178634, 67.81253104312965, 67.83534489502455,
        67.85814950868092, 67.88094486529843, 67.9037309460666, 67.92650773216471, 67.9492752047619, 67.97203334501707, 67.99478213407895, 68.01752155308604, 68.0402515831667, 68.06297220543908,
        68.08568340101114, 68.1083851509806, 68.13107743643504, 68.15376023845182, 68.17643353809815, 68.199097316431, 68.22175155449712, 68.24439623333313, 68.26703133396548, 68.28965683741032,
        68.31227272467369, 68.33487897675141, 68.35747557462912, 68.38006249928222, 68.40263973167602, 68.42520725276549, 68.44776504349555, 68.47031308480082, 68.4928513576058, 68.51537984282476,
        68.53789852136178, 68.56040737411075, 68.58290638195535, 68.60539552576911, 68.62787478641533, 68.65034414474714, 68.67280358160743, 68.69525307782895, 68.71769261423424, 68.74012217163563,
        68.76254173083528, 68.78495127262516, 68.80735077778701, 68.8297402270924, 68.85211960130272, 68.87448888116916, 68.89684804743268, 68.91919708082412, 68.94153596206404, 68.96386467186286,
        68.98618319092083, 69.00849149992793, 69.030789579564, 69.05307741049869, 69.07535497339146, 69.09762224889153, 69.11987921763794, 69.14212586025963, 69.16436215737517, 69.18658808959312,
        69.20880363751169, 69.23100878171904, 69.25320350279304, 69.27538778130136, 69.29756159780156, 69.31972493284093, 69.3418777669566, 69.36402008067552, 69.38615185451438, 69.40827306897974,
        69.43038370456797, 69.45248374176522, 69.47457316104747, 69.49665194288045, 69.51872006771976, 69.54077751601076, 69.56282426818868, 69.5848603046785, 69.606885605895, 69.62890015224284,
        69.65090392411639, 69.6728969018999, 69.69487906596738, 69.71685039668269, 69.73881087439946, 69.76076047946114, 69.78269919220098, 69.80462699294206, 69.82654386199725, 69.84844977966921,
        69.87034472625042, 69.8922286820232, 69.91410162725964, 69.93596354222161, 69.95781440716087, 69.97965420231888, 70.00148290792701, 70.02330050420636, 70.04510697136791, 70.06690228961236,
        70.08868643913027, 70.110459400102, 70.13222115269772, 70.1539716770774, 70.17571095339079, 70.19743896177752, 70.21915568236697, 70.24086109527829, 70.26255518062055, 70.28423791849251,
        70.3059092889828, 70.32756927216985, 70.3492178481219, 70.37085499689697, 70.39248069854291, 70.41409493309737, 70.4356976805878, 70.45728892103146, 70.47886863443544, 70.5004368007966,
        70.52199340010165, 70.54353841232705, 70.56507181743912, 70.58659359539395, 70.60810372613744, 70.62960218960532, 70.65108896572313, 70.67256403440618, 70.69402737555959, 70.71547896907833,
        70.73691879484716, 70.7583468327406, 70.77976306262305, 70.80116746434867, 70.82256001776142, 70.84394070269512, 70.86530949897332, 70.88666638640943, 70.90801134480667, 70.92934435395803,
        70.95066539364633, 70.97197444364421, 70.9932714837141, 71.01455649360821, 71.0358294530686, 71.05709034182713, 71.07833913960545, 71.09957582611503, 71.12080038105711, 71.1420127841228,
        71.16321301499298, 71.18440105333833, 71.20557687881934, 71.22674047108633, 71.24789180977942, 71.26903087452851, 71.29015764495331, 71.31127210066339, 71.33237422125805, 71.35346398632643,
        71.37454137544755, 71.39560636819009, 71.41665894411263, 71.43769908276356, 71.45872676368103, 71.47974196639308, 71.50074467041742, 71.52173485526171, 71.54271250042333, 71.5636775853895,
        71.58463008963723, 71.60556999263333, 71.62649727383447, 71.64741191268706, 71.66831388862734, 71.68920318108135, 71.71007976946498, 71.73094363318388, 71.75179475163353, 71.77263310419919,
        71.79345867025593, 71.81427142916868, 71.83507136029212, 71.85585844297074, 71.87663265653887, 71.89739398032064, 71.91814239362994, 71.9388778757705, 71.9596004060359, 71.98030996370944,
        72.00100652806428, 72.0216900783634, 72.04236059385954, 72.06301805379528, 72.08366243740299, 72.10429372390489, 72.12491189251291, 72.14551692242892, 72.16610879284444, 72.18668748294094,
        72.20725297188962, 72.2278052388515, 72.24834426297743, 72.26887002340803, 72.28938249927373, 72.3098816696948, 72.33036751378131, 72.3508400106331, 72.37129913933987, 72.39174487898106,
        72.41217720862599, 72.43259610733372, 72.45300155415315, 72.47339352812301, 72.49377200827178, 72.5141369736178, 72.53448840316919, 72.5548262759239, 72.57515057086961, 72.5954612669839,
        72.61575834323415, 72.63604177857748, 72.65631155196084, 72.67656764232105, 72.69681002858464, 72.717038689668, 72.73725360447736, 72.75745475190868, 72.7776421108478, 72.79781566017029,
        72.8179753787416, 72.83812124541693, 72.8582532390413, 72.8783713384496, 72.89847552246643, 72.91856576990625, 72.93864205957331, 72.9587043702617, 72.97875268075529, 72.99878696982772,
        73.01880721624251, 73.03881339875294, 73.0588054961021, 73.07878348702289, 73.09874735023804, 73.11869706446008, 73.13863260839129, 73.15855396072384, 73.17846110013964, 73.19835400531045,
        73.21823265489782, 73.2380970275531, 73.25794710191745, 73.27778285662187, 73.29760427028711, 73.31741132152376, 73.33720398893222, 73.35698225110268, 73.37674608661514, 73.39649547403941,
        73.41623039193514, 73.43595081885171, 73.45565673332838, 73.47534811389416, 73.49502493906793, 73.5146871873583, 73.53433483726378, 73.55396786727259, 73.5735862558628, 73.59318998150232,
        73.61277902264881, 73.63235335774978, 73.65191296524253, 73.67145782355412, 73.69098791110152, 73.71050320629138, 73.73000368752031, 73.74948933317457, 73.76896012163033, 73.78841603125353,
        73.80785704039991, 73.82728312741503, 73.84669427063429, 73.86609044838282, 73.8854716389756, 73.90483782071745, 73.92418897190292, 73.94352507081642, 73.96284609573216, 73.98215202491414,
        74.00144283661619, 74.02071850908195, 74.0399790205448, 74.05922434922805, 74.07845447334468, 74.09766937109758, 74.11686902067937, 74.13605340027257, 74.15522248804942, 74.17437626217196,
        74.19351470079215, 74.21263778205163, 74.23174548408191, 74.2508377850043, 74.2699146629299, 74.28897609595965, 74.30802206218426, 74.32705253968425, 74.34606750652998, 74.36506694078156,
        74.384050820489, 74.403019123692, 74.42197182842015, 74.4409089126928, 74.45983035451918, 74.47873613189822, 74.49762622281875, 74.51650060525934, 74.5353592571884, 74.55420215656414,
        74.5730292813346, 74.59184060943761, 74.61063611880074, 74.6294157873415, 74.64817959296708, 74.66692751357456, 74.68565952705079, 74.70437561127247, 74.723075744106, 74.74175990340771,
        74.76042806702367, 74.77908021278976, 74.7977163185317, 74.816336362065, 74.83494032119494, 74.85352817371668, 74.87209989741508, 74.89065547006494, 74.90919486943075, 74.9277180732669,
        74.9462250593175, 74.96471580531652, 74.98319028898771, 75.0016484880447, 75.02009038019081, 75.03851594311924, 75.05692515451298, 75.07531799204484, 75.09369443337742, 75.11205445616312,
        75.13039803804418, 75.14872515665257, 75.16703578961021, 75.18532991452867, 75.20360750900942, 75.22186855064369, 75.24011301701258, 75.25834088568692, 75.27655213422739, 75.29474674018446,
        75.31292468109847, 75.33108593449941, 75.34923047790726, 75.3673582888317, 75.38546934477223, 75.40356362321819, 75.42164110164867, 75.43970175753265, 75.45774556832883, 75.47577251148579,
        75.49378256444183, 75.51177570462517, 75.52975190945371, 75.54771115633528, 75.56565342266742, 75.58357868583754, 75.60148692322281, 75.61937811219025, 75.63725223009664, 75.65510925428858,
        75.67294916210255, 75.69077193086473, 75.70857753789114, 75.72636596048767, 75.7441371759499, 75.76189116156336, 75.77962789460321, 75.7973473523346, 75.81504951201234, 75.83273435088115,
        75.8504018461755, 75.8680519751197, 75.88568471492782, 75.90330004280378, 75.92089793594127, 75.93847837152386, 75.95604132672482, 75.9735867787073, 75.99111470462427, 76.00862508161842,
        76.02611788682235, 76.04359309735838, 76.0610506903387, 76.07849064286528, 76.09591293202989, 76.11331753491409, 76.13070442858933, 76.14807359011675, 76.1654249965474, 76.18275862492207,
        76.20007445227142, 76.2173724556158, 76.2346526119655, 76.2519148983205, 76.26915929167072, 76.28638576899576, 76.3035943072651, 76.320784883438, 76.33795747446351, 76.35511205728055,
        76.37224860881778, 76.3893671059937, 76.4064675257166, 76.4235498448846, 76.44061404038558, 76.45766008909729, 76.47468796788723, 76.49169765361277, 76.50868912312102, 76.52566235324892,
        76.54261732082324, 76.55955400266052, 76.57647237556714, 76.59337241633929, 76.6102541017629, 76.62711740861381, 76.64396231365757, 76.66078879364959, 76.67759682533507, 76.69438638544904,
        76.71115745071629, 76.72790999785147, 76.74464400355902, 76.76135944453314, 76.77805629745791, 76.79473453900717, 76.81139414584459, 76.8280350946236, 76.84465736198754, 76.86126092456941,
        76.87784575899214, 76.8944118418684, 76.91095914980072, 76.92748765938138, 76.9439973471925, 76.96048818980599, 76.97696016378357, 76.99341324567679, 77.009847412027, 77.02626263936531,
        77.04265890421273, 77.05903618307994, 77.07539445246755, 77.09173368886593, 77.10805386875523, 77.1243549686055, 77.14063696487646, 77.15689983401776, 77.17314355246879, 77.18936809665875,
        77.20557344300667, 77.22175956792135, 77.23792644780147, 77.25407405903546, 77.27020237800151, 77.28631138106773, 77.30240104459196, 77.31847134492186, 77.3345222583949, 77.35055376133836,
        77.36656583006935, 77.38255844089474, 77.39853157011123, 77.41448519400532, 77.43041928885333, 77.44633383092139, 77.46222879646542, 77.47810416173112, 77.49395990295406, 77.5097959963596,
        77.52561241816285, 77.54140914456879, 77.5571861517722, 77.57294341595764, 77.58868091329948, 77.60439861996191, 77.62009651209894, 77.63577456585432, 77.65143275736175, 77.66707106274454,
        77.68268945811595, 77.698287919579, 77.71386642322655, 77.72942494514119, 77.74496346139543, 77.76048194805146, 77.77598038116136, 77.791458736767, 77.80691699090008, 77.82235511958201,
        77.83777309882416, 77.85317090462755, 77.86854851298311, 77.88390589987155, 77.89924304126335, 77.91455991311888, 77.92985649138824, 77.94513275201135, 77.96038867091795, 77.97562422402761,
        77.99083938724966, 78.00603413648328, 78.02120844761743, 78.03636229653088, 78.05149565909218, 78.06660851115974, 78.08170082858177, 78.09677258719624, 78.11182376283098, 78.12685433130356,
        78.14186426842146, 78.15685354998185, 78.1718221517718, 78.18677004956812, 78.2016972191375, 78.21660363623637, 78.23148927661096, 78.24635411599736, 78.26119813012143, 78.27602129469888,
        78.29082358543515, 78.30560497802558, 78.32036544815524, 78.33510497149904, 78.3498235237217, 78.36452108047773, 78.37919761741145, 78.39385311015702, 78.40848753433838, 78.42310086556922,
        78.43769307945314, 78.45226415158349, 78.46681405754343, 78.48134277290595, 78.49585027323378, 78.51033653407958, 78.5248015309857, 78.53924523948433, 78.55366763509748, 78.568068693337,
        78.58244838970447, 78.59680669969133, 78.6111435987788, 78.62545906243793, 78.63975306612956, 78.65402558530435, 78.66827659540276, 78.68250607185504, 78.69671399008128, 78.71090032549135,
        78.72506505348494, 78.73920814945156, 78.75332958877047, 78.76742934681081, 78.78150739893148, 78.79556372048118, 78.80959828679846, 78.82361107321167, 78.8376020550389, 78.85157120758814,
        78.8655185061571, 78.87944392603337, 78.89334744249432, 78.90722903080712, 78.92108866622873, 78.93492632400596, 78.94874197937537, 78.96253560756341, 78.97630718378622, 78.99005668324988,
        79.00378408115013, 79.01748935267268, 79.03117247299292, 79.0448334172761, 79.05847216067724, 79.07208867834125, 79.08568294540271, 79.09925493698616, 79.11280462820585, 79.12633199416584,
        79.13983700996002, 79.15331965067209, 79.16677989137555, 79.18021770713369, 79.19363307299966, 79.20702596401637, 79.2203963552165, 79.23374422162263, 79.24706953824709, 79.26037228009201,
        79.27365242214935, 79.28690993940091, 79.3001448068182, 79.31335699936261, 79.32654649198533, 79.3397132596273, 79.35285727721939, 79.36597851968214, 79.37907696192597, 79.39215257885111,
        79.40520534534758, 79.4182352362952, 79.43124222656357, 79.44422629101219, 79.45718740449023, 79.47012554183682, 79.48304067788078, 79.49593278744076, 79.50880184532528, 79.52164782633258,
        79.53447070525075, 79.5472704568577, 79.56004705592112, 79.57280047719853, 79.58553069543723, 79.59823768537433, 79.61092142173679, 79.62358187924129, 79.63621903259443, 79.64883285649248,
        79.66142332562167, 79.67399041465794, 79.686534098267, 79.69905435110451, 79.71155114781577, 79.72402446303602, 79.73647427139024, 79.7489005474932, 79.76130326594958, 79.77368240135371,
        79.78603792828986, 79.79836982133202, 79.81067805504405, 79.82296260397959, 79.83522344268208, 79.84746054568475, 79.85967388751071, 79.87186344267276, 79.88402918567364, 79.8961710910058,
        79.90828913315151, 79.9203832865829, 79.93245352576186, 79.94449982514006, 79.95652215915904, 79.9685205022501, 79.98049482883441, 79.99244511332287, 80.00437133011624, 80.01627345360505,
        80.02815145816965, 80.04000531818019, 80.05183500799666, 80.06364050196886, 80.07542177443631, 80.0871787997284, 80.09891155216438, 80.1106200060532, 80.12230413569367, 80.13396391537441,
        80.14559931937386, 80.15721032196024, 80.16879689739156, 80.18035901991567, 80.19189666377021, 80.20340980318267, 80.21489841237026, 80.22636246554006, 80.23780193688897, 80.24921680060363,
        80.26060703086058, 80.27197260182606, 80.2833134876562, 80.29462966249689, 80.30592110048386, 80.31718777574261, 80.32842966238847, 80.33964673452658, 80.35083896625191, 80.36200633164914,
        80.37314880479288, 80.38426635974746, 80.39535897056703, 80.40642661129561, 80.41746925596698, 80.42848687860469, 80.43947945322213, 80.45044695382254, 80.46138935439888, 80.472306628934,
        80.48319875140054, 80.49406569576088, 80.50490743596725, 80.51572394596171, 80.5265151996761, 80.53728117103208, 80.54802183394109, 80.55873716230444, 80.56942713001317, 80.58009171094817,
        80.5907308789801, 80.60134460796951, 80.61193287176664, 80.62249564421165, 80.6330328991344, 80.64354461035467, 80.6540307516819, 80.66449129691553, 80.67492621984461, 80.68533549424812,
        80.69571909389482, 80.7060769925433, 80.71640916394185, 80.72671558182871, 80.73699621993183, 80.747251051969, 80.75748005164783, 80.7676831926657, 80.7778604487098, 80.78801179345719,
        80.79813720057467, 80.80823664371884, 80.81831009653617, 80.82835753266286, 80.83837892572502, 80.84837424933846, 80.85834347710883, 80.86828658263161, 80.87820353949212, 80.88809432126536,
        80.89795890151626, 80.90779725379949, 80.91760935165958, 80.92739516863082, 80.93715467823733, 80.94688785399302, 80.95659466940162, 80.96627509795671, 80.97592911314152, 80.98555668842933,
        80.99515779728297, 81.00473241315531, 81.01428050948881, 81.02380205971595, 81.0332970372588, 81.04276541552946, 81.05220716792962, 81.06162226785098, 81.07101068867487, 81.08037240377251,
        81.08970738650497, 81.09901561022303, 81.10829704826736, 81.11755167396834, 81.12677946064629, 81.1359803816112, 81.14515441016299, 81.15430151959126, 81.16342168317556, 81.17251487418508,
        81.181581065879, 81.19062023150619, 81.19963234430529, 81.20861737750485, 81.21757530432319, 81.22650609796844, 81.23540973163848, 81.2442861785211, 81.25313541179378, 81.26195740462393,
        81.27075213016862, 81.27951956157493, 81.28825967197955, 81.29697243450903, 81.30565782227981, 81.31431580839802, 81.32294636595974, 81.33154946805065, 81.34012508774649, 81.34867319811254,
        81.35719377220413, 81.36568678306621, 81.37415220373369, 81.38259000723112, 81.39100016657302, 81.39938265476363, 81.40773744479698, 81.41606450965696, 81.4243638223172, 81.43263535574127,
        81.44087908288239, 81.44909497668367, 81.45728301007799, 81.46544315598813, 81.47357538732649, 81.48167967699548, 81.48975599788719, 81.49780432288358, 81.50582462485639, 81.5138168766671,
        81.52178105116714, 81.52971712119762, 81.53762505958956, 81.54550483916367, 81.5533564327306, 81.56117981309067, 81.56897495303414, 81.57674182534095, 81.58448040278094, 81.5921906581137,
        81.59987256408871, 81.60752609344516, 81.61515121891202, 81.62274791320823, 81.63031614904237, 81.63785589911295, 81.64536713610818, 81.65284983270615, 81.6603039615747, 81.66772949537162,
        81.67512640674425, 81.68249466833, 81.68983425275589, 81.69714513263887, 81.70442728058565, 81.71168066919272, 81.71890527104647, 81.72610105872296, 81.7332680047882, 81.74040608179789,
        81.7475152622976, 81.75459551882265, 81.7616468238983, 81.76866915003944, 81.7756624697509, 81.78262675552725, 81.78956197985285, 81.79646811520196, 81.80334513403852, 81.81019300881646,
        81.81701171197923, 81.82380121596042, 81.83056149318314, 81.83729251606053, 81.84399425699534, 81.85066668838034, 81.85730978259788, 81.8639235120203, 81.87050784900966, 81.87706276591778,
        81.88358823508646, 81.89008422884707, 81.89655071952102, 81.90298767941931, 81.90939508084298, 81.91577289608264, 81.92212109741888, 81.92843965712198, 81.93472854745217, 81.9409877406593,
        81.94721720898319, 81.95341692465338, 81.95958685988919, 81.96572698689988, 81.97183727788438, 81.97791770503149, 81.98396824051979, 81.98998885651771, 81.9959795251834, 82.00194021866496,
        82.00787090910012, 82.01377156861659, 82.01964216933176, 82.02548268335285, 82.03129308277697, 82.03707333969089, 82.04282342617135, 82.04854331428477, 82.05423297608746, 82.05989238362545,
        82.0655215089347, 82.0711203240408, 82.07668880095939, 82.08222691169563, 82.08773462824476, 82.09321192259165, 82.09865876671097, 82.10407513256735, 82.10946099211503, 82.11481631729829,
        82.12014108005096, 82.12543525229688, 82.13069880594954, 82.13593171291242, 82.14113394507861, 82.14630547433116, 82.15144627254278, 82.15655631157617, 82.1616355632837, 82.16668399950754,
        82.17170159207978, 82.17668831282218, 82.18164413354647, 82.18656902605396, 82.19146296213601, 82.19632591357362, 82.20115785213767, 82.20595874958882, 82.21072857767756, 82.21546730814411,
        82.22017491271865, 82.22485136312105, 82.22949663106093, 82.2341106882379, 82.23869350634119, 82.24324505704999, 82.24776531203318, 82.25225424294955, 82.25671182144755, 82.26113801916564,
        82.26553280773186, 82.2698961587643, 82.27422804387064, 82.27852843464841, 82.2827973026851, 82.28703461955782, 82.29124035683363, 82.29541448606925, 82.29955697881138, 82.30366780659634,
        82.30774694095042, 82.31179435338962, 82.31581001541979, 82.31979389853653, 82.32374597422536, 82.32766621396149, 82.33155458920996, 82.33541107142567, 82.33923563205327, 82.34302824252728,
        82.34678887427194, 82.35051749870141, 82.35421408721949, 82.35787861122, 82.36151104208636, 82.36511135119198, 82.3686795098999, 82.37221548956315, 82.37571926152438, 82.37919079711615,
        82.3826300676609, 82.38603704447065, 82.38941169884751, 82.39275400208315, 82.39606392545925, 82.39934144024708, 82.40258651770796, 82.40579912909277, 82.40897924564241, 82.41212683858743,
        82.41524187914831, 82.41832433853524, 82.42137418794825, 82.42439139857721, 82.42737594160172, 82.43032778819133, 82.43324690950517, 82.43613327669242, 82.43898686089186, 82.44180763323227,
        82.44459556483207, 82.44735062679959, 82.45007279023288, 82.45276202621992, 82.45541830583839, 82.45804160015577, 82.46063188022949, 82.46318911710655, 82.46571328182401, 82.46820434540854,
        82.47066227887676, 82.47308705323495, 82.47547863947936, 82.4778370085959, 82.48016213156042, 82.48245397933847, 82.4847125228854, 82.48693773314648, 82.48912958105669, 82.49128803754087,
        82.49341307351355, 82.49550465987929, 82.49756276753223, 82.49958736735645, 82.50157843022578, 82.50353592700391, 82.50545982854425, 82.50735010569012, 82.50920672927458, 82.51102967012046,
        82.51281889904051, 82.5145743868372, 82.51629610430284, 82.5179840222195, 82.51963811135916, 82.52125834248348, 82.52284468634406, 82.52439711368214, 82.52591559522898, 82.52740010170541,
        82.52885060382226, 82.53026707228007, 82.53164947776916, 82.53299779096982, 82.53431198255191, 82.53559202317528, 82.53683788348951, 82.53804953413402, 82.539226945738, 82.54037008892047,
        82.54147893429023, 82.54255345244596, 82.54359361397601, 82.54459938945875, 82.54557074946213, 82.54650766454398, 82.54741010525208, 82.54827804212377, 82.54911144568642, 82.54991028645703,
        82.55067453494259, 82.55140416163967, 82.5520991370349, 82.55275943160447, 82.55338501581461, 82.55397586012117, 82.55453193496986, 82.55505321079627, 82.5555396580257, 82.55599124707334,
        82.55640794834409, 82.55678973223277, 82.55713656912388, 82.5574484293919, 82.55772528340088, 82.55796710150491, 82.55817385404774, 82.55834551136302, 82.55848204377408, 82.55858342159416,
        82.55864961512636, 82.55868059466339, 82.55867633048797, 82.55863679287248, 82.55856195207923, 82.55845177836021, 82.55830624195737, 82.55812531310227, 82.55790896201647, 82.55765715891118,
        82.5573698739876, 82.55704707743654, 82.55668873943867, 82.5562948301646, 82.55586531977453, 82.5554001784187, 82.55489937623693, 82.55436288335905, 82.55379066990449, 82.55318270598275,
        82.55253896169283, 82.5518594071238, 82.55114401235437, 82.55039274745316, 82.54960558247853, 82.54878248747863, 82.54792343249153, 82.54702838754494, 82.54609732265658, 82.54513020783374,
        82.54412701307378, 82.54308770836359, 82.54201226368014, 82.54090064898993, 82.53975283424954, 82.53856878940516, 82.53734848439282, 82.53609188913845, 82.53479897355768, 82.53346970755608,
        82.53210406102879, 82.53070200386105, 82.52926350592763, 82.52778853709339, 82.52627706721267, 82.52472906612998, 82.52314450367928, 82.52152334968461, 82.51986557395972, 82.51817114630805,
        82.51644003652306, 82.51467221438784, 82.51286764967546, 82.51102631214854, 82.50914817155984, 82.50723319765159, 82.50528136015612, 82.5032926287953, 82.50126697328105, 82.49920436331492,
        82.49710476858837, 82.49496815878263, 82.49279450356865, 82.49058377260742, 82.48833593554943, 82.48605096203526, 82.48372882169508, 82.48136948414907, 82.47897291900696, 82.47653909586859,
        82.4740679843233, 82.47155955395051, 82.4690137743192, 82.46643061498841, 82.46381004550679, 82.46115203541281, 82.4584565542349, 82.45572357149109, 82.45295305668942, 82.45014497932758,
        82.44729930889318, 82.44441601486349, 82.44149506670577, 82.43853643387692, 82.43554008582379, 82.43250599198298, 82.42943412178076, 82.42632444463348, 82.42317692994702, 82.4199915471173,
        82.41676826552988, 82.41350705456026, 82.41020788357356, 82.40687072192496, 82.40349553895916, 82.40008230401097, 82.39663098640472, 82.39314155545478, 82.38961398046516, 82.38604823072971,
        82.38244427553224, 82.37880208414613, 82.37512162583475, 82.37140286985117, 82.36764578543836, 82.36385034182895, 82.36001650824555, 82.35614425390044, 82.35223354799582, 82.34828435972359,
        82.34429665826555, 82.34027041279326, 82.336205592468, 82.33210216644106, 82.32796010385334, 82.32377937383569, 82.31955994550862, 82.31530178798266, 82.31100487035789, 82.30666916172443,
        82.30229463116201, 82.29788124774038, 82.29342898051883, 82.2889377985467, 82.28440767086303, 82.27983856649661, 82.27523045446621, 82.27058330378019, 82.26589708343688, 82.26117176242435,
        82.25640730972053, 82.25160369429304, 82.24676088509946, 82.24187885108701, 82.23695756119292, 82.231996984344, 82.22699708945707, 82.22195784543862, 82.21687922118494, 82.2117611855823,
        82.20660370750655, 82.20140675582353, 82.19617029938871, 82.19089430704759, 82.18557874763525, 82.18022358997675, 82.17482880288684, 82.16939435517016, 82.1639202156211, 82.15840635302382,
        82.15285273615244, 82.1472593337707, 82.14162611463233, 82.13595304748068, 82.13024010104907, 82.12448724406049, 82.11869444522789, 82.11286167325383, 82.1069888968309, 82.10107608464126,
        82.09512320535714, 82.08913022764034, 82.08309712014253, 82.07702385150534, 82.07091039035994, 82.06475670532761, 82.05856276501913, 82.05232853803537, 82.04605399296675, 82.03973909839371,
        82.03338382288635, 82.0269881350047, 82.02055200329842, 82.0140753963072, 82.00755828256038, 82.0010006305771, 81.99440240886642, 81.9877635859271, 81.9810841302478, 81.97436401030686,
        81.9676031945726, 81.96080165150292, 81.95395934954583, 81.94707625713878, 81.94015234270938, 81.93318757467479, 81.92618192144212, 81.91913535140823, 81.91204783295974, 81.90491933447323,
        81.89774982431489, 81.8905392708409, 81.8832876423971, 81.87599490731925, 81.86866103393282, 81.86128599055321, 81.85386974548541, 81.84641226702452, 81.83891352345519, 81.83137348305193,
        81.82379211407918, 81.81616938479104, 81.80850526343158, 81.80079971823443, 81.7930527174233, 81.78526422921148, 81.77743422180225, 81.76956266338854, 81.76164952215326, 81.7536947662689,
        81.74569836389799, 81.73766028319271, 81.72958049229504, 81.72145895933696, 81.71329565243997, 81.70509053971564, 81.69684358926516, 81.68855476917966, 81.68022404753995, 81.67185139241678,
        81.66343677187058, 81.65498015395171, 81.6464815067002, 81.63794079814603, 81.62935799630887, 81.62073306919824, 81.61206598481351, 81.60335671114372, 81.59460521616795, 81.58581146785484,
        81.57697543416305, 81.56809708304081, 81.55917638242641, 81.55021330024772, 81.54120780442264, 81.53215986285865, 81.52306944345325, 81.51393651409356, 81.50476104265658, 81.49554299700922,
        81.486282345008, 81.47697905449942, 81.46763309331968, 81.45824442929485, 81.44881303024071, 81.43933886396304, 81.42982189825717, 81.4202621009085, 81.41065943969201, 81.40101388237257,
        81.39132539670497, 81.38159395043358, 81.37181951129283, 81.3620020470067, 81.35214152528926, 81.34223791384406, 81.33229118036478, 81.32230129253466, 81.31226821802689, 81.3021919245044,
        81.29207237961998, 81.28190955101616, 81.27170340632526, 81.26145391316958, 81.25116103916098, 81.24082475190134, 81.23044501898218, 81.22002180798499, 81.20955508648088, 81.19904482203096,
        81.18849098218595, 81.1778935344866, 81.16725244646327, 81.15656768563623, 81.14583921951554, 81.13506701560097, 81.12425104138231, 81.1133912643389, 81.10248765194018, 81.09154017164505,
        81.08054879090254, 81.06951347715123, 81.05843419781976, 81.04731092032632, 81.0361436120791, 81.02493224047596, 81.01367677290472, 81.00237717674285, 80.99103341935768, 80.97964546810638,
        80.9682132903359, 80.95673685338306, 80.94521612457432, 80.9336510712262, 80.92204166064471, 80.91038786012601, 80.89868963695575, 80.88694695840967, 80.8751597917531, 80.86332810424122,
        80.85145186311915],
    "PADEL": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0.013984320356503988, 0.028969701002330206, 0.043955081648156424, 0.05894046229398242,
        0.07392584293980864, 0.08891122358563486, 0.10389660423146108, 0.11888198487728707, 0.1338673655231133, 0.1488527461689395, 0.1638381268147655, 0.17882350746059172, 0.19380888810641794, 0.20879426875224416,
        0.22377964939807016, 0.23876503004389638, 0.2537504106897226, 0.2687357913355486, 0.2837211719813748, 0.298706552627201, 0.31369193327302725, 0.32867731391885324, 0.34366269456467946, 0.3586480752105057,
        0.3736334558563317, 0.3886188365021579, 0.4036042171479841, 0.41858959779381033, 0.43357497843963655, 0.4485603590854623, 0.46354573973128854, 0.47853112037711476, 0.493516501022941, 0.5085018816687672,
        0.5234872623145934, 0.5384726429604196, 0.5534580236062454, 0.5684434042520716, 0.5834287848978978, 0.5984141655437241, 0.6133995461895503, 0.6283849268353765, 0.6433703074812027, 0.6583556881270285,
        0.6733410687728547, 0.6883264494186809, 0.7033118300645071, 0.7182972107103334, 0.7332825913561596, 0.7482679720019858, 0.7632533526478116, 0.7782387332936378, 0.793224113939464, 0.8082094945852902,
        0.8231948752311165, 0.8381802558769427, 0.8531656365227689, 0.8681510171685947, 0.8831363978144209, 0.8981217784602471, 0.9131071591060733, 0.9280925397518995, 0.9430779203977258, 0.958063301043552,
        0.9730486816893777, 0.988034062335204, 1.0030194429810302, 1.0180048236268564, 1.0329902042726826, 1.0479755849185088, 1.062960965564335, 1.0779463462101608, 1.092931726855987, 1.1079171075018133,
        1.1229024881476395, 1.1378878687934657, 1.152873249439292, 1.1678586300851181, 1.182844010730944, 1.1978293913767701, 1.2128147720225964, 1.2278001526684226, 1.2427855333142488, 1.257770913960075,
        1.2727562946059012, 1.287741675251727, 1.3027270558975532, 1.3177124365433794, 1.3326978171892057, 1.3476831978350319, 1.362668578480858, 1.3776539591266843, 1.39263933977251, 1.4076247204183363,
        1.4226101010641625, 1.4375954817099887, 1.452580862355815, 1.4675662430016412, 1.4825516236474674, 1.4975370042932932, 1.5125223849391194, 1.5275077655849456, 1.5424931462307718, 1.557478526876598,
        1.5724639075224243, 1.5874492881682505, 1.6024346688140763, 1.6174200494599025, 1.6324054301057287, 1.647390810751555, 1.6623761913973811, 1.6773615720432073, 1.6923469526890331, 1.7073323333348593,
        1.7223177139806856, 1.7373030946265118, 1.752288475272338, 1.7672738559181642, 1.7822592365639904, 1.7972446172098162, 1.8122299978556424, 1.8272153785014686, 1.8422007591472949, 1.857186139793121,
        1.8721715204389473, 1.8871569010847735, 1.9021422817305993, 1.9171276623764255, 1.9321130430222517, 1.947098423668078, 1.9620838043139042, 1.9770691849597304, 1.9920545656055566, 2.0070399462513824,
        2.0220253268972086, 2.037010707543035, 2.051996088188861, 2.0669814688346873, 2.0819668494805135, 2.0969522301263397, 2.1119376107721655, 2.1269229914179917, 2.141908372063818, 2.156893752709644,
        2.1718791333554703, 2.1868645140012966, 2.2018498946471228, 2.2168352752929485, 2.2318206559387748, 2.246806036584601, 2.261791417230427, 2.2767767978762534, 2.2917621785220796, 2.306747559167906,
        2.3217329398137316, 2.336718320459558, 2.351703701105384, 2.3666890817512103, 2.3816744623970365, 2.3966598430428627, 2.411645223688689, 2.426630604334515, 2.4416159849803414, 2.4566013656261676,
        2.471586746271993, 2.486572126917819, 2.5015575075636454, 2.5165428882094716, 2.531528268855298, 2.546513649501124, 2.5614990301469502, 2.5764844107927765, 2.5914697914386027, 2.606455172084429,
        2.621440552730255, 2.6364259333760813, 2.6514113140219076, 2.6663966946677338, 2.681382075313559, 2.6963674559593853, 2.7113528366052115, 2.7263382172510378, 2.741323597896864, 2.75630897854269,
        2.7712943591885164, 2.7862797398343426, 2.801265120480169, 2.816250501125995, 2.8312358817718213, 2.8462212624176475, 2.8612066430634737, 2.8761920237093, 2.8911774043551253, 2.9061627850009515,
        2.9211481656467777, 2.936133546292604, 2.95111892693843, 2.9661043075842564, 2.9810896882300826, 2.996075068875909, 3.011060449521735, 3.0260458301675612, 3.0410312108133875, 3.0560165914592137,
        3.07100197210504, 3.085987352750866, 3.1009727333966914, 3.1159581140425177, 3.130943494688344, 3.14592887533417, 3.1609142559799963, 3.1758996366258225, 3.1908850172716487, 3.205870397917475,
        3.220855778563301, 3.2358411592091274, 3.2508265398549536, 3.26581192050078, 3.280797301146606, 3.2957826817924323, 3.3107680624382576, 3.325753443084084, 3.34073882372991, 3.3557242043757363,
        3.3707095850215625, 3.3856949656673887, 3.400680346313215, 3.415665726959041, 3.4306511076048674, 3.4456364882506936, 3.46062186889652, 3.475607249542346, 3.4905926301881722, 3.5055780108339984,
        3.520563391479824, 3.53554877212565, 3.550534152771476, 3.5655195334173024, 3.5805049140631287, 3.595490294708955, 3.610475675354781, 3.6254610560006073, 3.6404464366464335, 3.6554318172922597,
        3.670417197938086, 3.685402578583912, 3.7003879592297384, 3.7153733398755637, 3.73035872052139, 3.745344101167216, 3.7603294818130424, 3.7753148624588686, 3.790300243104695, 3.805285623750521,
        3.8202710043963473, 3.8352563850421735, 3.8502417656879997, 3.865227146333826, 3.880212526979652, 3.8951979076254784, 3.9101832882713046, 3.92516866891713, 3.940154049562956, 3.9551394302087823,
        3.9701248108546086, 3.9851101915004348, 4.000095572146261, 4.015080952792087, 4.030066333437913, 4.04505171408374, 4.060037094729566, 4.075022475375392, 4.090007856021218, 4.1049932366670445,
        4.119978617312871, 4.134963997958696, 4.149949378604522, 4.1649347592503485, 4.179920139896175, 4.194905520542001, 4.209890901187827, 4.224876281833653, 4.23986166247948, 4.254847043125306,
        4.269832423771132, 4.284817804416958, 4.2998031850627845, 4.314788565708611, 4.329773946354437, 4.344759327000262, 4.3597447076460885, 4.374730088291915, 4.389715468937741, 4.404700849583567,
        4.419686230229393, 4.4346716108752195, 4.449656991521046, 4.464642372166872, 4.479627752812698, 4.494613133458524, 4.509598514104351, 4.524583894750177, 4.539569275396003, 4.554554656041828,
        4.569540036687655, 4.584525417333481, 4.599510797979307, 4.614496178625133, 4.6294815592709595, 4.644466939916786, 4.659452320562612, 4.674437701208438, 4.689423081854264, 4.704408462500091,
        4.719393843145917, 4.734379223791743, 4.749364604437569, 4.764349985083395, 4.779335365729221, 4.794320746375047, 4.809306127020873, 4.8242915076666995, 4.839276888312526, 4.854262268958352,
        4.869247649604178, 4.884233030250004, 4.8992184108958305, 4.914203791541657, 4.929189172187483, 4.944174552833309, 4.9591599334791345, 4.974145314124961, 4.989130694770787, 5.004116075416613,
        5.019101456062439, 5.034086836708266, 5.049072217354092, 5.064057597999918, 5.079042978645744, 5.0940283592915705, 5.109013739937397, 5.123999120583223, 5.138984501229049, 5.153969881874875,
        5.168955262520701, 5.183940643166527, 5.198926023812353, 5.213911404458179, 5.228896785104006, 5.243882165749832, 5.258867546395658, 5.273852927041484, 5.2888383076873104, 5.303823688333137,
        5.318809068978963, 5.333794449624789, 5.348779830270615, 5.3637652109164415, 5.378750591562267, 5.393735972208093, 5.408721352853919, 5.4237067334997455, 5.438692114145572, 5.453677494791398,
        5.468662875437224, 5.48364825608305, 5.498633636728877, 5.513619017374703, 5.528604398020529, 5.543589778666355, 5.5585751593121815, 5.573560539958008, 5.588545920603833, 5.603531301249659,
        5.6185166818954855, 5.633502062541312, 5.648487443187138, 5.663472823832964, 5.67845820447879, 5.693443585124617, 5.708428965770443, 5.723414346416269, 5.738399727062095, 5.753385107707921,
        5.768370488353748, 5.783355868999574, 5.798341249645399, 5.813326630291225, 5.828312010937052, 5.843297391582878, 5.858282772228704, 5.87326815287453, 5.8882535335203565, 5.903238914166183,
        5.918224294812009, 5.933209675457835, 5.948195056103661, 5.963180436749488, 5.978165817395314, 5.99315119804114, 6.008136578686965, 6.023121959332792, 6.038107339978618, 6.053092720624444,
        6.06807810127027, 6.0830634819160965, 6.098048862561923, 6.113034243207749, 6.128019623853575, 6.143005004499401, 6.157990385145228, 6.172975765791054, 6.18796114643688, 6.202946527082705,
        6.2179319077285315, 6.232917288374358, 6.247902669020184, 6.26288804966601, 6.277873430311836, 6.292858810957663, 6.307844191603489, 6.322829572249315, 6.337814952895141, 6.3528003335409675,
        6.367785714186794, 6.38277109483262, 6.397756475478446, 6.4127418561242715, 6.427727236770099, 6.442712617415924, 6.457697998061751, 6.472683378707576, 6.4876687593534035, 6.502654139999229,
        6.517639520645054, 6.532624901290881, 6.547610281936707, 6.562595662582534, 6.577581043228359, 6.592566423874186, 6.6075518045200115, 6.622537185165839, 6.637522565811664, 6.652507946457491,
        6.667493327103316, 6.682478707749143, 6.697464088394969, 6.712449469040796, 6.727434849686621, 6.7424202303324465, 6.757405610978274, 6.772390991624099, 6.787376372269926, 6.802361752915751,
        6.8173471335615785, 6.832332514207404, 6.847317894853231, 6.862303275499056, 6.877288656144883, 6.892274036790709, 6.907259417436536, 6.922244798082361, 6.9372301787281865, 6.952215559374014,
        6.967200940019839, 6.982186320665666, 6.997171701311491, 7.0121570819573185, 7.027142462603144, 7.042127843248971, 7.057113223894796, 7.072098604540623, 7.087083985186449, 7.102069365832276,
        7.117054746478101, 7.132040127123928, 7.1470255077697535, 7.162010888415579, 7.176996269061406, 7.191981649707231, 7.206967030353058, 7.221952410998884, 7.236937791644711, 7.251923172290536,
        7.266908552936363, 7.281893933582189, 7.296879314228016, 7.311864694873841, 7.326850075519668, 7.3418354561654935, 7.356820836811319, 7.371806217457146, 7.386791598102971, 7.401776978748798,
        7.416762359394624, 7.431747740040451, 7.446733120686276, 7.461718501332103, 7.476703881977929, 7.491689262623756, 7.506674643269581, 7.521660023915408, 7.536645404561233, 7.551630785207059,
        7.566616165852886, 7.581601546498711, 7.596586927144538, 7.611572307790364, 7.626557688436191, 7.641543069082016, 7.656528449727843, 7.6715138303736685, 7.686499211019496, 7.701484591665321,
        7.716469972311148, 7.731455352956973, 7.7464407336028005, 7.761426114248626, 7.776411494894451, 7.791396875540278, 7.806382256186104, 7.821367636831931, 7.836353017477756, 7.851338398123583,
        7.8663237787694085, 7.881309159415236, 7.896294540061061, 7.911279920706888, 7.926265301352713, 7.9412506819985405, 7.956236062644366, 7.971221443290191, 7.986206823936018, 8.001192204581844,
        8.01617758522767, 8.031162965873495, 8.046148346519324, 8.06113372716515, 8.076119107810975, 8.0911044884568, 8.106089869102629, 8.121075249748454, 8.13606063039428, 8.151046011040105,
        8.166031391685934, 8.181016772331759, 8.196002152977584, 8.21098753362341, 8.225972914269235, 8.240958294915064, 8.25594367556089, 8.270929056206715, 8.28591443685254, 8.300899817498369,
        8.315885198144194, 8.33087057879002, 8.345855959435845, 8.360841340081674, 8.375826720727499, 8.390812101373324, 8.40579748201915, 8.420782862664975, 8.435768243310804, 8.45075362395663,
        8.465739004602455, 8.48072438524828, 8.495709765894109, 8.510695146539934, 8.52568052718576, 8.540665907831585, 8.555651288477414, 8.570636669123239, 8.585622049769064, 8.60060743041489,
        8.615592811060715, 8.630578191706544, 8.64556357235237, 8.660548952998194, 8.67553433364402, 8.690519714289849, 8.705505094935674, 8.7204904755815, 8.735475856227325, 8.750461236873154,
        8.765446617518979, 8.780431998164804, 8.79541737881063, 8.810402759456455, 8.825388140102284, 8.84037352074811, 8.855358901393934, 8.87034428203976, 8.885329662685589, 8.900315043331414,
        8.91530042397724, 8.930285804623065, 8.945271185268894, 8.960256565914719, 8.975241946560544, 8.99022732720637, 9.005212707852195, 9.020198088498024, 9.035183469143849, 9.050168849789674,
        9.0651542304355, 9.080139611081329, 9.095124991727154, 9.11011037237298, 9.125095753018805, 9.140081133664633, 9.155066514310459, 9.170051894956284, 9.18503727560211, 9.200022656247938,
        9.215008036893764, 9.229993417539589, 9.244978798185414, 9.25996417883124, 9.274949559477069, 9.289934940122894, 9.30492032076872, 9.319905701414545, 9.334891082060373, 9.349876462706199,
        9.364861843352024, 9.37984722399785, 9.394832604643678, 9.409817985289504, 9.424803365935329, 9.439788746581154, 9.45477412722698, 9.469759507872809, 9.484744888518634, 9.49973026916446,
        9.514715649810285, 9.529701030456113, 9.544686411101939, 9.559671791747764, 9.57465717239359, 9.589642553039418, 9.604627933685244, 9.619613314331069, 9.634598694976894, 9.64958407562272,
        9.664569456268548, 9.679554836914374, 9.6945402175602, 9.709525598206024, 9.724510978851853, 9.739496359497679, 9.754481740143504, 9.76946712078933, 9.784452501435158, 9.799437882080984,
        9.814423262726809, 9.829408643372634, 9.84439402401846, 9.859379404664288, 9.874364785310114, 9.889350165955939, 9.904335546601764, 9.919320927247593, 9.934306307893419, 9.949291688539244,
        9.96427706918507, 9.979262449830898, 9.994247830476723, 10.009233211122549, 10.024218591768374, 10.0392039724142, 10.054189353060028, 10.069174733705854, 10.084160114351679, 10.099145494997504,
        10.114130875643333, 10.129116256289159, 10.144101636934984, 10.15908701758081, 10.174072398226638, 10.189057778872463, 10.204043159518289, 10.219028540164114, 10.234013920809943, 10.248999301455768,
        10.263984682101594, 10.278970062747419, 10.293955443393244, 10.308940824039073, 10.323926204684899, 10.338911585330724, 10.35389696597655, 10.368882346622378, 10.383867727268203, 10.398853107914029,
        10.413838488559854, 10.428823869205683, 10.443809249851508, 10.458794630497334, 10.473780011143159, 10.488765391788984, 10.503750772434813, 10.518736153080638, 10.533721533726464, 10.54870691437229,
        10.563692295018118, 10.578677675663943, 10.593663056309769, 10.608648436955594, 10.623633817601423, 10.638619198247248, 10.653604578893074, 10.668589959538899, 10.683575340184724, 10.698560720830553,
        10.713546101476378, 10.728531482122204, 10.743516862768029, 10.758502243413858, 10.773487624059683, 10.788473004705509, 10.803458385351334, 10.818443765997163, 10.833429146642988, 10.848414527288814,
        10.863399907934639, 10.878385288580464, 10.893370669226293, 10.908356049872118, 10.923341430517944, 10.938326811163769, 10.953312191809598, 10.968297572455423, 10.983282953101249, 10.998268333747074,
        11.013253714392903, 11.028239095038728, 11.043224475684553, 11.058209856330379, 11.073195236976208, 11.088180617622033, 11.103165998267858, 11.118151378913684, 11.133136759559509, 11.148122140205338,
        11.163107520851163, 11.178092901496989, 11.193078282142814, 11.208063662788643, 11.223049043434468, 11.238034424080293, 11.253019804726119, 11.268005185371948, 11.282990566017773, 11.297975946663598,
        11.312961327309424, 11.327946707955249, 11.342932088601078, 11.357917469246903, 11.372902849892728, 11.387888230538554, 11.402873611184383, 11.417858991830208, 11.432844372476033, 11.447829753121859,
        11.462815133767688, 11.477800514413513, 11.492785895059338, 11.507771275705164, 11.522756656350989, 11.537742036996818, 11.552727417642643, 11.567712798288468, 11.582698178934294, 11.597683559580123,
        11.612668940225948, 11.627654320871773, 11.642639701517599, 11.657625082163428, 11.672610462809253, 11.687595843455078, 11.702581224100904, 11.717566604746729, 11.732551985392558, 11.747537366038383,
        11.762522746684208, 11.777508127330034, 11.792493507975863, 11.807478888621688, 11.822464269267513, 11.837449649913339, 11.852435030559167, 11.867420411204993, 11.882405791850818, 11.897391172496643,
        11.912376553142469, 11.927361933788298, 11.942347314434123, 11.957332695079948, 11.972318075725774, 11.987303456371603, 12.002288837017428, 12.017274217663253, 12.032259598309079, 12.047244978954907,
        12.062230359600733, 12.077215740246558, 12.092201120892383, 12.107186501538212, 12.122171882184038, 12.137157262829863, 12.152142643475688, 12.167128024121514, 12.182113404767343, 12.197098785413168,
        12.212084166058993, 12.227069546704818, 12.242054927350647, 12.257040307996473, 12.272025688642298, 12.287011069288123, 12.301996449933952, 12.316981830579778, 12.331967211225603, 12.346952591871428,
        12.361937972517254, 12.376923353163082, 12.391908733808908, 12.406894114454733, 12.421879495100558, 12.436864875746387, 12.451850256392213, 12.466835637038038, 12.481821017683863, 12.496806398329692,
        12.511791778975518, 12.526777159621343, 12.541762540267168, 12.556747920912994, 12.571733301558822, 12.586718682204648, 12.601704062850473, 12.616689443496298, 12.631674824142127, 12.646660204787953,
        12.661645585433778, 12.676630966079603, 12.691616346725432, 12.706601727371257, 12.721587108017083, 12.736572488662908, 12.751557869308733, 12.766543249954562, 12.781528630600388, 12.796514011246213,
        12.811499391892038, 12.826484772537867, 12.841470153183693, 12.856455533829518, 12.871440914475343, 12.886426295121172, 12.901411675766997, 12.916397056412823, 12.931382437058648, 12.946367817704473,
        12.961353198350302, 12.976338578996128, 12.991323959641953, 13.006309340287778, 13.021294720933607, 13.036280101579433, 13.051265482225258, 13.066250862871083, 13.081236243516912, 13.096221624162737,
        13.111207004808563, 13.126192385454388, 13.141177766100217, 13.156163146746042, 13.171148527391868, 13.186133908037693, 13.201119288683518, 13.216104669329347, 13.231090049975172, 13.246075430620998,
        13.261060811266823, 13.276046191912652, 13.291031572558477, 13.306016953204303, 13.321002333850128, 13.335987714495957, 13.350973095141782, 13.365958475787608, 13.380943856433433, 13.395929237079258,
        13.410914617725087, 13.425899998370912, 13.440885379016738, 13.455870759662563, 13.470856140308392, 13.485841520954217, 13.500826901600043, 13.515812282245868, 13.530797662891697, 13.545783043537522,
        13.560768424183347, 13.575753804829173, 13.590739185474998, 13.605724566120827, 13.620709946766652, 13.635695327412478, 13.650680708058303, 13.665666088704132, 13.680651469349957, 13.695636849995783,
        13.710622230641608, 13.725607611287437, 13.740592991933262, 13.755578372579087, 13.770563753224913, 13.785549133870738, 13.800534514516567, 13.815519895162392, 13.830505275808218, 13.845490656454043,
        13.860476037099872, 13.875461417745697, 13.890446798391523, 13.905432179037348, 13.920417559683177, 13.935402940329002, 13.950388320974827, 13.965373701620653, 13.980359082266478, 13.995344462912307,
        14.010329843558132, 14.025315224203958, 14.040300604849783, 14.055285985495612, 14.070271366141437, 14.085256746787262, 14.100242127433088, 14.115227508078917, 14.130212888724742, 14.145198269370567,
        14.160183650016393, 14.175169030662222, 14.190154411308047, 14.205139791953872, 14.220125172599698, 14.235110553245523, 14.250095933891352, 14.265081314537177, 14.280066695183002, 14.295052075828828,
        14.310037456474657, 14.325022837120482, 14.340008217766307, 14.354993598412133, 14.369978979057962, 14.384964359703787, 14.399949740349612, 14.414935120995438, 14.429920501641266, 14.444905882287092,
        14.459891262932917, 14.474876643578742, 14.489862024224571, 14.504847404870397, 14.519832785516222, 14.534818166162047, 14.549803546807876, 14.564788927453701, 14.579774308099527, 14.594759688745352,
        14.609745069391177, 14.624730450037006, 14.639715830682832, 14.654701211328657, 14.669686591974482, 14.684671972620311, 14.699657353266137, 14.714642733911962, 14.729628114557787, 14.744613495203616,
        14.759598875849441, 14.774584256495267, 14.789569637141092, 14.804555017786921, 14.819540398432746, 14.834525779078572, 14.849511159724397, 14.864496540370222, 14.879481921016051, 14.894467301661876,
        14.909452682307702, 14.924438062953527, 14.939423443599356, 14.954408824245181, 14.969394204891007, 14.984379585536832, 14.999364966182661, 15.014350346828486, 15.029335727474312, 15.044321108120137,
        15.059306488765962, 15.074291869411791, 15.089277250057616, 15.104262630703442, 15.119248011349267, 15.134233391995096, 15.149218772640921, 15.164204153286747, 15.179189533932572, 15.1941749145784,
        15.209160295224226, 15.224145675870052, 15.239131056515877, 15.254116437161702, 15.269101817807531, 15.284087198453356, 15.299072579099182, 15.314057959745007, 15.329043340390836, 15.344028721036661,
        15.359014101682487, 15.373999482328312, 15.38898486297414, 15.403970243619966, 15.418955624265791, 15.433941004911617, 15.448926385557442, 15.463911766203271, 15.478897146849096, 15.493882527494922,
        15.508867908140747, 15.523853288786576, 15.538838669432401, 15.553824050078227, 15.568809430724052, 15.58379481136988, 15.598780192015706, 15.613765572661531, 15.628750953307357, 15.643736333953182,
        15.658721714599011, 15.673707095244836, 15.688692475890662, 15.703677856536487, 15.718663237182316, 15.733648617828141, 15.748633998473966, 15.763619379119792, 15.77860475976562, 15.793590140411446,
        15.808575521057271, 15.823560901703097, 15.838546282348926, 15.853531662994751, 15.868517043640576, 15.883502424286402, 15.898487804932227, 15.913473185578056, 15.928458566223881, 15.943443946869706,
        15.958429327515532, 15.97341470816136, 15.988400088807186, 16.00338546945301, 16.018370850098837, 16.033356230744666, 16.04834161139049, 16.063326992036316, 16.07831237268214, 16.093297753327967,
        16.108283133973796, 16.12326851461962, 16.138253895265446, 16.15323927591127, 16.1682246565571, 16.183210037202926, 16.19819541784875, 16.213180798494577, 16.228166179140405, 16.24315155978623,
        16.258136940432056, 16.27312232107788, 16.288107701723707, 16.303093082369536, 16.31807846301536, 16.333063843661186, 16.34804922430701, 16.36303460495284, 16.378019985598666, 16.39300536624449,
        16.407990746890317, 16.422976127536145, 16.43796150818197, 16.452946888827796, 16.46793226947362, 16.482917650119447, 16.497903030765276, 16.5128884114111, 16.527873792056926, 16.54285917270275,
        16.55784455334858, 16.572829933994406, 16.58781531464023, 16.602800695286057, 16.617786075931885, 16.63277145657771, 16.647756837223536, 16.66274221786936, 16.677727598515187, 16.692712979161016,
        16.70769835980684, 16.722683740452666, 16.73766912109849, 16.75265450174432, 16.767639882390146, 16.78262526303597, 16.797610643681796, 16.812596024327625, 16.82758140497345, 16.842566785619276,
        16.8575521662651, 16.87253754691093, 16.887522927556756, 16.90250830820258, 16.917493688848406, 16.93247906949423, 16.94746445014006, 16.962449830785886, 16.97743521143171, 16.992420592077536,
        17.007405972723365, 17.02239135336919, 17.037376734015016, 17.05236211466084, 17.06734749530667, 17.082332875952495, 17.09731825659832, 17.112303637244146, 17.12728901788997, 17.1422743985358,
        17.157259779181626, 17.17224515982745, 17.187230540473276, 17.202215921119105, 17.21720130176493, 17.232186682410756, 17.24717206305658, 17.26215744370241, 17.277142824348235, 17.29212820499406,
        17.307113585639886, 17.32209896628571, 17.33708434693154, 17.352069727577366, 17.36705510822319, 17.382040488869016, 17.397025869514845, 17.41201125016067, 17.426996630806496, 17.44198201145232,
        17.45696739209815, 17.471952772743975, 17.4869381533898, 17.501923534035626, 17.51690891468145, 17.53189429532728, 17.546879675973106, 17.56186505661893, 17.576850437264756, 17.591835817910585,
        17.60682119855641, 17.621806579202236, 17.63679195984806, 17.65177734049389, 17.666762721139715, 17.68174810178554, 17.696733482431366, 17.71171886307719, 17.72670424372302, 17.741689624368846,
        17.75667500501467, 17.771660385660496, 17.786645766306325, 17.80163114695215, 17.816616527597976, 17.8316019082438, 17.84658728888963, 17.861572669535455, 17.87655805018128, 17.891543430827106,
        17.906528811472935, 17.92151419211876, 17.936499572764586, 17.95148495341041, 17.966470334056236, 17.981455714702065, 17.99644109534789, 18.011426475993716, 18.02641185663954, 18.04139723728537,
        18.056382617931195, 18.07136799857702, 18.086353379222846, 18.101338759868675, 18.1163241405145, 18.131309521160325, 18.14629490180615, 18.161280282451976, 18.176265663097805, 18.19125104374363,
        18.206236424389456, 18.22122180503528, 18.23620718568111, 18.251192566326935, 18.26617794697276, 18.281163327618586, 18.296148708264415, 18.31113408891024, 18.326119469556065, 18.34110485020189,
        18.356090230847716, 18.371075611493545, 18.38606099213937, 18.401046372785196, 18.41603175343102, 18.43101713407685, 18.446002514722675, 18.4609878953685, 18.475973276014326, 18.490958656660155,
        18.50594403730598, 18.520929417951805, 18.53591479859763, 18.550900179243456, 18.565885559889285, 18.58087094053511, 18.595856321180936, 18.61084170182676, 18.62582708247259, 18.640812463118415,
        18.65579784376424, 18.670783224410066, 18.685768605055895, 18.70075398570172, 18.715739366347545, 18.73072474699337, 18.7457101276392, 18.760695508285025, 18.77568088893085, 18.790666269576676,
        18.8056516502225, 18.82063703086833, 18.835622411514155, 18.85060779215998, 18.865593172805806, 18.880578553451635, 18.89556393409746, 18.910549314743285, 18.92553469538911, 18.94052007603494,
        18.955505456680765, 18.97049083732659, 18.985476217972415, 19.00046159861824, 19.01544697926407, 19.030432359909895, 19.04541774055572, 19.060403121201546, 19.075388501847375, 19.0903738824932,
        19.105359263139025, 19.12034464378485, 19.13533002443068, 19.150315405076505, 19.16530078572233, 19.180286166368155, 19.19527154701398, 19.21025692765981, 19.225242308305635, 19.24022768895146,
        19.255213069597286, 19.270198450243115, 19.28518383088894, 19.300169211534765, 19.31515459218059, 19.33013997282642, 19.345125353472245, 19.36011073411807, 19.375096114763895, 19.39008149540972,
        19.40506687605555, 19.420052256701375, 19.4350376373472, 19.450023017993026, 19.465008398638854, 19.47999377928468, 19.494979159930505, 19.50996454057633, 19.52494992122216, 19.539935301867985,
        19.55492068251381, 19.569906063159635, 19.58489144380546, 19.59987682445129, 19.614862205097115, 19.62984758574294, 19.644832966388766, 19.659818347034594, 19.67480372768042, 19.689789108326245,
        19.70477448897207, 19.7197598696179, 19.734745250263725, 19.74973063090955, 19.764716011555375, 19.779701392201204, 19.79468677284703, 19.809672153492855, 19.82465753413868, 19.839642914784505,
        19.854628295430334, 19.86961367607616, 19.884599056721985, 19.89958443736781, 19.91456981801364, 19.929555198659465, 19.94454057930529, 19.959525959951115, 19.974511340596944, 19.98949672124277,
        20.004482101888595, 20.01946748253442, 20.034452863180245, 20.049438243826074, 20.0644236244719, 20.079409005117725, 20.09439438576355, 20.10937976640938, 20.124365147055205, 20.13935052770103,
        20.154335908346855, 20.169321288992684, 20.18430666963851, 20.199292050284335, 20.21427743093016, 20.229262811575985, 20.244248192221814, 20.25923357286764, 20.274218953513465, 20.28920433415929,
        20.30418971480512, 20.319175095450944, 20.33416047609677, 20.349145856742595, 20.364131237388424, 20.37911661803425, 20.394101998680075, 20.4090873793259, 20.424072759971725, 20.439058140617554,
        20.45404352126338, 20.469028901909205, 20.48401428255503, 20.49899966320086, 20.513985043846684, 20.52897042449251, 20.543955805138335, 20.558941185784164, 20.57392656642999, 20.588911947075815,
        20.60389732772164, 20.618882708367465, 20.633868089013294, 20.64885346965912, 20.663838850304945, 20.67882423095077, 20.6938096115966, 20.708794992242424, 20.72378037288825, 20.738765753534075,
        20.753751134179904, 20.76873651482573, 20.783721895471555, 20.79870727611738, 20.81369265676321, 20.828678037409034, 20.84366341805486, 20.858648798700685, 20.87363417934651, 20.88861955999234,
        20.903604940638164, 20.91859032128399, 20.933575701929815, 20.948561082575644, 20.96354646322147, 20.978531843867295, 20.99351722451312, 21.00850260515895, 21.023487985804774, 21.0384733664506,
        21.053458747096425, 21.06844412774225, 21.08342950838808, 21.098414889033904, 21.11340026967973, 21.128385650325555, 21.143371030971384, 21.15835641161721, 21.173341792263034, 21.18832717290886,
        21.20331255355469, 21.218297934200514, 21.23328331484634, 21.248268695492165, 21.26325407613799, 21.27823945678382, 21.293224837429644, 21.30821021807547, 21.323195598721295, 21.338180979367124,
        21.35316636001295, 21.368151740658774, 21.3831371213046, 21.39812250195043, 21.413107882596254, 21.42809326324208, 21.443078643887905, 21.45806402453373, 21.47304940517956, 21.488034785825384,
        21.50302016647121, 21.518005547117035, 21.532990927762864, 21.54797630840869, 21.562961689054514, 21.57794706970034, 21.59293245034617, 21.607917830991994, 21.62290321163782, 21.637888592283645,
        21.65287397292947, 21.6678593535753, 21.682844734221124, 21.69783011486695, 21.712815495512775, 21.727800876158604, 21.74278625680443, 21.757771637450254, 21.77275701809608, 21.78774239874191,
        21.802727779387734, 21.81771316003356, 21.832698540679385, 21.847683921325213, 21.86266930197104, 21.877654682616864, 21.89264006326269, 21.907625443908515, 21.922610824554344, 21.93759620520017,
        21.952581585845994, 21.96756696649182, 21.98255234713765, 21.997537727783474, 22.0125231084293, 22.027508489075124, 22.042493869720953, 22.05747925036678, 22.072464631012604, 22.08745001165843,
        22.102435392304255, 22.117420772950084, 22.13240615359591, 22.147391534241734, 22.16237691488756, 22.17736229553339, 22.192347676179214, 22.20733305682504, 22.222318437470864, 22.237303818116693,
        22.25228919876252, 22.267274579408344, 22.28225996005417, 22.297245340699995, 22.312230721345824, 22.32721610199165, 22.342201482637474, 22.3571868632833, 22.37217224392913, 22.387157624574954,
        22.40214300522078, 22.417128385866604, 22.432113766512433, 22.44709914715826, 22.462084527804084, 22.47706990844991, 22.492055289095735, 22.507040669741563, 22.52202605038739, 22.537011431033214,
        22.55199681167904, 22.56698219232487, 22.581967572970694, 22.59695295361652, 22.611938334262344, 22.626923714908173, 22.641909095554, 22.656894476199824, 22.67187985684565, 22.686865237491478,
        22.701850618137303, 22.71683599878313, 22.731821379428954, 22.74680676007478, 22.76179214072061, 22.776777521366434, 22.79176290201226, 22.806748282658084, 22.821733663303913, 22.83671904394974,
        22.851704424595564, 22.86668980524139, 22.881675185887218, 22.896660566533043, 22.91164594717887, 22.926631327824694, 22.94161670847052, 22.95660208911635, 22.971587469762174, 22.986572850408,
        23.001558231053824, 23.016543611699653, 23.03152899234548, 23.046514372991304, 23.06149975363713, 23.076485134282958, 23.091470514928783, 23.10645589557461, 23.121441276220434, 23.13642665686626,
        23.151412037512088, 23.166397418157914, 23.18138279880374, 23.196368179449564, 23.211353560095393, 23.22633894074122, 23.241324321387044, 23.25630970203287, 23.271295082678698, 23.286280463324523,
        23.30126584397035, 23.316251224616174, 23.331236605262, 23.346221985907828, 23.361207366553653, 23.37619274719948, 23.391178127845304, 23.406163508491133, 23.42114888913696, 23.436134269782784,
        23.45111965042861, 23.466105031074438, 23.481090411720263, 23.49607579236609, 23.511061173011914, 23.52604655365774, 23.541031934303568, 23.556017314949393, 23.57100269559522, 23.585988076241044,
        23.600973456886873, 23.6159588375327, 23.630944218178524, 23.64592959882435, 23.660914979470178, 23.675900360116003, 23.69088574076183, 23.705871121407654, 23.720856502053483, 23.735841882699308,
        23.750827263345133, 23.76581264399096, 23.780798024636784, 23.795783405282613, 23.81076878592844, 23.825754166574264, 23.84073954722009, 23.855724927865918, 23.870710308511743, 23.88569568915757,
        23.900681069803394, 23.915666450449223, 23.930651831095048, 23.945637211740873, 23.9606225923867, 23.975607973032524, 23.990593353678353, 24.005578734324178, 24.020564114970004, 24.03554949561583,
        24.050534876261658, 24.065520256907483, 24.08050563755331, 24.095491018199134, 24.110476398844963, 24.125461779490788, 24.140447160136613, 24.15543254078244, 24.170417921428264, 24.185403302074093,
        24.200388682719918, 24.215374063365743, 24.23035944401157, 24.245344824657398, 24.260330205303223, 24.27531558594905, 24.290300966594874, 24.305286347240703, 24.320271727886528, 24.335257108532353,
        24.35024248917818, 24.365227869824004, 24.380213250469833, 24.395198631115658, 24.410184011761483, 24.42516939240731, 24.440154773053138, 24.455140153698963, 24.47012553434479, 24.485110914990614,
        24.500096295636443, 24.515081676282268, 24.530067056928093, 24.54505243757392, 24.560037818219744, 24.575023198865573, 24.590008579511398, 24.604993960157223, 24.61997934080305, 24.634964721448878,
        24.649950102094703, 24.66493548274053, 24.679920863386354, 24.694906244032182, 24.709891624678008, 24.724877005323833, 24.73986238596966, 24.754847766615487, 24.769833147261313, 24.784818527907138,
        24.799803908552963, 24.81478928919879, 24.829774669844618, 24.844760050490443, 24.859745431136268, 24.874730811782094, 24.889716192427922, 24.904701573073748, 24.919686953719573, 24.9346723343654,
        24.949657715011227, 24.964643095657053, 24.979628476302878, 24.994613856948703, 25.00959923759453, 25.024584618240358, 25.039569998886183, 25.054555379532008, 25.069540760177834, 25.084526140823662,
        25.099511521469488, 25.114496902115313, 25.12948228276114, 25.144467663406967, 25.159453044052793, 25.174438424698618, 25.189423805344443, 25.20440918599027, 25.219394566636097, 25.234379947281923,
        25.249365327927748, 25.264350708573573, 25.279336089219402, 25.294321469865228, 25.309306850511053, 25.32429223115688, 25.339277611802707, 25.354262992448533, 25.369248373094358, 25.384233753740183,
        25.39921913438601, 25.414204515031837, 25.429189895677663, 25.444175276323488, 25.459160656969313, 25.474146037615142, 25.489131418260968, 25.504116798906793, 25.51910217955262, 25.534087560198447,
        25.549072940844272, 25.564058321490098, 25.579043702135923, 25.59402908278175, 25.609014463427577, 25.623999844073403, 25.638985224719228, 25.653970605365053, 25.668955986010882, 25.683941366656708,
        25.698926747302533, 25.71391212794836, 25.728897508594187, 25.743882889240012, 25.758868269885838, 25.773853650531663, 25.788839031177492, 25.803824411823317, 25.818809792469143, 25.833795173114968,
        25.848780553760793, 25.863765934406622, 25.878751315052448, 25.893736695698273, 25.908722076344098, 25.923707456989927, 25.938692837635752, 25.953678218281578, 25.968663598927403, 25.983648979573232,
        25.998634360219057, 26.013619740864883, 26.028605121510708, 26.043590502156533, 26.058575882802362, 26.073561263448187, 26.088546644094013, 26.103532024739838, 26.118517405385667, 26.133502786031492,
        26.148488166677318, 26.163473547323143, 26.178458927968972, 26.193444308614797, 26.208429689260623, 26.223415069906448, 26.238400450552273, 26.253385831198102, 26.268371211843927, 26.283356592489753,
        26.298341973135578, 26.313327353781407, 26.328312734427232, 26.343298115073058, 26.358283495718883, 26.373268876364712, 26.388254257010537, 26.403239637656363, 26.418225018302188, 26.433210398948013,
        26.448195779593842, 26.463181160239667, 26.478166540885493, 26.493151921531318, 26.508137302177147, 26.523122682822972, 26.538108063468798, 26.553093444114623, 26.568078824760452, 26.583064205406277,
        26.598049586052102, 26.613034966697928, 26.628020347343753, 26.643005727989582, 26.657991108635407, 26.672976489281233, 26.687961869927058, 26.702947250572887, 26.717932631218712, 26.732918011864538,
        26.747903392510363, 26.76288877315619, 26.777874153802017, 26.792859534447842, 26.807844915093668, 26.822830295739497, 26.837815676385322, 26.852801057031147, 26.867786437676973, 26.882771818322798,
        26.897757198968627, 26.912742579614452, 26.927727960260277, 26.942713340906103, 26.95769872155193, 26.972684102197757, 26.987669482843582, 27.002654863489408, 27.017640244135237, 27.032625624781062,
        27.047611005426887, 27.062596386072713, 27.077581766718538, 27.092567147364367, 27.107552528010192, 27.122537908656017, 27.137523289301843, 27.15250866994767, 27.167494050593497, 27.182479431239322,
        27.197464811885148, 27.212450192530977, 27.227435573176802, 27.242420953822627, 27.257406334468453, 27.272391715114278, 27.287377095760107, 27.302362476405932, 27.317347857051757, 27.332333237697583,
        27.34731861834341, 27.362303998989237, 27.377289379635062, 27.392274760280888, 27.407260140926716, 27.422245521572542, 27.437230902218367, 27.452216282864192, 27.467201663510018, 27.482187044155847,
        27.497172424801672, 27.512157805447497, 27.527143186093323, 27.54212856673915, 27.557113947384977, 27.572099328030802, 27.587084708676628, 27.602070089322456, 27.61705546996828, 27.632040850614107,
        27.647026231259932, 27.66201161190576, 27.676996992551587, 27.691982373197412, 27.706967753843237, 27.721953134489063, 27.73693851513489, 27.751923895780717, 27.766909276426542, 27.781894657072367,
        27.796880037718196, 27.81186541836402, 27.826850799009847, 27.841836179655672, 27.8568215603015, 27.871806940947327, 27.886792321593152, 27.901777702238977, 27.916763082884803, 27.93174846353063,
        27.946733844176457, 27.961719224822282, 27.976704605468107, 27.991689986113936, 28.00667536675976, 28.021660747405587, 28.036646128051412, 28.05163150869724, 28.066616889343067, 28.081602269988892,
        28.096587650634717, 28.111573031280543, 28.12655841192637, 28.141543792572197, 28.156529173218022, 28.171514553863847, 28.186499934509676, 28.2014853151555, 28.216470695801327, 28.231456076447152,
        28.24644145709298, 28.261426837738806, 28.276412218384632, 28.291397599030457, 28.306382979676282, 28.32136836032211, 28.336353740967937, 28.351339121613762, 28.366324502259587, 28.381309882905416,
        28.39629526355124, 28.411280644197067, 28.426266024842892, 28.44125140548872, 28.456236786134546, 28.47122216678037, 28.486207547426197, 28.501192928072022, 28.51617830871785, 28.531163689363677,
        28.546149070009502, 28.561134450655327, 28.576119831301156, 28.59110521194698, 28.606090592592807, 28.621075973238632, 28.63606135388446, 28.651046734530286, 28.66603211517611, 28.681017495821937,
        28.696002876467766, 28.71098825711359, 28.725973637759417, 28.740959018405242, 28.755944399051067, 28.770929779696896, 28.78591516034272, 28.800900540988547, 28.815885921634372, 28.8308713022802,
        28.845856682926026, 28.86084206357185, 28.875827444217677, 28.890812824863506, 28.90579820550933, 28.920783586155157, 28.935768966800982, 28.950754347446807, 28.965739728092636, 28.98072510873846,
        28.995710489384287, 29.010695870030112, 29.02568125067594, 29.040666631321766, 29.05565201196759, 29.070637392613417, 29.085622773259246, 29.10060815390507, 29.115593534550896, 29.130578915196722,
        29.145564295842547, 29.160549676488376, 29.1755350571342, 29.190520437780027, 29.205505818425852, 29.22049119907168, 29.235476579717506, 29.25046196036333, 29.265447341009157, 29.280432721654986,
        29.29541810230081, 29.310403482946636, 29.32538886359246, 29.340374244238287, 29.355359624884116, 29.37034500552994, 29.385330386175767, 29.400315766821592, 29.41530114746742, 29.430286528113246,
        29.44527190875907, 29.460257289404897, 29.475242670050726, 29.49022805069655, 29.505213431342376, 29.5201988119882, 29.535184192634027, 29.550169573279856, 29.56515495392568, 29.580140334571507,
        29.595125715217332, 29.61011109586316, 29.625096476508986, 29.64008185715481, 29.655067237800637, 29.670052618446466, 29.68503799909229, 29.700023379738116, 29.71500876038394, 29.72999414102977,
        29.744979521675596, 29.75996490232142, 29.774950282967247, 29.789935663613072, 29.8049210442589, 29.819906424904726, 29.83489180555055, 29.849877186196377, 29.864862566842206, 29.87984794748803,
        29.894833328133856, 29.90981870877968, 29.92480408942551, 29.939789470071336, 29.95477485071716, 29.969760231362987, 29.984745612008812, 29.99973099265464, 30.014716373300466, 30.02970175394629,
        30.044687134592117, 30.059672515237946, 30.07465789588377, 30.089643276529596, 30.10462865717542, 30.11961403782125, 30.134599418467076, 30.1495847991129, 30.164570179758726, 30.179555560404552,
        30.19454094105038, 30.209526321696206, 30.22451170234203, 30.239497082987857, 30.254482463633686, 30.26946784427951, 30.284453224925336, 30.29943860557116, 30.31442398621699, 30.329409366862816,
        30.34439474750864, 30.359380128154466, 30.37436550880029, 30.38935088944612, 30.404336270091946, 30.41932165073777, 30.4343070313836, 30.449292412029425, 30.46427779267525, 30.479263173321076,
        30.4942485539669, 30.509233934612727, 30.524219315258552, 30.539204695904385, 30.55419007655021, 30.569175457196035, 30.58416083784186, 30.599146218487686, 30.61413159913351, 30.629116979779337,
        30.644102360425162, 30.659087741070987, 30.67407312171682, 30.689058502362645, 30.70404388300847, 30.719029263654296, 30.73401464430012, 30.749000024945946, 30.76398540559177, 30.778970786237597,
        30.793956166883422, 30.808941547529255, 30.82392692817508, 30.838912308820905, 30.85389768946673, 30.868883070112556, 30.88386845075838, 30.898853831404207, 30.913839212050032, 30.928824592695864,
        30.94380997334169, 30.958795353987515, 30.97378073463334, 30.988766115279166, 31.00375149592499, 31.018736876570816, 31.033722257216642, 31.048707637862467, 31.0636930185083, 31.078678399154125,
        31.09366377979995, 31.108649160445776, 31.1236345410916, 31.138619921737426, 31.15360530238325, 31.168590683029077, 31.18357606367491, 31.198561444320735, 31.21354682496656, 31.228532205612385,
        31.24351758625821, 31.258502966904036, 31.27348834754986, 31.288473728195687, 31.303459108841512, 31.318444489487344, 31.33342987013317, 31.348415250778995, 31.36340063142482, 31.378386012070646,
        31.39337139271647, 31.408356773362296, 31.42334215400812, 31.438327534653947, 31.45331291529978, 31.468298295945605, 31.48328367659143, 31.498269057237255, 31.51325443788308, 31.528239818528906,
        31.54322519917473, 31.558210579820557, 31.57319596046639, 31.588181341112215, 31.60316672175804, 31.618152102403865, 31.63313748304969, 31.648122863695516, 31.66310824434134, 31.678093624987167,
        31.693079005632992, 31.708064386278824, 31.72304976692465, 31.738035147570475, 31.7530205282163, 31.768005908862126, 31.78299128950795, 31.797976670153776, 31.8129620507996, 31.827947431445427,
        31.84293281209126, 31.857918192737085, 31.87290357338291, 31.887888954028735, 31.90287433467456, 31.917859715320386, 31.93284509596621, 31.947830476612037, 31.96281585725787, 31.977801237903694,
        31.99278661854952, 32.00777199919534, 32.02275737984117, 32.03774276048699, 32.05272814113282, 32.06771352177864, 32.08269890242447, 32.0976842830703, 32.112669663716126, 32.12765504436195,
        32.14264042500778, 32.1576258056536, 32.17261118629943, 32.18759656694525, 32.20258194759108, 32.21756732823691, 32.232552708882736, 32.24753808952856, 32.262523470174386, 32.27750885082021,
        32.29249423146604, 32.30747961211186, 32.32246499275769, 32.33745037340351, 32.352435754049345, 32.36742113469517, 32.382406515340996, 32.39739189598682, 32.41237727663265, 32.42736265727847,
        32.4423480379243, 32.45733341857012, 32.47231879921595, 32.48730417986178, 32.502289560507606, 32.51727494115343, 32.53226032179926, 32.54724570244508, 32.56223108309091, 32.57721646373673,
        32.59220184438256, 32.60718722502839, 32.622172605674216, 32.63715798632004, 32.652143366965866, 32.66712874761169, 32.68211412825752, 32.69709950890334, 32.71208488954917, 32.72707027019499,
        32.742055650840825, 32.75704103148665, 32.772026412132476, 32.7870117927783, 32.80199717342413, 32.81698255406995, 32.83196793471578, 32.8469533153616, 32.86193869600743, 32.87692407665326,
        32.891909457299086, 32.90689483794491, 32.921880218590736, 32.93686559923656, 32.95185097988239, 32.96683636052821, 32.98182174117404, 32.99680712181987, 33.011792502465696, 33.02677788311152,
        33.041763263757346, 33.05674864440317, 33.071734025049, 33.08671940569482, 33.10170478634065, 33.11669016698647, 33.131675547632305, 33.14666092827813, 33.161646308923956, 33.17663168956978,
        33.19161707021561, 33.20660245086143, 33.22158783150726, 33.23657321215308, 33.251558592798915, 33.26654397344474, 33.281529354090566, 33.29651473473639, 33.311500115382216, 33.32648549602804,
        33.34147087667387, 33.35645625731969, 33.37144163796552, 33.38642701861135, 33.401412399257175, 33.416397779903, 33.431383160548826, 33.44636854119465, 33.46135392184048, 33.4763393024863,
        33.49132468313213, 33.50631006377795, 33.521295444423785, 33.53628082506961, 33.551266205715436, 33.56625158636126, 33.58123696700709, 33.59622234765291, 33.61120772829874, 33.62619310894456,
        33.641178489590395, 33.65616387023622, 33.671149250882046, 33.68613463152787, 33.701120012173696, 33.71610539281952, 33.73109077346535, 33.74607615411117, 33.761061534757, 33.77604691540283,
        33.791032296048655, 33.80601767669448, 33.821003057340306, 33.83598843798613, 33.85097381863196, 33.86595919927778, 33.88094457992361, 33.89592996056943, 33.910915341215265, 33.92590072186109,
        33.940886102506916, 33.95587148315274, 33.970856863798566, 33.98584224444439, 34.00082762509022, 34.01581300573604, 34.030798386381875, 34.0457837670277, 34.060769147673525, 34.07575452831935,
        34.090739908965176, 34.105725289611, 34.12071067025683, 34.13569605090265, 34.15068143154848, 34.16566681219431, 34.180652192840135, 34.19563757348596, 34.210622954131786, 34.22560833477761,
        34.24059371542344, 34.25557909606926, 34.27056447671509, 34.28554985736092, 34.300535238006745, 34.31552061865257, 34.330505999298396, 34.34549137994422, 34.360476760590046, 34.37546214123587,
        34.3904475218817, 34.40543290252752, 34.420418283173355, 34.43540366381918, 34.450389044465005, 34.46537442511083, 34.480359805756656, 34.49534518640248, 34.51033056704831, 34.52531594769413,
        34.54030132833996, 34.55528670898579, 34.570272089631615, 34.58525747027744, 34.600242850923266, 34.61522823156909, 34.63021361221492, 34.64519899286074, 34.66018437350657, 34.6751697541524,
        34.690155134798225, 34.70514051544405, 34.720125896089876, 34.7351112767357, 34.750096657381526, 34.76508203802735, 34.78006741867318, 34.795052799319, 34.810038179964835, 34.82502356061066,
        34.840008941256485, 34.85499432190231, 34.869979702548136, 34.88496508319396, 34.89995046383979, 34.91493584448561, 34.92992122513144, 34.94490660577727, 34.959891986423095, 34.97487736706892,
        34.989862747714746, 35.00484812836057, 35.019833509006396, 35.03481888965222, 35.04980427029805, 35.06478965094388, 35.079775031589705, 35.09476041223553, 35.109745792881355, 35.12473117352718,
        35.139716554173006, 35.15470193481883, 35.16968731546466, 35.18467269611048, 35.199658076756315, 35.21464345740214, 35.229628838047965, 35.24461421869379, 35.259599599339616, 35.27458497998544,
        35.28957036063127, 35.30455574127709, 35.319541121922924, 35.33452650256875, 35.349511883214575, 35.3644972638604, 35.379482644506226, 35.39446802515205, 35.409453405797876, 35.4244387864437,
        35.43942416708953, 35.45440954773536, 35.469394928381185, 35.48438030902701, 35.499365689672835, 35.51435107031866, 35.529336450964486, 35.54432183161031, 35.55930721225614, 35.57429259290196,
        35.589277973547794, 35.60426335419362, 35.619248734839445, 35.63423411548527, 35.649219496131096, 35.66420487677692, 35.679190257422746, 35.69417563806857, 35.709161018714404, 35.72414639936023,
        35.739131780006055, 35.75411716065188, 35.769102541297705, 35.78408792194353, 35.799073302589356, 35.81405868323518, 35.82904406388101, 35.84402944452684, 35.859014825172665, 35.87400020581849,
        35.888985586464315, 35.90397096711014, 35.918956347755966, 35.93394172840179, 35.94892710904762, 35.96391248969344, 35.978897870339274, 35.9938832509851, 36.008868631630925, 36.02385401227675,
        36.038839392922576, 36.0538247735684, 36.068810154214226, 36.08379553486005, 36.098780915505884, 36.11376629615171, 36.128751676797535, 36.14373705744336, 36.158722438089185, 36.17370781873501,
        36.188693199380836, 36.20367858002666, 36.21866396067249, 36.23364934131832, 36.248634721964144, 36.26362010260997, 36.278605483255795, 36.29359086390162, 36.308576244547446, 36.32356162519327,
        36.3385470058391, 36.35353238648493, 36.368517767130754, 36.38350314777658, 36.398488528422405, 36.41347390906823, 36.428459289714056, 36.44344467035988, 36.458430051005706, 36.47341543165153,
        36.488400812297364, 36.50338619294319, 36.518371573589015, 36.53335695423484, 36.548342334880665, 36.56332771552649, 36.578313096172316, 36.59329847681814, 36.60828385746397, 36.6232692381098,
        36.638254618755624, 36.65323999940145, 36.668225380047275, 36.6832107606931, 36.698196141338926, 36.71318152198475, 36.728166902630576, 36.74315228327641, 36.758137663922234, 36.77312304456806,
        36.788108425213885, 36.80309380585971, 36.818079186505535, 36.83306456715136, 36.848049947797186, 36.86303532844301, 36.878020709088844, 36.89300608973467, 36.907991470380495, 36.92297685102632,
        36.937962231672145, 36.95294761231797, 36.967932992963796, 36.98291837360962, 36.99790375425545, 37.01288913490128, 37.027874515547104, 37.04285989619293, 37.057845276838755, 37.07283065748458,
        37.087816038130406, 37.10280141877623, 37.117786799422056, 37.13277218006789, 37.147757560713714, 37.16274294135954, 37.177728322005365, 37.19271370265119, 37.207699083297015, 37.22268446394284,
        37.237669844588666, 37.25265522523449, 37.267640605880324, 37.28262598652615, 37.297611367171974, 37.3125967478178, 37.327582128463625, 37.34256750910945, 37.357552889755276, 37.3725382704011,
        37.38752365104693, 37.40250903169276, 37.417494412338584, 37.43247979298441, 37.447465173630235, 37.46245055427606, 37.477435934921886, 37.49242131556771, 37.507406696213536, 37.52239207685937,
        37.537377457505194, 37.55236283815102, 37.567348218796845, 37.58233359944267, 37.597318980088495, 37.61230436073432, 37.627289741380146, 37.64227512202597, 37.657260502671804, 37.67224588331763,
        37.687231263963454, 37.70221664460928, 37.717202025255105, 37.73218740590093, 37.747172786546756, 37.76215816719258, 37.77714354783841, 37.79212892848424, 37.807114309130064, 37.82209968977589,
        37.837085070421715, 37.85207045106754, 37.867055831713365, 37.88204121235919, 37.897026593005016, 37.91201197365085, 37.926997354296674, 37.9419827349425, 37.956968115588325, 37.97195349623415,
        37.986938876879975, 38.0019242575258, 38.016909638171626, 38.03189501881746, 38.046880399463284, 38.06186578010911, 38.076851160754934, 38.09183654140076, 38.106821922046585, 38.12180730269241,
        38.136792683338236, 38.15177806398406, 38.16676344462989, 38.18174882527572, 38.196734205921544, 38.21171958656737, 38.226704967213195, 38.24169034785902, 38.256675728504845, 38.27166110915067,
        38.286646489796496, 38.30163187044233, 38.316617251088154, 38.33160263173398, 38.346588012379804, 38.36157339302563, 38.376558773671455, 38.39154415431728, 38.406529534963106, 38.42151491560894,
        38.43650029625476, 38.45148567690059, 38.466471057546414, 38.48145643819224, 38.496441818838065, 38.51142719948389, 38.526412580129715, 38.54139796077554, 38.55638334142137, 38.5713687220672,
        38.586354102713024, 38.60133948335885, 38.616324864004675, 38.6313102446505, 38.646295625296325, 38.66128100594215, 38.676266386587976, 38.69125176723381, 38.706237147879634, 38.72122252852546,
        38.736207909171284, 38.75119328981711, 38.766178670462935, 38.78116405110876, 38.796149431754586, 38.81113481240042, 38.82612019304624, 38.84110557369207, 38.856090954337894, 38.87107633498372,
        38.886061715629545, 38.90104709627537, 38.916032476921195, 38.93101785756702, 38.94600323821285, 38.96098861885868, 38.975973999504504, 38.99095938015033, 39.005944760796154, 39.02093014144198,
        39.035915522087805, 39.05090090273363, 39.06588628337946, 39.08087166402529, 39.095857044671114, 39.11084242531694, 39.125827805962764, 39.14081318660859, 39.155798567254415, 39.17078394790024,
        39.185769328546066, 39.2007547091919, 39.21574008983772, 39.23072547048355, 39.245710851129374, 39.2606962317752, 39.275681612421025, 39.29066699306685, 39.305652373712675, 39.3206377543585,
        39.33562313500433, 39.35060851565016, 39.365593896295984, 39.38057927694181, 39.395564657587634, 39.41055003823346, 39.425535418879285, 39.44052079952511, 39.45550618017094, 39.47049156081677,
        39.48547694146259, 39.50046232210842, 39.515447702754244, 39.53043308340007, 39.545418464045895, 39.56040384469172, 39.575389225337545, 39.59037460598338, 39.6053599866292, 39.62034536727503,
        39.635330747920854, 39.65031612856668, 39.665301509212505, 39.68028688985833, 39.695272270504155, 39.71025765114998, 39.72524303179581, 39.74022841244164, 39.755213793087464, 39.77019917373329,
        39.785184554379114, 39.80016993502494, 39.815155315670765, 39.83014069631659, 39.84512607696242, 39.86011145760825, 39.87509683825407, 39.8900822188999, 39.905067599545724, 39.92005298019155,
        39.935038360837375, 39.9500237414832, 39.965009122129025, 39.97999450277486, 39.99497988342068, 40.00996526406651, 40.024950644712334, 40.03993602535816, 40.054921406003984, 40.06990678664981,
        40.084892167295635, 40.09987754794147, 40.11486292858729, 40.12984830923312, 40.14483368987894, 40.15981907052477, 40.174804451170594, 40.18978983181642, 40.204775212462245, 40.21976059310807,
        40.2347459737539, 40.24973135439973, 40.26471673504555, 40.27970211569138, 40.294687496337204, 40.30967287698303, 40.324658257628855, 40.33964363827468, 40.354629018920505, 40.36961439956634,
        40.38459978021216, 40.39958516085799, 40.414570541503814, 40.42955592214964, 40.444541302795464, 40.45952668344129, 40.474512064087115, 40.48949744473295, 40.50448282537877, 40.5194682060246,
        40.53445358667042, 40.54943896731625, 40.564424347962074, 40.5794097286079, 40.594395109253725, 40.60938048989955, 40.62436587054538, 40.63935125119121, 40.65433663183703, 40.66932201248286,
        40.684307393128684, 40.69929277377451, 40.714278154420334, 40.72926353506616, 40.744248915711985, 40.75923429635782, 40.77421967700364, 40.78920505764947, 40.804190438295294, 40.81917581894112,
        40.834161199586944, 40.84914658023277, 40.864131960878595, 40.87911734152443, 40.89410272217025, 40.90908810281608, 40.9240734834619, 40.93905886410773, 40.954044244753554, 40.96902962539938,
        40.984015006045205, 40.99900038669103, 41.01398576733686, 41.02897114798269, 41.04395652862851, 41.05894190927434, 41.073927289920164, 41.08891267056599, 41.103898051211814, 41.11888343185764,
        41.13386881250347, 41.1488541931493, 41.16383957379512, 41.17882495444095, 41.19381033508677, 41.2087957157326, 41.223781096378424, 41.23876647702425, 41.253751857670075, 41.26873723831591,
        41.28372261896173, 41.29870799960756, 41.31369338025338, 41.32867876089921, 41.343664141545034, 41.35864952219086, 41.373634902836685, 41.38862028348251, 41.40360566412834, 41.41859104477417,
        41.43357642541999, 41.44856180606582, 41.463547186711644, 41.47853256735747, 41.493517948003294, 41.50850332864912, 41.52348870929495, 41.53847408994078, 41.5534594705866, 41.56844485123243,
        41.58343023187825, 41.59841561252408, 41.613400993169904, 41.62838637381573, 41.643371754461555, 41.65835713510739, 41.67334251575321, 41.68832789639904, 41.70331327704486, 41.71829865769069,
        41.733284038336514, 41.74826941898234, 41.763254799628164, 41.77824018027399, 41.79322556091982, 41.80821094156565, 41.82319632221147, 41.8381817028573, 41.85316708350312, 41.86815246414895,
        41.883137844794774, 41.8981232254406, 41.91310860608643, 41.92809398673226, 41.94307936737808, 41.95806474802391, 41.97305012866973, 41.98803550931556, 42.003020889961384, 42.01800627060721,
        42.032991651253035, 42.04797703189887, 42.06296241254469, 42.07794779319052, 42.09293317383634, 42.10791855448217, 42.122903935127994, 42.13788931577382, 42.152874696419644, 42.16786007706548,
        42.1828454577113, 42.19783083835713, 42.21281621900295, 42.22780159964878, 42.2427869802946, 42.25777236094043, 42.272757741586254, 42.28774312223208, 42.30272850287791, 42.31771388352374,
        42.33269926416956, 42.34768464481539, 42.36267002546121, 42.37765540610704, 42.392640786752864, 42.40762616739869, 42.422611548044515, 42.43759692869035, 42.45258230933617, 42.467567689982,
        42.48255307062782, 42.49753845127365, 42.512523831919474, 42.5275092125653, 42.542494593211124, 42.55747997385696, 42.57246535450278, 42.58745073514861, 42.60243611579443, 42.61742149644026,
        42.63240687708608, 42.64739225773191, 42.662377638377734, 42.67736301902356, 42.69234839966939, 42.70733378031522, 42.72231916096104, 42.73730454160687, 42.75228992225269, 42.76727530289852,
        42.782260683544344, 42.79724606419017, 42.812231444835994, 42.82721682548183, 42.84220220612765, 42.85718758677348, 42.8721729674193, 42.88715834806513, 42.90214372871095, 42.91712910935678,
        42.932114490002604, 42.94709987064844, 42.96208525129426, 42.97707063194009, 42.99205601258591, 43.00704139323174, 43.02202677387756, 43.03701215452339, 43.051997535169214, 43.06698291581504,
        43.08196829646087, 43.0969536771067, 43.11193905775252, 43.12692443839835, 43.14190981904417, 43.15689519969, 43.171880580335824, 43.18686596098165, 43.20185134162748, 43.21683672227331,
        43.23182210291913, 43.24680748356496, 43.26179286421078, 43.27677824485661, 43.29176362550243, 43.30674900614826, 43.321734386794084, 43.33671976743992, 43.35170514808574, 43.36669052873157,
        43.38167590937739, 43.39666129002322, 43.41164667066904, 43.42663205131487, 43.441617431960694, 43.45660281260652, 43.47158819325235, 43.48657357389818, 43.501558954544, 43.51654433518983,
        43.53152971583565, 43.54651509648148, 43.561500477127304, 43.57648585777313, 43.59147123841896, 43.60645661906479, 43.62144199971061, 43.63642738035644, 43.65141276100226, 43.66639814164809,
        43.68138352229391, 43.69636890293974, 43.711354283585564, 43.726339664231396, 43.74132504487722, 43.75631042552305, 43.77129580616887, 43.7862811868147, 43.80126656746052, 43.81625194810635,
        43.831237328752174, 43.846222709398, 43.86120809004383, 43.87619347068966, 43.89117885133548, 43.90616423198131, 43.92114961262713, 43.93613499327296, 43.95112037391878, 43.96610575456461,
        43.98109113521044, 43.99607651585627, 44.01106189650209, 44.02604727714792, 44.04103265779374, 44.05601803843957, 44.07100341908539, 44.08598879973122, 44.100974180377044, 44.115959561022876,
        44.1309449416687, 44.14593032231453, 44.16091570296035, 44.17590108360618, 44.190886464252, 44.20587184489783, 44.220857225543654, 44.235842606189486, 44.25082798683531, 44.26581336748114,
        44.28079874812696, 44.29578412877279, 44.31076950941861, 44.32575489006444, 44.34074027071026, 44.35572565135609, 44.37071103200192, 44.38569641264775, 44.40068179329357, 44.4156671739394,
        44.43065255458522, 44.44563793523105, 44.46062331587687, 44.4756086965227, 44.490594077168524, 44.505579457814356, 44.52056483846018, 44.53555021910601, 44.55053559975183, 44.56552098039766,
        44.58050636104348, 44.59549174168931, 44.61047712233513, 44.625462502980966, 44.64044788362679, 44.65543326427262, 44.67041864491844, 44.68540402556427, 44.70038940621009, 44.71537478685592,
        44.73036016750174, 44.74534554814757, 44.7603309287934, 44.775316309439226, 44.79030169008505, 44.80528707073088, 44.8202724513767, 44.83525783202253, 44.85024321266835, 44.86522859331418,
        44.880213973960004, 44.895199354605836, 44.91018473525166, 44.92517011589749, 44.94015549654331, 44.95514087718914, 44.97012625783496, 44.98511163848079, 45.00009701912661, 45.015082399772446,
        45.03006778041827, 45.0450531610641, 45.06003854170992, 45.07502392235575, 45.09000930300157, 45.1049946836474, 45.11998006429322, 45.13496544493905, 45.14995082558488, 45.164936206230706,
        45.17992158687653, 45.19490696752236, 45.20989234816818, 45.22487772881401, 45.23986310945983, 45.25484849010566, 45.26983387075149, 45.284819251397316, 45.29980463204314, 45.31479001268897,
        45.32977539333479, 45.34476077398062, 45.35974615462644, 45.37473153527227, 45.38971691591809, 45.404702296563926, 45.41968767720975, 45.434673057855576, 45.4496584385014, 45.46464381914723,
        45.47962919979305, 45.49461458043888, 45.5095999610847, 45.52458534173053, 45.53957072237636, 45.554556103022186, 45.56954148366801, 45.58452686431384, 45.59951224495966, 45.61449762560549,
        45.62948300625131, 45.64446838689714, 45.65945376754297, 45.674439148188796, 45.68942452883462, 45.70440990948045, 45.71939529012627, 45.7343806707721, 45.74936605141792, 45.76435143206375,
        45.77933681270957, 45.794322193355406, 45.80930757400123, 45.824292954647056, 45.83927833529288, 45.85426371593871, 45.86924909658453, 45.88423447723036, 45.89921985787618, 45.91420523852201,
        45.92919061916784, 45.944175999813666, 45.95916138045949, 45.97414676110532, 45.98913214175114, 46.00411752239697, 46.01910290304279, 46.03408828368862, 46.04907366433445, 46.064059044980276,
        46.0790444256261, 46.09402980627193, 46.10901518691775, 46.12400056756358, 46.1389859482094, 46.15397132885523, 46.16895670950105, 46.183942090146886, 46.19892747079271, 46.213912851438536,
        46.22889823208436, 46.24388361273019, 46.25886899337601, 46.27385437402184, 46.28883975466766, 46.303825135313495, 46.31881051595932, 46.333795896605146, 46.34878127725097, 46.3637666578968,
        46.37875203854262, 46.39373741918845, 46.40872279983427, 46.4237081804801, 46.43869356112593, 46.453678941771756, 46.46866432241758, 46.483649703063406, 46.49863508370923, 46.51362046435506,
        46.52860584500088, 46.54359122564671, 46.55857660629253, 46.573561986938365, 46.58854736758419, 46.603532748230016, 46.61851812887584, 46.63350350952167, 46.64848889016749, 46.66347427081332,
        46.67845965145914, 46.693445032104975, 46.7084304127508, 46.723415793396626, 46.73840117404245, 46.75338655468828, 46.7683719353341, 46.78335731597993, 46.79834269662575, 46.81332807727158,
        46.82831345791741, 46.843298838563236, 46.85828421920906, 46.873269599854886, 46.88825498050071, 46.90324036114654, 46.91822574179236, 46.93321112243819, 46.94819650308402, 46.963181883729845,
        46.97816726437567, 46.993152645021496, 47.00813802566732, 47.02312340631315, 47.03810878695897, 47.0530941676048, 47.06807954825062, 47.083064928896455, 47.09805030954228, 47.113035690188106,
        47.12802107083393, 47.143006451479756, 47.15799183212558, 47.17297721277141, 47.18796259341723, 47.20294797406306, 47.21793335470889, 47.232918735354716, 47.24790411600054, 47.262889496646366,
        47.27787487729219, 47.29286025793802, 47.30784563858384, 47.32283101922967, 47.3378163998755, 47.352801780521325, 47.36778716116715, 47.382772541812976, 47.3977579224588, 47.41274330310463,
        47.42772868375045, 47.44271406439628, 47.4576994450421, 47.472684825687935, 47.48767020633376, 47.502655586979586, 47.51764096762541, 47.532626348271236, 47.54761172891706, 47.56259710956289,
        47.57758249020871, 47.59256787085454, 47.60755325150037, 47.622538632146195, 47.63752401279202, 47.652509393437846, 47.66749477408367, 47.6824801547295, 47.69746553537532, 47.71245091602115,
        47.72743629666698, 47.742421677312805, 47.75740705795863, 47.772392438604456, 47.78737781925028, 47.80236319989611, 47.81734858054193, 47.83233396118776, 47.84731934183358, 47.862304722479415,
        47.87729010312524, 47.892275483771066, 47.90726086441689, 47.922246245062716, 47.93723162570854, 47.95221700635437, 47.96720238700019, 47.982187767646025, 47.99717314829185, 48.012158528937675,
        48.0271439095835, 48.042129290229326, 48.05711467087515, 48.07210005152098, 48.0870854321668, 48.10207081281263, 48.11705619345846, 48.132041574104285, 48.14702695475011, 48.162012335395936,
        48.17699771604176, 48.191983096687586, 48.20696847733341, 48.22195385797924, 48.23693923862506, 48.251924619270895, 48.26690999991672, 48.281895380562545, 48.29688076120837, 48.311866141854196,
        48.32685152250002, 48.34183690314585, 48.35682228379167, 48.371807664437505, 48.38679304508333, 48.401778425729155, 48.41676380637498, 48.431749187020806, 48.44673456766663, 48.46171994831246,
        48.47670532895828, 48.49169070960411, 48.50667609024994, 48.521661470895765, 48.53664685154159, 48.551632232187416, 48.56661761283324, 48.581602993479066, 48.59658837412489, 48.61157375477072,
        48.62655913541654, 48.641544516062375, 48.6565298967082, 48.671515277354025, 48.68650065799985, 48.701486038645676, 48.7164714192915, 48.73145679993733, 48.74644218058315, 48.761427561228984,
        48.77641294187481, 48.791398322520635, 48.80638370316646, 48.821369083812286, 48.83635446445811, 48.85133984510394, 48.86632522574976, 48.88131060639559, 48.89629598704142, 48.911281367687245,
        48.92626674833307, 48.941252128978896, 48.95623750962472, 48.971222890270546, 48.98620827091637, 49.0011936515622, 49.01617903220803, 49.031164412853855, 49.04614979349968, 49.061135174145505,
        49.07612055479133, 49.091105935437156, 49.10609131608298, 49.12107669672881, 49.13606207737463, 49.151047458020464, 49.16603283866629, 49.181018219312115, 49.19600359995794, 49.210988980603766,
        49.22597436124959, 49.240959741895416, 49.25594512254124, 49.27093050318707, 49.2859158838329, 49.300901264478725, 49.31588664512455, 49.330872025770375, 49.3458574064162, 49.360842787062026,
        49.37582816770785, 49.39081354835368, 49.40579892899951, 49.420784309645335, 49.43576969029116, 49.450755070936985, 49.46574045158281, 49.480725832228636, 49.49571121287446, 49.51069659352029,
        49.52568197416611, 49.540667354811944, 49.55565273545777, 49.570638116103595, 49.58562349674942, 49.600608877395246, 49.61559425804107, 49.630579638686896, 49.64556501933272, 49.66055039997855,
        49.67553578062438, 49.690521161270205, 49.70550654191603, 49.720491922561855, 49.73547730320768, 49.750462683853506, 49.76544806449933, 49.78043344514516, 49.79541882579099, 49.810404206436814,
        49.82538958708264, 49.840374967728465, 49.85536034837429, 49.870345729020116, 49.88533110966594, 49.900316490311766, 49.91530187095759, 49.930287251603424, 49.94527263224925, 49.960258012895075,
        49.9752433935409, 49.990228774186726, 50.00521415483255, 50.020199535478376, 50.0351849161242, 50.050170296770034, 50.06515567741586, 50.080141058061685, 50.09512643870751, 50.110111819353335,
        50.12509719999916, 50.140082580644986, 50.15506796129081, 50.17005334193664, 50.18503872258247, 50.200024103228294, 50.21500948387412, 50.229994864519945, 50.24498024516577, 50.259965625811596,
        50.27495100645742, 50.289936387103246, 50.30492176774907, 50.319907148394904, 50.33489252904073, 50.349877909686555, 50.36486329033238, 50.379848670978205, 50.39483405162403, 50.409819432269856,
        50.42480481291568, 50.439790193561514, 50.45477557420734, 50.469760954853164, 50.48474633549899, 50.499731716144815, 50.51471709679064, 50.529702477436466, 50.54468785808229, 50.55967323872812,
        50.57465861937395, 50.589644000019774, 50.6046293806656, 50.619614761311425, 50.63460014195725, 50.649585522603076, 50.6645709032489, 50.679556283894726, 50.69454166454055, 50.709527045186384,
        50.72451242583221, 50.739497806478035, 50.75448318712386, 50.769468567769685, 50.78445394841551, 50.799439329061336, 50.81442470970716, 50.829410090352994, 50.84439547099882, 50.859380851644644,
        50.87436623229047, 50.889351612936295, 50.90433699358212, 50.919322374227946, 50.93430775487377, 50.949293135519596, 50.96427851616543, 50.979263896811254, 50.99424927745708, 51.009234658102905,
        51.02422003874873, 51.039205419394555, 51.05419080004038, 51.069176180686206, 51.08416156133204, 51.099146941977864, 51.11413232262369, 51.129117703269515, 51.14410308391534, 51.159088464561165,
        51.17407384520699, 51.189059225852816, 51.20404460649864, 51.219029987144474, 51.2340153677903, 51.249000748436124, 51.26398612908195, 51.278971509727775, 51.2939568903736, 51.308942271019426,
        51.32392765166525, 51.338913032311076, 51.35389841295691, 51.368883793602734, 51.38386917424856, 51.398854554894385, 51.41383993554021, 51.428825316186035, 51.44381069683186, 51.458796077477686,
        51.47378145812352, 51.488766838769344, 51.50375221941517, 51.518737600060994, 51.53372298070682, 51.548708361352645, 51.56369374199847, 51.578679122644296, 51.59366450329012, 51.60864988393595,
        51.62363526458178, 51.638620645227604, 51.65360602587343, 51.668591406519255, 51.68357678716508, 51.698562167810906, 51.71354754845673, 51.728532929102556, 51.74351830974839, 51.758503690394214,
        51.77348907104004, 51.788474451685865, 51.80345983233169, 51.818445212977515, 51.83343059362334, 51.848415974269166, 51.863401354915, 51.878386735560824, 51.89337211620665, 51.908357496852474,
        51.9233428774983, 51.938328258144125, 51.95331363878995, 51.968299019435776, 51.9832844000816, 51.99826978072743, 52.01325516137326, 52.028240542019084, 52.04322592266491, 52.058211303310735,
        52.07319668395656, 52.088182064602385, 52.10316744524821, 52.11815282589404, 52.13313820653987, 52.148123587185694, 52.16310896783152, 52.178094348477345, 52.19307972912317, 52.208065109768995,
        52.22305049041482, 52.238035871060646, 52.25302125170648, 52.268006632352304, 52.28299201299813, 52.297977393643954, 52.31296277428978, 52.327948154935605, 52.34293353558143, 52.357918916227256,
        52.37290429687308, 52.38788967751891, 52.40287505816474, 52.417860438810564, 52.43284581945639, 52.447831200102215, 52.46281658074804, 52.477801961393865, 52.49278734203969, 52.50777272268552,
        52.52275810333135, 52.537743483977174, 52.552728864623, 52.567714245268824, 52.58269962591465, 52.597685006560475, 52.6126703872063, 52.627655767852126, 52.64264114849796, 52.65762652914378,
        52.67261190978961, 52.687597290435434, 52.70258267108126, 52.717568051727085, 52.73255343237291, 52.747538813018735, 52.76252419366456, 52.77750957431039, 52.79249495495622, 52.807480335602044,
        52.82246571624787, 52.837451096893695, 52.85243647753952, 52.867421858185345, 52.88240723883117, 52.897392619477, 52.91237800012283, 52.927363380768654, 52.94234876141448, 52.957334142060304,
        52.97231952270613, 52.987304903351955, 53.00229028399778, 53.017275664643606, 53.03226104528944, 53.04724642593526, 53.06223180658109, 53.077217187226914, 53.09220256787274, 53.107187948518565,
        53.12217332916439, 53.137158709810215, 53.15214409045605, 53.16712947110187, 53.1821148517477, 53.197100232393524, 53.21208561303935, 53.227070993685174, 53.242056374331, 53.257041754976825,
        53.27202713562265, 53.28701251626848, 53.30199789691431, 53.316983277560134, 53.33196865820596, 53.346954038851784, 53.36193941949761, 53.376924800143435, 53.39191018078926, 53.406895561435086,
        53.42188094208092, 53.43686632272674, 53.45185170337257, 53.466837084018394, 53.48182246466422, 53.496807845310045, 53.51179322595587, 53.526778606601695, 53.54176398724753, 53.55674936789335,
        53.57173474853918, 53.586720129185004, 53.60170550983083, 53.616690890476654, 53.63167627112248, 53.646661651768305, 53.66164703241413, 53.67663241305996, 53.69161779370579, 53.70660317435161,
        53.72158855499744, 53.736573935643264, 53.75155931628909, 53.766544696934915, 53.78153007758074, 53.796515458226565, 53.8115008388724, 53.82648621951822, 53.84147160016405, 53.856456980809874,
        53.8714423614557, 53.886427742101525, 53.90141312274735, 53.916398503393175, 53.93138388403901, 53.94636926468483, 53.96135464533066, 53.976340025976484, 53.99132540662231, 54.006310787268134,
        54.02129616791396, 54.036281548559785, 54.05126692920561, 54.06625230985144, 54.08123769049727, 54.09622307114309, 54.11120845178892, 54.126193832434744, 54.14117921308057, 54.156164593726395,
        54.17114997437222, 54.18613535501805, 54.20112073566388, 54.2161061163097, 54.23109149695553, 54.246076877601354, 54.26106225824718, 54.276047638893004, 54.29103301953883, 54.306018400184655,
        54.32100378083049, 54.33598916147631, 54.35097454212214, 54.36595992276796, 54.38094530341379, 54.395930684059614, 54.41091606470544, 54.425901445351265, 54.44088682599709, 54.45587220664292,
        54.47085758728875, 54.48584296793457, 54.5008283485804, 54.515813729226224, 54.53079910987205, 54.545784490517875, 54.5607698711637, 54.57575525180953, 54.59074063245536, 54.60572601310118,
        54.62071139374701, 55.686742183736, 55.71188645672267, 55.73701982104315, 55.76214227669739, 55.787253823685475, 55.81235446200732, 55.83744419166301, 55.86252301265249, 55.88759092497578,
        55.912647928632865, 55.937694023623735, 55.96272920994845, 55.98775348760695, 56.01276685659927, 56.03776931692535, 56.06276086858527, 56.08774151157898, 56.11271124590651, 56.137670071567854,
        56.162617988562985, 56.187554996891876, 56.21248109655461, 56.237396287551164, 56.2623005698815, 56.28719394354566, 56.312076408543604, 56.336947964875364, 56.36180861254091, 56.38665835154028,
        56.41149718187346, 56.436325103540426, 56.46114211654118, 56.48594822087573, 56.510743416544116, 56.53552770354632, 56.560301081882315, 56.585063551552096, 56.609815112555694, 56.63455576489308,
        56.65928550856428, 56.6840043435693, 56.70871226990813, 56.73340928758073, 56.75809539658714, 56.782770596927364, 56.807434888601406, 56.83208827160924, 56.856730745950856, 56.88136231162629,
        56.90598296863551, 56.93059271697858, 56.955191556655436, 56.97977948766611, 57.00435651001054, 57.028922623688786, 57.05347782870085, 57.07802212504673, 57.1025555127264, 57.12707799173988,
        57.15158956208715, 57.17609022376821, 57.20057997678312, 57.22505882113181, 57.24952675681432, 57.273983783830616, 57.2984299021807, 57.3228651118646, 57.34728941288232, 57.37170280523385,
        57.39610528891917, 57.42049686393828, 57.44487753029118, 57.46924728797792, 57.49360613699845, 57.5179540773528, 57.54229110904093, 57.56661723206285, 57.59093244641862, 57.615236752108174,
        57.639530149131545, 57.663812637488704, 57.68808421717965, 57.71234488820441, 57.73659465056299, 57.76083350425536, 57.78506144928157, 57.809278485641514, 57.83348461333533, 57.857679832362905,
        57.8818641427243, 57.906037544419505, 57.9302000374485, 57.95435162181131, 57.978492297507884, 58.00262206453833, 58.02674092290253, 58.05084887260058, 58.07494591363239, 58.099032045998015,
        58.12310726969743, 58.147171584730685, 58.17122499109773, 58.195267488798564, 58.21929907783321, 58.24331975820165, 58.267329529903904, 58.291328392939974, 58.31531634730983, 58.33929339301348,
        58.36325953005097, 58.38721475842222, 58.41115907812731, 58.435092489166195, 58.45901499153889, 58.48292658524538, 58.506827270285655, 58.530717046659746, 58.55459591436765, 58.578463873409376,
        58.60232092378486, 58.62616706549419, 58.650002298537274, 58.673826622914206, 58.697640038624954, 58.72144254566949, 58.745234144047785, 58.769014833759925, 58.79278461480585, 58.816543487185626,
        58.84029145089916, 58.86402850594651, 58.88775465232767, 58.911469890042596, 58.935174219091365, 58.95886763947395, 58.982550151190324, 59.006221754240485, 59.029882448624434, 59.05353223434223,
        59.07717111139384, 59.100799079779236, 59.12441613949839, 59.148022290551395, 59.171617532938185, 59.19520186665879, 59.218775291713214, 59.242337808101425, 59.26588941582342, 59.28943011487924,
        59.31295990526887, 59.33647878699229, 59.35998676004955, 59.383483824440574, 59.406969980165414, 59.43044522722401, 59.453909565616485, 59.477362995342745, 59.50080551640279, 59.52423712879663,
        59.54765783252428, 59.57106762758575, 59.59446651398103, 59.617854491710105, 59.641231560772994, 59.66459772116967, 59.687952972900135, 59.711297315964416, 59.73463075036251, 59.757953276094426,
        59.7812648931601, 59.80456560155962, 59.82785540129292, 59.851134292360015, 59.87440227476095, 59.89765934849568, 59.92090551356419, 59.944140769966495, 59.96736511770264, 59.990578556772576,
        60.013781087176326, 60.03697270891384, 60.06015342198519, 60.083323226390334, 60.10648212212929, 60.12963010920204, 60.15276718760863, 60.175893357348954, 60.19900861842312, 60.222112970831105,
        60.24520641457288, 60.268288949648465, 60.29136057605784, 60.31442129380103, 60.33747110287801, 60.36051000328881, 60.38353799503342, 60.40655507811182, 60.42956125252401, 60.452556518269986,
        60.47554087534981, 60.498514323763445, 60.52147686351087, 60.54442849459208, 60.56736921700711, 60.59029903075593, 60.61321793583859, 60.63612593225504, 60.65902302000528, 60.681909199089304,
        60.704784469507146, 60.727648831258804, 60.75050228434428, 60.77334482876354, 60.79617646451659, 60.818997191603486, 60.84180701002414, 60.86460591977861, 60.8873939208669, 60.910171013289,
        60.932937197044865, 60.955692472134544, 60.97843683855807, 61.00117029631538, 61.02389284540648, 61.04660448583137, 61.0693052175901, 61.09199504068259, 61.11467395510893, 61.13734196086905,
        61.15999905796299, 61.18264524639069, 61.20528052615221, 61.22790489724757, 61.25051835967672, 61.27312091343968, 61.29571255853644, 61.31829329496698, 61.34086312273131, 61.36342204182948,
        61.38597005226147, 61.40850715402725, 61.431033347126785, 61.45354863156014, 61.47605300732734, 61.49854647442832, 61.521029032863126, 61.543500682631745, 61.56596142373412, 61.58841125617029,
        61.6108501799403, 61.63327819504413, 61.65569530148174, 61.678101499253145, 61.700496788358365, 61.72288116879737, 61.745254640570195, 61.767617203676835, 61.78996885811729, 61.81230960389151,
        61.83463944099954, 61.85695836944139, 61.87926638921705, 61.9015635003265, 61.92384970276974, 61.94612499654683, 61.96838938165767, 61.99064285810233, 62.01288542588081, 62.035117084993104,
        62.05733783543916, 62.079547677219026, 62.10174661033274, 62.12393463478024, 62.14611175056153, 62.16827795767661, 62.19043325612553, 62.21257764590821, 62.23471112702474, 62.25683369947505,
        62.27894536325918, 62.3010461183771, 62.32313596482881, 62.34521490261433, 62.36728293173367, 62.389340052186824, 62.41138626397374, 62.4334215670945, 62.45544596154902, 62.47745944733738,
        62.49946202445956, 62.52145369291553, 62.543434452705284, 62.56540430382883, 62.58736324628619, 62.60931128007739, 62.631248405202385, 62.65317462166114, 62.67508992945373, 62.69699432858012,
        62.71888781904032, 62.74077040083431, 62.76264207396214, 62.784502838423705, 62.806352694219115, 62.82819164134834, 62.85001967981135, 62.87183680960818, 62.8936430307388, 62.915438343203235,
        62.93722274700143, 62.958996242133495, 62.98075882859935, 63.00251050639899, 63.02425127553242, 63.04598113599964, 63.0677000878007, 63.08940813093558, 63.11110526540425, 63.13279149120673,
        63.154466808342974, 63.17613121681303, 63.19778471661694, 63.21942730775463, 63.24105899022611, 63.262679764031375, 63.28428962917046, 63.30588858564336, 63.327476633450075, 63.34905377259058,
        63.3706200030649, 63.39217532487301, 63.413719738014905, 63.43525324249062, 63.456775838300146, 63.47828752544349, 63.499788303920596, 63.521278173731545, 63.54275713487628, 63.564225187354836,
        63.58568233116718, 63.607128566313335, 63.62856389279328, 63.649988310607014, 63.67140181975459, 63.69280442023596, 63.71419611205114, 63.73557689520008, 63.75694676968287, 63.778305735499444,
        63.799653792649835, 63.82099094113404, 63.84231718095204, 63.86363251210382, 63.88493693458939, 63.906230448408806, 63.92751305356204, 63.94878475004906, 63.97004553786984, 63.99129541702446,
        64.01253438751287, 64.0337624493351, 64.05497960249114, 64.076185846981, 64.09738118280463, 64.11856560996203, 64.13973912845329, 64.16090173827835, 64.18205343943721, 64.20319423192986,
        64.22432411575632, 64.24544309091657, 64.26655115741063, 64.28764831523851, 64.30873456440021, 64.32980990489567, 64.35087433672494, 64.37192785988803, 64.39297047438494, 64.41400218021563,
        64.43502297738011, 64.45603286587844, 64.47703184571053, 64.49801991687643, 64.51899707937618, 64.53996333320968, 64.560918678377, 64.58186311487809, 64.60279664271305, 64.62371926188179,
        64.64463097238432, 64.66553177422064, 64.6864216673908, 64.70730065189473, 64.7281687277325, 64.74902589490405, 64.76987215340942, 64.79070750324858, 64.81153194442153, 64.8323454769283,
        64.8531481007689, 64.87393981594327, 64.89472062245146, 64.91549052029343, 64.93624950946922, 64.95699758997883, 64.97773476182222, 64.99846102499943, 65.01917637951043, 65.03988082535521,
        65.06057436253384, 65.08125699104626, 65.1019287108925, 65.12258952207252, 65.14323942458633, 65.16387841843395, 65.1845065036154, 65.20512368013065, 65.2257299479797, 65.24632530716254,
        65.26690975767919, 65.28748329952963, 65.30804593271388, 65.32859765723195, 65.34913847308384, 65.36966838026949, 65.39018737878895, 65.41069546864223, 65.43119264982933, 65.45167892235021,
        65.47215428620488, 65.4926187413934, 65.51307228791568, 65.53351492577177, 65.5539466549617, 65.5743674754854, 65.59477738734292, 65.61517639053419, 65.63556448505933, 65.65594167091827,
        65.67630794811099, 65.69666331663753, 65.71700777649785, 65.73734132769196, 65.75766397021992, 65.7779757040817, 65.79827652927726, 65.81856644580658, 65.83884545366972, 65.8591135528667,
        65.87937074339747, 65.89961702526206, 65.9198523984604, 65.9400768629926, 65.96029041885855, 65.98049306605834, 66.00068480459196, 66.02086563445935, 66.04103555566051, 66.06119456819549,
        66.08134267206431, 66.10147986726695, 66.12160615380334, 66.14172153167355, 66.16182600087758, 66.18191956141537, 66.20200221328703, 66.22207395649245, 66.24213479103169, 66.26218471690471,
        66.28222373411155, 66.30225184265221, 66.32226904252666, 66.34227533373492, 66.36227071627697, 66.38225519015283, 66.40222875536249, 66.42219141190596, 66.44214315978324, 66.46208399899432,
        66.48201392953918, 66.50193295141786, 66.52184106463035, 66.54173826917663, 66.56162456505676, 66.58149995227065, 66.60136443081835, 66.62121800069981, 66.64106066191515, 66.66089241446427,
        66.68071325834721, 66.70052319356391, 66.7203222201144, 66.74011033799876, 66.75988754721688, 66.77965384776884, 66.7994092396546, 66.8191537228741, 66.83888729742743, 66.8586099633146,
        66.87832172053557, 66.89802256909034, 66.91771250897888, 66.93739154020126, 66.9570596627574, 66.97671687664739, 66.99636318187119, 67.01599857842878, 67.03562306632016, 67.05523664554532,
        67.07483931610433, 67.09443107799713, 67.11401193122374, 67.13358187578412, 67.15314091167834, 67.17268903890634, 67.19222625746816, 67.2117525673638, 67.23126796859323, 67.25077246115644,
        67.27026604505345, 67.2897487202843, 67.30922048684896, 67.32868134474741, 67.34813129397962, 67.3675703345457, 67.38699846644552, 67.4064156896792, 67.42582200424665, 67.44521741014795,
        67.464601907383, 67.48397549595184, 67.50333817585452, 67.52268994709102, 67.54203080966131, 67.56136076356539, 67.58067980880331, 67.59998794537496, 67.61928517328049, 67.6385714925198,
        67.65784690309293, 67.67711140499982, 67.6963649982405, 67.71560768281505, 67.73483945872336, 67.75406032596551, 67.77327028454143, 67.79246933445116, 67.81165747569467, 67.83083470827204,
        67.85000103218319, 67.86915644742815, 67.88830095400688, 67.90743455191942, 67.92655724116581, 67.94566902174599, 67.96476989365995, 67.9838598569077, 68.0029389114893, 68.02200705740465,
        68.04106429465385, 68.06011062323684, 68.07914604315364, 68.09817055440423, 68.11718415698861, 68.13618685090681, 68.15517863615885, 68.17415951274465, 68.1931294806643, 68.21208853991767,
        68.2310366905049, 68.24997393242593, 68.26890026568076, 68.2878156902694, 68.30672020619183, 68.32561381344804, 68.3444965120381, 68.36336830196196, 68.38222918321962, 68.4010791558111,
        68.41991821973635, 68.43874637499538, 68.45756362158825, 68.47636995951494, 68.49516538877542, 68.51394990936969, 68.53272352129777, 68.55148622455964, 68.57023801915535, 68.58897890508486,
        68.60770888234815, 68.62642795094526, 68.64513611087612, 68.66383336214086, 68.68251970473936, 68.70119513867171, 68.71985966393781, 68.73851328053773, 68.75715598847144, 68.77578778773899,
        68.79440867834033, 68.81301866027549, 68.8316177335444, 68.85020589814714, 68.86878315408372, 68.88734950135408, 68.90590493995823, 68.92444946989617, 68.94298309116796, 68.9615058037735,
        68.9800176077129, 68.9985185029861, 69.0170084895931, 69.03548756753385, 69.05395573680842, 69.07241299741683, 69.09085934935904, 69.10929479263505, 69.12771932724483, 69.14613295318846,
        69.16453567046584, 69.18292747907707, 69.20130837902211, 69.21967837030094, 69.23803745291356, 69.25638562685997, 69.27472289214019, 69.29304924875426, 69.31136469670211, 69.32966923598373,
        69.34796286659919, 69.36624558854844, 69.3845174018315, 69.40277830644838, 69.42102830239905, 69.43926738968351, 69.45749556830175, 69.47571283825384, 69.49391919953975, 69.51211465215944,
        69.53029919611289, 69.54847283140019, 69.56663555802128, 69.5847873759762, 69.6029282852649, 69.62105828588743, 69.63917737784372, 69.6572855611338, 69.67538283575773, 69.69346920171547,
        69.711544659007, 69.72960920763235, 69.74766284759149, 69.76570557888438, 69.78373740151115, 69.8017583154717, 69.81976832076607, 69.8377674173942, 69.85575560535612, 69.87373288465191,
        69.89169925528147, 69.90965471724486, 69.92759927054205, 69.94553291517299, 69.96345565113775, 69.98136747843635, 69.99926839706875, 70.01715840703496, 70.03503750833492, 70.05290570096874,
        70.07076298493634, 70.08860936023775, 70.10644482687296, 70.12426938484198, 70.14208303414479, 70.15988577478139, 70.17767760675183, 70.19545853005606, 70.2132285446941, 70.23098765066594,
        70.24873584797159, 70.266473136611, 70.28419951658428, 70.30191498789132, 70.31961955053221, 70.33731320450683, 70.35499594981529, 70.37266778645757, 70.39032871443364, 70.40797873374352,
        70.42561784438719, 70.44324604636468, 70.46086333967595, 70.47846972432104, 70.49606520029995, 70.51364976761268, 70.53122342625916, 70.54878617623943, 70.56633801755355, 70.58387895020148,
        70.6014089741832, 70.61892808949871, 70.63643629614803, 70.65393359413115, 70.6714199834481, 70.68889546409885, 70.70636003608341, 70.72381369940173, 70.74125645405384, 70.75868830003982,
        70.77610923735959, 70.79351926601315, 70.8109183860005, 70.82830659732168, 70.84568389997663, 70.8630502939654, 70.88040577928801, 70.89775035594438, 70.91508402393457, 70.93240678325851,
        70.94971863391633, 70.96701957590794, 70.98430960923336, 71.00158873389255, 71.01885694988555, 71.03611425721236, 71.05336065587296, 71.07059614586741, 71.08782072719565, 71.10503439985764,
        71.12223716385346, 71.13942901918311, 71.15660996584656, 71.17378000384382, 71.19093913317487, 71.2080873538397, 71.22522466583833, 71.2423510691708, 71.25946656383708, 71.27657114983715,
        71.29366482717101, 71.31074759583866, 71.32781945584016, 71.34488040717544, 71.36193044984454, 71.37896958384745, 71.3959978091841, 71.41301512585459, 71.43002153385889, 71.44701703319701,
        71.46400162386892, 71.48097530587462, 71.49793807921414, 71.51488994388744, 71.53183089989459, 71.54876094723552, 71.56568008591027, 71.58258831591878, 71.59948563726108, 71.61637204993725,
        71.63324755394721, 71.65011214929096, 71.6669658359685, 71.68380861397988, 71.70064048332502, 71.71746144400397, 71.73427149601677, 71.75107063936336, 71.76785887404371, 71.78463620005788,
        71.80140261740586, 71.81815812608765, 71.83490272610327, 71.85163641745264, 71.86835920013586, 71.88507107415283, 71.90177203950365, 71.91846209618826, 71.93514124420669, 71.9518094835589,
        71.9684668142449, 71.98511323626472, 72.00174874961839, 72.01837335430581, 72.03498705032705, 72.0515898376821, 72.06818171637092, 72.08476268639357, 72.10133274775005, 72.11789190044031,
        72.13444014446434, 72.1509774798222, 72.16750390651386, 72.18401942453936, 72.20052403389865, 72.21701773459169, 72.23350052661858, 72.24997240997926, 72.26643338467376, 72.28288345070207,
        72.29932260806417, 72.31575085676006, 72.33216819678974, 72.34857462815326, 72.3649701508506, 72.38135476488172, 72.3977284702466, 72.41409126694536, 72.43044315497785, 72.44678413434421,
        72.46311420504436, 72.4794333670783, 72.49574162044603, 72.51203896514757, 72.52832540118293, 72.54460092855207, 72.56086554725506, 72.57711925729184, 72.59336205866238, 72.60959395136673,
        72.6258149354049, 72.64202501077689, 72.6582241774827, 72.67441243552226, 72.69058978489564, 72.70675622560283, 72.72291175764384, 72.73905638101867, 72.75519009572729, 72.77131290176966,
        72.78742479914585, 72.80352578785589, 72.81961586789971, 72.83569503927735, 72.85176330198875, 72.867820656034, 72.883867101413, 72.89990263812585, 72.91592726617252, 72.93194098555297,
        72.94794379626721, 72.96393569831524, 72.97991669169711, 72.99588677641277, 73.01184595246225, 73.02779421984552, 73.0437315785626, 73.05965802861344, 73.07557356999816, 73.09147820271663,
        73.10737192676895, 73.12325474215503, 73.13912664887489, 73.1549876469286, 73.17083773631613, 73.18667691703745, 73.20250518909252, 73.21832255248147, 73.23412900720415, 73.2499245532607,
        73.26570919065104, 73.28148291937516, 73.29724573943308, 73.31299765082481, 73.32873865355036, 73.34446874760972, 73.36018793300288, 73.37589620972982, 73.39159357779057, 73.40728003718512,
        73.42295558791348, 73.43862022997568, 73.45427396337165, 73.4699167881014, 73.48554870416497, 73.50116971156235, 73.51677981029356, 73.53237900035857, 73.54796728175735, 73.56354465448995,
        73.57911111855633, 73.59466667395655, 73.61021132069057, 73.6257450587584, 73.64126788815999, 73.6567798088954, 73.67228082096464, 73.68777092436768, 73.70325011910454, 73.71871840517515,
        73.73417578257958, 73.74962225131783, 73.76505781138987, 73.78048246279575, 73.79589620553541, 73.81129903960884, 73.82669096501608, 73.84207198175717, 73.85744208983205, 73.87280128924074,
        73.88814957998322, 73.90348696205949, 73.91881343546957, 73.93412900021347, 73.94943365629116, 73.96472740370267, 73.98001024244796, 73.99528217252704, 74.01054319393997, 74.02579330668668,
        74.04103251076721, 74.05626080618156, 74.07147819292966, 74.08668467101155, 74.10188024042732, 74.11706490117685, 74.13223865326022, 74.14740149667735, 74.1625534314283, 74.17769445751303,
        74.19282457493158, 74.20794378368397, 74.22305208377013, 74.23814947519007, 74.25323595794383, 74.2683115320314, 74.2833761974528, 74.298429954208, 74.31347280229697, 74.32850474171975,
        74.34352577247635, 74.35853589456674, 74.37353510799097, 74.38852341274897, 74.40350080884077, 74.41846729626637, 74.43342287502578, 74.44836754511901, 74.46330130654606, 74.47822415930686,
        74.49313610340151, 74.50803713882992, 74.52292726559217, 74.53780648368821, 74.55267479311807, 74.56753219388172, 74.58237868597915, 74.59721426941043, 74.6120389441755, 74.62685271027438,
        74.64165556770702, 74.6564475164735, 74.67122855657375, 74.68599868800784, 74.70075791077575, 74.71550622487744, 74.73024363031293, 74.7449701270822, 74.75968571518531, 74.77439039462222,
        74.78908416539294, 74.80376702749744, 74.81843898093577, 74.83310002570785, 74.8477501618138, 74.86238938925352, 74.87701770802708, 74.89163511813437, 74.90624161957551, 74.92083721235046,
        74.9354218964592, 74.94999567190179, 74.9645585386781, 74.97911049678827, 74.99365154623221, 75.00818168701001, 75.02270091912159, 75.03720924256696, 75.05170665734612, 75.06619316345906,
        75.08066876090588, 75.09513344968646, 75.10958722980088, 75.12403010124909, 75.13846206403106, 75.15288311814685, 75.16729326359645, 75.1816925003799, 75.1960808284971, 75.2104582479481,
        75.22482475873291, 75.23918036085153, 75.25352505430398, 75.26785883909024, 75.28218171521029, 75.29649368266409, 75.31079474145172, 75.32508489157318, 75.33936413302844, 75.35363246581751,
        75.36788988994034, 75.38213640539702, 75.39637201218748, 75.41059671031176, 75.42481049976986, 75.43901338056175, 75.45320535268739, 75.46738641614688, 75.48155657094016, 75.49571581706728,
        75.50986415452819, 75.52400158332286, 75.53812810345137, 75.55224371491367, 75.56634841770979, 75.58044221183972, 75.59452509730347, 75.60859707410096, 75.62265814223228, 75.63670830169742,
        75.65074755249636, 75.66477589462913, 75.67879332809564, 75.69279985289599, 75.70679546903013, 75.72078017649811, 75.73475397529988, 75.74871686543544, 75.76266884690479, 75.77660991970795,
        75.79054008384493, 75.80445933931573, 75.81836768612031, 75.83226512425868, 75.84615165373087, 75.86002727453685, 75.87389198667667, 75.88774579015028, 75.90158868495767, 75.91542067109889,
        75.92924174857386, 75.9430519173827, 75.95685117752534, 75.97063952900176, 75.98441697181197, 75.99818350595602, 76.01193913143383, 76.02568384824546, 76.03941765639094, 76.0531405558702,
        76.06685254668322, 76.08055362883006, 76.09424380231071, 76.10792306712518, 76.12159142327347, 76.13524887075552, 76.14889540957141, 76.16253103972106, 76.17615576120456, 76.18976957402187,
        76.20337247817297, 76.21696447365782, 76.2305455604765, 76.24411573862902, 76.25767500811533, 76.27122336893545, 76.28476082108936, 76.29828736457706, 76.31180299939855, 76.32530772555388,
        76.33880154304303, 76.35228445186597, 76.3657564520227, 76.3792175435132, 76.39266772633756, 76.40610700049571, 76.41953536598767, 76.43295282281345, 76.44635937097298, 76.45975501046631,
        76.47313974129351, 76.4865135634545, 76.49987647694927, 76.51322848177783, 76.52656957794018, 76.53989976543637, 76.55321904426638, 76.56652741443018, 76.5798248759278, 76.59311142875917,
        76.60638707292433, 76.61965180842337, 76.6329056352562, 76.6461485534228, 76.6593805629232, 76.67260166375745, 76.68581185592545, 76.6990111394273, 76.71219951426293, 76.72537698043239,
        76.7385435379356, 76.75169918677263, 76.7648439269435, 76.77797775844816, 76.79110068128661, 76.80421269545887, 76.81731380096493, 76.8304039978048, 76.84348328597848, 76.85655166548595,
        76.86960913632724, 76.88265569850232, 76.89569135201118, 76.90871609685387, 76.92172993303039, 76.93473286054068, 76.94772487938478, 76.9607059895627, 76.97367619107438, 76.9866354839199,
        76.99958386809924, 77.01252134361236, 77.02544791045928, 77.03836356863998, 77.05126831815453, 77.06416215900286, 77.07704509118501, 77.08991711470095, 77.10277822955071, 77.11562843573422,
        77.12846773325161, 77.14129612210276, 77.15411360228775, 77.1669201738065, 77.17971583665904, 77.19250059084543, 77.20527443636563, 77.21803737321962, 77.23078940140736, 77.24353052092899,
        77.25626073178434, 77.26898003397356, 77.28168842749658, 77.29438591235338, 77.30707248854397, 77.31974815606837, 77.33241291492659, 77.34506676511863, 77.35770970664446, 77.3703417395041,
        77.38296286369753, 77.39557307922472, 77.40817238608578, 77.42076078428063, 77.4333382738093, 77.44590485467172, 77.45846052686797, 77.47100529039803, 77.4835391452619, 77.49606209145959,
        77.50857412899107, 77.52107525785631, 77.53356547805537, 77.54604478958827, 77.55851319245495, 77.57097068665546, 77.58341727218972, 77.5958529490578, 77.60827771725972, 77.62069157679544,
        77.63309452766497, 77.64548656986828, 77.65786770340536, 77.67023792827628, 77.68259724448102, 77.69494565201954, 77.70728315089188, 77.71960974109798, 77.73192542263796, 77.74423019551166,
        77.75652405971924, 77.76880701526058, 77.78107906213576, 77.7933402003447, 77.80559042988743, 77.817829750764, 77.8300581629744, 77.84227566651857, 77.85448226139651, 77.86667794760832,
        77.8788627251539, 77.89103659403328, 77.90319955424648, 77.9153516057935, 77.92749274867428, 77.93962298288885, 77.95174230843726, 77.96385072531949, 77.97594823353553, 77.98803483308531,
        78.00011052396896, 78.01217530618636, 78.02422917973759, 78.03627214462263, 78.04830420084149, 78.0603253483941, 78.07233558728053, 78.08433491750078, 78.09632333905485, 78.10830085194273,
        78.12026745616437, 78.13222315171983, 78.1441679386091, 78.15610181683216, 78.16802478638907, 78.17993684727976, 78.19183799950422, 78.20372824306249, 78.21560757795457, 78.22747600418047,
        78.23933352174019, 78.25118013063367, 78.263015830861, 78.27484062242208, 78.286654505317, 78.29845747954575, 78.31024954510828, 78.32203070200457, 78.33380095023468, 78.34556028979863,
        78.35730872069637, 78.36904624292792, 78.3807728564933, 78.3924885613924, 78.40419335762535, 78.41588724519211, 78.42757022409269, 78.43924229432706, 78.45090345589519, 78.46255370879716,
        78.47419305303292, 78.48582148860253, 78.49743901550592, 78.50904563374313, 78.5206413433141, 78.53222614421885, 78.54380003645745, 78.55536302002987, 78.5669150949361, 78.57845626117607,
        78.58998651874988, 78.60150586765751, 78.61301430789892, 78.62451183947418, 78.6359984623832, 78.64747417662603, 78.65893898220263, 78.6703928791131, 78.68183586735735, 78.69326794693539,
        78.70468911784722, 78.7160993800929, 78.72749873367233, 78.73888717858561, 78.75026471483268, 78.76163134241357, 78.77298706132821, 78.78433187157667, 78.79566577315897, 78.80698876607507,
        78.81830085032497, 78.82960202590864, 78.84089229282615, 78.85217165107743, 78.86344010066254, 78.87469764158145, 78.88594427383417, 78.89717999742068, 78.90840481234098, 78.91961871859512,
        78.93082171618305, 78.94201380510479, 78.9531949853603, 78.96436525694965, 78.97552461987279, 78.98667307412974, 78.99781061972048, 79.00893725664507, 79.02005298490339, 79.03115780449552,
        79.0422517154215, 79.0533347176813, 79.06440681127488, 79.07546799620222, 79.08651827246344, 79.09755764005838, 79.10858609898717, 79.11960364924978, 79.1306102908462, 79.14160602377636,
        79.15259084804036, 79.16356476363818, 79.17452777056981, 79.18547986883523, 79.19642105843441, 79.20735133936746, 79.21827071163425, 79.2291791752349, 79.24007673016935, 79.25096337643758,
        79.2618391140396, 79.27270394297544, 79.2835578632451, 79.29440087484856, 79.30523297778582, 79.3160541720569, 79.32686445766176, 79.3376638346004, 79.34845230287287, 79.35922986247915,
        79.36999651341925, 79.38075225569311, 79.39149708930078, 79.40223101424228, 79.41295403051758, 79.4236661381267, 79.43436733706962, 79.44505762734629, 79.45573700895677, 79.4664054819011,
        79.47706304617925, 79.48770970179119, 79.49834544873688, 79.5089702870164, 79.51958421662972, 79.53018723757687, 79.54077934985783, 79.55136055347258, 79.56193084842111, 79.57249023470344,
        79.5830387123196, 79.59357628126959, 79.60410294155336, 79.6146186931709, 79.62512353612227, 79.63561747040744, 79.64610049602642, 79.65657261297922, 79.66703382126583, 79.67748412088618,
        79.68792351184037, 79.69835199412837, 79.7087695677502, 79.7191762327058, 79.72957198899518, 79.73995683661842, 79.75033077557539, 79.76069380586624, 79.77104592749087, 79.78138714044933,
        79.79171744474154, 79.80203684036753, 79.81234532732738, 79.82264290562104, 79.83292957524851, 79.84320533620972, 79.8534701885048, 79.86372413213364, 79.8739671670963, 79.8841992933928,
        79.89442051102306, 79.90463081998713, 79.91483022028497, 79.92501871191668, 79.93519629488218, 79.94536296918146, 79.95551873481453, 79.96566359178145, 79.97579754008213, 79.98592057971665,
        79.99603271068496, 80.00613393298708, 80.01622424662297, 80.02630365159267, 80.03637214789622, 80.04642973553355, 80.0564764145047, 80.06651218480961, 80.07653704644837, 80.08655099942088,
        80.09655404372724, 80.10654617936738, 80.11652740634138, 80.1264977246491, 80.13645713429064, 80.14640563526602, 80.15634322757519, 80.16626991121818, 80.17618568619498, 80.18609055250552,
        80.1959845101499, 80.2058675591281, 80.2157396994401, 80.2256009310859, 80.23545125406547, 80.24529066837887, 80.25511917402609, 80.2649367710071, 80.27474345932193, 80.28453923897057,
        80.29432410995297, 80.30409807226916, 80.31386112591922, 80.32361327090304, 80.3333545072207, 80.34308483487213, 80.35280425385734, 80.3625127641764, 80.37221036582928, 80.38189705881594,
        80.39157284313642, 80.40123771879065, 80.41089168577871, 80.42053474410058, 80.43016689375627, 80.43978813474577, 80.44939846706903, 80.45899789072611, 80.46858640571698, 80.47816401204169,
        80.48773070970022, 80.49728649869253, 80.5068313790186, 80.5163653506785, 80.5258884136722, 80.53540056799976, 80.54490181366107, 80.55439215065617, 80.56387157898511, 80.57334009864785,
        80.5827977096444, 80.59224441197473, 80.60168020563889, 80.61110509063683, 80.62051906696855, 80.62992213463413, 80.63931429363349, 80.64869554396667, 80.6580658856336, 80.66742531863439,
        80.67677384296896, 80.68611145863734, 80.69543816563952, 80.70475396397553, 80.71405885364528, 80.72335283464885, 80.73263590698626, 80.74190807065749, 80.7511693256625, 80.7604196720013,
        80.76965910967392, 80.7788876386803, 80.78810525902055, 80.7973119706946, 80.80650777370242, 80.81569266804404, 80.82486665371944, 80.83402973072869, 80.84318189907175, 80.8523231587486,
        80.86145350975924, 80.8705729521037, 80.87968148578194, 80.888779110794, 80.89786582713988, 80.90694163481957, 80.91600653383303, 80.92506052418027, 80.93410360586138, 80.94313577887628,
        80.95215704322497, 80.96116739890748, 80.97016684592374, 80.97915538427382, 80.98813301395775, 80.99709973497546, 81.006055547327, 81.01500045101228, 81.02393444603139, 81.03285753238431,
        81.04176971007105, 81.0506709790916, 81.05956133944595, 81.06844079113405, 81.07730933415597, 81.08616696851173, 81.09501369420131, 81.10384951122468, 81.11267441958181, 81.12148841927275,
        81.13029151029751, 81.13908369265611, 81.14786496634848, 81.15663533137469, 81.16539478773463, 81.17414333542838, 81.18288097445598, 81.1916077048174, 81.2003235265126, 81.20902843954157,
        81.2177224439044, 81.22640553960098, 81.23507772663142, 81.24373900499565, 81.25238937469366, 81.26102883572547, 81.26965738809106, 81.2782750317905, 81.28688176682375, 81.2954775931908,
        81.30406251089163, 81.31263651992627, 81.3211996202947, 81.32975181199699, 81.33829309503305, 81.3468234694029, 81.35534293510655, 81.36385149214401, 81.37234914051528, 81.38083588022037,
        81.38931171125925, 81.39777663363192, 81.40623064733843, 81.4146737523787, 81.42310594875279, 81.43152723646072, 81.43993761550242, 81.4483370858779, 81.45672564758719, 81.46510330063033,
        81.47347004500726, 81.48182588071798, 81.49017080776248, 81.49850482614083, 81.50682793585294, 81.51514013689889, 81.52344142927863, 81.53173181299219, 81.54001128803954, 81.54827985442064,
        81.55653751213562, 81.56478426118439, 81.57302010156697, 81.58124503328331, 81.5894590563335, 81.59766217071744, 81.60585437643523, 81.61403567348684, 81.62220606187223, 81.63036554159139,
        81.63851411264436, 81.64665177503117, 81.6547785287518, 81.66289437380622, 81.67099931019443, 81.67909333791643, 81.68717645697221, 81.69524866736184, 81.70330996908528, 81.71136036214251,
        81.71939984653353, 81.72742842225834, 81.735446089317, 81.74345284770946, 81.75144869743572, 81.75943363849579, 81.7674076708896, 81.77537079461725, 81.78332300967871, 81.79126431607399,
        81.79919471380309, 81.80711420286592, 81.81502278326259, 81.82292045499308, 81.83080721805739, 81.83868307245548, 81.84654801818739, 81.85440205525306, 81.86224518365255, 81.87007740338585,
        81.87789871445297, 81.8857091168539, 81.8935086105886, 81.90129719565711, 81.90907487205943, 81.91684163979555, 81.92459749886551, 81.93234244926926, 81.94007649100676, 81.94779962407809,
        81.95551184848325, 81.96321316422221, 81.97090357129498, 81.97858306970151, 81.98625165944189, 81.99390934051603, 82.001556112924, 82.0091919766658, 82.01681693174139, 82.02443097815073,
        82.0320341158939, 82.0396263449709, 82.04720766538169, 82.0547780771263, 82.0623375802047, 82.06988617461691, 82.07742386036288, 82.0849506374427, 82.09246650585634, 82.09997146560376,
        82.10746551668497, 82.11494865909997, 82.12242089284881, 82.12988221793147, 82.13733263434791, 82.14477214209812, 82.1522007411822, 82.15961843160001, 82.16702521335166, 82.17442108643714,
        82.18180605085642, 82.18918010660947, 82.1965432536963, 82.20389549211698, 82.21123682187148, 82.21856724295976, 82.22588675538184, 82.23319535913772, 82.2404930542274, 82.24777984065089,
        82.25505571840823, 82.26232068749933, 82.26957474792421, 82.27681789968291, 82.28405014277543, 82.29127147720176, 82.29848190296188, 82.30568142005582, 82.31287002848354, 82.32004772824503,
        82.32721451934039, 82.33437040176953, 82.3415153755325, 82.34864944062922, 82.35577259705975, 82.36288484482414, 82.36998618392231, 82.37707661435427, 82.38415613612004, 82.3912247492196,
        82.39828245365295, 82.40532924942015, 82.41236513652113, 82.41939011495593, 82.42640418472449, 82.43340734582686, 82.44039959826308, 82.44738094203309, 82.45435137713692, 82.46131090357453,
        82.46825952134593, 82.47519723045112, 82.48212403089015, 82.489039922663, 82.49594490576963, 82.50283898021003, 82.5097221459843, 82.5165944030923, 82.52345575153417, 82.53030619130983,
        82.53714572241928, 82.54397434486252, 82.55079205863954, 82.55759886375041, 82.5643947601951, 82.57117974797357, 82.57795382708584, 82.58471699753191, 82.59146925931178, 82.59821061242549,
        82.60494105687299, 82.6116605926543, 82.61836921976935, 82.62506693821824, 82.63175374800095, 82.63842964911747, 82.64509464156781, 82.65174872535188, 82.65839190046982, 82.66502416692153,
        82.67164552470707, 82.67825597382641, 82.68485551427956, 82.69144414606647, 82.6980218691872, 82.70458868364175, 82.7111445894301, 82.71768958655228, 82.72422367500822, 82.730746854798,
        82.73725912592154, 82.7437604883789, 82.7502509421701, 82.75673048729509, 82.76319912375384, 82.7696568515464, 82.77610367067281, 82.78253958113301, 82.78896458292702, 82.7953786760548,
        82.80178186051641, 82.80817413631179, 82.81455550344101, 82.82092596190405, 82.82728551170088, 82.83363415283146, 82.83997188529587, 82.84629870909411, 82.85261462422618, 82.85891963069203,
        82.86521372849167, 82.8714969176251, 82.87776919809231, 82.88403056989337, 82.89028103302824, 82.89652058749694, 82.90274923329936, 82.9089669704356, 82.91517379890568, 82.92136971870958,
        82.92755472984727, 82.93372883231878, 82.93989202612404, 82.9460443112631, 82.95218568773602, 82.95831615554273, 82.96443571468323, 82.97054436515752, 82.9766421069656, 82.98272894010752,
        82.98880486458326],
    "TENNIS": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0.013984320356503988, 0.028969701002330206, 0.043955081648156424, 0.05894046229398242,
        0.07392584293980864, 0.08891122358563486, 0.10389660423146108, 0.11888198487728707, 0.1338673655231133, 0.1488527461689395, 0.1638381268147655, 0.17882350746059172, 0.19380888810641794, 0.20879426875224416,
        0.22377964939807016, 0.23876503004389638, 0.2537504106897226, 0.2687357913355486, 0.2837211719813748, 0.298706552627201, 0.31369193327302725, 0.32867731391885324, 0.34366269456467946, 0.3586480752105057,
        0.3736334558563317, 0.3886188365021579, 0.4036042171479841, 0.41858959779381033, 0.43357497843963655, 0.4485603590854623, 0.46354573973128854, 0.47853112037711476, 0.493516501022941, 0.5085018816687672,
        0.5234872623145934, 0.5384726429604196, 0.5534580236062454, 0.5684434042520716, 0.5834287848978978, 0.5984141655437241, 0.6133995461895503, 0.6283849268353765, 0.6433703074812027, 0.6583556881270285,
        0.6733410687728547, 0.6883264494186809, 0.7033118300645071, 0.7182972107103334, 0.7332825913561596, 0.7482679720019858, 0.7632533526478116, 0.7782387332936378, 0.793224113939464, 0.8082094945852902,
        0.8231948752311165, 0.8381802558769427, 0.8531656365227689, 0.8681510171685947, 0.8831363978144209, 0.8981217784602471, 0.9131071591060733, 0.9280925397518995, 0.9430779203977258, 0.958063301043552,
        0.9730486816893777, 0.988034062335204, 1.0030194429810302, 1.0180048236268564, 1.0329902042726826, 1.0479755849185088, 1.062960965564335, 1.0779463462101608, 1.092931726855987, 1.1079171075018133,
        1.1229024881476395, 1.1378878687934657, 1.152873249439292, 1.1678586300851181, 1.182844010730944, 1.1978293913767701, 1.2128147720225964, 1.2278001526684226, 1.2427855333142488, 1.257770913960075,
        1.2727562946059012, 1.287741675251727, 1.3027270558975532, 1.3177124365433794, 1.3326978171892057, 1.3476831978350319, 1.362668578480858, 1.3776539591266843, 1.39263933977251, 1.4076247204183363,
        1.4226101010641625, 1.4375954817099887, 1.452580862355815, 1.4675662430016412, 1.4825516236474674, 1.4975370042932932, 1.5125223849391194, 1.5275077655849456, 1.5424931462307718, 1.557478526876598,
        1.5724639075224243, 1.5874492881682505, 1.6024346688140763, 1.6174200494599025, 1.6324054301057287, 1.647390810751555, 1.6623761913973811, 1.6773615720432073, 1.6923469526890331, 1.7073323333348593,
        1.7223177139806856, 1.7373030946265118, 1.752288475272338, 1.7672738559181642, 1.7822592365639904, 1.7972446172098162, 1.8122299978556424, 1.8272153785014686, 1.8422007591472949, 1.857186139793121,
        1.8721715204389473, 1.8871569010847735, 1.9021422817305993, 1.9171276623764255, 1.9321130430222517, 1.947098423668078, 1.9620838043139042, 1.9770691849597304, 1.9920545656055566, 2.0070399462513824,
        2.0220253268972086, 2.037010707543035, 2.051996088188861, 2.0669814688346873, 2.0819668494805135, 2.0969522301263397, 2.1119376107721655, 2.1269229914179917, 2.141908372063818, 2.156893752709644,
        2.1718791333554703, 2.1868645140012966, 2.2018498946471228, 2.2168352752929485, 2.2318206559387748, 2.246806036584601, 2.261791417230427, 2.2767767978762534, 2.2917621785220796, 2.306747559167906,
        2.3217329398137316, 2.336718320459558, 2.351703701105384, 2.3666890817512103, 2.3816744623970365, 2.3966598430428627, 2.411645223688689, 2.426630604334515, 2.4416159849803414, 2.4566013656261676,
        2.471586746271993, 2.486572126917819, 2.5015575075636454, 2.5165428882094716, 2.531528268855298, 2.546513649501124, 2.5614990301469502, 2.5764844107927765, 2.5914697914386027, 2.606455172084429,
        2.621440552730255, 2.6364259333760813, 2.6514113140219076, 2.6663966946677338, 2.681382075313559, 2.6963674559593853, 2.7113528366052115, 2.7263382172510378, 2.741323597896864, 2.75630897854269,
        2.7712943591885164, 2.7862797398343426, 2.801265120480169, 2.816250501125995, 2.8312358817718213, 2.8462212624176475, 2.8612066430634737, 2.8761920237093, 2.8911774043551253, 2.9061627850009515,
        2.9211481656467777, 2.936133546292604, 2.95111892693843, 2.9661043075842564, 2.9810896882300826, 2.996075068875909, 3.011060449521735, 3.0260458301675612, 3.0410312108133875, 3.0560165914592137,
        3.07100197210504, 3.085987352750866, 3.1009727333966914, 3.1159581140425177, 3.130943494688344, 3.14592887533417, 3.1609142559799963, 3.1758996366258225, 3.1908850172716487, 3.205870397917475,
        3.220855778563301, 3.2358411592091274, 3.2508265398549536, 3.26581192050078, 3.280797301146606, 3.2957826817924323, 3.3107680624382576, 3.325753443084084, 3.34073882372991, 3.3557242043757363,
        3.3707095850215625, 3.3856949656673887, 3.400680346313215, 3.415665726959041, 3.4306511076048674, 3.4456364882506936, 3.46062186889652, 3.475607249542346, 3.4905926301881722, 3.5055780108339984,
        3.520563391479824, 3.53554877212565, 3.550534152771476, 3.5655195334173024, 3.5805049140631287, 3.595490294708955, 3.610475675354781, 3.6254610560006073, 3.6404464366464335, 3.6554318172922597,
        3.670417197938086, 3.685402578583912, 3.7003879592297384, 3.7153733398755637, 3.73035872052139, 3.745344101167216, 3.7603294818130424, 3.7753148624588686, 3.790300243104695, 3.805285623750521,
        3.8202710043963473, 3.8352563850421735, 3.8502417656879997, 3.865227146333826, 3.880212526979652, 3.8951979076254784, 3.9101832882713046, 3.92516866891713, 3.940154049562956, 3.9551394302087823,
        3.9701248108546086, 3.9851101915004348, 4.000095572146261, 4.015080952792087, 4.030066333437913, 4.04505171408374, 4.060037094729566, 4.075022475375392, 4.090007856021218, 4.1049932366670445,
        4.119978617312871, 4.134963997958696, 4.149949378604522, 4.1649347592503485, 4.179920139896175, 4.194905520542001, 4.209890901187827, 4.224876281833653, 4.23986166247948, 4.254847043125306,
        4.269832423771132, 4.284817804416958, 4.2998031850627845, 4.314788565708611, 4.329773946354437, 4.344759327000262, 4.3597447076460885, 4.374730088291915, 4.389715468937741, 4.404700849583567,
        4.419686230229393, 4.4346716108752195, 4.449656991521046, 4.464642372166872, 4.479627752812698, 4.494613133458524, 4.509598514104351, 4.524583894750177, 4.539569275396003, 4.554554656041828,
        4.569540036687655, 4.584525417333481, 4.599510797979307, 4.614496178625133, 4.6294815592709595, 4.644466939916786, 4.659452320562612, 4.674437701208438, 4.689423081854264, 4.704408462500091,
        4.719393843145917, 4.734379223791743, 4.749364604437569, 4.764349985083395, 4.779335365729221, 4.794320746375047, 4.809306127020873, 4.8242915076666995, 4.839276888312526, 4.854262268958352,
        4.869247649604178, 4.884233030250004, 4.8992184108958305, 4.914203791541657, 4.929189172187483, 4.944174552833309, 4.9591599334791345, 4.974145314124961, 4.989130694770787, 5.004116075416613,
        5.019101456062439, 5.034086836708266, 5.049072217354092, 5.064057597999918, 5.079042978645744, 5.0940283592915705, 5.109013739937397, 5.123999120583223, 5.138984501229049, 5.153969881874875,
        5.168955262520701, 5.183940643166527, 5.198926023812353, 5.213911404458179, 5.228896785104006, 5.243882165749832, 5.258867546395658, 5.273852927041484, 5.2888383076873104, 5.303823688333137,
        5.318809068978963, 5.333794449624789, 5.348779830270615, 5.3637652109164415, 5.378750591562267, 5.393735972208093, 5.408721352853919, 5.4237067334997455, 5.438692114145572, 5.453677494791398,
        5.468662875437224, 5.48364825608305, 5.498633636728877, 5.513619017374703, 5.528604398020529, 5.543589778666355, 5.5585751593121815, 5.573560539958008, 5.588545920603833, 5.603531301249659,
        5.6185166818954855, 5.633502062541312, 5.648487443187138, 5.663472823832964, 5.67845820447879, 5.693443585124617, 5.708428965770443, 5.723414346416269, 5.738399727062095, 5.753385107707921,
        5.768370488353748, 5.783355868999574, 5.798341249645399, 5.813326630291225, 5.828312010937052, 5.843297391582878, 5.858282772228704, 5.87326815287453, 5.8882535335203565, 5.903238914166183,
        5.918224294812009, 5.933209675457835, 5.948195056103661, 5.963180436749488, 5.978165817395314, 5.99315119804114, 6.008136578686965, 6.023121959332792, 6.038107339978618, 6.053092720624444,
        6.06807810127027, 6.0830634819160965, 6.098048862561923, 6.113034243207749, 6.128019623853575, 6.143005004499401, 6.157990385145228, 6.172975765791054, 6.18796114643688, 6.202946527082705,
        6.2179319077285315, 6.232917288374358, 6.247902669020184, 6.26288804966601, 6.277873430311836, 6.292858810957663, 6.307844191603489, 6.322829572249315, 6.337814952895141, 6.3528003335409675,
        6.367785714186794, 6.38277109483262, 6.397756475478446, 6.4127418561242715, 6.427727236770099, 6.442712617415924, 6.457697998061751, 6.472683378707576, 6.4876687593534035, 6.502654139999229,
        6.517639520645054, 6.532624901290881, 6.547610281936707, 6.562595662582534, 6.577581043228359, 6.592566423874186, 6.6075518045200115, 6.622537185165839, 6.637522565811664, 6.652507946457491,
        6.667493327103316, 6.682478707749143, 6.697464088394969, 6.712449469040796, 6.727434849686621, 6.7424202303324465, 6.757405610978274, 6.772390991624099, 6.787376372269926, 6.802361752915751,
        6.8173471335615785, 6.832332514207404, 6.847317894853231, 6.862303275499056, 6.877288656144883, 6.892274036790709, 6.907259417436536, 6.922244798082361, 6.9372301787281865, 6.952215559374014,
        6.967200940019839, 6.982186320665666, 6.997171701311491, 7.0121570819573185, 7.027142462603144, 7.042127843248971, 7.057113223894796, 7.072098604540623, 7.087083985186449, 7.102069365832276,
        7.117054746478101, 7.132040127123928, 7.1470255077697535, 7.162010888415579, 7.176996269061406, 7.191981649707231, 7.206967030353058, 7.221952410998884, 7.236937791644711, 7.251923172290536,
        7.266908552936363, 7.281893933582189, 7.296879314228016, 7.311864694873841, 7.326850075519668, 7.3418354561654935, 7.356820836811319, 7.371806217457146, 7.386791598102971, 7.401776978748798,
        7.416762359394624, 7.431747740040451, 7.446733120686276, 7.461718501332103, 7.476703881977929, 7.491689262623756, 7.506674643269581, 7.521660023915408, 7.536645404561233, 7.551630785207059,
        7.566616165852886, 7.581601546498711, 7.596586927144538, 7.611572307790364, 7.626557688436191, 7.641543069082016, 7.656528449727843, 7.6715138303736685, 7.686499211019496, 7.701484591665321,
        7.716469972311148, 7.731455352956973, 7.7464407336028005, 7.761426114248626, 7.776411494894451, 7.791396875540278, 7.806382256186104, 7.821367636831931, 7.836353017477756, 7.851338398123583,
        7.8663237787694085, 7.881309159415236, 7.896294540061061, 7.911279920706888, 7.926265301352713, 7.9412506819985405, 7.956236062644366, 7.971221443290191, 7.986206823936018, 8.001192204581844,
        8.01617758522767, 8.031162965873495, 8.046148346519324, 8.06113372716515, 8.076119107810975, 8.0911044884568, 8.106089869102629, 8.121075249748454, 8.13606063039428, 8.151046011040105,
        8.166031391685934, 8.181016772331759, 8.196002152977584, 8.21098753362341, 8.225972914269235, 8.240958294915064, 8.25594367556089, 8.270929056206715, 8.28591443685254, 8.300899817498369,
        8.315885198144194, 8.33087057879002, 8.345855959435845, 8.360841340081674, 8.375826720727499, 8.390812101373324, 8.40579748201915, 8.420782862664975, 8.435768243310804, 8.45075362395663,
        8.465739004602455, 8.48072438524828, 8.495709765894109, 8.510695146539934, 8.52568052718576, 8.540665907831585, 8.555651288477414, 8.570636669123239, 8.585622049769064, 8.60060743041489,
        8.615592811060715, 8.630578191706544, 8.64556357235237, 8.660548952998194, 8.67553433364402, 8.690519714289849, 8.705505094935674, 8.7204904755815, 8.735475856227325, 8.750461236873154,
        8.765446617518979, 8.780431998164804, 8.79541737881063, 8.810402759456455, 8.825388140102284, 8.84037352074811, 8.855358901393934, 8.87034428203976, 8.885329662685589, 8.900315043331414,
        8.91530042397724, 8.930285804623065, 8.945271185268894, 8.960256565914719, 8.975241946560544, 8.99022732720637, 9.005212707852195, 9.020198088498024, 9.035183469143849, 9.050168849789674,
        9.0651542304355, 9.080139611081329, 9.095124991727154, 9.11011037237298, 9.125095753018805, 9.140081133664633, 9.155066514310459, 9.170051894956284, 9.18503727560211, 9.200022656247938,
        9.215008036893764, 9.229993417539589, 9.244978798185414, 9.25996417883124, 9.274949559477069, 9.289934940122894, 9.30492032076872, 9.319905701414545, 9.334891082060373, 9.349876462706199,
        9.364861843352024, 9.37984722399785, 9.394832604643678, 9.409817985289504, 9.424803365935329, 9.439788746581154, 9.45477412722698, 9.469759507872809, 9.484744888518634, 9.49973026916446,
        9.514715649810285, 9.529701030456113, 9.544686411101939, 9.559671791747764, 9.57465717239359, 9.589642553039418, 9.604627933685244, 9.619613314331069, 9.634598694976894, 9.64958407562272,
        9.664569456268548, 9.679554836914374, 9.6945402175602, 9.709525598206024, 9.724510978851853, 9.739496359497679, 9.754481740143504, 9.76946712078933, 9.784452501435158, 9.799437882080984,
        9.814423262726809, 9.829408643372634, 9.84439402401846, 9.859379404664288, 9.874364785310114, 9.889350165955939, 9.904335546601764, 9.919320927247593, 9.934306307893419, 9.949291688539244,
        9.96427706918507, 9.979262449830898, 9.994247830476723, 10.009233211122549, 10.024218591768374, 10.0392039724142, 10.054189353060028, 10.069174733705854, 10.084160114351679, 10.099145494997504,
        10.114130875643333, 10.129116256289159, 10.144101636934984, 10.15908701758081, 10.174072398226638, 10.189057778872463, 10.204043159518289, 10.219028540164114, 10.234013920809943, 10.248999301455768,
        10.263984682101594, 10.278970062747419, 10.293955443393244, 10.308940824039073, 10.323926204684899, 10.338911585330724, 10.35389696597655, 10.368882346622378, 10.383867727268203, 10.398853107914029,
        10.413838488559854, 10.428823869205683, 10.443809249851508, 10.458794630497334, 10.473780011143159, 10.488765391788984, 10.503750772434813, 10.518736153080638, 10.533721533726464, 10.54870691437229,
        10.563692295018118, 10.578677675663943, 10.593663056309769, 10.608648436955594, 10.623633817601423, 10.638619198247248, 10.653604578893074, 10.668589959538899, 10.683575340184724, 10.698560720830553,
        10.713546101476378, 10.728531482122204, 10.743516862768029, 10.758502243413858, 10.773487624059683, 10.788473004705509, 10.803458385351334, 10.818443765997163, 10.833429146642988, 10.848414527288814,
        10.863399907934639, 10.878385288580464, 10.893370669226293, 10.908356049872118, 10.923341430517944, 10.938326811163769, 10.953312191809598, 10.968297572455423, 10.983282953101249, 10.998268333747074,
        11.013253714392903, 11.028239095038728, 11.043224475684553, 11.058209856330379, 11.073195236976208, 11.088180617622033, 11.103165998267858, 11.118151378913684, 11.133136759559509, 11.148122140205338,
        11.163107520851163, 11.178092901496989, 11.193078282142814, 11.208063662788643, 11.223049043434468, 11.238034424080293, 11.253019804726119, 11.268005185371948, 11.282990566017773, 11.297975946663598,
        11.312961327309424, 11.327946707955249, 11.342932088601078, 11.357917469246903, 11.372902849892728, 11.387888230538554, 11.402873611184383, 11.417858991830208, 11.432844372476033, 11.447829753121859,
        11.462815133767688, 11.477800514413513, 11.492785895059338, 11.507771275705164, 11.522756656350989, 11.537742036996818, 11.552727417642643, 11.567712798288468, 11.582698178934294, 11.597683559580123,
        11.612668940225948, 11.627654320871773, 11.642639701517599, 11.657625082163428, 11.672610462809253, 11.687595843455078, 11.702581224100904, 11.717566604746729, 11.732551985392558, 11.747537366038383,
        11.762522746684208, 11.777508127330034, 11.792493507975863, 11.807478888621688, 11.822464269267513, 11.837449649913339, 11.852435030559167, 11.867420411204993, 11.882405791850818, 11.897391172496643,
        11.912376553142469, 11.927361933788298, 11.942347314434123, 11.957332695079948, 11.972318075725774, 11.987303456371603, 12.002288837017428, 12.017274217663253, 12.032259598309079, 12.047244978954907,
        12.062230359600733, 12.077215740246558, 12.092201120892383, 12.107186501538212, 12.122171882184038, 12.137157262829863, 12.152142643475688, 12.167128024121514, 12.182113404767343, 12.197098785413168,
        12.212084166058993, 12.227069546704818, 12.242054927350647, 12.257040307996473, 12.272025688642298, 12.287011069288123, 12.301996449933952, 12.316981830579778, 12.331967211225603, 12.346952591871428,
        12.361937972517254, 12.376923353163082, 12.391908733808908, 12.406894114454733, 12.421879495100558, 12.436864875746387, 12.451850256392213, 12.466835637038038, 12.481821017683863, 12.496806398329692,
        12.511791778975518, 12.526777159621343, 12.541762540267168, 12.556747920912994, 12.571733301558822, 12.586718682204648, 12.601704062850473, 12.616689443496298, 12.631674824142127, 12.646660204787953,
        12.661645585433778, 12.676630966079603, 12.691616346725432, 12.706601727371257, 12.721587108017083, 12.736572488662908, 12.751557869308733, 12.766543249954562, 12.781528630600388, 12.796514011246213,
        12.811499391892038, 12.826484772537867, 12.841470153183693, 12.856455533829518, 12.871440914475343, 12.886426295121172, 12.901411675766997, 12.916397056412823, 12.931382437058648, 12.946367817704473,
        12.961353198350302, 12.976338578996128, 12.991323959641953, 13.006309340287778, 13.021294720933607, 13.036280101579433, 13.051265482225258, 13.066250862871083, 13.081236243516912, 13.096221624162737,
        13.111207004808563, 13.126192385454388, 13.141177766100217, 13.156163146746042, 13.171148527391868, 13.186133908037693, 13.201119288683518, 13.216104669329347, 13.231090049975172, 13.246075430620998,
        13.261060811266823, 13.276046191912652, 13.291031572558477, 13.306016953204303, 13.321002333850128, 13.335987714495957, 13.350973095141782, 13.365958475787608, 13.380943856433433, 13.395929237079258,
        13.410914617725087, 13.425899998370912, 13.440885379016738, 13.455870759662563, 13.470856140308392, 13.485841520954217, 13.500826901600043, 13.515812282245868, 13.530797662891697, 13.545783043537522,
        13.560768424183347, 13.575753804829173, 13.590739185474998, 13.605724566120827, 13.620709946766652, 13.635695327412478, 13.650680708058303, 13.665666088704132, 13.680651469349957, 13.695636849995783,
        13.710622230641608, 13.725607611287437, 13.740592991933262, 13.755578372579087, 13.770563753224913, 13.785549133870738, 13.800534514516567, 13.815519895162392, 13.830505275808218, 13.845490656454043,
        13.860476037099872, 13.875461417745697, 13.890446798391523, 13.905432179037348, 13.920417559683177, 13.935402940329002, 13.950388320974827, 13.965373701620653, 13.980359082266478, 13.995344462912307,
        14.010329843558132, 14.025315224203958, 14.040300604849783, 14.055285985495612, 14.070271366141437, 14.085256746787262, 14.100242127433088, 14.115227508078917, 14.130212888724742, 14.145198269370567,
        14.160183650016393, 14.175169030662222, 14.190154411308047, 14.205139791953872, 14.220125172599698, 14.235110553245523, 14.250095933891352, 14.265081314537177, 14.280066695183002, 14.295052075828828,
        14.310037456474657, 14.325022837120482, 14.340008217766307, 14.354993598412133, 14.369978979057962, 14.384964359703787, 14.399949740349612, 14.414935120995438, 14.429920501641266, 14.444905882287092,
        14.459891262932917, 14.474876643578742, 14.489862024224571, 14.504847404870397, 14.519832785516222, 14.534818166162047, 14.549803546807876, 14.564788927453701, 14.579774308099527, 14.594759688745352,
        14.609745069391177, 14.624730450037006, 14.639715830682832, 14.654701211328657, 14.669686591974482, 14.684671972620311, 14.699657353266137, 14.714642733911962, 14.729628114557787, 14.744613495203616,
        14.759598875849441, 14.774584256495267, 14.789569637141092, 14.804555017786921, 14.819540398432746, 14.834525779078572, 14.849511159724397, 14.864496540370222, 14.879481921016051, 14.894467301661876,
        14.909452682307702, 14.924438062953527, 14.939423443599356, 14.954408824245181, 14.969394204891007, 14.984379585536832, 14.999364966182661, 15.014350346828486, 15.029335727474312, 15.044321108120137,
        15.059306488765962, 15.074291869411791, 15.089277250057616, 15.104262630703442, 15.119248011349267, 15.134233391995096, 15.149218772640921, 15.164204153286747, 15.179189533932572, 15.1941749145784,
        15.209160295224226, 15.224145675870052, 15.239131056515877, 15.254116437161702, 15.269101817807531, 15.284087198453356, 15.299072579099182, 15.314057959745007, 15.329043340390836, 15.344028721036661,
        15.359014101682487, 15.373999482328312, 15.38898486297414, 15.403970243619966, 15.418955624265791, 15.433941004911617, 15.448926385557442, 15.463911766203271, 15.478897146849096, 15.493882527494922,
        15.508867908140747, 15.523853288786576, 15.538838669432401, 15.553824050078227, 15.568809430724052, 15.58379481136988, 15.598780192015706, 15.613765572661531, 15.628750953307357, 15.643736333953182,
        15.658721714599011, 15.673707095244836, 15.688692475890662, 15.703677856536487, 15.718663237182316, 15.733648617828141, 15.748633998473966, 15.763619379119792, 15.77860475976562, 15.793590140411446,
        15.808575521057271, 15.823560901703097, 15.838546282348926, 15.853531662994751, 15.868517043640576, 15.883502424286402, 15.898487804932227, 15.913473185578056, 15.928458566223881, 15.943443946869706,
        15.958429327515532, 15.97341470816136, 15.988400088807186, 16.00338546945301, 16.018370850098837, 16.033356230744666, 16.04834161139049, 16.063326992036316, 16.07831237268214, 16.093297753327967,
        16.108283133973796, 16.12326851461962, 16.138253895265446, 16.15323927591127, 16.1682246565571, 16.183210037202926, 16.19819541784875, 16.213180798494577, 16.228166179140405, 16.24315155978623,
        16.258136940432056, 16.27312232107788, 16.288107701723707, 16.303093082369536, 16.31807846301536, 16.333063843661186, 16.34804922430701, 16.36303460495284, 16.378019985598666, 16.39300536624449,
        16.407990746890317, 16.422976127536145, 16.43796150818197, 16.452946888827796, 16.46793226947362, 16.482917650119447, 16.497903030765276, 16.5128884114111, 16.527873792056926, 16.54285917270275,
        16.55784455334858, 16.572829933994406, 16.58781531464023, 16.602800695286057, 16.617786075931885, 16.63277145657771, 16.647756837223536, 16.66274221786936, 16.677727598515187, 16.692712979161016,
        16.70769835980684, 16.722683740452666, 16.73766912109849, 16.75265450174432, 16.767639882390146, 16.78262526303597, 16.797610643681796, 16.812596024327625, 16.82758140497345, 16.842566785619276,
        16.8575521662651, 16.87253754691093, 16.887522927556756, 16.90250830820258, 16.917493688848406, 16.93247906949423, 16.94746445014006, 16.962449830785886, 16.97743521143171, 16.992420592077536,
        17.007405972723365, 17.02239135336919, 17.037376734015016, 17.05236211466084, 17.06734749530667, 17.082332875952495, 17.09731825659832, 17.112303637244146, 17.12728901788997, 17.1422743985358,
        17.157259779181626, 17.17224515982745, 17.187230540473276, 17.202215921119105, 17.21720130176493, 17.232186682410756, 17.24717206305658, 17.26215744370241, 17.277142824348235, 17.29212820499406,
        17.307113585639886, 17.32209896628571, 17.33708434693154, 17.352069727577366, 17.36705510822319, 17.382040488869016, 17.397025869514845, 17.41201125016067, 17.426996630806496, 17.44198201145232,
        17.45696739209815, 17.471952772743975, 17.4869381533898, 17.501923534035626, 17.51690891468145, 17.53189429532728, 17.546879675973106, 17.56186505661893, 17.576850437264756, 17.591835817910585,
        17.60682119855641, 17.621806579202236, 17.63679195984806, 17.65177734049389, 17.666762721139715, 17.68174810178554, 17.696733482431366, 17.71171886307719, 17.72670424372302, 17.741689624368846,
        17.75667500501467, 17.771660385660496, 17.786645766306325, 17.80163114695215, 17.816616527597976, 17.8316019082438, 17.84658728888963, 17.861572669535455, 17.87655805018128, 17.891543430827106,
        17.906528811472935, 17.92151419211876, 17.936499572764586, 17.95148495341041, 17.966470334056236, 17.981455714702065, 17.99644109534789, 18.011426475993716, 18.02641185663954, 18.04139723728537,
        18.056382617931195, 18.07136799857702, 18.086353379222846, 18.101338759868675, 18.1163241405145, 18.131309521160325, 18.14629490180615, 18.161280282451976, 18.176265663097805, 18.19125104374363,
        18.206236424389456, 18.22122180503528, 18.23620718568111, 18.251192566326935, 18.26617794697276, 18.281163327618586, 18.296148708264415, 18.31113408891024, 18.326119469556065, 18.34110485020189,
        18.356090230847716, 18.371075611493545, 18.38606099213937, 18.401046372785196, 18.41603175343102, 18.43101713407685, 18.446002514722675, 18.4609878953685, 18.475973276014326, 18.490958656660155,
        18.50594403730598, 18.520929417951805, 18.53591479859763, 18.550900179243456, 18.565885559889285, 18.58087094053511, 18.595856321180936, 18.61084170182676, 18.62582708247259, 18.640812463118415,
        18.65579784376424, 18.670783224410066, 18.685768605055895, 18.70075398570172, 18.715739366347545, 18.73072474699337, 18.7457101276392, 18.760695508285025, 18.77568088893085, 18.790666269576676,
        18.8056516502225, 18.82063703086833, 18.835622411514155, 18.85060779215998, 18.865593172805806, 18.880578553451635, 18.89556393409746, 18.910549314743285, 18.92553469538911, 18.94052007603494,
        18.955505456680765, 18.97049083732659, 18.985476217972415, 19.00046159861824, 19.01544697926407, 19.030432359909895, 19.04541774055572, 19.060403121201546, 19.075388501847375, 19.0903738824932,
        19.105359263139025, 19.12034464378485, 19.13533002443068, 19.150315405076505, 19.16530078572233, 19.180286166368155, 19.19527154701398, 19.21025692765981, 19.225242308305635, 19.24022768895146,
        19.255213069597286, 19.270198450243115, 19.28518383088894, 19.300169211534765, 19.31515459218059, 19.33013997282642, 19.345125353472245, 19.36011073411807, 19.375096114763895, 19.39008149540972,
        19.40506687605555, 19.420052256701375, 19.4350376373472, 19.450023017993026, 19.465008398638854, 19.47999377928468, 19.494979159930505, 19.50996454057633, 19.52494992122216, 19.539935301867985,
        19.55492068251381, 19.569906063159635, 19.58489144380546, 19.59987682445129, 19.614862205097115, 19.62984758574294, 19.644832966388766, 19.659818347034594, 19.67480372768042, 19.689789108326245,
        19.70477448897207, 19.7197598696179, 19.734745250263725, 19.74973063090955, 19.764716011555375, 19.779701392201204, 19.79468677284703, 19.809672153492855, 19.82465753413868, 19.839642914784505,
        19.854628295430334, 19.86961367607616, 19.884599056721985, 19.89958443736781, 19.91456981801364, 19.929555198659465, 19.94454057930529, 19.959525959951115, 19.974511340596944, 19.98949672124277,
        20.004482101888595, 20.01946748253442, 20.034452863180245, 20.049438243826074, 20.0644236244719, 20.079409005117725, 20.09439438576355, 20.10937976640938, 20.124365147055205, 20.13935052770103,
        20.154335908346855, 20.169321288992684, 20.18430666963851, 20.199292050284335, 20.21427743093016, 20.229262811575985, 20.244248192221814, 20.25923357286764, 20.274218953513465, 20.28920433415929,
        20.30418971480512, 20.319175095450944, 20.33416047609677, 20.349145856742595, 20.364131237388424, 20.37911661803425, 20.394101998680075, 20.4090873793259, 20.424072759971725, 20.439058140617554,
        20.45404352126338, 20.469028901909205, 20.48401428255503, 20.49899966320086, 20.513985043846684, 20.52897042449251, 20.543955805138335, 20.558941185784164, 20.57392656642999, 20.588911947075815,
        20.60389732772164, 20.618882708367465, 20.633868089013294, 20.64885346965912, 20.663838850304945, 20.67882423095077, 20.6938096115966, 20.708794992242424, 20.72378037288825, 20.738765753534075,
        20.753751134179904, 20.76873651482573, 20.783721895471555, 20.79870727611738, 20.81369265676321, 20.828678037409034, 20.84366341805486, 20.858648798700685, 20.87363417934651, 20.88861955999234,
        20.903604940638164, 20.91859032128399, 20.933575701929815, 20.948561082575644, 20.96354646322147, 20.978531843867295, 20.99351722451312, 21.00850260515895, 21.023487985804774, 21.0384733664506,
        21.053458747096425, 21.06844412774225, 21.08342950838808, 21.098414889033904, 21.11340026967973, 21.128385650325555, 21.143371030971384, 21.15835641161721, 21.173341792263034, 21.18832717290886,
        21.20331255355469, 21.218297934200514, 21.23328331484634, 21.248268695492165, 21.26325407613799, 21.27823945678382, 21.293224837429644, 21.30821021807547, 21.323195598721295, 21.338180979367124,
        21.35316636001295, 21.368151740658774, 21.3831371213046, 21.39812250195043, 21.413107882596254, 21.42809326324208, 21.443078643887905, 21.45806402453373, 21.47304940517956, 21.488034785825384,
        21.50302016647121, 21.518005547117035, 21.532990927762864, 21.54797630840869, 21.562961689054514, 21.57794706970034, 21.59293245034617, 21.607917830991994, 21.62290321163782, 21.637888592283645,
        21.65287397292947, 21.6678593535753, 21.682844734221124, 21.69783011486695, 21.712815495512775, 21.727800876158604, 21.74278625680443, 21.757771637450254, 21.77275701809608, 21.78774239874191,
        21.802727779387734, 21.81771316003356, 21.832698540679385, 21.847683921325213, 21.86266930197104, 21.877654682616864, 21.89264006326269, 21.907625443908515, 21.922610824554344, 21.93759620520017,
        21.952581585845994, 21.96756696649182, 21.98255234713765, 21.997537727783474, 22.0125231084293, 22.027508489075124, 22.042493869720953, 22.05747925036678, 22.072464631012604, 22.08745001165843,
        22.102435392304255, 22.117420772950084, 22.13240615359591, 22.147391534241734, 22.16237691488756, 22.17736229553339, 22.192347676179214, 22.20733305682504, 22.222318437470864, 22.237303818116693,
        22.25228919876252, 22.267274579408344, 22.28225996005417, 22.297245340699995, 22.312230721345824, 22.32721610199165, 22.342201482637474, 22.3571868632833, 22.37217224392913, 22.387157624574954,
        22.40214300522078, 22.417128385866604, 22.432113766512433, 22.44709914715826, 22.462084527804084, 22.47706990844991, 22.492055289095735, 22.507040669741563, 22.52202605038739, 22.537011431033214,
        22.55199681167904, 22.56698219232487, 22.581967572970694, 22.59695295361652, 22.611938334262344, 22.626923714908173, 22.641909095554, 22.656894476199824, 22.67187985684565, 22.686865237491478,
        22.701850618137303, 22.71683599878313, 22.731821379428954, 22.74680676007478, 22.76179214072061, 22.776777521366434, 22.79176290201226, 22.806748282658084, 22.821733663303913, 22.83671904394974,
        22.851704424595564, 22.86668980524139, 22.881675185887218, 22.896660566533043, 22.91164594717887, 22.926631327824694, 22.94161670847052, 22.95660208911635, 22.971587469762174, 22.986572850408,
        23.001558231053824, 23.016543611699653, 23.03152899234548, 23.046514372991304, 23.06149975363713, 23.076485134282958, 23.091470514928783, 23.10645589557461, 23.121441276220434, 23.13642665686626,
        23.151412037512088, 23.166397418157914, 23.18138279880374, 23.196368179449564, 23.211353560095393, 23.22633894074122, 23.241324321387044, 23.25630970203287, 23.271295082678698, 23.286280463324523,
        23.30126584397035, 23.316251224616174, 23.331236605262, 23.346221985907828, 23.361207366553653, 23.37619274719948, 23.391178127845304, 23.406163508491133, 23.42114888913696, 23.436134269782784,
        23.45111965042861, 23.466105031074438, 23.481090411720263, 23.49607579236609, 23.511061173011914, 23.52604655365774, 23.541031934303568, 23.556017314949393, 23.57100269559522, 23.585988076241044,
        23.600973456886873, 23.6159588375327, 23.630944218178524, 23.64592959882435, 23.660914979470178, 23.675900360116003, 23.69088574076183, 23.705871121407654, 23.720856502053483, 23.735841882699308,
        23.750827263345133, 23.76581264399096, 23.780798024636784, 23.795783405282613, 23.81076878592844, 23.825754166574264, 23.84073954722009, 23.855724927865918, 23.870710308511743, 23.88569568915757,
        23.900681069803394, 23.915666450449223, 23.930651831095048, 23.945637211740873, 23.9606225923867, 23.975607973032524, 23.990593353678353, 24.005578734324178, 24.020564114970004, 24.03554949561583,
        24.050534876261658, 24.065520256907483, 24.08050563755331, 24.095491018199134, 24.110476398844963, 24.125461779490788, 24.140447160136613, 24.15543254078244, 24.170417921428264, 24.185403302074093,
        24.200388682719918, 24.215374063365743, 24.23035944401157, 24.245344824657398, 24.260330205303223, 24.27531558594905, 24.290300966594874, 24.305286347240703, 24.320271727886528, 24.335257108532353,
        24.35024248917818, 24.365227869824004, 24.380213250469833, 24.395198631115658, 24.410184011761483, 24.42516939240731, 24.440154773053138, 24.455140153698963, 24.47012553434479, 24.485110914990614,
        24.500096295636443, 24.515081676282268, 24.530067056928093, 24.54505243757392, 24.560037818219744, 24.575023198865573, 24.590008579511398, 24.604993960157223, 24.61997934080305, 24.634964721448878,
        24.649950102094703, 24.66493548274053, 24.679920863386354, 24.694906244032182, 24.709891624678008, 24.724877005323833, 24.73986238596966, 24.754847766615487, 24.769833147261313, 24.784818527907138,
        24.799803908552963, 24.81478928919879, 24.829774669844618, 24.844760050490443, 24.859745431136268, 24.874730811782094, 24.889716192427922, 24.904701573073748, 24.919686953719573, 24.9346723343654,
        24.949657715011227, 24.964643095657053, 24.979628476302878, 24.994613856948703, 25.00959923759453, 25.024584618240358, 25.039569998886183, 25.054555379532008, 25.069540760177834, 25.084526140823662,
        25.099511521469488, 25.114496902115313, 25.12948228276114, 25.144467663406967, 25.159453044052793, 25.174438424698618, 25.189423805344443, 25.20440918599027, 25.219394566636097, 25.234379947281923,
        25.249365327927748, 25.264350708573573, 25.279336089219402, 25.294321469865228, 25.309306850511053, 25.32429223115688, 25.339277611802707, 25.354262992448533, 25.369248373094358, 25.384233753740183,
        25.39921913438601, 25.414204515031837, 25.429189895677663, 25.444175276323488, 25.459160656969313, 25.474146037615142, 25.489131418260968, 25.504116798906793, 25.51910217955262, 25.534087560198447,
        25.549072940844272, 25.564058321490098, 25.579043702135923, 25.59402908278175, 25.609014463427577, 25.623999844073403, 25.638985224719228, 25.653970605365053, 25.668955986010882, 25.683941366656708,
        25.698926747302533, 25.71391212794836, 25.728897508594187, 25.743882889240012, 25.758868269885838, 25.773853650531663, 25.788839031177492, 25.803824411823317, 25.818809792469143, 25.833795173114968,
        25.848780553760793, 25.863765934406622, 25.878751315052448, 25.893736695698273, 25.908722076344098, 25.923707456989927, 25.938692837635752, 25.953678218281578, 25.968663598927403, 25.983648979573232,
        25.998634360219057, 26.013619740864883, 26.028605121510708, 26.043590502156533, 26.058575882802362, 26.073561263448187, 26.088546644094013, 26.103532024739838, 26.118517405385667, 26.133502786031492,
        26.148488166677318, 26.163473547323143, 26.178458927968972, 26.193444308614797, 26.208429689260623, 26.223415069906448, 26.238400450552273, 26.253385831198102, 26.268371211843927, 26.283356592489753,
        26.298341973135578, 26.313327353781407, 26.328312734427232, 26.343298115073058, 26.358283495718883, 26.373268876364712, 26.388254257010537, 26.403239637656363, 26.418225018302188, 26.433210398948013,
        26.448195779593842, 26.463181160239667, 26.478166540885493, 26.493151921531318, 26.508137302177147, 26.523122682822972, 26.538108063468798, 26.553093444114623, 26.568078824760452, 26.583064205406277,
        26.598049586052102, 26.613034966697928, 26.628020347343753, 26.643005727989582, 26.657991108635407, 26.672976489281233, 26.687961869927058, 26.702947250572887, 26.717932631218712, 26.732918011864538,
        26.747903392510363, 26.76288877315619, 26.777874153802017, 26.792859534447842, 26.807844915093668, 26.822830295739497, 26.837815676385322, 26.852801057031147, 26.867786437676973, 26.882771818322798,
        26.897757198968627, 26.912742579614452, 26.927727960260277, 26.942713340906103, 26.95769872155193, 26.972684102197757, 26.987669482843582, 27.002654863489408, 27.017640244135237, 27.032625624781062,
        27.047611005426887, 27.062596386072713, 27.077581766718538, 27.092567147364367, 27.107552528010192, 27.122537908656017, 27.137523289301843, 27.15250866994767, 27.167494050593497, 27.182479431239322,
        27.197464811885148, 27.212450192530977, 27.227435573176802, 27.242420953822627, 27.257406334468453, 27.272391715114278, 27.287377095760107, 27.302362476405932, 27.317347857051757, 27.332333237697583,
        27.34731861834341, 27.362303998989237, 27.377289379635062, 27.392274760280888, 27.407260140926716, 27.422245521572542, 27.437230902218367, 27.452216282864192, 27.467201663510018, 27.482187044155847,
        27.497172424801672, 27.512157805447497, 27.527143186093323, 27.54212856673915, 27.557113947384977, 27.572099328030802, 27.587084708676628, 27.602070089322456, 27.61705546996828, 27.632040850614107,
        27.647026231259932, 27.66201161190576, 27.676996992551587, 27.691982373197412, 27.706967753843237, 27.721953134489063, 27.73693851513489, 27.751923895780717, 27.766909276426542, 27.781894657072367,
        27.796880037718196, 27.81186541836402, 27.826850799009847, 27.841836179655672, 27.8568215603015, 27.871806940947327, 27.886792321593152, 27.901777702238977, 27.916763082884803, 27.93174846353063,
        27.946733844176457, 27.961719224822282, 27.976704605468107, 27.991689986113936, 28.00667536675976, 28.021660747405587, 28.036646128051412, 28.05163150869724, 28.066616889343067, 28.081602269988892,
        28.096587650634717, 28.111573031280543, 28.12655841192637, 28.141543792572197, 28.156529173218022, 28.171514553863847, 28.186499934509676, 28.2014853151555, 28.216470695801327, 28.231456076447152,
        28.24644145709298, 28.261426837738806, 28.276412218384632, 28.291397599030457, 28.306382979676282, 28.32136836032211, 28.336353740967937, 28.351339121613762, 28.366324502259587, 28.381309882905416,
        28.39629526355124, 28.411280644197067, 28.426266024842892, 28.44125140548872, 28.456236786134546, 28.47122216678037, 28.486207547426197, 28.501192928072022, 28.51617830871785, 28.531163689363677,
        28.546149070009502, 28.561134450655327, 28.576119831301156, 28.59110521194698, 28.606090592592807, 28.621075973238632, 28.63606135388446, 28.651046734530286, 28.66603211517611, 28.681017495821937,
        28.696002876467766, 28.71098825711359, 28.725973637759417, 28.740959018405242, 28.755944399051067, 28.770929779696896, 28.78591516034272, 28.800900540988547, 28.815885921634372, 28.8308713022802,
        28.845856682926026, 28.86084206357185, 28.875827444217677, 28.890812824863506, 28.90579820550933, 28.920783586155157, 28.935768966800982, 28.950754347446807, 28.965739728092636, 28.98072510873846,
        28.995710489384287, 29.010695870030112, 29.02568125067594, 29.040666631321766, 29.05565201196759, 29.070637392613417, 29.085622773259246, 29.10060815390507, 29.115593534550896, 29.130578915196722,
        29.145564295842547, 29.160549676488376, 29.1755350571342, 29.190520437780027, 29.205505818425852, 29.22049119907168, 29.235476579717506, 29.25046196036333, 29.265447341009157, 29.280432721654986,
        29.29541810230081, 29.310403482946636, 29.32538886359246, 29.340374244238287, 29.355359624884116, 29.37034500552994, 29.385330386175767, 29.400315766821592, 29.41530114746742, 29.430286528113246,
        29.44527190875907, 29.460257289404897, 29.475242670050726, 29.49022805069655, 29.505213431342376, 29.5201988119882, 29.535184192634027, 29.550169573279856, 29.56515495392568, 29.580140334571507,
        29.595125715217332, 29.61011109586316, 29.625096476508986, 29.64008185715481, 29.655067237800637, 29.670052618446466, 29.68503799909229, 29.700023379738116, 29.71500876038394, 29.72999414102977,
        29.744979521675596, 29.75996490232142, 29.774950282967247, 29.789935663613072, 29.8049210442589, 29.819906424904726, 29.83489180555055, 29.849877186196377, 29.864862566842206, 29.87984794748803,
        29.894833328133856, 29.90981870877968, 29.92480408942551, 29.939789470071336, 29.95477485071716, 29.969760231362987, 29.984745612008812, 29.99973099265464, 30.014716373300466, 30.02970175394629,
        30.044687134592117, 30.059672515237946, 30.07465789588377, 30.089643276529596, 30.10462865717542, 30.11961403782125, 30.134599418467076, 30.1495847991129, 30.164570179758726, 30.179555560404552,
        30.19454094105038, 30.209526321696206, 30.22451170234203, 30.239497082987857, 30.254482463633686, 30.26946784427951, 30.284453224925336, 30.29943860557116, 30.31442398621699, 30.329409366862816,
        30.34439474750864, 30.359380128154466, 30.37436550880029, 30.38935088944612, 30.404336270091946, 30.41932165073777, 30.4343070313836, 30.449292412029425, 30.46427779267525, 30.479263173321076,
        30.4942485539669, 30.509233934612727, 30.524219315258552, 30.539204695904385, 30.55419007655021, 30.569175457196035, 30.58416083784186, 30.599146218487686, 30.61413159913351, 30.629116979779337,
        30.644102360425162, 30.659087741070987, 30.67407312171682, 30.689058502362645, 30.70404388300847, 30.719029263654296, 30.73401464430012, 30.749000024945946, 30.76398540559177, 30.778970786237597,
        30.793956166883422, 30.808941547529255, 30.82392692817508, 30.838912308820905, 30.85389768946673, 30.868883070112556, 30.88386845075838, 30.898853831404207, 30.913839212050032, 30.928824592695864,
        30.94380997334169, 30.958795353987515, 30.97378073463334, 30.988766115279166, 31.00375149592499, 31.018736876570816, 31.033722257216642, 31.048707637862467, 31.0636930185083, 31.078678399154125,
        31.09366377979995, 31.108649160445776, 31.1236345410916, 31.138619921737426, 31.15360530238325, 31.168590683029077, 31.18357606367491, 31.198561444320735, 31.21354682496656, 31.228532205612385,
        31.24351758625821, 31.258502966904036, 31.27348834754986, 31.288473728195687, 31.303459108841512, 31.318444489487344, 31.33342987013317, 31.348415250778995, 31.36340063142482, 31.378386012070646,
        31.39337139271647, 31.408356773362296, 31.42334215400812, 31.438327534653947, 31.45331291529978, 31.468298295945605, 31.48328367659143, 31.498269057237255, 31.51325443788308, 31.528239818528906,
        31.54322519917473, 31.558210579820557, 31.57319596046639, 31.588181341112215, 31.60316672175804, 31.618152102403865, 31.63313748304969, 31.648122863695516, 31.66310824434134, 31.678093624987167,
        31.693079005632992, 31.708064386278824, 31.72304976692465, 31.738035147570475, 31.7530205282163, 31.768005908862126, 31.78299128950795, 31.797976670153776, 31.8129620507996, 31.827947431445427,
        31.84293281209126, 31.857918192737085, 31.87290357338291, 31.887888954028735, 31.90287433467456, 31.917859715320386, 31.93284509596621, 31.947830476612037, 31.96281585725787, 31.977801237903694,
        31.99278661854952, 32.00777199919534, 32.02275737984117, 32.03774276048699, 32.05272814113282, 32.06771352177864, 32.08269890242447, 32.0976842830703, 32.112669663716126, 32.12765504436195,
        32.14264042500778, 32.1576258056536, 32.17261118629943, 32.18759656694525, 32.20258194759108, 32.21756732823691, 32.232552708882736, 32.24753808952856, 32.262523470174386, 32.27750885082021,
        32.29249423146604, 32.30747961211186, 32.32246499275769, 32.33745037340351, 32.352435754049345, 32.36742113469517, 32.382406515340996, 32.39739189598682, 32.41237727663265, 32.42736265727847,
        32.4423480379243, 32.45733341857012, 32.47231879921595, 32.48730417986178, 32.502289560507606, 32.51727494115343, 32.53226032179926, 32.54724570244508, 32.56223108309091, 32.57721646373673,
        32.59220184438256, 32.60718722502839, 32.622172605674216, 32.63715798632004, 32.652143366965866, 32.66712874761169, 32.68211412825752, 32.69709950890334, 32.71208488954917, 32.72707027019499,
        32.742055650840825, 32.75704103148665, 32.772026412132476, 32.7870117927783, 32.80199717342413, 32.81698255406995, 32.83196793471578, 32.8469533153616, 32.86193869600743, 32.87692407665326,
        32.891909457299086, 32.90689483794491, 32.921880218590736, 32.93686559923656, 32.95185097988239, 32.96683636052821, 32.98182174117404, 32.99680712181987, 33.011792502465696, 33.02677788311152,
        33.041763263757346, 33.05674864440317, 33.071734025049, 33.08671940569482, 33.10170478634065, 33.11669016698647, 33.131675547632305, 33.14666092827813, 33.161646308923956, 33.17663168956978,
        33.19161707021561, 33.20660245086143, 33.22158783150726, 33.23657321215308, 33.251558592798915, 33.26654397344474, 33.281529354090566, 33.29651473473639, 33.311500115382216, 33.32648549602804,
        33.34147087667387, 33.35645625731969, 33.37144163796552, 33.38642701861135, 33.401412399257175, 33.416397779903, 33.431383160548826, 33.44636854119465, 33.46135392184048, 33.4763393024863,
        33.49132468313213, 33.50631006377795, 33.521295444423785, 33.53628082506961, 33.551266205715436, 33.56625158636126, 33.58123696700709, 33.59622234765291, 33.61120772829874, 33.62619310894456,
        33.641178489590395, 33.65616387023622, 33.671149250882046, 33.68613463152787, 33.701120012173696, 33.71610539281952, 33.73109077346535, 33.74607615411117, 33.761061534757, 33.77604691540283,
        33.791032296048655, 33.80601767669448, 33.821003057340306, 33.83598843798613, 33.85097381863196, 33.86595919927778, 33.88094457992361, 33.89592996056943, 33.910915341215265, 33.92590072186109,
        33.940886102506916, 33.95587148315274, 33.970856863798566, 33.98584224444439, 34.00082762509022, 34.01581300573604, 34.030798386381875, 34.0457837670277, 34.060769147673525, 34.07575452831935,
        34.090739908965176, 34.105725289611, 34.12071067025683, 34.13569605090265, 34.15068143154848, 34.16566681219431, 34.180652192840135, 34.19563757348596, 34.210622954131786, 34.22560833477761,
        34.24059371542344, 34.25557909606926, 34.27056447671509, 34.28554985736092, 34.300535238006745, 34.31552061865257, 34.330505999298396, 34.34549137994422, 34.360476760590046, 34.37546214123587,
        34.3904475218817, 34.40543290252752, 34.420418283173355, 34.43540366381918, 34.450389044465005, 34.46537442511083, 34.480359805756656, 34.49534518640248, 34.51033056704831, 34.52531594769413,
        34.54030132833996, 34.55528670898579, 34.570272089631615, 34.58525747027744, 34.600242850923266, 34.61522823156909, 34.63021361221492, 34.64519899286074, 34.66018437350657, 34.6751697541524,
        34.690155134798225, 34.70514051544405, 34.720125896089876, 34.7351112767357, 34.750096657381526, 34.76508203802735, 34.78006741867318, 34.795052799319, 34.810038179964835, 34.82502356061066,
        34.840008941256485, 34.85499432190231, 34.869979702548136, 34.88496508319396, 34.89995046383979, 34.91493584448561, 34.92992122513144, 34.94490660577727, 34.959891986423095, 34.97487736706892,
        34.989862747714746, 35.00484812836057, 35.019833509006396, 35.03481888965222, 35.04980427029805, 35.06478965094388, 35.079775031589705, 35.09476041223553, 35.109745792881355, 35.12473117352718,
        35.139716554173006, 35.15470193481883, 35.16968731546466, 35.18467269611048, 35.199658076756315, 35.21464345740214, 35.229628838047965, 35.24461421869379, 35.259599599339616, 35.27458497998544,
        35.28957036063127, 35.30455574127709, 35.319541121922924, 35.33452650256875, 35.349511883214575, 35.3644972638604, 35.379482644506226, 35.39446802515205, 35.409453405797876, 35.4244387864437,
        35.43942416708953, 35.45440954773536, 35.469394928381185, 35.48438030902701, 35.499365689672835, 35.51435107031866, 35.529336450964486, 35.54432183161031, 35.55930721225614, 35.57429259290196,
        35.589277973547794, 35.60426335419362, 35.619248734839445, 35.63423411548527, 35.649219496131096, 35.66420487677692, 35.679190257422746, 35.69417563806857, 35.709161018714404, 35.72414639936023,
        35.739131780006055, 35.75411716065188, 35.769102541297705, 35.78408792194353, 35.799073302589356, 35.81405868323518, 35.82904406388101, 35.84402944452684, 35.859014825172665, 35.87400020581849,
        35.888985586464315, 35.90397096711014, 35.918956347755966, 35.93394172840179, 35.94892710904762, 35.96391248969344, 35.978897870339274, 35.9938832509851, 36.008868631630925, 36.02385401227675,
        36.038839392922576, 36.0538247735684, 36.068810154214226, 36.08379553486005, 36.098780915505884, 36.11376629615171, 36.128751676797535, 36.14373705744336, 36.158722438089185, 36.17370781873501,
        36.188693199380836, 36.20367858002666, 36.21866396067249, 36.23364934131832, 36.248634721964144, 36.26362010260997, 36.278605483255795, 36.29359086390162, 36.308576244547446, 36.32356162519327,
        36.3385470058391, 36.35353238648493, 36.368517767130754, 36.38350314777658, 36.398488528422405, 36.41347390906823, 36.428459289714056, 36.44344467035988, 36.458430051005706, 36.47341543165153,
        36.488400812297364, 36.50338619294319, 36.518371573589015, 36.53335695423484, 36.548342334880665, 36.56332771552649, 36.578313096172316, 36.59329847681814, 36.60828385746397, 36.6232692381098,
        36.638254618755624, 36.65323999940145, 36.668225380047275, 36.6832107606931, 36.698196141338926, 36.71318152198475, 36.728166902630576, 36.74315228327641, 36.758137663922234, 36.77312304456806,
        36.788108425213885, 36.80309380585971, 36.818079186505535, 36.83306456715136, 36.848049947797186, 36.86303532844301, 36.878020709088844, 36.89300608973467, 36.907991470380495, 36.92297685102632,
        36.937962231672145, 36.95294761231797, 36.967932992963796, 36.98291837360962, 36.99790375425545, 37.01288913490128, 37.027874515547104, 37.04285989619293, 37.057845276838755, 37.07283065748458,
        37.087816038130406, 37.10280141877623, 37.117786799422056, 37.13277218006789, 37.147757560713714, 37.16274294135954, 37.177728322005365, 37.19271370265119, 37.207699083297015, 37.22268446394284,
        37.237669844588666, 37.25265522523449, 37.267640605880324, 37.28262598652615, 37.297611367171974, 37.3125967478178, 37.327582128463625, 37.34256750910945, 37.357552889755276, 37.3725382704011,
        37.38752365104693, 37.40250903169276, 37.417494412338584, 37.43247979298441, 37.447465173630235, 37.46245055427606, 37.477435934921886, 37.49242131556771, 37.507406696213536, 37.52239207685937,
        37.537377457505194, 37.55236283815102, 37.567348218796845, 37.58233359944267, 37.597318980088495, 37.61230436073432, 37.627289741380146, 37.64227512202597, 37.657260502671804, 37.67224588331763,
        37.687231263963454, 37.70221664460928, 37.717202025255105, 37.73218740590093, 37.747172786546756, 37.76215816719258, 37.77714354783841, 37.79212892848424, 37.807114309130064, 37.82209968977589,
        37.837085070421715, 37.85207045106754, 37.867055831713365, 37.88204121235919, 37.897026593005016, 37.91201197365085, 37.926997354296674, 37.9419827349425, 37.956968115588325, 37.97195349623415,
        37.986938876879975, 38.0019242575258, 38.016909638171626, 38.03189501881746, 38.046880399463284, 38.06186578010911, 38.076851160754934, 38.09183654140076, 38.106821922046585, 38.12180730269241,
        38.136792683338236, 38.15177806398406, 38.16676344462989, 38.18174882527572, 38.196734205921544, 38.21171958656737, 38.226704967213195, 38.24169034785902, 38.256675728504845, 38.27166110915067,
        38.286646489796496, 38.30163187044233, 38.316617251088154, 38.33160263173398, 38.346588012379804, 38.36157339302563, 38.376558773671455, 38.39154415431728, 38.406529534963106, 38.42151491560894,
        38.43650029625476, 38.45148567690059, 38.466471057546414, 38.48145643819224, 38.496441818838065, 38.51142719948389, 38.526412580129715, 38.54139796077554, 38.55638334142137, 38.5713687220672,
        38.586354102713024, 38.60133948335885, 38.616324864004675, 38.6313102446505, 38.646295625296325, 38.66128100594215, 38.676266386587976, 38.69125176723381, 38.706237147879634, 38.72122252852546,
        38.736207909171284, 38.75119328981711, 38.766178670462935, 38.78116405110876, 38.796149431754586, 38.81113481240042, 38.82612019304624, 38.84110557369207, 38.856090954337894, 38.87107633498372,
        38.886061715629545, 38.90104709627537, 38.916032476921195, 38.93101785756702, 38.94600323821285, 38.96098861885868, 38.975973999504504, 38.99095938015033, 39.005944760796154, 39.02093014144198,
        39.035915522087805, 39.05090090273363, 39.06588628337946, 39.08087166402529, 39.095857044671114, 39.11084242531694, 39.125827805962764, 39.14081318660859, 39.155798567254415, 39.17078394790024,
        39.185769328546066, 39.2007547091919, 39.21574008983772, 39.23072547048355, 39.245710851129374, 39.2606962317752, 39.275681612421025, 39.29066699306685, 39.305652373712675, 39.3206377543585,
        39.33562313500433, 39.35060851565016, 39.365593896295984, 39.38057927694181, 39.395564657587634, 39.41055003823346, 39.425535418879285, 39.44052079952511, 39.45550618017094, 39.47049156081677,
        39.48547694146259, 39.50046232210842, 39.515447702754244, 39.53043308340007, 39.545418464045895, 39.56040384469172, 39.575389225337545, 39.59037460598338, 39.6053599866292, 39.62034536727503,
        39.635330747920854, 39.65031612856668, 39.665301509212505, 39.68028688985833, 39.695272270504155, 39.71025765114998, 39.72524303179581, 39.74022841244164, 39.755213793087464, 39.77019917373329,
        39.785184554379114, 39.80016993502494, 39.815155315670765, 39.83014069631659, 39.84512607696242, 39.86011145760825, 39.87509683825407, 39.8900822188999, 39.905067599545724, 39.92005298019155,
        39.935038360837375, 39.9500237414832, 39.965009122129025, 39.97999450277486, 39.99497988342068, 40.00996526406651, 40.024950644712334, 40.03993602535816, 40.054921406003984, 40.06990678664981,
        40.084892167295635, 40.09987754794147, 40.11486292858729, 40.12984830923312, 40.14483368987894, 40.15981907052477, 40.174804451170594, 40.18978983181642, 40.204775212462245, 40.21976059310807,
        40.2347459737539, 40.24973135439973, 40.26471673504555, 40.27970211569138, 40.294687496337204, 40.30967287698303, 40.324658257628855, 40.33964363827468, 40.354629018920505, 40.36961439956634,
        40.38459978021216, 40.39958516085799, 40.414570541503814, 40.42955592214964, 40.444541302795464, 40.45952668344129, 40.474512064087115, 40.48949744473295, 40.50448282537877, 40.5194682060246,
        40.53445358667042, 40.54943896731625, 40.564424347962074, 40.5794097286079, 40.594395109253725, 40.60938048989955, 40.62436587054538, 40.63935125119121, 40.65433663183703, 40.66932201248286,
        40.684307393128684, 40.69929277377451, 40.714278154420334, 40.72926353506616, 40.744248915711985, 40.75923429635782, 40.77421967700364, 40.78920505764947, 40.804190438295294, 40.81917581894112,
        40.834161199586944, 40.84914658023277, 40.864131960878595, 40.87911734152443, 40.89410272217025, 40.90908810281608, 40.9240734834619, 40.93905886410773, 40.954044244753554, 40.96902962539938,
        40.984015006045205, 40.99900038669103, 41.01398576733686, 41.02897114798269, 41.04395652862851, 41.05894190927434, 41.073927289920164, 41.08891267056599, 41.103898051211814, 41.11888343185764,
        41.13386881250347, 41.1488541931493, 41.16383957379512, 41.17882495444095, 41.19381033508677, 41.2087957157326, 41.223781096378424, 41.23876647702425, 41.253751857670075, 41.26873723831591,
        41.28372261896173, 41.29870799960756, 41.31369338025338, 41.32867876089921, 41.343664141545034, 41.35864952219086, 41.373634902836685, 41.38862028348251, 41.40360566412834, 41.41859104477417,
        41.43357642541999, 41.44856180606582, 41.463547186711644, 41.47853256735747, 41.493517948003294, 41.50850332864912, 41.52348870929495, 41.53847408994078, 41.5534594705866, 41.56844485123243,
        41.58343023187825, 41.59841561252408, 41.613400993169904, 41.62838637381573, 41.643371754461555, 41.65835713510739, 41.67334251575321, 41.68832789639904, 41.70331327704486, 41.71829865769069,
        41.733284038336514, 41.74826941898234, 41.763254799628164, 41.77824018027399, 41.79322556091982, 41.80821094156565, 41.82319632221147, 41.8381817028573, 41.85316708350312, 41.86815246414895,
        41.883137844794774, 41.8981232254406, 41.91310860608643, 41.92809398673226, 41.94307936737808, 41.95806474802391, 41.97305012866973, 41.98803550931556, 42.003020889961384, 42.01800627060721,
        42.032991651253035, 42.04797703189887, 42.06296241254469, 42.07794779319052, 42.09293317383634, 42.10791855448217, 42.122903935127994, 42.13788931577382, 42.152874696419644, 42.16786007706548,
        42.1828454577113, 42.19783083835713, 42.21281621900295, 42.22780159964878, 42.2427869802946, 42.25777236094043, 42.272757741586254, 42.28774312223208, 42.30272850287791, 42.31771388352374,
        42.33269926416956, 42.34768464481539, 42.36267002546121, 42.37765540610704, 42.392640786752864, 42.40762616739869, 42.422611548044515, 42.43759692869035, 42.45258230933617, 42.467567689982,
        42.48255307062782, 42.49753845127365, 42.512523831919474, 42.5275092125653, 42.542494593211124, 42.55747997385696, 42.57246535450278, 42.58745073514861, 42.60243611579443, 42.61742149644026,
        42.63240687708608, 42.64739225773191, 42.662377638377734, 42.67736301902356, 42.69234839966939, 42.70733378031522, 42.72231916096104, 42.73730454160687, 42.75228992225269, 42.76727530289852,
        42.782260683544344, 42.79724606419017, 42.812231444835994, 42.82721682548183, 42.84220220612765, 42.85718758677348, 42.8721729674193, 42.88715834806513, 42.90214372871095, 42.91712910935678,
        42.932114490002604, 42.94709987064844, 42.96208525129426, 42.97707063194009, 42.99205601258591, 43.00704139323174, 43.02202677387756, 43.03701215452339, 43.051997535169214, 43.06698291581504,
        43.08196829646087, 43.0969536771067, 43.11193905775252, 43.12692443839835, 43.14190981904417, 43.15689519969, 43.171880580335824, 43.18686596098165, 43.20185134162748, 43.21683672227331,
        43.23182210291913, 43.24680748356496, 43.26179286421078, 43.27677824485661, 43.29176362550243, 43.30674900614826, 43.321734386794084, 43.33671976743992, 43.35170514808574, 43.36669052873157,
        43.38167590937739, 43.39666129002322, 43.41164667066904, 43.42663205131487, 43.441617431960694, 43.45660281260652, 43.47158819325235, 43.48657357389818, 43.501558954544, 43.51654433518983,
        43.53152971583565, 43.54651509648148, 43.561500477127304, 43.57648585777313, 43.59147123841896, 43.60645661906479, 43.62144199971061, 43.63642738035644, 43.65141276100226, 43.66639814164809,
        43.68138352229391, 43.69636890293974, 43.711354283585564, 43.726339664231396, 43.74132504487722, 43.75631042552305, 43.77129580616887, 43.7862811868147, 43.80126656746052, 43.81625194810635,
        43.831237328752174, 43.846222709398, 43.86120809004383, 43.87619347068966, 43.89117885133548, 43.90616423198131, 43.92114961262713, 43.93613499327296, 43.95112037391878, 43.96610575456461,
        43.98109113521044, 43.99607651585627, 44.01106189650209, 44.02604727714792, 44.04103265779374, 44.05601803843957, 44.07100341908539, 44.08598879973122, 44.100974180377044, 44.115959561022876,
        44.1309449416687, 44.14593032231453, 44.16091570296035, 44.17590108360618, 44.190886464252, 44.20587184489783, 44.220857225543654, 44.235842606189486, 44.25082798683531, 44.26581336748114,
        44.28079874812696, 44.29578412877279, 44.31076950941861, 44.32575489006444, 44.34074027071026, 44.35572565135609, 44.37071103200192, 44.38569641264775, 44.40068179329357, 44.4156671739394,
        44.43065255458522, 44.44563793523105, 44.46062331587687, 44.4756086965227, 44.490594077168524, 44.505579457814356, 44.52056483846018, 44.53555021910601, 44.55053559975183, 44.56552098039766,
        44.58050636104348, 44.59549174168931, 44.61047712233513, 44.625462502980966, 44.64044788362679, 44.65543326427262, 44.67041864491844, 44.68540402556427, 44.70038940621009, 44.71537478685592,
        44.73036016750174, 44.74534554814757, 44.7603309287934, 44.775316309439226, 44.79030169008505, 44.80528707073088, 44.8202724513767, 44.83525783202253, 44.85024321266835, 44.86522859331418,
        44.880213973960004, 44.895199354605836, 44.91018473525166, 44.92517011589749, 44.94015549654331, 44.95514087718914, 44.97012625783496, 44.98511163848079, 45.00009701912661, 45.015082399772446,
        45.03006778041827, 45.0450531610641, 45.06003854170992, 45.07502392235575, 45.09000930300157, 45.1049946836474, 45.11998006429322, 45.13496544493905, 45.14995082558488, 45.164936206230706,
        45.17992158687653, 45.19490696752236, 45.20989234816818, 45.22487772881401, 45.23986310945983, 45.25484849010566, 45.26983387075149, 45.284819251397316, 45.29980463204314, 45.31479001268897,
        45.32977539333479, 45.34476077398062, 45.35974615462644, 45.37473153527227, 45.38971691591809, 45.404702296563926, 45.41968767720975, 45.434673057855576, 45.4496584385014, 45.46464381914723,
        45.47962919979305, 45.49461458043888, 45.5095999610847, 45.52458534173053, 45.53957072237636, 45.554556103022186, 45.56954148366801, 45.58452686431384, 45.59951224495966, 45.61449762560549,
        45.62948300625131, 45.64446838689714, 45.65945376754297, 45.674439148188796, 45.68942452883462, 45.70440990948045, 45.71939529012627, 45.7343806707721, 45.74936605141792, 45.76435143206375,
        45.77933681270957, 45.794322193355406, 45.80930757400123, 45.824292954647056, 45.83927833529288, 45.85426371593871, 45.86924909658453, 45.88423447723036, 45.89921985787618, 45.91420523852201,
        45.92919061916784, 45.944175999813666, 45.95916138045949, 45.97414676110532, 45.98913214175114, 46.00411752239697, 46.01910290304279, 46.03408828368862, 46.04907366433445, 46.064059044980276,
        46.0790444256261, 46.09402980627193, 46.10901518691775, 46.12400056756358, 46.1389859482094, 46.15397132885523, 46.16895670950105, 46.183942090146886, 46.19892747079271, 46.213912851438536,
        46.22889823208436, 46.24388361273019, 46.25886899337601, 46.27385437402184, 46.28883975466766, 46.303825135313495, 46.31881051595932, 46.333795896605146, 46.34878127725097, 46.3637666578968,
        46.37875203854262, 46.39373741918845, 46.40872279983427, 46.4237081804801, 46.43869356112593, 46.453678941771756, 46.46866432241758, 46.483649703063406, 46.49863508370923, 46.51362046435506,
        46.52860584500088, 46.54359122564671, 46.55857660629253, 46.573561986938365, 46.58854736758419, 46.603532748230016, 46.61851812887584, 46.63350350952167, 46.64848889016749, 46.66347427081332,
        46.67845965145914, 46.693445032104975, 46.7084304127508, 46.723415793396626, 46.73840117404245, 46.75338655468828, 46.7683719353341, 46.78335731597993, 46.79834269662575, 46.81332807727158,
        46.82831345791741, 46.843298838563236, 46.85828421920906, 46.873269599854886, 46.88825498050071, 46.90324036114654, 46.91822574179236, 46.93321112243819, 46.94819650308402, 46.963181883729845,
        46.97816726437567, 46.993152645021496, 47.00813802566732, 47.02312340631315, 47.03810878695897, 47.0530941676048, 47.06807954825062, 47.083064928896455, 47.09805030954228, 47.113035690188106,
        47.12802107083393, 47.143006451479756, 47.15799183212558, 47.17297721277141, 47.18796259341723, 47.20294797406306, 47.21793335470889, 47.232918735354716, 47.24790411600054, 47.262889496646366,
        47.27787487729219, 47.29286025793802, 47.30784563858384, 47.32283101922967, 47.3378163998755, 47.352801780521325, 47.36778716116715, 47.382772541812976, 47.3977579224588, 47.41274330310463,
        47.42772868375045, 47.44271406439628, 47.4576994450421, 47.472684825687935, 47.48767020633376, 47.502655586979586, 47.51764096762541, 47.532626348271236, 47.54761172891706, 47.56259710956289,
        47.57758249020871, 47.59256787085454, 47.60755325150037, 47.622538632146195, 47.63752401279202, 47.652509393437846, 47.66749477408367, 47.6824801547295, 47.69746553537532, 47.71245091602115,
        47.72743629666698, 47.742421677312805, 47.75740705795863, 47.772392438604456, 47.78737781925028, 47.80236319989611, 47.81734858054193, 47.83233396118776, 47.84731934183358, 47.862304722479415,
        47.87729010312524, 47.892275483771066, 47.90726086441689, 47.922246245062716, 47.93723162570854, 47.95221700635437, 47.96720238700019, 47.982187767646025, 47.99717314829185, 48.012158528937675,
        48.0271439095835, 48.042129290229326, 48.05711467087515, 48.07210005152098, 48.0870854321668, 48.10207081281263, 48.11705619345846, 48.132041574104285, 48.14702695475011, 48.162012335395936,
        48.17699771604176, 48.191983096687586, 48.20696847733341, 48.22195385797924, 48.23693923862506, 48.251924619270895, 48.26690999991672, 48.281895380562545, 48.29688076120837, 48.311866141854196,
        48.32685152250002, 48.34183690314585, 48.35682228379167, 48.371807664437505, 48.38679304508333, 48.401778425729155, 48.41676380637498, 48.431749187020806, 48.44673456766663, 48.46171994831246,
        48.47670532895828, 48.49169070960411, 48.50667609024994, 48.521661470895765, 48.53664685154159, 48.551632232187416, 48.56661761283324, 48.581602993479066, 48.59658837412489, 48.61157375477072,
        48.62655913541654, 48.641544516062375, 48.6565298967082, 48.671515277354025, 48.68650065799985, 48.701486038645676, 48.7164714192915, 48.73145679993733, 48.74644218058315, 48.761427561228984,
        48.77641294187481, 48.791398322520635, 48.80638370316646, 48.821369083812286, 48.83635446445811, 48.85133984510394, 48.86632522574976, 48.88131060639559, 48.89629598704142, 48.911281367687245,
        48.92626674833307, 48.941252128978896, 48.95623750962472, 48.971222890270546, 48.98620827091637, 49.0011936515622, 49.01617903220803, 49.031164412853855, 49.04614979349968, 49.061135174145505,
        49.07612055479133, 49.091105935437156, 49.10609131608298, 49.12107669672881, 49.13606207737463, 49.151047458020464, 49.16603283866629, 49.181018219312115, 49.19600359995794, 49.210988980603766,
        49.22597436124959, 49.240959741895416, 49.25594512254124, 49.27093050318707, 49.2859158838329, 49.300901264478725, 49.31588664512455, 49.330872025770375, 49.3458574064162, 49.360842787062026,
        49.37582816770785, 49.39081354835368, 49.40579892899951, 49.420784309645335, 49.43576969029116, 49.450755070936985, 49.46574045158281, 49.480725832228636, 49.49571121287446, 49.51069659352029,
        49.52568197416611, 49.540667354811944, 49.55565273545777, 49.570638116103595, 49.58562349674942, 49.600608877395246, 49.61559425804107, 49.630579638686896, 49.64556501933272, 49.66055039997855,
        49.67553578062438, 49.690521161270205, 49.70550654191603, 49.720491922561855, 49.73547730320768, 49.750462683853506, 49.76544806449933, 49.78043344514516, 49.79541882579099, 49.810404206436814,
        49.82538958708264, 49.840374967728465, 49.85536034837429, 49.870345729020116, 49.88533110966594, 49.900316490311766, 49.91530187095759, 49.930287251603424, 49.94527263224925, 49.960258012895075,
        49.9752433935409, 49.990228774186726, 50.00521415483255, 50.020199535478376, 50.0351849161242, 50.050170296770034, 50.06515567741586, 50.080141058061685, 50.09512643870751, 50.110111819353335,
        50.12509719999916, 50.140082580644986, 50.15506796129081, 50.17005334193664, 50.18503872258247, 50.200024103228294, 50.21500948387412, 50.229994864519945, 50.24498024516577, 50.259965625811596,
        50.27495100645742, 50.289936387103246, 50.30492176774907, 50.319907148394904, 50.33489252904073, 50.349877909686555, 50.36486329033238, 50.379848670978205, 50.39483405162403, 50.409819432269856,
        50.42480481291568, 50.439790193561514, 50.45477557420734, 50.469760954853164, 50.48474633549899, 50.499731716144815, 50.51471709679064, 50.529702477436466, 50.54468785808229, 50.55967323872812,
        50.57465861937395, 50.589644000019774, 50.6046293806656, 50.619614761311425, 50.63460014195725, 50.649585522603076, 50.6645709032489, 50.679556283894726, 50.69454166454055, 50.709527045186384,
        50.72451242583221, 50.739497806478035, 50.75448318712386, 50.769468567769685, 50.78445394841551, 50.799439329061336, 50.81442470970716, 50.829410090352994, 50.84439547099882, 50.859380851644644,
        50.87436623229047, 50.889351612936295, 50.90433699358212, 50.919322374227946, 50.93430775487377, 50.949293135519596, 50.96427851616543, 50.979263896811254, 50.99424927745708, 51.009234658102905,
        51.02422003874873, 51.039205419394555, 51.05419080004038, 51.069176180686206, 51.08416156133204, 51.099146941977864, 51.11413232262369, 51.129117703269515, 51.14410308391534, 51.159088464561165,
        51.17407384520699, 51.189059225852816, 51.20404460649864, 51.219029987144474, 51.2340153677903, 51.249000748436124, 51.26398612908195, 51.278971509727775, 51.2939568903736, 51.308942271019426,
        51.32392765166525, 51.338913032311076, 51.35389841295691, 51.368883793602734, 51.38386917424856, 51.398854554894385, 51.41383993554021, 51.428825316186035, 51.44381069683186, 51.458796077477686,
        51.47378145812352, 51.488766838769344, 51.50375221941517, 51.518737600060994, 51.53372298070682, 51.548708361352645, 51.56369374199847, 51.578679122644296, 51.59366450329012, 51.60864988393595,
        51.62363526458178, 51.638620645227604, 51.65360602587343, 51.668591406519255, 51.68357678716508, 51.698562167810906, 51.71354754845673, 51.728532929102556, 51.74351830974839, 51.758503690394214,
        51.77348907104004, 51.788474451685865, 51.80345983233169, 51.818445212977515, 51.83343059362334, 51.848415974269166, 51.863401354915, 51.878386735560824, 51.89337211620665, 51.908357496852474,
        51.9233428774983, 51.938328258144125, 51.95331363878995, 51.968299019435776, 51.9832844000816, 51.99826978072743, 52.01325516137326, 52.028240542019084, 52.04322592266491, 52.058211303310735,
        52.07319668395656, 52.088182064602385, 52.10316744524821, 52.11815282589404, 52.13313820653987, 52.148123587185694, 52.16310896783152, 52.178094348477345, 52.19307972912317, 52.208065109768995,
        52.22305049041482, 52.238035871060646, 52.25302125170648, 52.268006632352304, 52.28299201299813, 52.297977393643954, 52.31296277428978, 52.327948154935605, 52.34293353558143, 52.357918916227256,
        52.37290429687308, 52.38788967751891, 52.40287505816474, 52.417860438810564, 52.43284581945639, 52.447831200102215, 52.46281658074804, 52.477801961393865, 52.49278734203969, 52.50777272268552,
        52.52275810333135, 52.537743483977174, 52.552728864623, 52.567714245268824, 52.58269962591465, 52.597685006560475, 52.6126703872063, 52.627655767852126, 52.64264114849796, 52.65762652914378,
        52.67261190978961, 52.687597290435434, 52.70258267108126, 52.717568051727085, 52.73255343237291, 52.747538813018735, 52.76252419366456, 52.77750957431039, 52.79249495495622, 52.807480335602044,
        52.82246571624787, 52.837451096893695, 52.85243647753952, 52.867421858185345, 52.88240723883117, 52.897392619477, 52.91237800012283, 52.927363380768654, 52.94234876141448, 52.957334142060304,
        52.97231952270613, 52.987304903351955, 53.00229028399778, 53.017275664643606, 53.03226104528944, 53.04724642593526, 53.06223180658109, 53.077217187226914, 53.09220256787274, 53.107187948518565,
        53.12217332916439, 53.137158709810215, 53.15214409045605, 53.16712947110187, 53.1821148517477, 53.197100232393524, 53.21208561303935, 53.227070993685174, 53.242056374331, 53.257041754976825,
        53.27202713562265, 53.28701251626848, 53.30199789691431, 53.316983277560134, 53.33196865820596, 53.346954038851784, 53.36193941949761, 53.376924800143435, 53.39191018078926, 53.406895561435086,
        53.42188094208092, 53.43686632272674, 53.45185170337257, 53.466837084018394, 53.48182246466422, 53.496807845310045, 53.51179322595587, 53.526778606601695, 53.54176398724753, 53.55674936789335,
        53.57173474853918, 53.586720129185004, 53.60170550983083, 53.616690890476654, 53.63167627112248, 53.646661651768305, 53.66164703241413, 53.67663241305996, 53.69161779370579, 53.70660317435161,
        53.72158855499744, 53.736573935643264, 53.75155931628909, 53.766544696934915, 53.78153007758074, 53.796515458226565, 53.8115008388724, 53.82648621951822, 53.84147160016405, 53.856456980809874,
        53.8714423614557, 53.886427742101525, 53.90141312274735, 53.916398503393175, 53.93138388403901, 53.94636926468483, 53.96135464533066, 53.976340025976484, 53.99132540662231, 54.006310787268134,
        54.02129616791396, 54.036281548559785, 54.05126692920561, 54.06625230985144, 54.08123769049727, 54.09622307114309, 54.11120845178892, 54.126193832434744, 54.14117921308057, 54.156164593726395,
        54.17114997437222, 54.18613535501805, 54.20112073566388, 54.2161061163097, 54.23109149695553, 54.246076877601354, 54.26106225824718, 54.276047638893004, 54.29103301953883, 54.306018400184655,
        54.32100378083049, 54.33598916147631, 54.35097454212214, 54.36595992276796, 54.38094530341379, 54.395930684059614, 54.41091606470544, 54.425901445351265, 54.44088682599709, 54.45587220664292,
        54.47085758728875, 54.48584296793457, 54.5008283485804, 54.515813729226224, 54.53079910987205, 54.545784490517875, 54.5607698711637, 54.57575525180953, 54.59074063245536, 54.60572601310118,
        54.62071139374701, 55.686742183736, 55.71188645672267, 55.73701982104315, 55.76214227669739, 55.787253823685475, 55.81235446200732, 55.83744419166301, 55.86252301265249, 55.88759092497578,
        55.912647928632865, 55.937694023623735, 55.96272920994845, 55.98775348760695, 56.01276685659927, 56.03776931692535, 56.06276086858527, 56.08774151157898, 56.11271124590651, 56.137670071567854,
        56.162617988562985, 56.187554996891876, 56.21248109655461, 56.237396287551164, 56.2623005698815, 56.28719394354566, 56.312076408543604, 56.336947964875364, 56.36180861254091, 56.38665835154028,
        56.41149718187346, 56.436325103540426, 56.46114211654118, 56.48594822087573, 56.510743416544116, 56.53552770354632, 56.560301081882315, 56.585063551552096, 56.609815112555694, 56.63455576489308,
        56.65928550856428, 56.6840043435693, 56.70871226990813, 56.73340928758073, 56.75809539658714, 56.782770596927364, 56.807434888601406, 56.83208827160924, 56.856730745950856, 56.88136231162629,
        56.90598296863551, 56.93059271697858, 56.955191556655436, 56.97977948766611, 57.00435651001054, 57.028922623688786, 57.05347782870085, 57.07802212504673, 57.1025555127264, 57.12707799173988,
        57.15158956208715, 57.17609022376821, 57.20057997678312, 57.22505882113181, 57.24952675681432, 57.273983783830616, 57.2984299021807, 57.3228651118646, 57.34728941288232, 57.37170280523385,
        57.39610528891917, 57.42049686393828, 57.44487753029118, 57.46924728797792, 57.49360613699845, 57.5179540773528, 57.54229110904093, 57.56661723206285, 57.59093244641862, 57.615236752108174,
        57.639530149131545, 57.663812637488704, 57.68808421717965, 57.71234488820441, 57.73659465056299, 57.76083350425536, 57.78506144928157, 57.809278485641514, 57.83348461333533, 57.857679832362905,
        57.8818641427243, 57.906037544419505, 57.9302000374485, 57.95435162181131, 57.978492297507884, 58.00262206453833, 58.02674092290253, 58.05084887260058, 58.07494591363239, 58.099032045998015,
        58.12310726969743, 58.147171584730685, 58.17122499109773, 58.195267488798564, 58.21929907783321, 58.24331975820165, 58.267329529903904, 58.291328392939974, 58.31531634730983, 58.33929339301348,
        58.36325953005097, 58.38721475842222, 58.41115907812731, 58.435092489166195, 58.45901499153889, 58.48292658524538, 58.506827270285655, 58.530717046659746, 58.55459591436765, 58.578463873409376,
        58.60232092378486, 58.62616706549419, 58.650002298537274, 58.673826622914206, 58.697640038624954, 58.72144254566949, 58.745234144047785, 58.769014833759925, 58.79278461480585, 58.816543487185626,
        58.84029145089916, 58.86402850594651, 58.88775465232767, 58.911469890042596, 58.935174219091365, 58.95886763947395, 58.982550151190324, 59.006221754240485, 59.029882448624434, 59.05353223434223,
        59.07717111139384, 59.100799079779236, 59.12441613949839, 59.148022290551395, 59.171617532938185, 59.19520186665879, 59.218775291713214, 59.242337808101425, 59.26588941582342, 59.28943011487924,
        59.31295990526887, 59.33647878699229, 59.35998676004955, 59.383483824440574, 59.406969980165414, 59.43044522722401, 59.453909565616485, 59.477362995342745, 59.50080551640279, 59.52423712879663,
        59.54765783252428, 59.57106762758575, 59.59446651398103, 59.617854491710105, 59.641231560772994, 59.66459772116967, 59.687952972900135, 59.711297315964416, 59.73463075036251, 59.757953276094426,
        59.7812648931601, 59.80456560155962, 59.82785540129292, 59.851134292360015, 59.87440227476095, 59.89765934849568, 59.92090551356419, 59.944140769966495, 59.96736511770264, 59.990578556772576,
        60.013781087176326, 60.03697270891384, 60.06015342198519, 60.083323226390334, 60.10648212212929, 60.12963010920204, 60.15276718760863, 60.175893357348954, 60.19900861842312, 60.222112970831105,
        60.24520641457288, 60.268288949648465, 60.29136057605784, 60.31442129380103, 60.33747110287801, 60.36051000328881, 60.38353799503342, 60.40655507811182, 60.42956125252401, 60.452556518269986,
        60.47554087534981, 60.498514323763445, 60.52147686351087, 60.54442849459208, 60.56736921700711, 60.59029903075593, 60.61321793583859, 60.63612593225504, 60.65902302000528, 60.681909199089304,
        60.704784469507146, 60.727648831258804, 60.75050228434428, 60.77334482876354, 60.79617646451659, 60.818997191603486, 60.84180701002414, 60.86460591977861, 60.8873939208669, 60.910171013289,
        60.932937197044865, 60.955692472134544, 60.97843683855807, 61.00117029631538, 61.02389284540648, 61.04660448583137, 61.0693052175901, 61.09199504068259, 61.11467395510893, 61.13734196086905,
        61.15999905796299, 61.18264524639069, 61.20528052615221, 61.22790489724757, 61.25051835967672, 61.27312091343968, 61.29571255853644, 61.31829329496698, 61.34086312273131, 61.36342204182948,
        61.38597005226147, 61.40850715402725, 61.431033347126785, 61.45354863156014, 61.47605300732734, 61.49854647442832, 61.521029032863126, 61.543500682631745, 61.56596142373412, 61.58841125617029,
        61.6108501799403, 61.63327819504413, 61.65569530148174, 61.678101499253145, 61.700496788358365, 61.72288116879737, 61.745254640570195, 61.767617203676835, 61.78996885811729, 61.81230960389151,
        61.83463944099954, 61.85695836944139, 61.87926638921705, 61.9015635003265, 61.92384970276974, 61.94612499654683, 61.96838938165767, 61.99064285810233, 62.01288542588081, 62.035117084993104,
        62.05733783543916, 62.079547677219026, 62.10174661033274, 62.12393463478024, 62.14611175056153, 62.16827795767661, 62.19043325612553, 62.21257764590821, 62.23471112702474, 62.25683369947505,
        62.27894536325918, 62.3010461183771, 62.32313596482881, 62.34521490261433, 62.36728293173367, 62.389340052186824, 62.41138626397374, 62.4334215670945, 62.45544596154902, 62.47745944733738,
        62.49946202445956, 62.52145369291553, 62.543434452705284, 62.56540430382883, 62.58736324628619, 62.60931128007739, 62.631248405202385, 62.65317462166114, 62.67508992945373, 62.69699432858012,
        62.71888781904032, 62.74077040083431, 62.76264207396214, 62.784502838423705, 62.806352694219115, 62.82819164134834, 62.85001967981135, 62.87183680960818, 62.8936430307388, 62.915438343203235,
        62.93722274700143, 62.958996242133495, 62.98075882859935, 63.00251050639899, 63.02425127553242, 63.04598113599964, 63.0677000878007, 63.08940813093558, 63.11110526540425, 63.13279149120673,
        63.154466808342974, 63.17613121681303, 63.19778471661694, 63.21942730775463, 63.24105899022611, 63.262679764031375, 63.28428962917046, 63.30588858564336, 63.327476633450075, 63.34905377259058,
        63.3706200030649, 63.39217532487301, 63.413719738014905, 63.43525324249062, 63.456775838300146, 63.47828752544349, 63.499788303920596, 63.521278173731545, 63.54275713487628, 63.564225187354836,
        63.58568233116718, 63.607128566313335, 63.62856389279328, 63.649988310607014, 63.67140181975459, 63.69280442023596, 63.71419611205114, 63.73557689520008, 63.75694676968287, 63.778305735499444,
        63.799653792649835, 63.82099094113404, 63.84231718095204, 63.86363251210382, 63.88493693458939, 63.906230448408806, 63.92751305356204, 63.94878475004906, 63.97004553786984, 63.99129541702446,
        64.01253438751287, 64.0337624493351, 64.05497960249114, 64.076185846981, 64.09738118280463, 64.11856560996203, 64.13973912845329, 64.16090173827835, 64.18205343943721, 64.20319423192986,
        64.22432411575632, 64.24544309091657, 64.26655115741063, 64.28764831523851, 64.30873456440021, 64.32980990489567, 64.35087433672494, 64.37192785988803, 64.39297047438494, 64.41400218021563,
        64.43502297738011, 64.45603286587844, 64.47703184571053, 64.49801991687643, 64.51899707937618, 64.53996333320968, 64.560918678377, 64.58186311487809, 64.60279664271305, 64.62371926188179,
        64.64463097238432, 64.66553177422064, 64.6864216673908, 64.70730065189473, 64.7281687277325, 64.74902589490405, 64.76987215340942, 64.79070750324858, 64.81153194442153, 64.8323454769283,
        64.8531481007689, 64.87393981594327, 64.89472062245146, 64.91549052029343, 64.93624950946922, 64.95699758997883, 64.97773476182222, 64.99846102499943, 65.01917637951043, 65.03988082535521,
        65.06057436253384, 65.08125699104626, 65.1019287108925, 65.12258952207252, 65.14323942458633, 65.16387841843395, 65.1845065036154, 65.20512368013065, 65.2257299479797, 65.24632530716254,
        65.26690975767919, 65.28748329952963, 65.30804593271388, 65.32859765723195, 65.34913847308384, 65.36966838026949, 65.39018737878895, 65.41069546864223, 65.43119264982933, 65.45167892235021,
        65.47215428620488, 65.4926187413934, 65.51307228791568, 65.53351492577177, 65.5539466549617, 65.5743674754854, 65.59477738734292, 65.61517639053419, 65.63556448505933, 65.65594167091827,
        65.67630794811099, 65.69666331663753, 65.71700777649785, 65.73734132769196, 65.75766397021992, 65.7779757040817, 65.79827652927726, 65.81856644580658, 65.83884545366972, 65.8591135528667,
        65.87937074339747, 65.89961702526206, 65.9198523984604, 65.9400768629926, 65.96029041885855, 65.98049306605834, 66.00068480459196, 66.02086563445935, 66.04103555566051, 66.06119456819549,
        66.08134267206431, 66.10147986726695, 66.12160615380334, 66.14172153167355, 66.16182600087758, 66.18191956141537, 66.20200221328703, 66.22207395649245, 66.24213479103169, 66.26218471690471,
        66.28222373411155, 66.30225184265221, 66.32226904252666, 66.34227533373492, 66.36227071627697, 66.38225519015283, 66.40222875536249, 66.42219141190596, 66.44214315978324, 66.46208399899432,
        66.48201392953918, 66.50193295141786, 66.52184106463035, 66.54173826917663, 66.56162456505676, 66.58149995227065, 66.60136443081835, 66.62121800069981, 66.64106066191515, 66.66089241446427,
        66.68071325834721, 66.70052319356391, 66.7203222201144, 66.74011033799876, 66.75988754721688, 66.77965384776884, 66.7994092396546, 66.8191537228741, 66.83888729742743, 66.8586099633146,
        66.87832172053557, 66.89802256909034, 66.91771250897888, 66.93739154020126, 66.9570596627574, 66.97671687664739, 66.99636318187119, 67.01599857842878, 67.03562306632016, 67.05523664554532,
        67.07483931610433, 67.09443107799713, 67.11401193122374, 67.13358187578412, 67.15314091167834, 67.17268903890634, 67.19222625746816, 67.2117525673638, 67.23126796859323, 67.25077246115644,
        67.27026604505345, 67.2897487202843, 67.30922048684896, 67.32868134474741, 67.34813129397962, 67.3675703345457, 67.38699846644552, 67.4064156896792, 67.42582200424665, 67.44521741014795,
        67.464601907383, 67.48397549595184, 67.50333817585452, 67.52268994709102, 67.54203080966131, 67.56136076356539, 67.58067980880331, 67.59998794537496, 67.61928517328049, 67.6385714925198,
        67.65784690309293, 67.67711140499982, 67.6963649982405, 67.71560768281505, 67.73483945872336, 67.75406032596551, 67.77327028454143, 67.79246933445116, 67.81165747569467, 67.83083470827204,
        67.85000103218319, 67.86915644742815, 67.88830095400688, 67.90743455191942, 67.92655724116581, 67.94566902174599, 67.96476989365995, 67.9838598569077, 68.0029389114893, 68.02200705740465,
        68.04106429465385, 68.06011062323684, 68.07914604315364, 68.09817055440423, 68.11718415698861, 68.13618685090681, 68.15517863615885, 68.17415951274465, 68.1931294806643, 68.21208853991767,
        68.2310366905049, 68.24997393242593, 68.26890026568076, 68.2878156902694, 68.30672020619183, 68.32561381344804, 68.3444965120381, 68.36336830196196, 68.38222918321962, 68.4010791558111,
        68.41991821973635, 68.43874637499538, 68.45756362158825, 68.47636995951494, 68.49516538877542, 68.51394990936969, 68.53272352129777, 68.55148622455964, 68.57023801915535, 68.58897890508486,
        68.60770888234815, 68.62642795094526, 68.64513611087612, 68.66383336214086, 68.68251970473936, 68.70119513867171, 68.71985966393781, 68.73851328053773, 68.75715598847144, 68.77578778773899,
        68.79440867834033, 68.81301866027549, 68.8316177335444, 68.85020589814714, 68.86878315408372, 68.88734950135408, 68.90590493995823, 68.92444946989617, 68.94298309116796, 68.9615058037735,
        68.9800176077129, 68.9985185029861, 69.0170084895931, 69.03548756753385, 69.05395573680842, 69.07241299741683, 69.09085934935904, 69.10929479263505, 69.12771932724483, 69.14613295318846,
        69.16453567046584, 69.18292747907707, 69.20130837902211, 69.21967837030094, 69.23803745291356, 69.25638562685997, 69.27472289214019, 69.29304924875426, 69.31136469670211, 69.32966923598373,
        69.34796286659919, 69.36624558854844, 69.3845174018315, 69.40277830644838, 69.42102830239905, 69.43926738968351, 69.45749556830175, 69.47571283825384, 69.49391919953975, 69.51211465215944,
        69.53029919611289, 69.54847283140019, 69.56663555802128, 69.5847873759762, 69.6029282852649, 69.62105828588743, 69.63917737784372, 69.6572855611338, 69.67538283575773, 69.69346920171547,
        69.711544659007, 69.72960920763235, 69.74766284759149, 69.76570557888438, 69.78373740151115, 69.8017583154717, 69.81976832076607, 69.8377674173942, 69.85575560535612, 69.87373288465191,
        69.89169925528147, 69.90965471724486, 69.92759927054205, 69.94553291517299, 69.96345565113775, 69.98136747843635, 69.99926839706875, 70.01715840703496, 70.03503750833492, 70.05290570096874,
        70.07076298493634, 70.08860936023775, 70.10644482687296, 70.12426938484198, 70.14208303414479, 70.15988577478139, 70.17767760675183, 70.19545853005606, 70.2132285446941, 70.23098765066594,
        70.24873584797159, 70.266473136611, 70.28419951658428, 70.30191498789132, 70.31961955053221, 70.33731320450683, 70.35499594981529, 70.37266778645757, 70.39032871443364, 70.40797873374352,
        70.42561784438719, 70.44324604636468, 70.46086333967595, 70.47846972432104, 70.49606520029995, 70.51364976761268, 70.53122342625916, 70.54878617623943, 70.56633801755355, 70.58387895020148,
        70.6014089741832, 70.61892808949871, 70.63643629614803, 70.65393359413115, 70.6714199834481, 70.68889546409885, 70.70636003608341, 70.72381369940173, 70.74125645405384, 70.75868830003982,
        70.77610923735959, 70.79351926601315, 70.8109183860005, 70.82830659732168, 70.84568389997663, 70.8630502939654, 70.88040577928801, 70.89775035594438, 70.91508402393457, 70.93240678325851,
        70.94971863391633, 70.96701957590794, 70.98430960923336, 71.00158873389255, 71.01885694988555, 71.03611425721236, 71.05336065587296, 71.07059614586741, 71.08782072719565, 71.10503439985764,
        71.12223716385346, 71.13942901918311, 71.15660996584656, 71.17378000384382, 71.19093913317487, 71.2080873538397, 71.22522466583833, 71.2423510691708, 71.25946656383708, 71.27657114983715,
        71.29366482717101, 71.31074759583866, 71.32781945584016, 71.34488040717544, 71.36193044984454, 71.37896958384745, 71.3959978091841, 71.41301512585459, 71.43002153385889, 71.44701703319701,
        71.46400162386892, 71.48097530587462, 71.49793807921414, 71.51488994388744, 71.53183089989459, 71.54876094723552, 71.56568008591027, 71.58258831591878, 71.59948563726108, 71.61637204993725,
        71.63324755394721, 71.65011214929096, 71.6669658359685, 71.68380861397988, 71.70064048332502, 71.71746144400397, 71.73427149601677, 71.75107063936336, 71.76785887404371, 71.78463620005788,
        71.80140261740586, 71.81815812608765, 71.83490272610327, 71.85163641745264, 71.86835920013586, 71.88507107415283, 71.90177203950365, 71.91846209618826, 71.93514124420669, 71.9518094835589,
        71.9684668142449, 71.98511323626472, 72.00174874961839, 72.01837335430581, 72.03498705032705, 72.0515898376821, 72.06818171637092, 72.08476268639357, 72.10133274775005, 72.11789190044031,
        72.13444014446434, 72.1509774798222, 72.16750390651386, 72.18401942453936, 72.20052403389865, 72.21701773459169, 72.23350052661858, 72.24997240997926, 72.26643338467376, 72.28288345070207,
        72.29932260806417, 72.31575085676006, 72.33216819678974, 72.34857462815326, 72.3649701508506, 72.38135476488172, 72.3977284702466, 72.41409126694536, 72.43044315497785, 72.44678413434421,
        72.46311420504436, 72.4794333670783, 72.49574162044603, 72.51203896514757, 72.52832540118293, 72.54460092855207, 72.56086554725506, 72.57711925729184, 72.59336205866238, 72.60959395136673,
        72.6258149354049, 72.64202501077689, 72.6582241774827, 72.67441243552226, 72.69058978489564, 72.70675622560283, 72.72291175764384, 72.73905638101867, 72.75519009572729, 72.77131290176966,
        72.78742479914585, 72.80352578785589, 72.81961586789971, 72.83569503927735, 72.85176330198875, 72.867820656034, 72.883867101413, 72.89990263812585, 72.91592726617252, 72.93194098555297,
        72.94794379626721, 72.96393569831524, 72.97991669169711, 72.99588677641277, 73.01184595246225, 73.02779421984552, 73.0437315785626, 73.05965802861344, 73.07557356999816, 73.09147820271663,
        73.10737192676895, 73.12325474215503, 73.13912664887489, 73.1549876469286, 73.17083773631613, 73.18667691703745, 73.20250518909252, 73.21832255248147, 73.23412900720415, 73.2499245532607,
        73.26570919065104, 73.28148291937516, 73.29724573943308, 73.31299765082481, 73.32873865355036, 73.34446874760972, 73.36018793300288, 73.37589620972982, 73.39159357779057, 73.40728003718512,
        73.42295558791348, 73.43862022997568, 73.45427396337165, 73.4699167881014, 73.48554870416497, 73.50116971156235, 73.51677981029356, 73.53237900035857, 73.54796728175735, 73.56354465448995,
        73.57911111855633, 73.59466667395655, 73.61021132069057, 73.6257450587584, 73.64126788815999, 73.6567798088954, 73.67228082096464, 73.68777092436768, 73.70325011910454, 73.71871840517515,
        73.73417578257958, 73.74962225131783, 73.76505781138987, 73.78048246279575, 73.79589620553541, 73.81129903960884, 73.82669096501608, 73.84207198175717, 73.85744208983205, 73.87280128924074,
        73.88814957998322, 73.90348696205949, 73.91881343546957, 73.93412900021347, 73.94943365629116, 73.96472740370267, 73.98001024244796, 73.99528217252704, 74.01054319393997, 74.02579330668668,
        74.04103251076721, 74.05626080618156, 74.07147819292966, 74.08668467101155, 74.10188024042732, 74.11706490117685, 74.13223865326022, 74.14740149667735, 74.1625534314283, 74.17769445751303,
        74.19282457493158, 74.20794378368397, 74.22305208377013, 74.23814947519007, 74.25323595794383, 74.2683115320314, 74.2833761974528, 74.298429954208, 74.31347280229697, 74.32850474171975,
        74.34352577247635, 74.35853589456674, 74.37353510799097, 74.38852341274897, 74.40350080884077, 74.41846729626637, 74.43342287502578, 74.44836754511901, 74.46330130654606, 74.47822415930686,
        74.49313610340151, 74.50803713882992, 74.52292726559217, 74.53780648368821, 74.55267479311807, 74.56753219388172, 74.58237868597915, 74.59721426941043, 74.6120389441755, 74.62685271027438,
        74.64165556770702, 74.6564475164735, 74.67122855657375, 74.68599868800784, 74.70075791077575, 74.71550622487744, 74.73024363031293, 74.7449701270822, 74.75968571518531, 74.77439039462222,
        74.78908416539294, 74.80376702749744, 74.81843898093577, 74.83310002570785, 74.8477501618138, 74.86238938925352, 74.87701770802708, 74.89163511813437, 74.90624161957551, 74.92083721235046,
        74.9354218964592, 74.94999567190179, 74.9645585386781, 74.97911049678827, 74.99365154623221, 75.00818168701001, 75.02270091912159, 75.03720924256696, 75.05170665734612, 75.06619316345906,
        75.08066876090588, 75.09513344968646, 75.10958722980088, 75.12403010124909, 75.13846206403106, 75.15288311814685, 75.16729326359645, 75.1816925003799, 75.1960808284971, 75.2104582479481,
        75.22482475873291, 75.23918036085153, 75.25352505430398, 75.26785883909024, 75.28218171521029, 75.29649368266409, 75.31079474145172, 75.32508489157318, 75.33936413302844, 75.35363246581751,
        75.36788988994034, 75.38213640539702, 75.39637201218748, 75.41059671031176, 75.42481049976986, 75.43901338056175, 75.45320535268739, 75.46738641614688, 75.48155657094016, 75.49571581706728,
        75.50986415452819, 75.52400158332286, 75.53812810345137, 75.55224371491367, 75.56634841770979, 75.58044221183972, 75.59452509730347, 75.60859707410096, 75.62265814223228, 75.63670830169742,
        75.65074755249636, 75.66477589462913, 75.67879332809564, 75.69279985289599, 75.70679546903013, 75.72078017649811, 75.73475397529988, 75.74871686543544, 75.76266884690479, 75.77660991970795,
        75.79054008384493, 75.80445933931573, 75.81836768612031, 75.83226512425868, 75.84615165373087, 75.86002727453685, 75.87389198667667, 75.88774579015028, 75.90158868495767, 75.91542067109889,
        75.92924174857386, 75.9430519173827, 75.95685117752534, 75.97063952900176, 75.98441697181197, 75.99818350595602, 76.01193913143383, 76.02568384824546, 76.03941765639094, 76.0531405558702,
        76.06685254668322, 76.08055362883006, 76.09424380231071, 76.10792306712518, 76.12159142327347, 76.13524887075552, 76.14889540957141, 76.16253103972106, 76.17615576120456, 76.18976957402187,
        76.20337247817297, 76.21696447365782, 76.2305455604765, 76.24411573862902, 76.25767500811533, 76.27122336893545, 76.28476082108936, 76.29828736457706, 76.31180299939855, 76.32530772555388,
        76.33880154304303, 76.35228445186597, 76.3657564520227, 76.3792175435132, 76.39266772633756, 76.40610700049571, 76.41953536598767, 76.43295282281345, 76.44635937097298, 76.45975501046631,
        76.47313974129351, 76.4865135634545, 76.49987647694927, 76.51322848177783, 76.52656957794018, 76.53989976543637, 76.55321904426638, 76.56652741443018, 76.5798248759278, 76.59311142875917,
        76.60638707292433, 76.61965180842337, 76.6329056352562, 76.6461485534228, 76.6593805629232, 76.67260166375745, 76.68581185592545, 76.6990111394273, 76.71219951426293, 76.72537698043239,
        76.7385435379356, 76.75169918677263, 76.7648439269435, 76.77797775844816, 76.79110068128661, 76.80421269545887, 76.81731380096493, 76.8304039978048, 76.84348328597848, 76.85655166548595,
        76.86960913632724, 76.88265569850232, 76.89569135201118, 76.90871609685387, 76.92172993303039, 76.93473286054068, 76.94772487938478, 76.9607059895627, 76.97367619107438, 76.9866354839199,
        76.99958386809924, 77.01252134361236, 77.02544791045928, 77.03836356863998, 77.05126831815453, 77.06416215900286, 77.07704509118501, 77.08991711470095, 77.10277822955071, 77.11562843573422,
        77.12846773325161, 77.14129612210276, 77.15411360228775, 77.1669201738065, 77.17971583665904, 77.19250059084543, 77.20527443636563, 77.21803737321962, 77.23078940140736, 77.24353052092899,
        77.25626073178434, 77.26898003397356, 77.28168842749658, 77.29438591235338, 77.30707248854397, 77.31974815606837, 77.33241291492659, 77.34506676511863, 77.35770970664446, 77.3703417395041,
        77.38296286369753, 77.39557307922472, 77.40817238608578, 77.42076078428063, 77.4333382738093, 77.44590485467172, 77.45846052686797, 77.47100529039803, 77.4835391452619, 77.49606209145959,
        77.50857412899107, 77.52107525785631, 77.53356547805537, 77.54604478958827, 77.55851319245495, 77.57097068665546, 77.58341727218972, 77.5958529490578, 77.60827771725972, 77.62069157679544,
        77.63309452766497, 77.64548656986828, 77.65786770340536, 77.67023792827628, 77.68259724448102, 77.69494565201954, 77.70728315089188, 77.71960974109798, 77.73192542263796, 77.74423019551166,
        77.75652405971924, 77.76880701526058, 77.78107906213576, 77.7933402003447, 77.80559042988743, 77.817829750764, 77.8300581629744, 77.84227566651857, 77.85448226139651, 77.86667794760832,
        77.8788627251539, 77.89103659403328, 77.90319955424648, 77.9153516057935, 77.92749274867428, 77.93962298288885, 77.95174230843726, 77.96385072531949, 77.97594823353553, 77.98803483308531,
        78.00011052396896, 78.01217530618636, 78.02422917973759, 78.03627214462263, 78.04830420084149, 78.0603253483941, 78.07233558728053, 78.08433491750078, 78.09632333905485, 78.10830085194273,
        78.12026745616437, 78.13222315171983, 78.1441679386091, 78.15610181683216, 78.16802478638907, 78.17993684727976, 78.19183799950422, 78.20372824306249, 78.21560757795457, 78.22747600418047,
        78.23933352174019, 78.25118013063367, 78.263015830861, 78.27484062242208, 78.286654505317, 78.29845747954575, 78.31024954510828, 78.32203070200457, 78.33380095023468, 78.34556028979863,
        78.35730872069637, 78.36904624292792, 78.3807728564933, 78.3924885613924, 78.40419335762535, 78.41588724519211, 78.42757022409269, 78.43924229432706, 78.45090345589519, 78.46255370879716,
        78.47419305303292, 78.48582148860253, 78.49743901550592, 78.50904563374313, 78.5206413433141, 78.53222614421885, 78.54380003645745, 78.55536302002987, 78.5669150949361, 78.57845626117607,
        78.58998651874988, 78.60150586765751, 78.61301430789892, 78.62451183947418, 78.6359984623832, 78.64747417662603, 78.65893898220263, 78.6703928791131, 78.68183586735735, 78.69326794693539,
        78.70468911784722, 78.7160993800929, 78.72749873367233, 78.73888717858561, 78.75026471483268, 78.76163134241357, 78.77298706132821, 78.78433187157667, 78.79566577315897, 78.80698876607507,
        78.81830085032497, 78.82960202590864, 78.84089229282615, 78.85217165107743, 78.86344010066254, 78.87469764158145, 78.88594427383417, 78.89717999742068, 78.90840481234098, 78.91961871859512,
        78.93082171618305, 78.94201380510479, 78.9531949853603, 78.96436525694965, 78.97552461987279, 78.98667307412974, 78.99781061972048, 79.00893725664507, 79.02005298490339, 79.03115780449552,
        79.0422517154215, 79.0533347176813, 79.06440681127488, 79.07546799620222, 79.08651827246344, 79.09755764005838, 79.10858609898717, 79.11960364924978, 79.1306102908462, 79.14160602377636,
        79.15259084804036, 79.16356476363818, 79.17452777056981, 79.18547986883523, 79.19642105843441, 79.20735133936746, 79.21827071163425, 79.2291791752349, 79.24007673016935, 79.25096337643758,
        79.2618391140396, 79.27270394297544, 79.2835578632451, 79.29440087484856, 79.30523297778582, 79.3160541720569, 79.32686445766176, 79.3376638346004, 79.34845230287287, 79.35922986247915,
        79.36999651341925, 79.38075225569311, 79.39149708930078, 79.40223101424228, 79.41295403051758, 79.4236661381267, 79.43436733706962, 79.44505762734629, 79.45573700895677, 79.4664054819011,
        79.47706304617925, 79.48770970179119, 79.49834544873688, 79.5089702870164, 79.51958421662972, 79.53018723757687, 79.54077934985783, 79.55136055347258, 79.56193084842111, 79.57249023470344,
        79.5830387123196, 79.59357628126959, 79.60410294155336, 79.6146186931709, 79.62512353612227, 79.63561747040744, 79.64610049602642, 79.65657261297922, 79.66703382126583, 79.67748412088618,
        79.68792351184037, 79.69835199412837, 79.7087695677502, 79.7191762327058, 79.72957198899518, 79.73995683661842, 79.75033077557539, 79.76069380586624, 79.77104592749087, 79.78138714044933,
        79.79171744474154, 79.80203684036753, 79.81234532732738, 79.82264290562104, 79.83292957524851, 79.84320533620972, 79.8534701885048, 79.86372413213364, 79.8739671670963, 79.8841992933928,
        79.89442051102306, 79.90463081998713, 79.91483022028497, 79.92501871191668, 79.93519629488218, 79.94536296918146, 79.95551873481453, 79.96566359178145, 79.97579754008213, 79.98592057971665,
        79.99603271068496, 80.00613393298708, 80.01622424662297, 80.02630365159267, 80.03637214789622, 80.04642973553355, 80.0564764145047, 80.06651218480961, 80.07653704644837, 80.08655099942088,
        80.09655404372724, 80.10654617936738, 80.11652740634138, 80.1264977246491, 80.13645713429064, 80.14640563526602, 80.15634322757519, 80.16626991121818, 80.17618568619498, 80.18609055250552,
        80.1959845101499, 80.2058675591281, 80.2157396994401, 80.2256009310859, 80.23545125406547, 80.24529066837887, 80.25511917402609, 80.2649367710071, 80.27474345932193, 80.28453923897057,
        80.29432410995297, 80.30409807226916, 80.31386112591922, 80.32361327090304, 80.3333545072207, 80.34308483487213, 80.35280425385734, 80.3625127641764, 80.37221036582928, 80.38189705881594,
        80.39157284313642, 80.40123771879065, 80.41089168577871, 80.42053474410058, 80.43016689375627, 80.43978813474577, 80.44939846706903, 80.45899789072611, 80.46858640571698, 80.47816401204169,
        80.48773070970022, 80.49728649869253, 80.5068313790186, 80.5163653506785, 80.5258884136722, 80.53540056799976, 80.54490181366107, 80.55439215065617, 80.56387157898511, 80.57334009864785,
        80.5827977096444, 80.59224441197473, 80.60168020563889, 80.61110509063683, 80.62051906696855, 80.62992213463413, 80.63931429363349, 80.64869554396667, 80.6580658856336, 80.66742531863439,
        80.67677384296896, 80.68611145863734, 80.69543816563952, 80.70475396397553, 80.71405885364528, 80.72335283464885, 80.73263590698626, 80.74190807065749, 80.7511693256625, 80.7604196720013,
        80.76965910967392, 80.7788876386803, 80.78810525902055, 80.7973119706946, 80.80650777370242, 80.81569266804404, 80.82486665371944, 80.83402973072869, 80.84318189907175, 80.8523231587486,
        80.86145350975924, 80.8705729521037, 80.87968148578194, 80.888779110794, 80.89786582713988, 80.90694163481957, 80.91600653383303, 80.92506052418027, 80.93410360586138, 80.94313577887628,
        80.95215704322497, 80.96116739890748, 80.97016684592374, 80.97915538427382, 80.98813301395775, 80.99709973497546, 81.006055547327, 81.01500045101228, 81.02393444603139, 81.03285753238431,
        81.04176971007105, 81.0506709790916, 81.05956133944595, 81.06844079113405, 81.07730933415597, 81.08616696851173, 81.09501369420131, 81.10384951122468, 81.11267441958181, 81.12148841927275,
        81.13029151029751, 81.13908369265611, 81.14786496634848, 81.15663533137469, 81.16539478773463, 81.17414333542838, 81.18288097445598, 81.1916077048174, 81.2003235265126, 81.20902843954157,
        81.2177224439044, 81.22640553960098, 81.23507772663142, 81.24373900499565, 81.25238937469366, 81.26102883572547, 81.26965738809106, 81.2782750317905, 81.28688176682375, 81.2954775931908,
        81.30406251089163, 81.31263651992627, 81.3211996202947, 81.32975181199699, 81.33829309503305, 81.3468234694029, 81.35534293510655, 81.36385149214401, 81.37234914051528, 81.38083588022037,
        81.38931171125925, 81.39777663363192, 81.40623064733843, 81.4146737523787, 81.42310594875279, 81.43152723646072, 81.43993761550242, 81.4483370858779, 81.45672564758719, 81.46510330063033,
        81.47347004500726, 81.48182588071798, 81.49017080776248, 81.49850482614083, 81.50682793585294, 81.51514013689889, 81.52344142927863, 81.53173181299219, 81.54001128803954, 81.54827985442064,
        81.55653751213562, 81.56478426118439, 81.57302010156697, 81.58124503328331, 81.5894590563335, 81.59766217071744, 81.60585437643523, 81.61403567348684, 81.62220606187223, 81.63036554159139,
        81.63851411264436, 81.64665177503117, 81.6547785287518, 81.66289437380622, 81.67099931019443, 81.67909333791643, 81.68717645697221, 81.69524866736184, 81.70330996908528, 81.71136036214251,
        81.71939984653353, 81.72742842225834, 81.735446089317, 81.74345284770946, 81.75144869743572, 81.75943363849579, 81.7674076708896, 81.77537079461725, 81.78332300967871, 81.79126431607399,
        81.79919471380309, 81.80711420286592, 81.81502278326259, 81.82292045499308, 81.83080721805739, 81.83868307245548, 81.84654801818739, 81.85440205525306, 81.86224518365255, 81.87007740338585,
        81.87789871445297, 81.8857091168539, 81.8935086105886, 81.90129719565711, 81.90907487205943, 81.91684163979555, 81.92459749886551, 81.93234244926926, 81.94007649100676, 81.94779962407809,
        81.95551184848325, 81.96321316422221, 81.97090357129498, 81.97858306970151, 81.98625165944189, 81.99390934051603, 82.001556112924, 82.0091919766658, 82.01681693174139, 82.02443097815073,
        82.0320341158939, 82.0396263449709, 82.04720766538169, 82.0547780771263, 82.0623375802047, 82.06988617461691, 82.07742386036288, 82.0849506374427, 82.09246650585634, 82.09997146560376,
        82.10746551668497, 82.11494865909997, 82.12242089284881, 82.12988221793147, 82.13733263434791, 82.14477214209812, 82.1522007411822, 82.15961843160001, 82.16702521335166, 82.17442108643714,
        82.18180605085642, 82.18918010660947, 82.1965432536963, 82.20389549211698, 82.21123682187148, 82.21856724295976, 82.22588675538184, 82.23319535913772, 82.2404930542274, 82.24777984065089,
        82.25505571840823, 82.26232068749933, 82.26957474792421, 82.27681789968291, 82.28405014277543, 82.29127147720176, 82.29848190296188, 82.30568142005582, 82.31287002848354, 82.32004772824503,
        82.32721451934039, 82.33437040176953, 82.3415153755325, 82.34864944062922, 82.35577259705975, 82.36288484482414, 82.36998618392231, 82.37707661435427, 82.38415613612004, 82.3912247492196,
        82.39828245365295, 82.40532924942015, 82.41236513652113, 82.41939011495593, 82.42640418472449, 82.43340734582686, 82.44039959826308, 82.44738094203309, 82.45435137713692, 82.46131090357453,
        82.46825952134593, 82.47519723045112, 82.48212403089015, 82.489039922663, 82.49594490576963, 82.50283898021003, 82.5097221459843, 82.5165944030923, 82.52345575153417, 82.53030619130983,
        82.53714572241928, 82.54397434486252, 82.55079205863954, 82.55759886375041, 82.5643947601951, 82.57117974797357, 82.57795382708584, 82.58471699753191, 82.59146925931178, 82.59821061242549,
        82.60494105687299, 82.6116605926543, 82.61836921976935, 82.62506693821824, 82.63175374800095, 82.63842964911747, 82.64509464156781, 82.65174872535188, 82.65839190046982, 82.66502416692153,
        82.67164552470707, 82.67825597382641, 82.68485551427956, 82.69144414606647, 82.6980218691872, 82.70458868364175, 82.7111445894301, 82.71768958655228, 82.72422367500822, 82.730746854798,
        82.73725912592154, 82.7437604883789, 82.7502509421701, 82.75673048729509, 82.76319912375384, 82.7696568515464, 82.77610367067281, 82.78253958113301, 82.78896458292702, 82.7953786760548,
        82.80178186051641, 82.80817413631179, 82.81455550344101, 82.82092596190405, 82.82728551170088, 82.83363415283146, 82.83997188529587, 82.84629870909411, 82.85261462422618, 82.85891963069203,
        82.86521372849167, 82.8714969176251, 82.87776919809231, 82.88403056989337, 82.89028103302824, 82.89652058749694, 82.90274923329936, 82.9089669704356, 82.91517379890568, 82.92136971870958,
        82.92755472984727, 82.93372883231878, 82.93989202612404, 82.9460443112631, 82.95218568773602, 82.95831615554273, 82.96443571468323, 82.97054436515752, 82.9766421069656, 82.98272894010752,
        82.98880486458326],
    "PICKLEBALL": [],
};
exports.tableRPM2MPHMap = {
    "physics-20B-base": RPM2MPH_ATOM_20ABCDE,
    "physics-20C-base": RPM2MPH_ATOM_20ABCDE,
    "physics-20D-base": RPM2MPH_ATOM_20ABCDE,
    "physics-20E-base": RPM2MPH_ATOM_20ABCDE,
    "physics-30A-base": RPM2MPH_ATOM_30A,
};
