import * as React from "react";

import { AOI } from "@volley/shared/dist/apps/app-common-models";

import { StyleOptions } from "../../../../Admin/Clips/canvasDrawing";
import {
    drawCourt,
    drawBall,
    drawAOI,
} from "../../../../Admin/Clips/primitiveDrawing";

import { Serve } from "./models";

interface CourtProps {
    serves: Serve[];
    targetAOIs: AOI[];
}

function styleForServe(serve: Serve): StyleOptions {
    const base: StyleOptions = {
        strokeStyle: "white",
        lineWidth: 2,
        fillStyle: "white",
    };

    const color = serve.inAoi ? "yellow" : "red";

    return {
        ...base,
        strokeStyle: color,
        fillStyle: color,
    };
}

export default function Court({ serves, targetAOIs }: CourtProps) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Redraw the court
        drawCourt(ctx);

        // Draw all serves
        serves.forEach((result) => {
            drawBall(
                ctx,
                { ...result.position, z: 0 },
                1,
                styleForServe(result),
            );
        });

        // Draw all AOIs
        targetAOIs.forEach((aoi) => {
            drawAOI(ctx, aoi, 1, {
                strokeStyle: "yellow",
                lineWidth: 2,
                fillStyle: "rgba(255, 255, 0, 0.10)",
            });
        });
    }, [serves, targetAOIs]);

    return <canvas ref={canvasRef} width={300} height={400} />;
}
